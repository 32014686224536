/**
 * Fits width and height values into maxWidth and maxHeight, while maintaining the original aspect ratio.
 * @param aspectRatio optional. If supplied the aspect ratio is changed to this. (examples: 2/1, 16/9)
 */
export function calculateMediaSizeLimit(originalWidth: number, originalHeight: number, maxWidth: number, maxHeight: number, aspectRatio?: number) {
    if (aspectRatio) {
        if (originalHeight > originalWidth / aspectRatio) {
            originalHeight = originalWidth / aspectRatio
        } else if (originalWidth > originalHeight * aspectRatio) {
            originalWidth = originalHeight * aspectRatio
        }
    }

    let width = Math.min(originalWidth, maxWidth)
    let height = originalHeight / (originalWidth / width)

    if (height > maxHeight) {
        height = maxHeight
        width = originalWidth / (originalHeight / height)
    }

    return { width, height }
}
