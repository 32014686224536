import { Box, Card, CardContent, CardHeader, CardProps, SxProps, Theme, styled } from "@mui/material"
import { PropsWithChildren } from "react"
import { Icon } from "../Icons"

const StyledCard = styled(Card, {
    shouldForwardProp: (prop) => prop !== "disabled",
})<{ disabled?: boolean }>(({ theme, disabled }) => ({
    border: "1px solid transparent",
    transition: "all .2s ease",
    position: "relative",
    overflow: "unset", // this is for dropdown items

    ...(disabled && {
        opacity: 0.6,
        pointerEvents: "none",
        zIndex: -1,
        "&:after": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "inherit",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
        },
    }),
}))

const StyledCardContent = styled(CardContent)({
    padding: "1em",
    "&:last-child": {
        paddingBottom: "1em",
    },
})

const StyledCardHeader = styled(CardHeader)({
    padding: "1em 1em 0 1em",
})

export type SimpleCardProps = CardProps & {
    title?: string
    subHeader?: string & React.ReactNode
    icon?: string
    disabled?: boolean
    sxCard?: SxProps<Theme>
    sxHeader?: SxProps<Theme>
    sxContent?: SxProps<Theme>
}

export function SimpleCard(props: PropsWithChildren<SimpleCardProps>) {
    const { title, icon, disabled, subHeader, sxCard, sxHeader, sxContent, children, sx, ...rest } = props
    const avatar = icon ? <Icon name={icon} /> : null

    return (
        <Box padding="0.5em 0.75em" sx={sx} className={`simple-card${disabled ? " disabled" : ""}`}>
            <StyledCard disabled={disabled} sx={sxCard} {...rest}>
                <StyledCardHeader sx={sxHeader} avatar={avatar} title={title} subheader={subHeader} />
                <StyledCardContent tabIndex={disabled ? -1 : undefined} sx={sxContent}>
                    {children}
                </StyledCardContent>
            </StyledCard>
        </Box>
    )
}
