import { Typography as MuiTypography, TypographyProps as MuiTypographyProps, TypographyTypeMap, styled } from "@mui/material"
import { ElementType, ForwardedRef, ReactElement, forwardRef } from "react"

export type TypographyProps<D extends ElementType = TypographyTypeMap["defaultComponent"], P = {}> = MuiTypographyProps<D, P> & {
    maxLines?: number
}

const StyledTypography = styled(MuiTypography, {
    shouldForwardProp: (prop) => prop !== "maxLines",
})<TypographyProps>(({ maxLines }) => {
    if (maxLines === 1) {
        return {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        }
    }

    if ((maxLines ?? 0) > 1) {
        return {
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": `${maxLines}`,
            "-webkit-box-orient": "vertical",
        }
    }
})

// reexport components with "components" flag => https://mui.com/material-ui/guides/composition/#with-typescript
function TypographyComponent<C extends ElementType>(props: TypographyProps<C, { component?: C }>, ref: ForwardedRef<HTMLSpanElement>) {
    const { highPriority, ...rest } = props
    return <StyledTypography {...rest} color={props.color === "highlight" ? "highlight.main" : props.color} ref={ref} />
}

// Cast necessary as "forwardRef" is not working with a generic component (see https://stackoverflow.com/a/58473012/15647468)
export const Typography = forwardRef(TypographyComponent) as <C extends ElementType>(
    p: TypographyProps<C, { component?: C; ref?: ForwardedRef<HTMLElement> }>
) => ReactElement
