import { ActivatableModule, ModuleGroupId, ModuleOption, ModuleOptionType, ModulePackage } from "@tm/models"

export function getModuleGroupIconName(moduleGroupId: ModuleGroupId) {
    switch (moduleGroupId) {
        case "fastCalculator":
            return "fast-calculator"
        case "gpi":
            return "group"
        case "technicalData":
        case "technicalDataTruck":
            return "tech-data"
        default:
            return ""
    }
}

export function getModuleGroupTextId(moduleGroupId: ModuleGroupId, translateText: (key: string | number) => string) {
    switch (moduleGroupId) {
        case "fastCalculator": {
            return translateText(598)
        }
        case "gpi": {
            return translateText(1145)
        }
        case "technicalData": {
            return translateText(12987)
        }
        case "technicalDataTruck": {
            return translateText(12986)
        }
        case "damageCalculation": {
            return translateText(1718)
        }
        default:
            return ""
    }
}

export function getModuleCompareRoute(moduleGroupId: ModuleGroupId) {
    switch (moduleGroupId) {
        case "gpi":
            return "gpi"
        case "technicalData":
            return "td"
        case "technicalDataTruck":
            return "td-truck"
        case "fastCalculator":
            return "fast-calc-next"
        default:
            return ""
    }
}

export function getCombinedModuleOptionsAndPackages(module: ActivatableModule) {
    const defaultFullOptionModule = module.moduleOptions.find((moduleOption) => moduleOption.type === ModuleOptionType.Full)
    const defaultDemoOptionModule = module.moduleOptions.find((moduleOption) => moduleOption.type === ModuleOptionType.Demo)

    let fullModulePackage: ModulePackage | undefined
    let demoModulePackage: ModulePackage | undefined

    let fullOptionModule: ModuleOption | undefined
    let demoOptionModule: ModuleOption | undefined

    // demo 2.0 - packages
    if (defaultFullOptionModule?.active) {
        fullModulePackage = module.modulePackages?.[1]?.moduleAvailabilities.find(
            (x) => x.type === ModuleOptionType.Full && (x.active || x.activatable)
        )
            ? module.modulePackages?.[1]
            : module.modulePackages?.[0]

        if (module.modulePackages?.[1]?.moduleAvailabilities.find((y) => y.type === ModuleOptionType.Demo && (y.active || y.activatable))) {
            demoModulePackage = module.modulePackages?.[1]
        }

        fullOptionModule = fullModulePackage?.moduleAvailabilities.find((x) => x.type === ModuleOptionType.Full)

        demoOptionModule = demoModulePackage?.moduleAvailabilities.find((x) => x.type === ModuleOptionType.Demo)
    }

    demoOptionModule = demoOptionModule ?? defaultDemoOptionModule
    fullOptionModule = fullOptionModule ?? defaultFullOptionModule

    const isFullPackageActive = defaultFullOptionModule?.active && (!fullModulePackage || (fullModulePackage && fullOptionModule?.active))

    return {
        defaultFullOptionModule,
        defaultDemoOptionModule,
        fullModulePackage,
        demoModulePackage,
        fullOptionModule,
        demoOptionModule,
        isFullPackageActive,
    }
}

export function getModuleOptionsAndPackages(mainModule: ActivatableModule) {
    const defaultFullOptionModule = mainModule.moduleOptions.find((moduleOption) => moduleOption.type === ModuleOptionType.Full)
    const defaultDemoOptionModule = mainModule.moduleOptions.find((moduleOption) => moduleOption.type === ModuleOptionType.Demo)

    let activeFullModulePackage: ModulePackage | undefined
    let activeDemoModulePackage: ModulePackage | undefined

    let activatableFullModulePackage: ModulePackage | undefined
    let activatableDemoModulePackage: ModulePackage | undefined

    let fullOptionModule: ModuleOption | undefined
    let demoOptionModule: ModuleOption | undefined

    if (defaultFullOptionModule?.active) {
        if (mainModule.modulePackages?.[1]?.moduleAvailabilities.find((y) => y.type === ModuleOptionType.Full && y.activatable)) {
            activatableFullModulePackage = { ...mainModule.modulePackages[1] }
            activeFullModulePackage = { ...mainModule.modulePackages[0] }
            fullOptionModule = activatableFullModulePackage?.moduleAvailabilities.find((x) => x.type === ModuleOptionType.Full && x.activatable)
        } else {
            activeFullModulePackage = mainModule.modulePackages?.[1]?.moduleAvailabilities.find((x) => x.type === ModuleOptionType.Full && x.active)
                ? mainModule.modulePackages?.[1]
                : mainModule.modulePackages?.[0]
            fullOptionModule = activeFullModulePackage?.moduleAvailabilities.find((x) => x.type === ModuleOptionType.Full && x.active)
        }

        if (mainModule.modulePackages?.[1]?.moduleAvailabilities.find((y) => y.type === ModuleOptionType.Demo && y.active)) {
            activeDemoModulePackage = { ...mainModule.modulePackages[1] }
            demoOptionModule = activeDemoModulePackage?.moduleAvailabilities.find((x) => x.type === ModuleOptionType.Demo && x.active)
        }

        if (mainModule.modulePackages?.[1]?.moduleAvailabilities.find((y) => y.type === ModuleOptionType.Demo && y.activatable)) {
            activatableDemoModulePackage = { ...mainModule.modulePackages[1] }
            demoOptionModule = activatableDemoModulePackage?.moduleAvailabilities.find((x) => x.type === ModuleOptionType.Demo && x.activatable)
        }
    }

    fullOptionModule = fullOptionModule ?? defaultFullOptionModule
    demoOptionModule = demoOptionModule ?? defaultDemoOptionModule

    const isFullPackageActive = defaultFullOptionModule?.active && !activatableFullModulePackage

    return {
        defaultFullOptionModule,
        defaultDemoOptionModule,
        activeFullModulePackage,
        activeDemoModulePackage,
        activatableFullModulePackage,
        activatableDemoModulePackage,
        fullOptionModule,
        demoOptionModule,
        isFullPackageActive,
    }
}
