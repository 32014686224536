import { Stack, StackProps, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ArticleReferenceType, ModificationState } from "@tm/models"
import { concat } from "@tm/utils"
import { Tooltip } from "../../../generics/tooltip"
import { Icon } from "../../../generics/Icons"
import { Typography } from "../../../generics/typographie"

export type ArticleReferenceParms = {
    label: string
    referenceType: ArticleReferenceType
    modificationState?: ModificationState
    manufacturer?: string
}

type ClickableReferenceProps = StackProps & {
    isClickable?: boolean
    isRemoved?: boolean
}

const ClickableReference = styled(Stack, {
    shouldForwardProp: (prop: string) => !["isClickable", "isRemoved"].includes(prop),
})<ClickableReferenceProps>(({ theme, isClickable, isRemoved }) => ({
    svg: {
        marginRight: 2,
    },
    ...(isRemoved && {
        textDecoration: "line-through",
    }),
    ...(isClickable && {
        cursor: "pointer",
        "&:hover *": {
            color: theme.palette.primary.main,
        },
    }),
}))

ClickableReference.defaultProps = { direction: "row" }

type ReferenceProps = { reference: ArticleReferenceParms; onClick: (reference: ArticleReferenceParms) => void }

export function Reference(props: ReferenceProps) {
    const { onClick, reference } = props
    const { translateText } = useLocalization()

    const handleClick = () => onClick(reference)

    return (
        <ClickableReference onClick={handleClick} isClickable>
            {(() => {
                switch (reference.modificationState) {
                    case ModificationState.Added:
                        return (
                            <Tooltip title={translateText(886)}>
                                <Icon name="plus" size="12px" />
                            </Tooltip>
                        )
                    case ModificationState.Removed:
                        return (
                            <Tooltip title={translateText(895)}>
                                <Icon name="minus" size="12px" />
                            </Tooltip>
                        )
                    default:
                }
            })()}
            <Typography variant="label" noWrap>
                {concat(" ", reference.label, reference.manufacturer && `(${reference.manufacturer})`)}
            </Typography>
        </ClickableReference>
    )
}
