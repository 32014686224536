import { registerOutsideClick } from "@tm/utils"
import * as React from "react"

import { Icon } from ".."
import { bindMethodsToContext } from "../../helper";

export type Props = {
    name: string
    disabled?: boolean
    active?: boolean
    initiallyOpened?: boolean
    asDropDown?: boolean
    className?: string
    value?: string
    skin?: "dark"
    layout?: "holo"
    closeDropdownOnOutsideClick?: boolean
    renderHeaderAppendix?(): React.ReactNode
    onChangeOpen?(opened: boolean): void
    shouldComponentToggle?(e: React.MouseEvent<any>): boolean
}

export type State = {
    opened: boolean
}

export default class Collapsible extends React.Component<Props, State> {
    private dropDownRef: HTMLDivElement | null = null
    private removeOutsideClick?: () => void

    static defaultProps: Partial<Props> = {
        closeDropdownOnOutsideClick: true,
    }

    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)

        this.state = {
            opened: this.props.initiallyOpened || false,
        }
    }

    componentWillUnmount() {
        if (this.removeOutsideClick) {
            this.removeOutsideClick()
            this.removeOutsideClick = undefined
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.initiallyOpened != nextProps.initiallyOpened && nextProps.initiallyOpened != undefined && this.state.opened != nextProps.initiallyOpened) {
            this.setState({ opened: !!nextProps.initiallyOpened })
        }
    }

    private handleDropDownRef(ref: HTMLDivElement | null) {
        this.dropDownRef = ref
    }

    private handleDropDownToggleOpen(e: React.MouseEvent<any>) {
        if (this.removeOutsideClick) {
            this.removeOutsideClick()
            this.removeOutsideClick = undefined
        }

        if (!this.state.opened && this.dropDownRef && this.props.closeDropdownOnOutsideClick) {
            this.removeOutsideClick = registerOutsideClick(this.dropDownRef, this.handleToggleOpen)
        }

        this.handleToggleOpen(e)
    }

    private handleToggleOpen(e?: any) {
        const { shouldComponentToggle } = this.props
        const preventToggle = shouldComponentToggle && e && !shouldComponentToggle(e)

        if (preventToggle || this.props.disabled) return

        const opened = !this.state.opened

        this.setState({ opened })

        const { onChangeOpen } = this.props
        onChangeOpen && onChangeOpen(opened)
    }

    expand() {
        if (this.state.opened) return
        this.handleToggleOpen()
    }

    collapse() {
        if (!this.state.opened) return
        this.handleToggleOpen()

        if (this.removeOutsideClick) {
            this.removeOutsideClick()
            this.removeOutsideClick = undefined
        }
    }

    private renderHeader() {
        const { asDropDown, name, disabled, active, renderHeaderAppendix, value, layout } = this.props
        const { opened } = this.state

        if (asDropDown) {
            let className = "collapsible__header btn"
            if (layout == "holo") className += " btn--holo"
            if (disabled) className += " btn--disabled"
            else if (active) className += " is-active"

            return (
                <div className={className} onClick={this.handleDropDownToggleOpen}>
                    <Icon className="btn__icon ml-0" name={opened ? "up" : "down"} />
                    <div className="collapsible__caption btn__content">
                        <div className="collapsible__label">{name}</div>
                        {value && <div className="collapsible__value">{value}</div>}
                    </div>
                    <div className="is-right collapsible__appendix btn__content">
                        {renderHeaderAppendix && renderHeaderAppendix()}
                    </div>
                </div>
            )
        }

        return (
            <div className="collapsible__header" onClick={this.handleToggleOpen}>
                <Icon name={opened ? "up" : "down"} />
                <div className="collapsible__caption">
                    <div className="collapsible__label">{name}</div>
                    {!opened && value && <div className="collapsible__value">{value}</div>}
                </div>
                {renderHeaderAppendix && renderHeaderAppendix()}
            </div>
        )
    }

    render() {
        const { asDropDown, value, disabled, skin } = this.props
        const { opened } = this.state

        let className = "collapsible "
        className += disabled ? "collapsible--disabled " : ""
        className += asDropDown ? "collapsible--dropdown " : ""
        className += opened ? "collapsible--opened " : ""
        className += value ? "has-value " : ""
        className += skin ? `collapsible--${skin} ` : ""
        className += this.props.className || ""

        return (
            <div className={className} ref={asDropDown ? this.handleDropDownRef : undefined}>
                {this.renderHeader()}
                <div className="collapsible__content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
