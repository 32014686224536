import { Box, Button, Popover, styled } from "@mui/material"
import { Article } from "@tm/models"
import { useEffect } from "react"
import { ImageViewer as ImageViewerComponent } from "../imageViewer/index"
import { Icon } from "../../generics/Icons"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

const CloseButton = styled(Button)({
    position: "absolute",
    right: 2,
    top: 2,
})
CloseButton.defaultProps = {
    variant: "text",
    startIcon: <Icon name="close" />,
}

type ImageViewerProps = {
    article: Article
    anchorEl?: Element | null
    onClose(): void
}

export function ImageViewer(props: ImageViewerProps) {
    const { anchorEl, onClose } = props
    const { isLoading, data, setEnabled } = useDefaultArticleItemState((x) => x.articleImages)

    useEffect(() => {
        setEnabled(true)
    }, [])

    return (
        <Popover
            open
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            sx={{ ml: 1 }}
        >
            <CloseButton onClick={onClose} />
            <Box width={800} height={480}>
                <ImageViewerComponent images={data || []} isLoading={isLoading} />
            </Box>
        </Popover>
    )
}
