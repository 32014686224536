import { useMemo } from "react"
import { ErpIcon, ErpIconType } from "@tm/models"
import { useDefaultArticleItemState } from "../components/ArticleItem/ContextProviderStates/DefaultArticleItemState"

export function useErpSpecialIcons(position: ErpIconPosition) {
    const articleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos)
    const erpInfo = articleErpInfos?.default?.state === "success" ? articleErpInfos.default.response : undefined

    return useMemo(() => erpInfo?.specialIcons?.filter((x) => getIconPosition(x) === position) ?? [], [erpInfo?.specialIcons, position])
}

export type ErpIconPosition = "erpInfo" | "articleInfo"

function getIconPosition(icon: ErpIcon): ErpIconPosition {
    switch (icon.type) {
        case ErpIconType.SafetyDataSheets:
        case ErpIconType.Documents:
        case ErpIconType.ReplacementArticles:
            return "articleInfo"
        case ErpIconType.ProcurementCosts:
        case ErpIconType.Promotional:
        case ErpIconType.StockPart:
        case ErpIconType.ReplenishmentArticle:
        case ErpIconType.Deposit:
        case ErpIconType.Hazmat:
        case ErpIconType.NoOvernightShipping:
        case ErpIconType.NonRefundable:
        case ErpIconType.Discounted:
        case ErpIconType.TransportationCosts:
        default:
            return "erpInfo"
    }
}
