import { memo, SVGProps } from "react"

export const ManufacturerFallback = memo<SVGProps<SVGSVGElement>>((props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <path
            fillRule="evenodd"
            d="M20.52 16.11h2v-2.06h-2v2.06zm-3 0h2v-2.06h-2v2.06zm-3 0h1.98v-2.06h-1.99v2.06zm-4.65 1.52a1.66 1.66 0 01-.6.76l-.36.26a1.7 1.7 0 00-.3.3.87.87 0 00-.17.42v.32H7.3v-.38a1.74 1.74 0 01.4-1.02c.1-.12.22-.22.33-.3l.31-.25c.1-.09.17-.18.23-.28.06-.1.09-.23.08-.38 0-.26-.06-.45-.19-.58a.73.73 0 00-.52-.18.82.82 0 00-.4.09.79.79 0 00-.27.23c-.07.1-.12.22-.15.35-.04.13-.05.27-.05.43H5.82c.01-.3.06-.59.16-.84a1.95 1.95 0 011.06-1.1c.25-.1.53-.15.84-.15.4 0 .73.06 1 .17.26.1.48.24.64.4a1.5 1.5 0 01.46 1.06c0 .27-.04.5-.1.67zM7.2 21.5H8.5v-1.3H7.18v1.3zm16.88-4.6V8.48l-4.14 2.36V8.48l-4.58 2.36V8.48l-5.89 3.4H8.32V7H5.71v4.88H3v12.41h27V16.9h-5.93z"
        />
    </svg>
))
