import { atom } from "recoil"

export type BarcodeToastState = {
    show: boolean
}

export const barcodeToastState = atom<BarcodeToastState>({
    key: "parts.barcodeToast.barcodeToastState",
    default: { show: false },
})
