import { VoucherType } from "@tm/models"

export function mapVoucherTypeForUrl(type: VoucherType): string | undefined {
    switch (type) {
        case VoucherType.Order:
            return "orders"
        case VoucherType.CostEstimation:
            return "cost-estimation"
        case VoucherType.Return:
            return "returns"
        default:
            return undefined
    }
}

export function mapVoucherTypeFromUrl(type: string | undefined): VoucherType {
    switch (type) {
        case "orders":
            return VoucherType.Order
        case "returns":
            return VoucherType.Return
        case "cost-estimation":
        default:
            return VoucherType.CostEstimation
    }
}
