import { UIAHtmlAttribut, UITypes } from "../types"

const ButtonFake: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "button.fake",
}

const Button: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "button",
}

export const ButtonControls = {
    ButtonFake,
    Button,
}
