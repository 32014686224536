import { WholesalerArticleNumbersItemState } from "@tm/models"
import { useArticleItemState } from "."

export function useWholesalerArticleNumbersItemState(): WholesalerArticleNumbersItemState
export function useWholesalerArticleNumbersItemState<TReturnType>(selector: (value: WholesalerArticleNumbersItemState) => TReturnType): TReturnType
export function useWholesalerArticleNumbersItemState<TReturnType>(
    selector?: (value: WholesalerArticleNumbersItemState) => TReturnType
): WholesalerArticleNumbersItemState | TReturnType {
    return useArticleItemState((context: WholesalerArticleNumbersItemState) => {
        if (!selector) {
            return context
        }

        return selector(context)
    }) as WholesalerArticleNumbersItemState | TReturnType
}
