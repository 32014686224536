import { ErpPrice } from "@tm/models"
import { Fragment } from "react"
import { Box } from "@mui/material"
import { PriceValue } from "./PriceValue"
import { PriceGrid } from "./PriceGrid"
import { PriceAddition } from "./PriceAddition"
import { Typography } from "../../../generics/typographie"
import { ADDITIONAL_INFO_ICON_SIZE } from "../constants"

type OtherPricesProps = {
    prices: ErpPrice[]
}

export function OtherPrices({ prices }: OtherPricesProps) {
    return (
        <PriceGrid>
            {prices.map((price) => (
                <Fragment key={price.description}>
                    <Typography variant="label" lineHeight={1.2} maxLines={1} textAlign="right">
                        {/** @todo Clarify if "description" should only be displayed in the tooltip and "shortDescription" should be introduced. */}
                        {price.description}
                    </Typography>
                    <PriceValue price={price} variant="label" />
                    <Box width={ADDITIONAL_INFO_ICON_SIZE} />
                    {price.memos
                        ?.filter((memo) => memo.text)
                        .map((memo) => (
                            <PriceAddition key={memo.text} content={memo.text} />
                        ))}
                </Fragment>
            ))}
        </PriceGrid>
    )
}
