import { styled, IconButtonProps } from "@mui/material"
import { StyledComponent } from "@mui/styles"
import { IconButton as GenericIconButton } from "../../generics/button"

// export const IconButton = styled(MuiIconButton)({ padding: 0 }) as ComponentType<IconButtonProps>

export type CustomIconButtonProps = IconButtonProps & {
    hoverEffect?: boolean
}

export const IconButton = styled(GenericIconButton, { shouldForwardProp: (prop: string) => prop !== "hoverEffect" })<CustomIconButtonProps>(
    ({ theme, hoverEffect }) => ({
        padding: 0,
        "&:hover": hoverEffect
            ? {
                  color: theme.palette.primary.main,
              }
            : undefined,
    })
) as StyledComponent<CustomIconButtonProps>
