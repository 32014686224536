import { Article, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

const KEY = "parts_useArticlesByWholesalerArticleNumber"
export function useArticlesByWholesalerArticleNumber(wholesalerArticleNumber: string | undefined) {
    const { data } = useQuery(
        [KEY, wholesalerArticleNumber],
        () => {
            return Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByWholesalerArticleNumber)
                .subscribe(wholesalerArticleNumber)
                .load()
        },
        {
            staleTime: 4 * 60 * 60 * 1000, // 4 hours
            enabled: !!wholesalerArticleNumber,
        }
    )

    return { articles: data }
}
