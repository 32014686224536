import { UserInteractionLog, TmaEModule, ArticleListRouteKey } from "@tm/models"
import { TmaEventTracking } from "../.."
import { uniqueId } from "../../.."
import { AddToBasketTrigger } from "./models"
import { getCatalogOrigin } from "../../helper/origin"

export interface ArticleListViewOptions {
    compactView: boolean
    showArticleImages: boolean
    showVehicleRecordsFilters: boolean
}

export const AddToBasket = {
    AddCatalogPartToBasket: {
        GetUserInteractionLog: (positionNumber?: number, origin?: TmaEModule, skip?: boolean): UserInteractionLog | undefined => {
            const request = TmaEventTracking.getRequestData("ARTICLE_LIST_FILTERED")

            if (request?.searchStep) {
                return<UserInteractionLog>{
                    eventId: uniqueId(),
                    id: request.searchStep!.processId,
                    stepNumber: request.searchStep!.number,
                    fromPageNumber: request.articleList?.paging.pageIndex,
                    fromPositionNumber: positionNumber,
                    foundByQuery: request.querySearch?.query,
                    origin: origin ?? request.origin,
                    vin: request.vin
                }
            }

            return skip ? undefined : AddToBasket.AddCustomToBasket.GetUserInteractionLog(undefined, origin)
        },
    },
    AddUniPartToBasket: {
        GetUserInteractionLog: (positionNumber?: number, origin?: TmaEModule, skip?: boolean): UserInteractionLog | undefined => {
            const request = TmaEventTracking.getRequestData("PREMIUM_UNI_PARTS_SEARCH")

            if (request?.searchStep) {
                return<UserInteractionLog>{
                    eventId: uniqueId(),
                    id: request.searchStep.processId,
                    stepNumber: request.searchStep.number,
                    fromPageNumber: request.articleList?.paging.pageIndex,
                    fromPositionNumber: positionNumber,
                    foundByQuery: request.query,
                    origin: origin ?? request.origin
                }
            }

            return skip ? undefined : AddToBasket.AddCustomToBasket.GetUserInteractionLog(undefined, origin)
        },
    },
    AddCustomToBasket: {
        GetUserInteractionLog: (addToBasketTrigger?: AddToBasketTrigger, origin?: TmaEModule): UserInteractionLog => {

            origin = origin ?? getCatalogOrigin()

            // TODO - remove trigger if all cases have been taken into account
            switch (addToBasketTrigger) {
                case "import":
                case "customArticle":
                case "wholesalerPartList":
                case "oeArticle":
                    break
            }

            return<UserInteractionLog>{
                eventId: uniqueId(),
                id: uniqueId(),
                origin,
            }
        }
    },
    DeterminePartsListOriginByRouteKey: (routeKey: ArticleListRouteKey | undefined): TmaEModule | undefined => {
        switch (routeKey) {
            case "details-partslist":
            case "details-mainpartslist":
                return TmaEModule.ARTICLE_DETAILS_PARTSLIST
            case "details-mainaccessories":
            case "details-accessories":
                return TmaEModule.AERTICLE_DETAILS_ACCESSORYLIST
            case "details-erp-pawn-articles":
            case "details-erp-obligated-articles":
            case "details-erp-accessory-articles":
                return TmaEModule.ARTICLE_ERPINFOFORM
            case "details-erp-alternative-articles":
            case "details-erp-replacement-articles":
            case "details-erp-linked-articles":
            case "vehicle-ordered-articles":
                return TmaEModule.ARTICLE_DETAILS
            // case "parts-offers-list":
            // case "part-alternatives":
            // case "parts-vehicles-list":
            // case "parts-direct-list":
            //     return TmaEModule.ARTICLE_LIST
            // case "parts-universal-list":
            //     return TmaEModule.PREMIUMUNIPARTSSEARCH_ARTICLE_LIST
            default:
                return undefined
        }
    }
}
