import { ButtonProps, styled } from "@mui/material"
import { ComponentType } from "react"
import { Button } from "../../generics/button"
import { Icon } from "../../generics/Icons"

export const BasketButton = styled(Button)({
    width: 90,
    "&.MuiButton-bordered": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
}) as ComponentType<ButtonProps>
BasketButton.defaultProps = {
    color: "highlight",
    variant: "bordered",
    startIcon: <Icon name="cart" />,
}
