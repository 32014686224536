import { forwardRef, useMemo } from "react"
import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps as MuiDateTimePickerProps, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { Locale } from "date-fns"

import { TextField } from "../../textfield"
import { Icon } from "../../Icons"
import { getDateFnsLocale, LocalizationType } from "../helper/locale"

export type DateTimePickerProps = Omit<MuiDateTimePickerProps<Date, Date>, "renderInput"> & {
    localisation: LocalizationType
}

export const DateTimePicker = forwardRef<HTMLDivElement, DateTimePickerProps>(({ localisation, ...rest }, ref) => {
    const localLanguage = useMemo((): Locale => {
        return getDateFnsLocale(localisation)
    }, [localisation])

    const dateMask = useMemo((): { inputFormat: string; mask: string } => {
        /* use this to see how you should configure the date https://date-fns.org/v2.25.0/docs/format */
        switch (localLanguage.code) {
            case "de": {
                return {
                    inputFormat: "dd.MM.yyyy | HH:mm",
                    mask: "__.__.____ | __:__",
                }
            }

            default: {
                return {
                    inputFormat: "yyyy/MM/dd | HH:mm aaa",
                    mask: "____/__/__ | __:__ ___",
                }
            }
        }
    }, [localLanguage])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localLanguage}>
            <MuiDateTimePicker
                inputFormat={dateMask.inputFormat}
                mask={dateMask.mask}
                ref={ref}
                ampm={false}
                disableMaskedInput={false}
                renderInput={(params) => <TextField {...params} />}
                components={{
                    OpenPickerIcon: (props) => <Icon name="calendar" {...props} />,
                    ...(rest.components || {}),
                }}
                views={["year", "month", "day", "hours", "minutes"]}
                showToolbar
                openTo="year"
                {...rest}
            />
        </LocalizationProvider>
    )
})
