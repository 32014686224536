import { Article } from "@tm/models"
import { TmaEventTracking } from "../.."

const TMA_EVENT = "ARTICLE_LIST_FILTERED"
export interface ArticleListViewOptions {
    compactView: boolean
    showArticleImages: boolean
    showVehicleRecordsFilters: boolean
}

export const ArticleEventHelper = {
    Parts: {
        ImageClick: {
            Click: (article: Article, vehicleId?: string) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_IMAGE_CLICKED",
                    action: "article-image-click",
                    bundle: "parts",
                    component: "repository",
                    payload: { article, vehicleId },
                    source: "product-group-search",
                    type: "click",
                })
            },
        },
    },
}
