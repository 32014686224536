import { CacheProvider } from "@emotion/react"
import createCache from "@emotion/cache"
import { ThemeProvider as MuiThemeProvider } from "@mui/material"
import { ThemeProviderProps } from "@mui/material/styles/ThemeProvider"

export function ThemeProvider(props: ThemeProviderProps) {
    const stylesCache = createCache({ key: "nx", speedy: true })
    return (
        <CacheProvider value={stylesCache}>
            <MuiThemeProvider {...props}>{props.children}</MuiThemeProvider>
        </CacheProvider>
    )
}
