import { ContainerAction, IContainerBundleEntity, IContainerBundleLoader, IContainerBundleUpdater, IMergable, IModelActions, IModelContainer, IModelSubscription, IRegisterModel, SubscriptionEvents } from "../../models"
import { base64ToObject } from "../../tools/base64"
import { equals } from "../../tools/equals"
import RegisteredActionsContainer, { IRegisteredContainerActionsMergeObject } from "../actions/RegisteredActionsContainer"
import { BundleContainerFactory } from "../factories/BundleContainerFactory"
import { ModelSubscriptionFactory } from "../factories/ModelSubscriptionFactory"
import { IMergableSubscriptionsHolder, SubscriptionsHolder } from "../subscriptions/subscription-holder"
import { InternalEntity } from "./container-internal-entity"
import { ActionResolver, PromiseExecuter } from "./unregistered-container"

export type SubscriptionMergeObject<SModel> = {
    subscriptions: IMergableSubscriptionsHolder<SModel>
    listeners: { [type: string]: Array<(identifier: any[], object: any) => void> }
}

interface IRegistrationContainerMergeObject<SModel> extends
    SubscriptionMergeObject<SModel>,
    IRegisteredContainerActionsMergeObject { }

export interface IRegisteredContainerBundle<SModel> extends
    IModelContainer<SModel, ContainerAction>,
    IMergable<SubscriptionMergeObject<SModel>, IModelContainer<SModel, ContainerAction>> {
    registerActionContainer(actions: RegisteredActionsContainer): void
    registerModelActions(modelActions: IModelActions<SModel>): void
    isRegistered: boolean
}

/**
 * RegisteredContainerBUndle holds all the actions which are and will be registered
 * will manage at least load, save and store for model
 * and store results of actions related to their loading paramaters
 */
export class RegisteredContainerBundle<SModel> implements IRegisteredContainerBundle<SModel>{

    private internalStorage: Map<string, IContainerBundleEntity<SModel>>;
    private subscriptions: IMergableSubscriptionsHolder<SModel>;
    private actionContainer?: RegisteredActionsContainer;
    private pendingMergeObject?: IRegistrationContainerMergeObject<SModel>
    private pendingActionRequests: { [actionName: string]: Array<ActionResolver> }
    private listeners: { [type: string]: Array<(identifier: any[], object: any) => void> }
    public isRegistered: boolean = true


    private modelActions?: IModelActions<SModel>
    private storage: {
        createEntity: (identifier: string) => IContainerBundleEntity<SModel>
        loadEntity: (identifier: string) => IContainerBundleEntity<SModel> | undefined
    }
    private activeLoadingPromises: {
        [id: string]: Promise<SModel>
    }

    constructor(brm: IRegisterModel<SModel>) {
        this.listeners = {}
        this.pendingMergeObject = undefined
        this.pendingActionRequests = {}
        this.activeLoadingPromises = {}
        this.internalStorage = new Map<string, InternalEntity<SModel>>()
        this.subscriptions = ModelSubscriptionFactory.createSubscriptionHolder<SModel>()

        this.modelActions = brm.modelActions
        this.actionContainer = brm.containerActions ? BundleContainerFactory.createActionsContainer(brm.containerActions) : undefined

        this.storage = {
            createEntity: (identifier: string) => {

                // create internalstorageobject
                const internalstorageobject = new InternalEntity<any>() // TODO: Factory

                // save internalstorageobject
                this.internalStorage.set(identifier, internalstorageobject)
                return internalstorageobject as IContainerBundleEntity<any>
            },
            loadEntity: (identifier: string) => {
                return (this.internalStorage as any).get(identifier)
            }
        }
    }

    private loadEntity(identifier: string): Promise<SModel> {
        if (!this.modelActions) {
            return new Promise((resolve, reject) => reject("no registered model actions"))
        }

        // i know how to load the object

        // get internalstorageobject
        const internalstorageobject = this.storage.createEntity(identifier)

        // prepare loading
        const recoveredParams = base64ToObject(identifier)
        const params = (typeof recoveredParams === "object" ? recoveredParams : [recoveredParams])

        // go load
        let proms = this.modelActions
            .load(...params)
            .then((response: SModel) => {
                if (response && this.modelActions) {
                    for (let key in this.modelActions) {
                        if (key != "save" && key != "load") {
                            Object.defineProperty(response, key, {
                                value: this.modelActions[key]
                            })
                        }
                    }
                }

                internalstorageobject.result = response

                return response
            })

        return proms
    }

    /**
     * how to load an entity will be injected to each contract
     * Bundle these two actions to a new class as repo?!
     * so i can work with a composition
     */
    private load: IContainerBundleLoader<SModel> = (contractId: string): Promise<SModel> => {
        // wenn aktuell geladen wird, nimm das promise und gib es an die subscriptions zurück
        if (contractId in this.activeLoadingPromises) {
            return this.activeLoadingPromises[contractId]
        }

        const entity = this.storage.loadEntity(contractId)
        const result = entity?.result

        // erstelle einen active loading promise und lösche sobald geladen wurde
        if (!entity || !result || entity.isExpired(.05)) {
            this.activeLoadingPromises[contractId] = this.loadEntity(contractId)
                .finally(() => delete this.activeLoadingPromises[contractId])

            return this.activeLoadingPromises[contractId]
        }

        return Promise.resolve(result)
    }

    private save: IContainerBundleUpdater<SModel> = (contractId: string, changedModel: SModel): Promise<SModel> | void => {
        if (!this.modelActions?.save) return

        const storedEntity = this.internalStorage.get(contractId)

        if (storedEntity?.result && equals(storedEntity.result, changedModel)) {
            return Promise.resolve(changedModel)
        }
        else {
            return this.modelActions.save(changedModel)
        }
    }


    /**
     * subscribe on an entity
     * @param identifier parameter which are needed to identify the result e.g.: sgs.get<VehicleRecord>().subscribe("7861ada7-6b1e-4546-8cf1-1422bd49c0cd", 5600)
     * @returns ISubscriptionContract<SModel>, a contract which handles listeners, save and load the entity
     */
    public subscribe(...identifier: any[]): IModelSubscription<SModel> {
        const contract = this.subscriptions.create(this.load, this.save, ...identifier) // save contract
        Object.keys(this.listeners).forEach(event => {
            this.listeners[event].forEach(listener => {
                const hookListener = (object: any) => listener(contract.identifier, object)
                hookListener.isHook = true
                contract.addListener(event as SubscriptionEvents, hookListener)
            })
        })
        return contract
    }

    public addListener(event: SubscriptionEvents, listener: (identifier: any[], object: any) => void) {
        if (!this.listeners[event])
            this.listeners[event] = []

        Object.keys(this.subscriptions.contracts).forEach(key => {
            const contract = this.subscriptions.contracts[key]
            const hookListener = (object: any) => listener(contract.identifier, object)
            hookListener.isHook = true
            contract.addListener(event, hookListener)
        })
        this.listeners[event].push(listener)
    }

    public callAction(name: string, ...params: Array<any>): Promise<any> {
        if (!this.actionContainer) {
            console.warn("no actions registered, implement delayedActionRequests")
            return new Promise<unknown>((resolve, reject) => { reject() })
        }


        return this.actionContainer.callAction(name, ...params).then(
            response => {
                return new Promise<unknown>(resolve => {
                    resolve(response)
                })
            },
            () => {
                this.pendingActionRequests[name] = this.pendingActionRequests[name] || []

                return new Promise<unknown>((resolve, reject) => {
                    this.pendingActionRequests[name].push({ params, promiseExecutor: { resolve, reject } })
                })
            }
        )
    }

    public action(name: any): any {
        if (!this.actionContainer) {
            console.warn("no actions registered, implement delayedActionRequests")
            return () => new Promise((resolve, reject) => { reject() })
        }

        return this.actionContainer.action(name)
    }

    public registerModelActions(modelActions: IModelActions<SModel>) {
        this.modelActions = modelActions

        if (this.pendingMergeObject) {
            this.merge(this.pendingMergeObject)
            this.pendingMergeObject = undefined // check against mergeObject.loadRequests mb not all request could be loaded?!
        }
    }

    public registerActionContainer(actionContainer: RegisteredActionsContainer) {
        if (!this.actionContainer)
            this.actionContainer = actionContainer
        else
            this.actionContainer = this.actionContainer.merge(actionContainer)

        this.resolvePendingActionCalls()
    }

    private resolvePendingActionCalls() {
        if (!this.actionContainer) return

        for (let actionName in this.pendingActionRequests) {
            const pendingActions = this.pendingActionRequests[actionName]
            const actionCount = pendingActions.length
            for (let i = 0; i < actionCount; i++) {
                const pendingAction = pendingActions[i]

                if (pendingAction) {
                    this.actionContainer.callAction(actionName, pendingAction.params).then((response) => {
                        pendingAction.promiseExecutor.resolve(response)

                        // const storageEntity = this.storage.createEntity(StorageFactory.createIdentifier(name, ...pendingAction.params))
                        // storageEntity.result = response
                        delete this.pendingActionRequests[actionName][i]
                    })
                }
            }
        }
    }

    public resolveLoadRequets(pendingRequests: { [key: string]: Array<PromiseExecuter<SModel>> }) {
        (this.subscriptions as SubscriptionsHolder<SModel>).triggerLoad(pendingRequests)
    }

    public merge(mergeObject: IRegistrationContainerMergeObject<SModel>) {

        if (this.modelActions) {
            this.subscriptions = this.subscriptions.merge({
                subscriptionsHolder: mergeObject.subscriptions,
                loader: this.load,
                updater: this.save
            })
            this.listeners = mergeObject.listeners
        }
        else
            this.pendingMergeObject = mergeObject

        this.pendingActionRequests = { ...this.pendingActionRequests, ...mergeObject.pendingActionRequests }

        if (this.actionContainer)
            this.resolvePendingActionCalls()

        return this
    }
}
