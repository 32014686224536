import { WorkTaskDetailsReadModel } from "@tm/models";
import { encodeUniqueId, decodeUniqueId } from "@tm/utils";
import { WorkTaskProvider } from "./WorkTaskProvider";

export function openWorkTask(this: WorkTaskProvider, workTaskId: string, subPage?: string, skipRedirect?: boolean, alreadyLoadedWorkTask?: WorkTaskDetailsReadModel): void {
    let encodedWorkTaskId = workTaskId
    let decodedWorkTaskId: string | undefined = workTaskId

    if (workTaskId.length === 36) {
        encodedWorkTaskId = encodeUniqueId(workTaskId)
    }
    else if (workTaskId.length === 22) {
        decodedWorkTaskId = decodeUniqueId(workTaskId)
    }

    const { workTaskInfo } = this.state

    if (workTaskInfo && workTaskInfo.id === decodedWorkTaskId) {
        this.loadWorkTaskInfo(workTaskInfo.id, alreadyLoadedWorkTask)
    }

    if (skipRedirect !== true) {
        let url = "/" + encodedWorkTaskId

        if (subPage) {
            if (subPage.indexOf("/") !== 0) {
                url += "/"
            }

            url += subPage
        }

        this.props.history.push(url)
    }
}
