import { useMemo } from "react"
import { AvailabilityStatus, ErpInformation, ErpSystemConfig, SuccessArticleErpInfo } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Stack, styled } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { useArticleItemState } from "../ContextProviderStates"

const DEFAULTGAP = 0.5

type AvailabilitiesProps = {
    defaultArticleErpInfo?: SuccessArticleErpInfo
    alternativeArticleErpInfo?: SuccessArticleErpInfo
    isCompact?: boolean
}

export function Availabilities({ defaultArticleErpInfo, alternativeArticleErpInfo, isCompact }: AvailabilitiesProps) {
    const { translateText } = useLocalization()
    const AvailabilityComponent = useArticleItemState((x) => x.AvailabilityComponent)
    const handleClickAvailability = useDefaultArticleItemState((x) => x.handleClickAvailability)

    const Availability = useMemo(() => styled(AvailabilityComponent)({ "> div": { display: "block" } }), [AvailabilityComponent])
    const info = useDefaultArticleItemState((x) => x.extendedDeliveryInfo?.info)

    const erpInfos = useMemo(() => {
        const list: {
            availability: ErpInformation["availability"]
            requestedQuantity?: number
            warehouses?: ErpInformation["warehouses"]
            tour?: ErpInformation["tour"]
            erpSystem?: ErpSystemConfig
            itemId?: string
        }[] = []
        if (defaultArticleErpInfo) {
            // teccom request are always from the "default ERP system, useArticleExtendedDeliveryInfo
            if (info) {
                list.push({ ...info, erpSystem: defaultArticleErpInfo.erpSystem })
            } else {
                list.push({ ...defaultArticleErpInfo.response, erpSystem: defaultArticleErpInfo.erpSystem })
            }
        } else {
            list.push({ availability: { type: AvailabilityStatus.Unknown, shortDescription: translateText(12684) } })
        }
        if (alternativeArticleErpInfo) {
            list.push({ ...alternativeArticleErpInfo.response, erpSystem: alternativeArticleErpInfo.erpSystem })
        }
        return list
    }, [defaultArticleErpInfo, alternativeArticleErpInfo, translateText, info])

    return (
        <Stack gap={DEFAULTGAP} flexWrap="wrap" flexDirection="row">
            {erpInfos.map((info, index) => (
                <Availability
                    onClick={(useSpecialProcurement) => handleClickAvailability(info.erpSystem, useSpecialProcurement)}
                    key={`availability_${index}`}
                    {...info}
                    showSmallIcons
                    isCompact={isCompact}
                />
            ))}
        </Stack>
    )
}
