import { useQuery } from "react-query";
import { Decimal } from "decimal.js-light";
import { AmountItem } from ".";

export function useItems(maxAmount: number, minAmount: number, division: number, unit: string | undefined) {
    const { data } = useQuery(
        ["controls_amount-field_items", maxAmount, minAmount, division, unit],
        () => {
            return Promise.resolve() // Force async execution
                .then(() => {
                    const items: Array<AmountItem> = []

                    let itemValue = new Decimal(division)

                    while (itemValue.lessThanOrEqualTo(maxAmount) && items.length < 10000) {
                        if (itemValue.greaterThanOrEqualTo(minAmount)) {
                            items.push({
                                value: itemValue.todp(2).toNumber(),
                                unit,
                                division,
                                maxAmount,
                                minAmount,
                            })
                        }

                        itemValue = itemValue.plus(division)
                    }

                    return items
                })
        },
        { staleTime: Infinity }
    )

    return { items: data }
}