import { PropsWithChildren } from "react"
import { applyMiddleware, compose, createStore, Action, MiddlewareAPI, Dispatch } from "redux"
import thunk from "redux-thunk"
import { BundleComponent, useMicro } from "@tm/morpheus"
import { CommunicationChannel, CommunicationContext } from "../channel"

export type TransmitAction = Action & {
    targetComponentId?: string
}

export function getComponentFromBundle<C extends BundleComponent>(
    bundleComponent: C,
    communicationChannel?: CommunicationChannel,
    communicationId?: string
): NonNullable<C["component"]> {
    const { transmit, receive, component: Component } = bundleComponent
    let { reduce } = bundleComponent

    if (!reduce && transmit) {
        reduce = (state: unknown, action: unknown) => state
    }

    if (reduce) {
        const transmitter = (api: MiddlewareAPI) => (next: Dispatch<Action>) => (action: TransmitAction) => {
            if (transmit) {
                const transmitAction = transmit(action)

                if (transmitAction) {
                    communicationChannel?.forEach((channelItem) => {
                        const [targetReceive, targetStore] = channelItem

                        // don't publish into the same component from where the transmit comes
                        if (targetReceive === receive) {
                            return
                        }

                        // receive only into targetComponentId if it's set
                        if (!!action.targetComponentId && action.targetComponentId !== communicationId) {
                            return
                        }

                        targetReceive(transmitAction, targetStore.dispatch, targetStore.getState)
                    })
                }
            }

            return next(action)
        }

        const extReduce = (state: unknown, action: Action<string>) => {
            if (typeof action != "function" && action.type === "@@redux/INIT") {
                return reduce!(undefined, action)
            }

            return reduce!(state, action)
        }

        const store = createStore(extReduce, compose(applyMiddleware(thunk, transmitter)))
        if (receive) {
            communicationChannel?.push([receive, store])
        }

        return function ReduxStoreWrapper(props: PropsWithChildren<unknown>) {
            // Should be great to be able to start with an empty state, but NEXT was built to use the old state
            // useMemo(() => {
            //     store.dispatch({type: "@@redux/INIT"})
            // }, [])

            const { renderMicro } = useMicro()

            return (
                <CommunicationContext.Provider value={communicationChannel}>
                    <Component {...props} store={store} renderMicro={renderMicro} />
                </CommunicationContext.Provider>
            )
        } as NonNullable<C["component"]>
    }

    return Component
}
