import { memo } from "react"
import { IconButton } from "../../generics/button"
import { Icon } from "../../generics/Icons"

type Props = {
    handlePreviousImage(): void
    handleNextImage(): void
    handleZoomIn(): void
    handleZoomOut(): void
    previousEnabled: boolean
    nextEnabled: boolean
    zoomInEnabled: boolean
    zoomOutEnabled: boolean
    onClose?(): void
}

export const ImageViewerActions = memo<Props>(
    ({
        handleNextImage,
        handlePreviousImage,
        onClose,
        handleZoomOut,
        handleZoomIn,
        nextEnabled,
        previousEnabled,
        zoomInEnabled,
        zoomOutEnabled,
    }: Props) => {
        return (
            <div className="image-viewer__actions">
                <IconButton onClick={handlePreviousImage} disabled={!previousEnabled}>
                    <Icon name="prev" />
                </IconButton>

                <IconButton onClick={handleNextImage} disabled={!nextEnabled}>
                    <Icon name="next" />
                </IconButton>

                <IconButton onClick={handleZoomIn} disabled={!zoomInEnabled}>
                    <Icon name="plus" />
                </IconButton>
                <IconButton onClick={handleZoomOut} disabled={!zoomOutEnabled}>
                    <Icon name="minus" />
                </IconButton>
                {onClose && (
                    <IconButton className="close-btn" onClick={onClose}>
                        <Icon name="close" />
                    </IconButton>
                )}
            </div>
        )
    }
)
