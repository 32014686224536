import * as React from 'react'
import { Button } from '..'
import { AllowedFileSelectionTypes, FileSelectError, getAcceptAttribute, getMimeTypes } from '../../helper/fileTypes'
import { ButtonProps } from '../button'

export type FileSelectButtonProps = {
    /** To use more filetypes, extend the mapping object in @tm/controls/file-select-button */
    allowedFileTypes: AllowedFileSelectionTypes
    /** This will be passed to the button. onClick will be overwritten. */
    buttonProps?: ButtonProps
    /** In kB */
    maxFileSize: number
    children?: React.ReactNode

    onOpenFileDialog?(): void
    onLoad(fileData: string, fileName: string, fileMimeType: string): void
    onError?(type: FileSelectError): void
}

export const FileSelectButton = (props: FileSelectButtonProps) => {
    const ref = React.useRef<HTMLInputElement>(null)

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files && files.length) {
            const file = files[0]

            if (file) {
                if (getMimeTypes(props.allowedFileTypes).indexOf(file.type) <= -1) {
                    props.onError?.("WRONG_FILE_TYPE")
                }
                else if (file.size > (1024 * props.maxFileSize)) {
                    props.onError?.("FILE_TOO_BIG")
                }
                else {
                    let reader = new FileReader()

                    reader.onload = (e: any) => {
                        if (e.target && e.target.result) {
                            props.onLoad(e.target.result, file.name, file.type)

                            if (ref.current)
                                ref.current.value = ""
                        }
                    }

                    reader.readAsDataURL(file)
                }
            }
        }
    }

    const showFileDialog = () => {
        props.onOpenFileDialog?.()
        ref.current && ref.current.click()
    }

    return (
        <>
            <input type="file" accept={getAcceptAttribute(props.allowedFileTypes)} style={{ display: "none" }} ref={ref} onChange={onChangeFile} />
            <Button {...props.buttonProps} onClick={showFileDialog}>{props.children}</Button>
        </>
    )
}