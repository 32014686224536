/** *
 * @description slightly modified version of the original, should be a fast hashing method
 * @source https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 */

export function generateHash(value: string) {
    let hash = 0
    if (!value.length) {
        return hash
    }
    for (let i = 0, imax = value.length, n; i < imax; ++i) {
        n = value.charCodeAt(i)
        hash = (hash << 5) - hash + n
        hash &= hash // Convert to 32-bit integer
    }
    return Math.abs(hash)
}
