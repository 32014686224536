import { PromptProps } from "./models";
import * as React from "react"
import Dialog from ".";
import { ReactNode } from "react"

export type WarningPromptProps = {
    cancelButtonText?: string
    confirmationButtonText: string
    doNotCloseOnConfirm?: boolean
    layout?: "stretch"
    ref: any
    text: string | ReactNode
    time?: Date
    hideCloseButton?: boolean
    onCancel?(e: Event): void
    onClose?(e: Event): void
    onConfirm?(e: Event): void
}

export default class WarningPrompt extends React.PureComponent<WarningPromptProps>{
    dialogRef: React.RefObject<Dialog> = React.createRef()

    show() {
        this.dialogRef.current?.show()
    }

    render() {
        return <Dialog
            {...this.props}
            skin="warning"
            confirmButtonText={this.props.confirmationButtonText}
            cancelButtonText={this.props.cancelButtonText}
            iconName="attention-dark"
            ref={this.dialogRef}
            hideCloseButton={this.props.hideCloseButton}
        >
            {this.props.children}
        </Dialog>
    }
}
