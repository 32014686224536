import { ReplaceButtonBundle, EReplaceButtonBundle } from "@tm/models"

/**
 * please add a description if you know this function
 * @param value
 * @returns
 */
export function isBundleWithReplaceButtonMicro(value: string): value is ReplaceButtonBundle {
    return (
        value === EReplaceButtonBundle.DMGDat ||
        value === EReplaceButtonBundle.FastCalculatorNext ||
        value === EReplaceButtonBundle.FastService ||
        value === EReplaceButtonBundle.DMGEurotax
    )
}
