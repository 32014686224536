import * as React from "react"
import Morpheus from "..";
import { TemplateRenderer, TemplateIdentifier } from "../models/bundle";
import { ComponentIdentifier } from "../models/configuration";


type TemplateRendererProps = {
    template: TemplateIdentifier
}

export function withTemplate<T extends {}>(target: ComponentIdentifier, ComponentClass: React.ComponentClass<T>): React.ComponentClass<T & TemplateRendererProps> {

    const originalRender = ComponentClass.prototype.render
    ComponentClass.prototype.rendererIdentifier = target
    ComponentClass.prototype.render = function() {
        return Morpheus.renderTemplate(this.props.template.bundle, this.props.template.template, this) || originalRender()
    }

    class Cx extends React.Component<T & TemplateRendererProps> {
        render() {
            return React.createElement(ComponentClass, this.props as Readonly<T>)
        }
    }

    return Cx
}
