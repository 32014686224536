import { ActiveVehicleDataProviders, RegisteredModels, ViewState } from "@tm/models"
import { Container } from "@tm/nexus"
import { useEffect, useState } from "react"
import { atomFamily, useRecoilStateLoadable } from "recoil"
import { encodeUniqueId } from "../.."

function getSubscription(workTaskId: string) {
    const key = `${encodeUniqueId(workTaskId)}__ACTIVE_VEHICLE_DATA_PROVIDERS`
    const subscription = Container.getInstance<ViewState<ActiveVehicleDataProviders | undefined>>(RegisteredModels.ViewState).subscribe(key)
    return { key, subscription }
}

const ActiveVehicleDataProvidersState = atomFamily<ActiveVehicleDataProviders | undefined, string>({
    key: "activeVehicleDataProvidersForWorkTask",
    default: undefined,
})

// TODO: Currently every newly rendered component starts an API request.
// Maybe change this to a recoil atomFamily with a default value from a selector?

export function useActiveVehicleDataProviders(workTaskId: string | undefined) {
    const [recoilState, setRecoilState] = useRecoilStateLoadable(ActiveVehicleDataProvidersState(workTaskId ?? ""))
    const [isLoading, setIsLoading] = useState(recoilState.state !== "hasValue")

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        const { subscription } = getSubscription(workTaskId)

        const unsubs = [
            subscription.addListener(
                "loaded",
                (response) => {
                    setRecoilState(response.value)
                    setIsLoading(false)
                },
                () => {
                    setIsLoading(false)
                }
            ),
            subscription.addListener("updated", (response) => {
                setRecoilState(response.value)
                setIsLoading(false)
            }),
        ]

        subscription.load()

        return () => {
            unsubs.forEach((unsub) => unsub())
        }
    }, [workTaskId, setRecoilState])

    return {
        isLoading,
        activeProviders: recoilState.state === "hasValue" ? recoilState.contents : undefined,
    }
}
