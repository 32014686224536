import { useCallback } from "react"
import { useHistory, useParams } from "react-router"
import { encodeUniqueId } from "../.."
import { renderRoute } from "../../helpers/url"

type InputParams = {
    moduleName?: string
    partsDetailsUrl: string
    productGroupId?: number
    supplierArticleNumber?: string
    supplierId?: number
    workTaskId?: string
    openModal?(modalIndex: "1", url: string): void
}

/**
 * @deprecated Use `useOpenArticleDetailsClickHandle` instead. When only useOpenArticleDetailsClickHandle is used in ALL bundles, rename it to useOpenArticleDetails
 */
export function useOpenArticleDetails({
    moduleName,
    partsDetailsUrl,
    productGroupId,
    supplierArticleNumber,
    supplierId,
    workTaskId,
    openModal,
}: InputParams) {
    const history = useHistory()
    const matchParams = useParams<{ workTaskId?: string }>()

    const handleOpenArticleDetails = useCallback(
        (subPage?: string, scrollTo?: string) => {
            if (supplierId && supplierArticleNumber && productGroupId) {
                const url = renderRoute(partsDetailsUrl, {
                    ...matchParams,
                    module: moduleName,
                    productGroupId,
                    supplierId,
                    supplierArticleNo: supplierArticleNumber ? encodeURIComponent(supplierArticleNumber) : undefined,
                    partsDetailsSubPage: subPage ? encodeURIComponent(subPage) : undefined,
                    scrollTo: subPage && scrollTo ? encodeURIComponent(scrollTo) : undefined,
                    workTaskId: workTaskId ? encodeUniqueId(workTaskId) : matchParams.workTaskId,
                })
                if (openModal) {
                    openModal("1", url)
                } else {
                    history.push(url)
                }
            }
        },
        [history, matchParams, partsDetailsUrl, supplierId, supplierArticleNumber, moduleName, productGroupId, workTaskId, openModal]
    )

    return handleOpenArticleDetails
}
