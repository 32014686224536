import { UIAHtmlAttribut, UITypes } from "../types"

const ArticelListHeader: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "articlelist.header",
}

const ArticelListHeaderTechnicalData: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "articlelist.header.technicalData",
}

const ArticelListHeaderTechnicalDataTooltip: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "articlelist.header.technicalData.tooltip",
}

const ArticelListHeaderTechnicalDataHaynes: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "articlelist.header.technicalData.button",
}

const ArticelListHeaderTechnicalDataTecrmi: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "articlelist.header.technicalData.button",
}

const ArticelListHeaderToolRental: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "articlelist.header.toolrental",
}

const ArticelListHeaderFastcalc: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "articlelist.header.fastcalculator",
}

export const ArticleList = {
    ArticelListHeader,
    ArticelListHeaderTechnicalData,
    ArticelListHeaderTechnicalDataTooltip,
    ArticelListHeaderTechnicalDataHaynes,
    ArticelListHeaderTechnicalDataTecrmi,
    ArticelListHeaderToolRental,
    ArticelListHeaderFastcalc,
}
