import { Box, Stack } from "@mui/material"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../generics/typographie"
import { Icon } from "../../generics/Icons"

export function Descriptions() {
    const { description, missingVehicleConnectionTitle, additionalDescriptions } = useDefaultArticleItemState((x) => x.displayArticle.description)

    return (
        <Box>
            {additionalDescriptions?.map((additionalDescription) => (
                <Stack key={additionalDescription} direction="row" gap={0.5}>
                    <Icon name="plus" />
                    <Typography variant="body2">{additionalDescription}</Typography>
                </Stack>
            ))}
            {!!description && <Typography variant="body2">{description}</Typography>}
            {missingVehicleConnectionTitle && (
                <Box>
                    <Typography variant="body2">{missingVehicleConnectionTitle}</Typography>
                    <Icon name="missing-vehicle-connection" size="l" />
                </Box>
            )}
        </Box>
    )
}
