import { Stack } from "@mui/material"
import { CostEstimationButton } from "./CostEstimationButton"
import { AddOeToBasket } from "./AddOeToBasket"
import { useOeArticleItemState } from "../ContextProviderStates/OeArticleItemState"
import { ReplaceButton } from "./ReplaceButton"

export default function OeTransactions() {
    const { hideCostEstimationButton, highlightCostEstimationButton, hideAddToBasketButton } = useOeArticleItemState((x) => x.options)
    const showReplaceButton = useOeArticleItemState((x) => x.showReplaceButton)
    const handleReplaceArticle = useOeArticleItemState((x) => x.handleReplaceArticle)

    return (
        <Stack spacing={0.5} direction="row" justifyContent="end">
            {!showReplaceButton ? !hideAddToBasketButton && <AddOeToBasket /> : <ReplaceButton onClick={handleReplaceArticle} />}

            {!hideCostEstimationButton && (
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                <CostEstimationButton onAddToCostEstimation={() => {}} highlightCostEstimationButton={highlightCostEstimationButton} disabled />
            )}
        </Stack>
    )
}
