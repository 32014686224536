import { memo, SVGProps } from "react"

export const BikeFallback = memo<SVGProps<SVGSVGElement>>((props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <path
            fillRule="evenodd"
            d="M24.33 22.46a2.98 2.98 0 01-2.96-2.99 2.96 2.96 0 112.96 2.99zm-6.09-7.38a1.91 1.91 0 01-.72.9l-.43.32a2 2 0 00-.35.36c-.1.13-.17.3-.2.5v.38H15.2v-.45c.02-.29.07-.53.16-.72a2.1 2.1 0 01.7-.86 6 6 0 00.37-.3c.11-.1.2-.21.28-.33.07-.12.1-.27.1-.45 0-.31-.08-.54-.23-.68a.86.86 0 00-.63-.22.98.98 0 00-.47.1.93.93 0 00-.32.28c-.08.12-.15.26-.19.41a2 2 0 00-.06.5h-1.47c0-.35.07-.68.18-.98a2.24 2.24 0 011.25-1.3 3.16 3.16 0 012.19.01c.33.12.58.29.78.48a1.77 1.77 0 01.54 1.26c0 .32-.04.58-.13.79zm-3.2 4.6h1.58v-1.54h-1.58v1.55zm-3.94 1.14c.15-.52.21-1.06.17-1.63l.68-.12c.15.57.24 1.16.27 1.75H11.1zm-3.35 1.43a2.96 2.96 0 01-2.62-5.3c1.27-.76 3-.44 3.81.73l.01.03-2.73 1.12.31 1.21 3.07-.55c.13.99-.73 2.3-1.85 2.76zm20.62-5.05a4.74 4.74 0 00-3.43-2.32c.26-.04.51-.06.76-.08.58-.03.94-.46.85-1.04a14.9 14.9 0 00-.15-.87c-.2-.97-.7-1.8-1.13-2.72a2.43 2.43 0 00-.79-.89c.04-.51.05-1.28-.24-1.28-.27 0-.5.4-.61.79-.6-.29-1.22-.48-1.62-.62a.82.82 0 00-1.05.5c-.16.42.04.87.46 1.07l1.38.51c.35.16.66.4.9.69-.18.07-.32.16-.47.2l-1.07.19-.63-1.13-.82.4a5.58 5.58 0 00-.52-.21c-1-.34-2.7-.43-3.75-.46-1.59-.04-4.16 1.01-4.61 2.45-.03.08-.01.23-.1.25C9.5 13 8.57 11.97 8 10.92a.84.84 0 00-.76-.47H6.7c-2-.02-1.18-.04-3.17-.06-.66 0-1.05.48-.88 1.12.09.33.93.11 1.13.39C4.6 13 5 13.47 6.35 13.64c.42.05.84.03 1.25.11a5.36 5.36 0 013.5 3.07l-.52.21a4.66 4.66 0 00-8.33 3.99 4.66 4.66 0 008.72.17l.84.91h6.58c.56 0 .72-.36.72-.94 0-.86.2-1.7.55-2.49l.13-.24a4.7 4.7 0 003.26 5.53 4.64 4.64 0 005.32-6.76z"
        />
    </svg>
))
