import { CacheKey, AjaxRequest } from "."

export const RequestCache: Record<CacheKey, Promise<unknown>> = {}

export function handleRequestCaching<Res>(doAjaxRequest: AjaxRequest<Res>, key: CacheKey): Promise<Res> {
    RequestCache[key] = RequestCache[key] ?? doAjaxRequest()
    RequestCache[key].finally(() => deleteFromRequestCache(key))

    return RequestCache[key] as Promise<Res>
}

export function deleteFromRequestCache(key: CacheKey) {
    delete RequestCache[key]
}
