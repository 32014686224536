import { Box } from "@mui/material"
import { Typography } from "../../../../generics/typographie"

type InfoItemProps = {
    item: { label?: string; value?: string }
}

export default function InfoItem({ item }: InfoItemProps) {
    const { label, value } = item

    return (
        <Box display="flex" alignItems="center">
            <Typography variant="label">{label}</Typography>
            <Typography variant="label" fontWeight="600" marginLeft=".25em">
                {value}
            </Typography>
        </Box>
    )
}
