import { useMemo } from "react"
import { getModuleFromUserContext } from "../../helper"
import { useUser } from "../../user/consumer/useUser"
import { AvailabilityStatus, UserModuleType } from "@tm/models"

export function useAvailabilityStatus(): {
    availabilityStatusIdsToShow?: AvailabilityStatus[]
    availabilityStatusIdsToShowSecondary?: AvailabilityStatus[]
} {
    const { userContext } = useUser()
    const catalogModule = getModuleFromUserContext(userContext, UserModuleType.Catalog)

    const statusIds = useMemo(() => {
        const availabilityStatusIdsToShow = catalogModule?.availabilityStatusIdsToShow
        const availabilityStatusIdsToShowSecondary = catalogModule?.availabilityStatusIdsToShowSecondary
        return { availabilityStatusIdsToShowSecondary, availabilityStatusIdsToShow }
    }, [catalogModule])

    return statusIds
}
