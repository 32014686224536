import { Component } from "react"

export default class ToolbarContent extends Component {

    constructor(props: any) {
        super(props);
    }

    render() {
        const baseName = "toolbar__content"
        const className = `${baseName}`

        return (
            <div className={className}>
                {this.props.children}
            </div>
        );
    }
}
