export * from "./share"

export function isValidEmail(email: string | undefined) {
    if (!email || email.trim().length === 0) {
        return false
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailPattern.test(email)) {
        return false
    }

    return true
}
