import * as React from "react"
import Month from "./Month";
import YearDropdown from "./year-dropdown";
import { LocalizationProps } from "@tm/localization"
import Icon from "../icon"
import { bindMethodsToContext } from "../../helper/bindObjectContext";
import { DateUnit } from "../../models/Date";

export default class Calendar extends React.Component<CalendarProps, CalendarState> {
    /**
     *
     */
    constructor(props: CalendarProps) {
        super(props);

        this.state = {
            date: this.getDateInView()
        }

        bindMethodsToContext(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: CalendarProps) {
        if (nextProps.selected && this.props.selected && !nextProps.selected.isSame(this.props.selected, DateUnit.Day)) {
            this.setState({
                date: nextProps.selected
            });
        }
    }

    getDateInView(): Date {
        if (this.props.selected) {
            return this.props.selected;
        } else if (this.props.minDate) {
            return this.props.minDate;
        }

        return new Date();
    }

    increaseMonth(): void {
        this.changeDate((this.state.date as Date).add(1, DateUnit.Month));
    }

    decreaseMonth(): void {
        this.changeDate((this.state.date as Date).subtract(1, DateUnit.Month));
    }

    changeYear(year: number): void {
        this.changeDate((this.state.date as Date).set(year, DateUnit.Year));
    }

    changeMonth(month: number): void {
        this.changeDate((this.state.date as Date).set(month, DateUnit.Month));
    }

    changeDate(date: Date) {
        this.setState({ date });

        if (this.props.accuracy == "Month") {
            this.props.onSelect(date)
        }
    }

    handleMouseWheel(e: React.WheelEvent) {
        e.preventDefault();
        e.stopPropagation();

        if (e.deltaY < 0) {
            this.decreaseMonth()
        } else {
            this.increaseMonth()
        }
    }

    renderDayNames(): JSX.Element[] {
        const startOfWeek = (this.state.date as Date).startOf(DateUnit.Week);

        const weeks = [0, 1, 2, 3, 4, 5, 6].map((offset) => {
            const day = startOfWeek.add(offset, DateUnit.Day);
            return (
                <div key={offset} className="calendar__day">
                    {this.props.localization.date(day, "ddd")}
                </div>
            );
        });

        weeks.unshift(<div key="-1" className="calendar__day" />);

        return weeks;
    }

    renderTodayButton(): JSX.Element | null {
        const today = new Date();
        const { minDate, maxDate } = this.props
        if (minDate && today.isBefore(minDate) || maxDate && today.isAfter(maxDate)) {
            return null;
        }

        return (
            <button className="btn btn--s flat today" onClick={() => this.props.onSelect(today)}>
                {this.props.localization.translate(142)}
            </button>
        );
    }

    renderHeader(): JSX.Element {
        const previousDisabled = false // Helper.allDaysDisabledBefore(this.state.date, "month", this.props.minDate);
        const nextDisabled = false // Helper.allDaysDisabledAfter(this.state.date, "month", this.props.maxDate);

        return (
            <div className="calendar__header">
                <button
                    className="btn btn--ghost"
                    disabled={previousDisabled}
                    onClick={this.decreaseMonth.bind(this)}>
                        <Icon name="prev" size="s" />
                </button>

                <div className="calendar__current">
                    <span>
                        <div>
                            {this.props.localization.date((this.state.date as Date), "MMMM yyyy")}
                        </div>
                        <YearDropdown
                            onChangeYear={this.changeYear.bind(this)}
                            onChangeMonth={this.changeMonth.bind(this)}
                            year={(this.state.date as Date).year()}
                            month={(this.state.date as Date).month()}
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            localization = {this.props.localization}
                            />
                    </span>
                </div>

                <button
                    className="btn btn--ghost"
                    disabled={nextDisabled}
                    onClick={this.increaseMonth.bind(this)}>
                        <Icon name="next" size="s" />
                </button>
            </div>
        );
    }

    renderMonths(): JSX.Element {
        const { date } = this.state;

        return (
            <div className="calendar__months">
                {
                    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((offset) => {
                        const month = (date as Date).set(offset, DateUnit.Month);

                        let className = "calendar__month";
                        className += offset == (date as Date).month() ? " is-selected" : "";
                        className += month.isSame(new Date(), DateUnit.Month) ? " is-today" : "";

                        if (this.props.minDate) {
                            className += (date as Date).isBefore(this.props.minDate) ? " is-disabled" : "";
                        }

                        if (this.props.maxDate && !className.includes("is-disabled")) {
                            className += (date as Date).isAfter(this.props.maxDate) ? " is-disabled" : "";
                        }

                        return (
                            <div key={offset} className={className} onClick={this.changeMonth.bind(this, offset)}>
                                {this.props.localization.date(month, "MMM")}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    render() {
        if (this.props.accuracy == "Month") {
            return (
                <div className="calendar month-mode" ref={this.props.setRef} >
                    {this.renderHeader()}
                    {this.renderMonths()}
                </div>
            );
        }

        return (
            <div className="calendar" ref={this.props.setRef} onWheel={this.handleMouseWheel.bind(this)} >
                {this.renderHeader()}
                <div className="calendar__day-names">
                    {this.renderDayNames()}
                </div>
                <Month
                    day={this.state.date as Date}
                    onDayClick={this.props.onSelect}
                    selected={this.props.selected}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                />
                <div className="calendar__today-btn-wrapper">
                    {this.renderTodayButton()}
                </div>
            </div>
        );
    }
}

export type CalendarProps = LocalizationProps & {
    selected?: Date;
    minDate?: Date;
    maxDate?: Date;
    accuracy?: "Year" | "Month" | "Week" |"Day";
    setRef(ref: HTMLElement|null): any;
    onSelect(day: Date): void;
}
export interface CalendarState {
    date?: Date;
}
