import { channel } from "@tm/models"
import Morpheus from "@tm/morpheus"

export function closeModalOrDrawer(isDrawer?: boolean, goBack?: boolean) {
    if (isDrawer) {
        channel("GLOBAL").publish("DRAWER/CLOSE", {})
        return
    }

    if (goBack) {
        Morpheus.getHistory().goBack()
    } else {
        Morpheus.closeView("1")
    }
}
