type Params<Request, Response, ServiceResponse> = {
    callService: (requests: Array<Request>) => Promise<ServiceResponse>
    /** Decide what to return for each request. If this function returns undefined, the promise will be rejected. */
    mapServiceResponseToResponse: (serviceResponse: ServiceResponse, request: Request) => Response | undefined
    compareRequestsForFiltering?: (a: Request, b: Request) => boolean
    /** default: 25 - defines the timespan (in ms) in which the requests will be buffered. */
    bufferTimespanMs?: number
    /** defines the maximum number of items in a single service call. undefined = no limitation. */
    maxQueueLength?: number
}

export function createBufferedRequestFunction<Request, Response, ServiceResponse>({
    callService,
    mapServiceResponseToResponse,
    compareRequestsForFiltering,
    bufferTimespanMs = 25,
    maxQueueLength,
}: Params<Request, Response, ServiceResponse>) {
    type BufferedRequest = {
        request: Request
        resolve: (response: Response) => void
        reject: (error: string) => void
        /** If `true` this request is already existing and will be exluded in the service call but the `resolve` or `reject` will still be called afterwards. */
        isDuplicate: boolean
    }

    const queue: Array<BufferedRequest> = []
    let bufferTimeout: number

    function sendRequest() {
        const requestItems = queue.splice(0, queue.length) // splice to remove request items from queue...

        if (!requestItems.length) {
            return
        }

        callService(requestItems.filter((x) => !x.isDuplicate).map((item) => item.request)).then(
            (data) => {
                requestItems.forEach((requestItem) => {
                    const response = mapServiceResponseToResponse(data, requestItem.request)

                    if (response !== undefined) {
                        requestItem.resolve(response)
                    } else {
                        requestItem.reject("No corresponding item in response")
                    }
                })
            },
            (error) => {
                requestItems.forEach((item) => {
                    item.reject(error)
                })
            }
        )
    }

    return (request: Request) => {
        return new Promise<Response>((resolve, reject) => {
            queue.push({
                request,
                resolve,
                reject,
                isDuplicate: compareRequestsForFiltering ? queue.some((x) => compareRequestsForFiltering(request, x.request)) : false,
            })

            window.clearTimeout(bufferTimeout)

            // Duplicate requests should not count towards the maximum queue length
            if (maxQueueLength && queue.filter((x) => !x.isDuplicate).length >= maxQueueLength) {
                sendRequest()
                return
            }

            bufferTimeout = window.setTimeout(sendRequest, bufferTimespanMs)
        })
    }
}
