import { TmaSender } from "../../tma-sender"
import { DamageCalculationModuleCalledRequest } from "../models"
import { TmaEvent, BaseTrackingModel } from "../../../models/models"

export class DamageCalculationDatModuleCalled extends TmaSender<DamageCalculationModuleCalledRequest> implements TmaEvent {
    key = "damage-calculation/dat"

    constructor() {
        super("/dat/repairEstimateModuleCalled")
        this.requestBody = {}
    }

    handle = (trackingData: BaseTrackingModel<DamageCalculationModuleCalledRequest>): Promise<void> | void => {
        if (trackingData.payload?.vehicleId) {
            this.requestBody.vehicleId = trackingData.payload.vehicleId
        }

        return this.sendEvent()
    }
}
