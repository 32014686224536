import { ReactNode } from "react"
import { Button, styled } from "@mui/material"
import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { Icon } from "../../../generics/Icons"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../../generics/typographie"
import { Tooltip } from "../../../generics/tooltip"

type PopoverWrapperProps = {
    children: ReactNode
    icon: string
    color: "primary" | "warning"
}

export const TooltipContentText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.font?.fontFamily?.secondary,
    fontSize: theme.typography.body2.fontSize,
})) as typeof Typography

const StyledButton = styled(Button)({
    minWidth: "auto",
    margin: "0 4px",
    padding: "0",
})

export const TooltipWrapper = (props: PopoverWrapperProps) => {
    const { children, icon, color } = props
    const toggleExpand = useDefaultArticleItemState((x) => x.toggleExpand)

    const handleButtonClick = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListExpandArticle, false)
        toggleExpand()
    }

    return (
        <>
            <Tooltip width="none" arrow title={<>{children}</>} color={color} variant="light" contentPadding="0 12px">
                <StyledButton onClick={handleButtonClick}>
                    <Icon color={color} name={icon} sx={{ verticalAlign: "top" }} />
                </StyledButton>
            </Tooltip>
        </>
    )
}
