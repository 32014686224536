import { useRecoilState } from "recoil"
import { multiWidgetTab } from "../../../appState/dashboard/multiWidget"

export type ModulePropsMultiWidget = {
    storeId?: string
}

export const useMultiWidgetState = (moduleProps?: ModulePropsMultiWidget) => {
    return useRecoilState(multiWidgetTab(moduleProps?.storeId || "default"))
}
