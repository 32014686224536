import { ItemTour } from "@tm/models"

export function mapTour(data: any): ItemTour | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        expectedDelivery: data.expectedDelivery ? new Date(data.expectedDelivery) : undefined,
        tourStart: data.tourStart ? new Date(data.tourStart) : undefined,
        tourOrderAcceptanceTimeLimit: data.tourOrderAcceptanceTimeLimit ? new Date(data.tourOrderAcceptanceTimeLimit) : undefined,
    }
}
