import { CatalogContext } from "../enumerations"

export type ShowOrderStatusRequest = {
    distributorId?: number
    telesalesCustomerNo?: string
    catalogContext?: CatalogContext
    orderIdentifier: OrderIdentifier[]
}

type OrderIdentifier = {
    catalogOrderId?: string
    wholesalerOrderId?: string
    customOrderId?: string
}

export type ShowOrderStatusResponse = {
    hasErrors: false
    orderStatus: OrderStatus[]
    distributorId?: number
}

type OrderStatus = {
    orderIdentifier: OrderIdentifier
    orderStatusMessage?: OrderStatusMessage
}

export type OrderStatusMessage = {
    type: StatusMessageType
    text: string
}

export enum StatusMessageType {
    Error = 0,
    Information = 1,
    OrderAccepted = 2,
    OrderDeclined = 3,
}

export type ShowOrderStatusError = {
    hasErrors: true
    exception?: unknown
    errorText: string
    traceId: string
}
