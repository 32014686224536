import { forwardRef, ImgHTMLAttributes, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react"
import { SvgFallback } from "./fallbacks/svg-fallback"
import { ArticleFallback } from "./fallbacks/article-fallback"
import { ManufacturerFallback } from "./fallbacks/manufacturer-fallback"
import { CarFallback } from "./fallbacks/car-fallback"
import { BikeFallback } from "./fallbacks/bike-fallback"
import { FlagFallback } from "./fallbacks/Flag"

/**
 * @param src Image source
 * @param type used to show a fallback image (rename to fallbackType?)
 */
export type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
    src: string
    type?: "article" | "manufacturer" | "car" | "bike" | "flag"
}

export const Image = forwardRef<HTMLImageElement, ImageProps>((props, ref) => {
    const { onError, src, type, alt, title, className } = props
    const [error, setError] = useState<boolean>(false)

    useEffect(() => {
        setError(false)
    }, [src])

    const handleError = useCallback(
        (e: SyntheticEvent<HTMLImageElement, Event>) => {
            setError(true)
            onError?.(e)
        },
        [onError]
    )

    const fallbackImage = useMemo(() => {
        let FallbackComponent

        switch (type) {
            case "article":
                FallbackComponent = ArticleFallback
                break
            case "manufacturer":
                FallbackComponent = ManufacturerFallback
                break
            case "car":
                FallbackComponent = CarFallback
                break
            case "bike":
                FallbackComponent = BikeFallback
                break
            case "flag":
                FallbackComponent = FlagFallback
                break
            default:
                FallbackComponent = SvgFallback
                break
        }

        const commonProps = {
            width: "100%",
            height: "100%",
        }

        const fallback =
            type === "flag" ? <FallbackComponent alt={alt || ""} title={title} {...commonProps} /> : <FallbackComponent {...commonProps} />

        return (
            <span className={`error-image error-image__${type} ${className || ""}`} title={title}>
                {fallback}
            </span>
        )
    }, [type, className, title, alt])

    if (error || !props?.src) {
        return fallbackImage
    }

    return <img {...props} alt={alt || ""} ref={ref} className={`image${className ? ` ${className}` : ""}`} onError={handleError} />
})
