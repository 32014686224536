import { ElementType, ForwardedRef, ReactElement, forwardRef } from "react"
import { SelectProps } from "@mui/material"
import { Overwrite } from "@tm/utils"
import { TextField, TextFieldProps } from "../textfield"

type Props<T> = Overwrite<TextFieldProps, { SelectProps?: SelectProps<T> }> & {
    customIcon?: ElementType
    maxLayerHeight?: number
}

// eslint-disable-next-line @typescript-eslint/no-shadow
function SelectTextFieldComponent<T>({ maxLayerHeight, customIcon, SelectProps, children, ...rest }: Props<T>, ref: ForwardedRef<HTMLDivElement>) {
    const selectProps: Partial<SelectProps<unknown>> = {
        ...(SelectProps as SelectProps<unknown>),
        ...(maxLayerHeight ? { MenuProps: { PaperProps: { sx: { maxHeight: maxLayerHeight } } } } : undefined),
        ...(customIcon ? { IconComponent: customIcon } : undefined),
    }

    return (
        <TextField ref={ref} {...rest} select SelectProps={selectProps}>
            {children}
        </TextField>
    )
}

// Cast necessary as "forwardRef" is not working with a generic component (see https://stackoverflow.com/a/58473012/15647468)
export const SelectTextField = forwardRef(SelectTextFieldComponent) as <T>(p: Props<T> & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement
