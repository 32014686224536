import * as React from "react"
// TODO: remove utils dependency
import { ButtonKeyDefinition, bindSpecialReactMethods, registerOutsideClick } from "@tm/utils"
import { Button, ButtonProps, Popover, Icon, Text } from "..";
import DropdownItem from "../dropdown-menu/components/dropdown-item";

export type LabeledDropdownProps<FItem extends {}> = {
    label: string
    items: Array<FItem>
    value?: FItem
    itemView: any
    displayView?: any
    inputView?: any
    amountItemsToShow?: number
    preSelectedValue?: FItem
    // layout?: Array<ButtonLayout>
    disabled?: boolean
    className?: string
    selected?: boolean
    /**
     * If a value is passed in and selected ist not true, you have to handle the selection here, mb not? onSelect
     */
    onSelectionClick?(value: FItem): void
    onSelect(value: FItem, previousValue?: FItem): void
    onDeselect?(value: FItem): void
}

export type LabeledDropdownState<FItem> = {
    open: boolean
    value?: FItem
    selected: boolean
    startShowIndex: number
    preSelectedItemIndex: number
    alreadyFocused: boolean
}

export default class LabeledDropdown<TItem extends {} = any> extends React.Component<LabeledDropdownProps<TItem>, LabeledDropdownState<TItem>> {
    private baseName = "dropdown"
    private element: React.RefObject<HTMLDivElement>
    private inputElement: React.RefObject<any>
    private inputViewElement: React.RefObject<any>
    private recentlyFocused: boolean
    private unregisterOutsideClick?: () => void

    constructor(props: LabeledDropdownProps<TItem>) {
        super(props);
        // bindMethodsToContext(this, ["open", "close"])
        bindSpecialReactMethods(this)

        this.state = {
            open: false,
            value: this.props.value,
            startShowIndex: 0,
            selected: !!this.props.selected,
            preSelectedItemIndex: 0,
            alreadyFocused: false
        }

        this.recentlyFocused = false
        this.inputElement = React.createRef()   // this one is the input element of the dropdownbox which handles all the events, like focus, keyboardevent, ...
        this.inputViewElement = React.createRef()
        this.element = React.createRef()
        this.setUnregisterOutsideClick = this.setUnregisterOutsideClick.bind(this)
        this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this)
    }

    componentWillUnmount() {
        // this.unregisterOutsideClick && this.unregisterOutsideClick()
    }

    componentDidMount() {
        if (this.inputViewElement.current) {
            const { current } = this.inputViewElement

            setTimeout(() => {
                current.focus()
                current.select()
            }, 0)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: LabeledDropdownProps<TItem>) {
        if (nextProps.value && (!this.props.value || !areEqualObjects(nextProps.value, this.props.value))) {
            this.setState({ value: nextProps.value })
        }
    }

    render() {
        const { open, startShowIndex } = this.state
        const { amountItemsToShow, disabled, className, displayView, inputView, label, preSelectedValue, value } = this.props

        const baseName = this.baseName
        const qualifiedClassName = `${baseName} labeled-${baseName}${open ? " is-active" : ""}${className ? " " + className : ""}`
        const View = this.props.itemView
        const DisplayView = displayView || View
        const InputView = inputView || null


        const arrowdnClassName = `${baseName}__icon` + (amountItemsToShow && (startShowIndex + amountItemsToShow == this.props.items.length) || startShowIndex == this.props.items.length ? ` ${baseName}__icon--disabled` : "");
        const arrowupClassName = `${baseName}__icon` + (startShowIndex == 0 ? ` ${baseName}__icon--disabled` : "")
        const displayItems = this.props.items.slice(startShowIndex, amountItemsToShow ? startShowIndex + amountItemsToShow : this.props.items.length)
        const { selected } = this.state

        const selectedFromProps = this.props.selected

        const labelDropdownProps: React.HTMLProps<HTMLDivElement> = {
            className: qualifiedClassName,
            onClick: value || (preSelectedValue && !selected) ? this.handleSelectionClick : this.handleToggleClick,
            ref: this.element,
            onFocus: this.handleFocus,
            onWheel: this.handleMouseWheel,
            onKeyDown: this.handleKeyPress,
            style: open ? styles.btn.active : undefined
        }

        const highlight = !!preSelectedValue

        const iconName = value ? "close" : (open ? "up" : "down")
        const AppendToButton = <Button fakeButton={true} layout={["ghost"]} icon={iconName} className={"labeled-dropdown__icon"} onClick={iconName == "close" ? this.handleDeselectItem : this.handleToggleClick} />


        const btnProps: ButtonProps & { ref: React.RefObject<any> } = {
            ref: this.inputElement,
            isActive: highlight ? (!selected || !value) && highlight : selectedFromProps,
            disabled: disabled,
            // icon: (value  ? undefined : (open ? "up" : "down")),
            scaleIcon: true,
            skin: highlight ? "highlight" : undefined,
            layout: highlight ? ["iconRight"] : ["iconRight", "holo"],
            appendItem: AppendToButton
        }

        const displayViewProps = !selected || !value && preSelectedValue ? preSelectedValue : value

        return (
            <div {...labelDropdownProps} >
                <Button {...btnProps} >
                    <Text size={value || preSelectedValue ? "xs" : "s"} modifiers={["sub", "block"]} className={`labeled-${baseName}__label${!displayViewProps ? "--no-selection" : ""}`}>{label}</Text>
                    <DisplayView
                        {...displayViewProps}
                        onChange={this.handleSelectItem}
                    />
                </Button>

                <Popover
                    alignArrow="center"
                    className={`${baseName}__box`}
                    active={open}
                >
                    <>
                        {InputView &&
                            <InputView
                                {...this.state.value}
                                inputRef={this.inputViewElement}
                                onChange={this.handleSelectItem}
                            />
                        }

                        {amountItemsToShow &&
                            <div key={"amount-item-up"} className={arrowupClassName} onClick={this.handleDecreaseClick} onMouseDown={this.handleMouseDownDecrease} onMouseUp={this.handleMouseUp}>
                                <Icon name="up" size="s" />
                            </div>
                        }

                        <div className={`${baseName}__items ${baseName}__items--centered`}>
                            {displayItems.length &&
                                displayItems.map(this.renderItem)
                            }
                        </div>

                        {amountItemsToShow &&
                            <div key={"amount-item-down"} className={arrowdnClassName} onClick={this.handleIncreaseClick} onMouseDown={this.handleMouseDownIncrease} onMouseUp={this.handleMouseUp} >
                                <Icon name="down" size="s" />
                            </div>
                        }
                    </>
                </Popover>
            </div>
        );
    }

    renderItem(item?: TItem, index?: number): JSX.Element | null {
        if (!item) { return null }

        const { preSelectedItemIndex, value } = this.state
        const View = this.props.itemView

        let className = `${this.baseName}__item`

        if (areEqualObjects(value, item)) {
            className = `${className} ${className}--selected`
        }

        if (preSelectedItemIndex == index) {
            className = `${className} ${this.baseName}__item--preselected`
        }

        return <React.Fragment key={"dropdown-item#" + index}>
            <DropdownItem
                View={this.props.itemView}
                item={item}
                className={className}
                isListItem={true}
                onClick={this.handleItemClick}
            />
        </React.Fragment>
    }

    handleItemClick(e: React.MouseEvent<HTMLDivElement>, item: TItem) {
        this.stopBubbling(e)

        if (!areEqualObjects(item, this.state.value)) {
            this.handleSelectItem(item)
        } else {
            this.handleOutsideClick()
        }
    }

    handleSelectItem(item: TItem) {
        const { onSelect } = this.props
        const previousItem = this.state.value

        this.setState({
            value: item,
            selected: true
        })

        if (onSelect) {
            onSelect(item, previousItem)
        }

        this.handleOutsideClick()
    }

    handleDeselectItem(e: React.MouseEvent<HTMLDivElement>) {
        this.stopBubbling(e)

        const { onDeselect } = this.props
        const { value } = this.state

        onDeselect && onDeselect(value as TItem)

        this.setState({
            value: undefined,
            selected: false
        })

        this.handleOutsideClick()
    }

    handleToggleClick(e: any) {
        this.stopBubbling(e)

        if (this.props.disabled) {
            return
        }

        this.handleSetMidItem()

        if (this.state.alreadyFocused || this.recentlyFocused) {
            this.handleRecentlyFocused()
            return
        }

        this.toggleDropdownMenu()

        this.handleInputViewFocus()
    }

    handleSelectionClick(e: any) {
        this.stopBubbling(e)

        if (this.props.disabled) {
            return
        }

        const { preSelectedValue } = this.props
        const { value } = this.state

        if (value) {
            this.handleDeselectItem(e)
        } else if (preSelectedValue) {
            this.handleSelectItem(preSelectedValue)
        } else {
            this.toggleDropdownMenu()
        }
    }

    stopBubbling(e: any) {
        e.preventDefault()
        e.stopPropagation()
        e.bubbles = false
    }

    setUnregisterOutsideClick() {
        const { open } = this.state

        if (!open && this.element && this.element.current) {
            this.unregisterOutsideClick = registerOutsideClick(this.element.current, this.handleOutsideClick)
        }
    }

    toggleDropdownMenu() {
        this.setUnregisterOutsideClick()

        this.setState(
            (prevState: LabeledDropdownState<TItem>, props: LabeledDropdownProps<TItem>) => {
                return { open: !prevState.open }
            },
            this.handleInputViewFocus
        )
    }

    handleKeyPress(e: any) {
        switch (e.key) {
            case ButtonKeyDefinition.Enter: {
                this.handleEnter()
                break;
            }
            case ButtonKeyDefinition.ArrowDown: {
                this.stopBubbling(e);
                this.handleArrowDown()
                break;
            }
            case ButtonKeyDefinition.ArrowUp: {
                this.stopBubbling(e);
                this.handleArrowUp()
                break;
            }
        }

    }

    handleEnter() {
        const { startShowIndex, preSelectedItemIndex } = this.state
        let itemIndex = preSelectedItemIndex + startShowIndex

        this.handleSelectItem(this.props.items[itemIndex])
    }

    handleRecentlyFocused() {
        this.recentlyFocused = false

        this.setState({
            alreadyFocused: true
        })

        this.handleInputViewFocus()
    }

    handleArrowDown() {
        const { amountItemsToShow } = this.props
        const localMaxIndex = (amountItemsToShow ? amountItemsToShow : this.props.items.length) - 1
        let itemIndex = this.state.preSelectedItemIndex

        if (itemIndex < localMaxIndex) {
            itemIndex += 1
        } else {
            this.handleShowStartIndex(1)
        }

        this.handlePreselectItem(itemIndex)
    }

    handleArrowUp() {
        let itemIndex = this.state.preSelectedItemIndex

        if (itemIndex > 0) {
            itemIndex += -1
        } else {
            this.handleShowStartIndex(-1)
        }

        this.handlePreselectItem(itemIndex)
    }


    handleShowStartIndex(step: 1 | -1) {
        const { startShowIndex } = this.state
        const { items, amountItemsToShow } = this.props
        const nextItemsCount = amountItemsToShow || Math.round((items.length - .5) / 2)

        if (startShowIndex == 0 && step < 0) {
            return
        }

        if (startShowIndex + step > items.length - nextItemsCount) {
            return
        }

        this.setState({
            startShowIndex: amountItemsToShow ? startShowIndex + step : 0
        })
    }

    handlePreselectItem(indexOfItem: number) {
        this.setState({ preSelectedItemIndex: indexOfItem })
    }

    handleMouseWheel(e: React.WheelEvent<HTMLDivElement>) {
        this.stopBubbling(e)

        if (e.deltaY > 0) {
            this.handleShowStartIndex(1)
        } else {
            this.handleShowStartIndex(-1)
        }
    }

    handleSetMidItem() {
        const { items, amountItemsToShow } = this.props
        const { value } = this.state
        // const itemIndex = this.state.value ? this.props.items.indexOf(this.state.value) : 0

        let itemIndex = !value ? -1 : items.findIndex((item: TItem) => {
            return areEqualObjects(value, item)
        })

        if (itemIndex < 0) { itemIndex = 0 }

        // index of the item in props which has to be the first item of the filtered items when the selected item should be in the middle
        const start = itemIndex - Math.round(((this.props.amountItemsToShow || this.props.items.length) - .5) / 2)

        // there is no index lower 0
        const startShowIndex = start < 0 ? 0 : start

        this.setState({
            startShowIndex: startShowIndex,
            preSelectedItemIndex: itemIndex - startShowIndex
        })
    }

    handleIncreaseClick(e: any) {
        this.stopBubbling(e)

        this.handleShowStartIndex(1)
        this.clearSelection()
        this.handleReFocusButton()
    }

    handleReFocusButton() {
        if (this.inputViewElement) {
            this.handleInputViewFocus()
        } else {
            setTimeout(() => {
                this.inputElement.current && this.inputElement.current.focus()
            }, 0)
        }
    }

    handleInputViewFocus() {
        const { current } = this.inputViewElement

        if (this.state.open && current) {
            setTimeout(() => {
                current.focus && current.focus()
                current.select && current.select()
            }, 50)
        }
    }

    mouseDownInterval: any
    delayDecreaseInterval: any

    handleMouseDownDecrease(e: any) {
        this.stopBubbling(e)

        this.startInterval(() => this.handleShowStartIndex(-1))
    }

    handleMouseDownIncrease(e: any) {
        this.stopBubbling(e)

        this.startInterval(() => this.handleShowStartIndex(1))

    }

    startInterval(action: () => void) {
        action();

        this.startDelayDecrease(
            (intervalDelay: number) => {
                this.setInterval(
                    action,
                    intervalDelay
                )
            }
        )
    }

    startDelayDecrease(setNewTimerCallback: (delay: number) => void) {
        const step = 15
        let delayDecrease = 250

        this.delayDecreaseInterval = setInterval(
            () => {
                clearInterval(this.mouseDownInterval)
                if (delayDecrease - step > 0) {
                    delayDecrease -= step
                }

                setNewTimerCallback(delayDecrease)
            },
            delayDecrease + 100
        )
    }

    setInterval(intervalCallback: () => void, intervalDelay: number) {
        this.mouseDownInterval = setInterval(intervalCallback, intervalDelay)
    }

    handleMouseUp(e: any) {
        this.stopBubbling(e)
        clearInterval(this.mouseDownInterval)
        clearInterval(this.delayDecreaseInterval)
    }

    handleDecreaseClick(e: any) {
        this.stopBubbling(e)

        this.handleShowStartIndex(-1)
        this.clearSelection()
        this.handleReFocusButton()
    }

    clearSelection() {
        if (window.getSelection) {
            var sel = window.getSelection();
            if (sel) {
                sel.removeAllRanges();
            }
        }
    }

    handleFocus(e: React.FocusEvent<HTMLDivElement>) {
        const { open, alreadyFocused, value } = this.state
        const { preSelectedValue } = this.props
        // focus inputViewElement
        if (!open && !(value || preSelectedValue) && !alreadyFocused) {
            this.handleSetMidItem()
            this.toggleDropdownMenu()
            // need to remember if it was recently focused to handle open/close status of the dropdownbox inside the button onclick event
            this.recentlyFocused = true
        }
    }

    handleOutsideClick() {
        this.setState({
            alreadyFocused: false,
            open: false
        })
    }
}


const areEqualObjects = function (x: any, y: any): boolean {
    let equal = false

    if (typeof (x) != typeof (y) ||
        typeof (x) == "function"
    ) {
        return false
    }

    if (typeof (x) == "object") {
        for (let key in x) {
            if ((y as Object).hasOwnProperty(key)) {

                if (typeof x[key] == "object" && typeof y[key] == "object") {
                    equal = areEqualObjects(x, y)
                } else {
                    equal = y[key] == x[key]
                }


                if (!equal) {
                    return false
                }
            }
        }
    } else {
        equal = x == y
    }

    return equal
}

// TODO: hard coded inline colors wtf
// CE 6.9.18
const styles = {
    btn: {
        active: {
            background: "rgba(178, 219, 251, 0.6)" //#color-primary-l30, 0.6
        }
    }
}
