import { EventStorage } from "../events/event-storage"
export class DataPublisher<TData> {
    protected storage: EventStorage<TData>

    constructor(storage: EventStorage<TData>) {
        this.storage = storage
    }

    getEventData() {
        return this.storage.get()
    }
}
