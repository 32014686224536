import { VrmLookupTextIds } from "@tm/models"
import { formatVin, getValue, Overwrite, setValue } from "@tm/utils"
import { Component } from "react"
import { SuggestionTextField, SuggestionTextFieldProps } from "../"
import { bindMethodsToContext } from "../../helper"

export type Props = Overwrite<SuggestionTextFieldProps, {
    coloredBorder?: boolean
    value?: string
    onChange?(model: any, path?: Array<any>, validVin?: boolean): void
    tecDocManufacturerId?: number
}> & {
    datVinSearchIsValid?: boolean
    vinSearchRequired?: boolean
    vinSearchOptional?: boolean
    datPricePerRequest?: string
    vinSearchInformalMessage?: VrmLookupTextIds
    checkVinLoading?: boolean
    translateText?(key: string | number): string
    validateVin?(): void
}

export type State = {
    validVin?: boolean
}

const VIN_LENGTH = 17
const VIN_LENGTH_SMALL = 7
const BMW_TecDoc_Id = 16
let willUnmount = false
class VinField extends Component<Props, State> {
    private suggestionTimeoutId: number
    private requestCount: number = 0

    constructor(props: Props) {
        super(props)
        this.state = {
            validVin: this.validateVin(props.value ?? props?.model?.vin)
        }
        bindMethodsToContext(this)
    }

    componentWillUnmount() {
        window.clearTimeout(this.suggestionTimeoutId)
    }

    componentDidMount() {
        willUnmount = false
    }

    UNSAFE_componentWillMount() {
        willUnmount = true
    }

    getSuggestions(query: string): Promise<Array<string>> {
        const { getSuggestionData, getSuggestionDataAsync } = this.props

        if (getSuggestionDataAsync) {
            return new Promise<Array<string>>((resolve, reject) => {
                const requestCount = ++this.requestCount

                window.clearTimeout(this.suggestionTimeoutId)

                // not sure if the promise will be cleared on unmount,
                // what currently is happing, the vin field sends an async call due to a default value
                // gets unmounted (251ms later) and the promise will try to update an unmounted component
                this.suggestionTimeoutId = window.setTimeout(() =>
                    getSuggestionDataAsync(query).then((data: any) => {
                        if (requestCount == this.requestCount) {
                            if (!willUnmount) {
                                resolve(
                                    data || []
                                )
                            }
                        }
                    }, () => {
                        reject([])
                    })
                    , 250)
            })
        }
        else if (getSuggestionData) {
            return Promise.resolve(getSuggestionData(query) || [])
        }
        return Promise.resolve([])
    }

    handleChange(model: any, path?: Array<string>) {
        const { onChange } = this.props
        let value: string | undefined = model && path ? getValue(model, path) : model

        if (!value) {
            this.setState({ validVin: undefined })
            onChange && onChange(model, path, undefined)
            return
        }

        let isValid = this.validateVin(value) 

        if (!isValid) {
            this.setState({ validVin: false })
            onChange && onChange(model, path, false)
            return
        }

        value = formatVin(value)

        if (model && path) {
            model = setValue(model, path, value)
        }
        else {
            model = value
        }

        this.setState({ validVin: true })
        onChange && onChange(model, path, true)

        if (value.match(/(\d|[a-zA-Z])\1{9,}/)) {
            this.setState({ validVin: false })
            onChange && onChange(model, path, false)
            return
        }
    }

    handleChangeConfirm(model: any, path?: Array<string>) {
        this.handleChange(model, path)

        let value: string | undefined = model && path ? getValue(model, path) : model
        let isValid = this.validateVin(value)

        if (this.props.onChangeConfirm) {
            this.props.onChangeConfirm(model, path, isValid)
        }
    }

    validateVin(value?: string) {
        const { tecDocManufacturerId } = this.props
        let isValid = value?.length == VIN_LENGTH

        if (!isValid && tecDocManufacturerId == BMW_TecDoc_Id) {
            isValid = value?.length == VIN_LENGTH_SMALL
        }

        return isValid
    }

    render() {
        const { coloredBorder, ...rest } = this.props
        const { validVin } = this.state

        let className = "vin-field "

        if (coloredBorder && (!!rest.value?.length || !!rest?.model?.vin?.length)) {
            if (this.props.vinSearchRequired) {
                className += this.props.datVinSearchIsValid ? "vin-field--success " : "vin-field--danger "
            }
            else {
                className += validVin ? "vin-field--success " : "vin-field--danger "
            }
        }

        if (this.props.className) className += this.props.className

        return (
            <SuggestionTextField
                {...rest}
                className={className}
                pattern={/[\da-zA-Z]*/}
                maxLength={VIN_LENGTH}
                showLength
                onChange={this.handleChange}
                onChangeConfirm={this.handleChangeConfirm}
                formatter={formatVin}
            />
        );
    }
}

export default VinField