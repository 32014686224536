import { Dictionary, IContext } from ".."
export * from "./validation"


export { }

declare global {
    interface Array<T> {
        orderBy(selector: (item: T) => number | string | boolean | Date, descending?: boolean): T[]
        groupBy(selector: (item: T) => number | string): { [group: string]: T[] }
    }

}


Array.prototype["orderBy"] = function (selector: (item: any) => number | string | boolean | Date, descending: boolean = false) {
    const list = this.map((value, index) => {
        return {
            index: index,
            value: value,
            criteria: selector(value)
        }
    });
    list.sort((left, right) => {
        const a = left.criteria;
        const b = right.criteria;
        let result = left.index - right.index;
        if (a !== b) {
            if (a > b || a === void 0) result = 1;
            if (a < b || b === void 0) result = -1;
        }
        return descending ? -result : result;
    });
    return list.map(x => x.value);
}

Array.prototype["groupBy"] = function (selector: (item: any) => number | string) {
    let result: { [group: string]: Array<any> } = {};
    this.forEach(item => {
        let group = selector(item);
        if (group == null)
            group = "";
        let list = result[group];
        if (!list) {
            list = [];
            result[group] = list;
        }
        list.push(item);
    });
    return result;
}

export function dictionaryToArray<T>(dictionary: Dictionary<T>) {
    return Object.keys(dictionary).map(key => ({ key, value: dictionary[key] }))
}
