import { TmaEModule } from "@tm/models"
import { TmaEventTracking } from "../.."
import { BaseTrackingModel } from "../../models/models"
import { VehicleSelectionLog } from "./models"
import { getCatalogOrigin } from "../../helper/origin"
import { uniqueId } from "../../../helpers/id"

export const VehicleSelectionEventHelper = {
    GetVehicleSelectionLog: () => {
        const data = TmaEventTracking.getRequestData("VEHICLE_SELECTION")
        if (data) {
            const log: VehicleSelectionLog = {
                eventId: uniqueId(),
                origin: data.origin || TmaEModule.UNDEFINED,
                dataSourceId: data.dataSourceId!,
                searchTerm: data.searchTerm!,
                selectedConstructionYearFilter: data.selectedConstructionYearFilter,
            }
            return log
        }
    },
    List: {
        Graphical: (dataSourceId?: number) => {
            TmaEventTracking.dispatchTrack({
                tmaEvent: "VEHICLE_SELECTION",
                action: "setIdentification",
                bundle: "vehicle",
                component: "repository",
                payload: {
                    origin: TmaEModule.VEHICLE_GRAPHIC_SEARCH,
                    dataSourceId,
                },
                type: "models",
                source: "models",
            })
        },
        Search: (data: { dataSourceId?: number; query?: string }) => {
            TmaEventTracking.dispatchTrack({
                tmaEvent: "VEHICLE_SELECTION",
                action: "setIdentification",
                bundle: "vehicle",
                component: "repository",
                payload: {
                    origin: TmaEModule.VEHICLE_GRAPHIC_SEARCH,
                    dataSourceId: data.dataSourceId,
                    searchTerm: data.query,
                },
                type: "models",
                source: "models",
            })
        },
    },
    Search: {
        FromWidget: (data: { dataSourceId?: number; query?: string }) => {
            TmaEventTracking.dispatchTrack({
                tmaEvent: "VEHICLE_SELECTION",
                action: "setIdentification",
                bundle: "vehicle",
                component: "repository",
                payload: {
                    origin: document.location.pathname == "/" ? TmaEModule.START_PAGE : TmaEModule.DASHBOARD,
                    dataSourceId: data.dataSourceId,
                    searchTerm: data.query,
                },
                type: "models",
                source: "models",
            })
        },

        ModelYearFilter: (year: number) => {
            TmaEventTracking.dispatchTrack({
                tmaEvent: "VEHICLE_SELECTION",
                action: "selectConstructionYearFilter",
                bundle: "vehicle",
                component: "ModelYearFilter",
                payload: {
                    year,
                },
                type: "click",
                source: "filter",
            })
        },
    },
    Apply: (dataSourceId?: number) => {
        const origin = getCatalogOrigin()
        let action = "setContextType"
        let payload: {} = {
            origin: origin,
        }
        if (dataSourceId) {
            action = "setIdentification"
            payload = {
                ...payload,
                dataSourceId,
            }
        }

        TmaEventTracking.dispatchTrack({
            tmaEvent: "VEHICLE_SELECTION",
            action,
            bundle: "vehicle",
            component: "repository",
            payload,
            type: "models",
            source: "models",
        })
    },
}

class VehicleSelectionEventTracker {
    private _trackingData: { [key: string]: string } = {
        tmaEvent: "VEHICLE_SELECTION",
    }

    get Vehicle() {
        this._trackingData.bundle = "vehicle"
        const _this = this
        return {
            get Filter() {
                _this._trackingData.action = "setModelYearFilter"
                _this._trackingData.component = "action"
                return {
                    setModelYearFilter: (year: number) => {
                        TmaEventTracking.dispatchTrack({
                            ..._this._trackingData,
                            payload: {
                                year,
                            },
                            type: "click",
                            source: "filter",
                        } as BaseTrackingModel<any>)
                    },
                }
            },
            get List() {
                _this._trackingData.action = "handleApply"
                _this._trackingData.component = "list"
                return {
                    handleApplyButtonClick: (dataSourceId: number) => {
                        TmaEventTracking.dispatchTrack({
                            ..._this._trackingData,
                            payload: {
                                dataSourceId,
                            },
                            type: "click",
                            source: "button",
                        } as BaseTrackingModel<any>)
                    },
                }
            },
            Widget: {
                uniqueVehicleFound: (dataSourceId: number) => {
                    TmaEventTracking.dispatchTrack({
                        tmaEvent: "VEHICLE_SELECTION",
                        action: "handleUniqueVehicleFound",
                        bundle: "vehicle",
                        component: "widget",
                        payload: {
                            dataSourceId,
                        },
                        type: "request",
                        source: "input",
                    })
                },
            },
        }
    }

    Crm = {
        List: {
            handleApplyButtonClick: (dataSourceId: number) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "VEHICLE_SELECTION",
                    action: "handleApply",
                    bundle: "crm",
                    component: "list",
                    payload: {
                        dataSourceId,
                    },
                    type: "click",
                    source: "button",
                })
            },
        },
        Widget: {
            // TODO: rename to correct name
            applyCustomerWithVehicle: (dataSourceId: number) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "VEHICLE_SELECTION",
                    action: "applyCustomerWithVehicle",
                    bundle: "crm",
                    component: "widget",
                    payload: {
                        dataSourceId,
                    },
                    type: "request",
                    source: "input",
                })
            },
        },
    }
}

export const VehicleSelectionEvents = new VehicleSelectionEventTracker()
