import { Stack } from "@mui/material"
import { BasketAmountField } from "./BasketAmountField"
import { AddToBasketButton } from "./AddToBasketButton"

type AddToBasketProps = {
    onAddToBasket(): void
    onRemoveFromBasket(): void
    handleChangeQuantity: (value: number, loadErpInfo?: boolean) => void
    originalQuantity: number
    division: number
    basketQuantity?: number
    hideBasketQuantity?: boolean
    hasSuggestedQuantity?: boolean
    disableButton: boolean
}

export function AddToBasket(props: AddToBasketProps) {
    const {
        onAddToBasket,
        onRemoveFromBasket,
        handleChangeQuantity,
        originalQuantity,
        division,
        basketQuantity,
        hideBasketQuantity,
        hasSuggestedQuantity,
        disableButton,
    } = props

    return (
        <Stack direction="row" className="addToBasket">
            <BasketAmountField
                originalQuantity={originalQuantity}
                division={division}
                handleChangeQuantity={handleChangeQuantity}
                hasSuggestedQuantity={hasSuggestedQuantity}
                loadErpInfos
            />
            <AddToBasketButton
                onAddToBasket={onAddToBasket}
                onRemoveFromBasket={onRemoveFromBasket}
                basketQuantity={basketQuantity}
                hideBasketQuantity={hideBasketQuantity}
                disabled={disableButton}
            />
        </Stack>
    )
}
