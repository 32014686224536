import * as React from "react"
import DatePicker from "react-datepicker"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { bindMethodsToContext } from "../../helper"
import { getLocaleDateString, getLocaleTimeString, handleScrollIntoView } from "./helper"
import DateFieldCustomInput from "./custom-input"
import { DateFieldProps } from "."

export class DateFieldAny extends React.Component<DateFieldProps, { calender?: DatePicker }> {
    localeFormat?: string[] | string
    timeFormat?: string

    private selectYearElement: HTMLElement | null = null
    private timerId: number | undefined;

    private yearSelector = ".react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll .react-datepicker__year-read-view"
    private yearDropdownSelector = ".react-datepicker__year-dropdown.react-datepicker__year-dropdown--scrollable"
    private yearSelectedElSelector = ".react-datepicker__year-option--selected_year"

    constructor(props: DateFieldProps) {
        super(props)
        bindMethodsToContext(this)
        this.localeFormat = getLocaleDateString(this.props.localization.language)
        if (this.localeFormat && this.props.showTimeSelect) {
            this.timeFormat = getLocaleTimeString(this.props.localization.language)
            if (Array.isArray(this.localeFormat))
                this.localeFormat = this.localeFormat.map(s => s += " " + this.timeFormat)
            else
                this.localeFormat += " " + this.timeFormat
        }

        this.state = {
            calender: undefined
        }
    }

    componentDidUpdate(prevProps: DateFieldProps) {
        if (this.props.autoFocus && !prevProps.autoFocus) {
            this.state.calender?.setFocus()
            this.state.calender?.setOpen(true)
            this.handleRegisterClick()
        }
    }

    handleRegisterClick() {
        clearInterval(this.timerId)

        this.selectYearElement = document.querySelector(this.yearSelector)

        if (!this.selectYearElement) {
            this.timerId = setTimeout(this.handleRegisterClick as TimerHandler, 100)
            return
        }

        this.handleUnRegisterClick()
        this.selectYearElement.addEventListener("click", this.handleScroll)
    }

    handleUnRegisterClick() {
        clearInterval(this.timerId)
        this.selectYearElement?.removeEventListener("click", this.handleScroll)
    }

    handleScroll() {
        setTimeout(() => {
            handleScrollIntoView(this.yearDropdownSelector, this.yearSelectedElSelector)
        }, 100)
    }

    componentWillUnmount() {
        this.handleUnRegisterClick()
    }

    handleDateChange(date: Date | null) {

        this.handleUnRegisterClick()

        const { onChange, useUtc, showTimeSelect } = this.props
        if (date) {

            if (!showTimeSelect) {
                // workaround to be 11:59 in UTC/GMT in order to not get date differences
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 11 - (date.getTimezoneOffset() / 60), 59, 0);
            } else if (useUtc) {
                date = new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000))
            }
        }
        onChange?.(date)
    }

    render() {
        let { readonly, autoFocus, disabled, minDate, maxDate, value, floatingLabel, showClear, label, className, required, openToDate, modelState, path, size } = this.props
        const tabIndex = readonly ? 0 : this.props.tabIndex
        let realValue = value
        className += ` input--${size ? size : "m"}`

        if (required && !realValue) {
            if (minDate) {
                realValue = minDate
            }
            else if (maxDate) {
                realValue = maxDate
            }
            else {
                realValue = new Date()
            }
        }

        const datePlaceholder = Array.isArray(this.localeFormat) ? this.localeFormat[0] : this.localeFormat
        return (
            <DatePicker
                ref={(ref) => {
                    if (ref && this.state.calender != ref) {
                        this.setState({ calender: ref }, () => {
                            if (autoFocus) {
                                ref.setFocus()
                                ref.setOpen(true)
                                this.handleRegisterClick()
                            }
                        })
                    }
                }}
                customInput={
                    <DateFieldCustomInput
                        showClear={!!showClear && !readonly}
                        label={label}
                        floatingLabel={floatingLabel}
                        wrapperClassName={className}
                        initiallyFocused={autoFocus}
                        modelState={modelState}
                        path={path}
                        onFocus={() => {
                            this.state.calender?.setOpen(true)
                            this.handleRegisterClick()
                        }}
                        onBlur={() => {
                            this.state.calender?.setOpen(false)
                        }}
                    />
                }

                strictParsing={true}
                className="input__field input__field--date"
                dateFormat={this.localeFormat}
                disabled={!!disabled}
                locale={this.props.localization.language}
                maxDate={maxDate}
                minDate={minDate}
                openToDate={openToDate}
                placeholderText={datePlaceholder}
                readOnly={readonly}
                required={required}
                selected={realValue}
                tabIndex={tabIndex}
                onChange={this.handleDateChange}
                onSelect={this.props.ignoreOnSelect ? undefined : this.handleDateChange}
                onInputClick={this.handleRegisterClick}
                onClickOutside={this.handleUnRegisterClick}
                showYearDropdown={true}
                showMonthDropdown={true}
                scrollableYearDropdown={true}
                yearDropdownItemNumber={100}
                showTimeSelect={this.props.showTimeSelect}
                timeFormat={this.timeFormat}
                timeIntervals={this.props.timeIntervals}
                dropdownMode={"scroll"}
            />
        )
    }
}


const Component: React.ComponentClass<Omit<DateFieldProps, keyof LocalizationProps>> = withLocalization(DateFieldAny)
export default Component
