import { FilledTextFieldProps, styled } from "@mui/material"
import { LocalizationProvider, DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useLocalization } from "@tm/localization"
import { Locale } from "date-fns"
import { forwardRef, ReactElement, useCallback, useMemo } from "react"
import { DateUnit } from "@tm/utils"
import { Icon } from "../../Icons"
import { TextField, TextFieldProps } from "../../textfield"
import { getDateFnsLocale, LocalizationType } from "../helper/locale"

const StyledTextField = styled(TextField)({
    ".MuiInputAdornment-positionEnd": {
        margin: 0,
    },
    ".inputButtons": {
        padding: 0,
    },
})

export type DatePickerProps = Omit<MuiDatePickerProps<Date, Date>, "renderInput"> & {
    localisation: LocalizationType
    textfieldProps?: TextFieldProps
    size?: "small" | "medium" | "large" | "extralarge"
    renderInputOverride?: ReactElement
}

function fixUTCDate(date: Date | null): Date | null {
    if (!date) {
        return date
    }

    // Because the datepicker only selects a date and not a time,
    // we create a new date with the time set to UTC 00:00:00
    const newDate = new Date()
    newDate.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate())
    newDate.setUTCHours(0, 0, 0, 0)

    return newDate
}

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
    ({ localisation, renderInputOverride, textfieldProps = { sx: { width: 180 } }, onAccept, onChange, ...rest }, ref) => {
        const { translateText } = useLocalization()

        const showTodayButton =
            (rest.minDate === undefined || !rest.minDate.isAfter(new Date(), DateUnit.Day)) &&
            (rest.maxDate === undefined || !rest.maxDate.isBefore(new Date(), DateUnit.Day))

        const localLanguage = useMemo((): Locale => {
            return getDateFnsLocale(localisation)
        }, [localisation])

        const dateMask = useMemo((): { inputFormat: string; mask: string } => {
            /* use this to see how you should configure the date https://date-fns.org/v2.25.0/docs/format */
            switch (localLanguage.code) {
                case "de":
                case "it": {
                    return {
                        inputFormat: "dd.MM.yyyy",
                        mask: "__.__.____",
                    }
                }

                default: {
                    return {
                        inputFormat: "yyyy/MM/dd",
                        mask: "____/__/__",
                    }
                }
            }
        }, [localLanguage.code])

        const handleAccept = useCallback(
            (date: Date | null) => {
                onAccept?.(fixUTCDate(date))
            },
            [onAccept]
        )

        const handleChange = useCallback(
            (date: Date | null) => {
                onChange(fixUTCDate(date))
            },
            [onChange]
        )

        const renderTextfield = useCallback(
            (params: TextFieldProps) => {
                const castedParams = { ...params, size: rest.size } as FilledTextFieldProps

                if (renderInputOverride) {
                    return React.cloneElement(renderInputOverride as ReactElement, {
                        ...castedParams,
                    })
                }

                return <StyledTextField {...textfieldProps} {...castedParams} />
            },
            [renderInputOverride, textfieldProps, rest.size]
        )

        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localLanguage} localeText={{ todayButtonLabel: translateText(142) }}>
                <MuiDatePicker
                    inputFormat={dateMask.inputFormat}
                    mask={dateMask.mask}
                    ref={ref}
                    disableMaskedInput={false}
                    renderInput={(params) => renderTextfield(params)}
                    components={{
                        OpenPickerIcon: (props) => <Icon name="calendar" height="24px" {...props} />,
                        ...(rest.components || {}),
                    }}
                    componentsProps={{
                        actionBar: showTodayButton
                            ? {
                                  actions: ["today"],
                              }
                            : undefined,
                        ...rest.componentsProps,
                    }}
                    views={["year", "month", "day"]}
                    onAccept={onAccept && handleAccept}
                    onChange={handleChange}
                    {...rest}
                />
            </LocalizationProvider>
        )
    }
)
