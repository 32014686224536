import { ButtonKeyDefinition, concat, equals, registerOutsideClick } from "@tm/utils"
import * as React from "react"

import { Button, Icon, Loader, Popover } from "../"
import { BaseDisableableProps, Positioning, SizedProps } from "../../models/SharedModels"
import { ButtonLayout, ButtonSkins } from "../button"
import DropdownItem from "./components/dropdown-item"
import { ReactNode } from "react"

const SEARCH_KEYS = "abcdefghijklmnopqrstuvwxyz1234567890"

export type DropdownProps<FItem> = {
    items: Array<FItem> | undefined
    value?: FItem
    itemView: React.ComponentType<FItem & WithChangeHandler<FItem>>
    displayView?: React.ComponentType<FItem & WithChangeHandler<FItem>>
    inputView?: React.ComponentType<FItem & WithChangeHandler<FItem> & WithInputRef>
    coverView?: React.ComponentType
    itemWrapper?: React.ComponentType<{ children: React.ReactNode }>
    getSearchValue?: (item: FItem) => string
    amountItemsToShow?: number
    isActive?: boolean
    enableLoaderInDropdown?: boolean
    layout?: Array<ButtonLayout>
    skin?: ButtonSkins
    alignArrow?: Positioning
    onDropdownOpen?: () => void
    onDropdownClose?: () => void
    onPopOverClose?: () => void
    onClick?(e: React.MouseEvent): void
    onFocus?(e: React.FocusEvent): void
    onChange?(value: FItem): void
    onMouseEnter?(): void
    onMouseLeave?(): void
    onShowIndexChange?(direction: 1 | -1): void
    onRef?(ref: HTMLDivElement): void
    submitOnTab?: boolean
    emptyPlaceholder?: string
} & BaseDisableableProps & SizedProps

export type DropdownState<FItem> = {
    open: boolean
    value?: FItem
    startShowIndex: number
    preSelectedItemIndex: number
    alreadyFocused: boolean
    searchValues?: string[]
}

type WithChangeHandler<FItem> = {
    onChange?(value: FItem): void
}

type WithInputRef = {
    inputRef: React.RefObject<HTMLInputElement>
}

export default class Dropdown<TItem extends {}> extends React.PureComponent<DropdownProps<TItem>, DropdownState<TItem>> {
    private baseName = "dropdown"

    private divRef: HTMLDivElement | undefined
    /** this one is the input element of the dropdownbox which handles all the events, like focus, keyboardevent, ... */
    private buttonRef = React.createRef<Button>()
    private inputRef = React.createRef<HTMLInputElement>()

    private recentlyFocused = false
    private unregisterOutsideClick?: () => void

    constructor(props: DropdownProps<TItem>) {
        super(props)

        let searchValues: string[] | undefined
        if (this.props.getSearchValue) {
            searchValues = this.props.items?.map(item => this.props.getSearchValue!(item).toLowerCase())
        }

        this.state = {
            open: false,
            value: this.props.value,
            startShowIndex: 0,
            preSelectedItemIndex: 0,
            alreadyFocused: false,
            searchValues,
        }
    }

    componentWillUnmount() {
        this.unregisterOutsideClick?.()
        this.divRef?.removeEventListener("wheel", this.handleMouseWheel)
    }

    componentDidMount() {
        this.focusInput()
    }

    UNSAFE_componentWillReceiveProps(nextProps: DropdownProps<TItem>) {
        if (!equals(this.props.value, nextProps.value)) {
            this.setState({ value: nextProps.value })
        }

        if (!equals(this.props.items, nextProps.items)) {
            this.handleSetMidItem(nextProps.items)
        }
    }

    public focus = () => {
        this.buttonRef.current?.focus()
    }

    public toggleDropdownMenu = () => {
        if (this.state.open) {
            this.unregisterOutsideClick?.()
            this.props.onDropdownClose?.()
        }
        else {
            this.setUnregisterOutsideClick()
            this.props.onDropdownOpen?.()
        }

        this.setState(prevState => ({ open: !prevState.open }), this.focusInput)
    }

    private focusInput = () => {
        const { current } = this.inputRef

        if (!this.state.open || !current) return

        window.setTimeout(() => {
            current.focus()
            current.select()
        }, 50)
    }

    private handleFocus = (e: React.FocusEvent) => {
        // focus inputViewElement
        if (!this.state.open && !this.state.alreadyFocused) {
            this.props.onFocus?.(e)

            this.handleSetMidItem(this.props.items)
            this.toggleDropdownMenu()

            // need to remember if it was recently focused to handle open/close status of the dropdownbox inside the button onclick event
            this.recentlyFocused = true
        }
    }

    private setUnregisterOutsideClick = () => {
        if (!this.state.open && this.divRef) {
            this.unregisterOutsideClick = registerOutsideClick(this.divRef, this.handlePopoverClose)
        }
    }

    private handleDivRef = (ref: HTMLDivElement | null) => {
        if (ref) {
            this.divRef = ref
            this.props.onRef?.(ref)
            ref.addEventListener("wheel", this.handleMouseWheel, { passive: false })
        }
    }

    private stopBubbling = (e: React.SyntheticEvent) => {
        e.preventDefault()
        e.stopPropagation()
        e.bubbles = false
    }

    private handlePopoverClose = () => {
        this.setState({ alreadyFocused: false, open: false })
        this.props.onPopOverClose?.()
    }

    private handleMouseWheel = (e: WheelEvent) => {
        if ((this.divRef as any).contains(e.target)) {
            e.stopPropagation()
            e.preventDefault()
        }

        const { amountItemsToShow } = this.props

        const deltaIndex = e.shiftKey && amountItemsToShow ? amountItemsToShow : 1

        if (e.deltaY > 0) {
            this.handleShowStartIndex(deltaIndex)
        }
        else {
            this.handleShowStartIndex(-deltaIndex)
        }
    }

    private handleShowStartIndex = (step: number) => {
        const { items, amountItemsToShow } = this.props

        let endReached = false

        if (!amountItemsToShow || step == 0) {
            return endReached
        }

        let { startShowIndex } = this.state

        if (!items) {
            endReached = true
        }
        else if (step < 0 && startShowIndex + step < 0) {
            startShowIndex = 0
            endReached = true
        }
        else if (step > 0 && startShowIndex + step > items.length - amountItemsToShow) {
            startShowIndex = items.length <= amountItemsToShow ? 0 : items.length - amountItemsToShow
            endReached = true
        }
        else {
            startShowIndex += step
        }

        this.props.onShowIndexChange?.(step < 0 ? -1 : 1)

        this.setState({ startShowIndex })
        return endReached
    }

    private handleSetMidItem = (items: DropdownProps<TItem>['items'] = []) => {
        const { amountItemsToShow = items.length } = this.props
        const { value } = this.state

        let itemIndex = !value ? 0 : items.findIndex(item => equals(value, item))
        if (itemIndex < 0) itemIndex = 0

        // index of the item in props which has to be the first item of the filtered items when the selected item should be in the middle
        const start = itemIndex - Math.round(amountItemsToShow / 2)

        // there is no index lower 0
        let startShowIndex = 0
        
        if (start > 0 && items.length >= amountItemsToShow) {
            startShowIndex = start >= items.length - amountItemsToShow ? items.length - amountItemsToShow : start
        }

        this.setState({ startShowIndex, preSelectedItemIndex: itemIndex - startShowIndex })
    }

    private handleToggleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        // i know this is an ugly fix, but there is a presentation on monday
        const buttonElementRef: Element | undefined = (this.buttonRef.current as any)?.buttonElement?.current
        const dropdownChildren = buttonElementRef?.children && Array.from(buttonElementRef.children)

        const childWasClicked = (ref: Element): boolean => {
            const refChildren = ref.children && Array.from(ref.children)

            if (ref == e.target) {
                return true
            }

            if (refChildren && refChildren.length > 0) {
                return refChildren.some(childWasClicked)
            }

            return false
        }

        if (buttonElementRef && e.target && e.target != buttonElementRef &&
            Array.isArray(dropdownChildren) &&
            !dropdownChildren.some(childWasClicked)) {

            this.stopBubbling(e)
            return
        }

        this.props.onClick?.(e)

        this.stopBubbling(e)

        if (this.props.disabled) return

        if (!this.state.alreadyFocused || this.recentlyFocused) {
            this.recentlyFocused = false
            this.setState({ alreadyFocused: true })
            this.focusInput()
            return
        }

        this.toggleDropdownMenu()

        this.focusInput()
    }

    private handleSelectItem = (item: TItem) => {
        this.setState({ value: item })

        this.props.onChange?.(item)

        this.handlePopoverClose()
    }

    private handleItemClick = (e: React.MouseEvent<HTMLDivElement>, item: TItem) => {
        this.stopBubbling(e)
        this.handleSelectItem(item)
    }

    private handleKeyPress = (e: React.KeyboardEvent) => {
        const { items, submitOnTab } = this.props

        if (!items) {
            return
        }

        switch (e.key) {
            case ButtonKeyDefinition.Enter: {
                const { startShowIndex, preSelectedItemIndex } = this.state
                this.handleSelectItem(items[preSelectedItemIndex + startShowIndex])
                return
            }
            case ButtonKeyDefinition.Tab: {
                if (submitOnTab && this.state.open) {
                    const { startShowIndex, preSelectedItemIndex } = this.state
                    this.handleSelectItem(items[preSelectedItemIndex + startShowIndex])
                }
                return
            }
            case ButtonKeyDefinition.ArrowUp:
            case ButtonKeyDefinition.ArrowDown: {
                this.stopBubbling(e)

                const { amountItemsToShow } = this.props
                const { preSelectedItemIndex } = this.state

                switch (e.key) {
                    case ButtonKeyDefinition.ArrowUp:
                        if (preSelectedItemIndex > 0) {
                            this.setState({
                                preSelectedItemIndex: preSelectedItemIndex - 1,
                            })
                        }
                        else {
                            const endReached = this.handleShowStartIndex(-1)
                            if (endReached || !amountItemsToShow) {
                                this.setState({
                                    preSelectedItemIndex: (amountItemsToShow ?? items.length) - 1,
                                    startShowIndex: items.length - (amountItemsToShow ?? items.length),
                                })
                            }
                        }
                        break
                    case ButtonKeyDefinition.ArrowDown:
                        if (preSelectedItemIndex < ((amountItemsToShow ?? items.length) - 1)) {
                            this.setState({
                                preSelectedItemIndex: preSelectedItemIndex + 1,
                            })
                        }
                        else {
                            const endReached = this.handleShowStartIndex(1)
                            if (endReached || !amountItemsToShow) {
                                this.setState({
                                    preSelectedItemIndex: 0,
                                    startShowIndex: 0
                                })
                            }
                        }
                        break
                }

                return
            }
        }

        // jump directly to the position which first char is equals to the typed key
        if (this.state.searchValues) {
            const key = e.key.toLowerCase()
            if (SEARCH_KEYS.indexOf(key) != -1) {
                const startShowIndex = this.state.searchValues.findIndex(x => x[0] == key)
                if (startShowIndex != -1) {
                    const step = startShowIndex - this.state.startShowIndex
                    this.handleShowStartIndex(step)
                }
            }
        }

    }

    private handleArrowUpClick = (e: React.MouseEvent) => {
        this.stopBubbling(e)
        this.handleShowStartIndex(-1)
        this.handleArrowClick()
    }

    private handleArrowDownClick = (e: React.MouseEvent) => {
        this.stopBubbling(e)
        this.handleShowStartIndex(1)
        this.handleArrowClick()
    }

    private handleArrowClick = () => {
        if (this.inputRef.current) {
            this.focusInput()
        }
        else {
            window.setTimeout(() => this.buttonRef.current?.focus(), 0)
        }
    }

    private renderArrow = (direction: "up" | "down") => {
        const { amountItemsToShow } = this.props
        const { startShowIndex } = this.state

        if (!amountItemsToShow) return

        let disabled: boolean
        let onClick: React.MouseEventHandler

        switch (direction) {
            case "up":
                disabled = startShowIndex <= 0
                onClick = this.handleArrowUpClick
                break
            case "down":
                disabled = startShowIndex + amountItemsToShow >= (this.props.items?.length || 0)
                onClick = this.handleArrowDownClick
                break
        }

        return (
            <div
                key={`amount-item-${direction}`}
                className={concat(" ", `${this.baseName}__icon`, disabled && `${this.baseName}__icon--disabled`)}
                onClick={onClick}
            >
                <Icon name={direction} size="s" />
            </div>
        )
    }

    private renderItem = (item?: TItem, index?: number) => {
        if (!item) return

        const { value, preSelectedItemIndex } = this.state

        const className = concat(" ",
            `${this.baseName}__item`,
            equals(value, item) && `${this.baseName}__item--selected`,
            preSelectedItemIndex == index && `${this.baseName}__item--preselected`
        )

        return (
            <DropdownItem<TItem>
                key={"dropdown-item#" + index}
                item={item}
                View={this.props.itemView}
                className={className}
                isListItem
                onClick={this.handleItemClick}
            />
        )
    }

    private generateOptionList = (): ReactNode => {
        const {
            items,
            enableLoaderInDropdown,
            emptyPlaceholder,
            amountItemsToShow
        } = this.props
        const { startShowIndex } = this.state

        const displayItems = this.props.items?.slice(startShowIndex, amountItemsToShow ? startShowIndex + amountItemsToShow : this.props.items?.length || 0)

        if (!items || !displayItems) {
            return enableLoaderInDropdown && <Loader />
        }

        if (displayItems && displayItems.length === 0) {
            return emptyPlaceholder || '--'
        }

        return displayItems.map(this.renderItem)
    }

    render() {
        const { open, value } = this.state
        const {
            size,
            skin,
            layout,
            disabled,
            displayView,
            inputView,
            isActive,
            coverView: CoverView,
            itemWrapper: ItemWrapper,
            alignArrow,
        } = this.props

        const className = concat(" ", this.baseName, open && "is-active", value && "has-value", this.props.className)
        const View = this.props.itemView
        const DisplayView = displayView || View
        const InputView = inputView || undefined

        const popoverArrowPosition: Positioning = ["top"]
        if (layout?.includes("iconRight")) {
            popoverArrowPosition.push("right")
        }


        const optionList = (
            <div className={`${this.baseName}__items ${this.baseName}__items--centered`}>
                {this.generateOptionList()}
            </div>
        )

        return (
            <div
                className={className}
                onMouseUp={this.handleToggleClick}
                ref={this.handleDivRef}
                onFocus={this.handleFocus}
                onKeyDown={this.handleKeyPress}
                onMouseEnter={this.props.onMouseEnter}
                onMouseLeave={this.props.onMouseLeave}
            >
                <Button
                    isActive={isActive}
                    ref={this.buttonRef}
                    disabled={disabled}
                    onClick={(e) => e.stopPropagation()}
                    icon={open ? "up" : "down"}
                    layout={layout}
                    skin={skin}
                    size={size}
                >
                    {
                        this.state.value
                            ? <DisplayView {...this.state.value} onChange={this.handleSelectItem} />
                            : (CoverView && <CoverView />)
                    }
                </Button>
                <Popover
                    alignArrow={alignArrow ?? popoverArrowPosition}
                    className={`${this.baseName}__box`}
                    active={open}
                    onOutsideInteraction={(e) => {
                        if (!(this.divRef as any).contains(e.target)) {
                            this.setState((pref) => ({ ...pref, open: !pref.open }))
                        }
                    }}
                >
                    <>
                        {
                            InputView && !!this.state.value &&
                            <InputView
                                {...this.state.value}
                                inputRef={this.inputRef}
                                onChange={this.handleSelectItem}
                            />
                        }

                        {this.renderArrow("up")}

                        {ItemWrapper ? <ItemWrapper>{optionList}</ItemWrapper> : optionList}

                        {this.renderArrow("down")}
                    </>
                </Popover>
            </div>
        )
    }
}
