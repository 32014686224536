import { memo, SVGProps } from "react"

export const SvgFallback = memo<SVGProps<SVGSVGElement>>((props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g fill="#767676" opacity=".2">
            <path d="M7 13c0 .5.1 1.1.3 1.6L9 12.8c.1-1.5 1.3-2.7 2.8-2.8l1.7-1.7c-.4-.2-1-.3-1.5-.3-2.8 0-5 2.2-5 5z" />
            <path d="M4 8h4.5l2-3h2.9l1.3 2 1.4-1.4L15 3.9c-.4-.6-1-.9-1.7-.9h-2.9c-.5 0-1.2.3-1.5.9L7.5 6H4a2 2 0 00-2 2v11c0 .2 0 .5.1.7L4 17.8V8zM20.3 6l2.2-2.2-1.1-1.1L2.9 21.2 4 22.4 5.4 21H20a2 2 0 002-2V8c0-1-.7-1.8-1.7-2zm-5.6 5.7c.2.4.3.8.3 1.3 0 1.7-1.3 3-3 3-.5 0-.9-.1-1.3-.3l4-4zM18.4 8H20v11H7.4l1.8-1.8c.8.5 1.8.8 2.8.8 2.8 0 5-2.2 5-5 0-1-.3-2-.8-2.8L18.4 8z" />
        </g>
    </svg>
))
