import { memo } from "react"
import { Skeleton, styled } from "@mui/material"

import { ArticleImage } from "@tm/models"
import { Image } from "../../generics/image"

type Props = {
    image: ArticleImage
    isLoading: boolean
}

const IMAGEHEIGHT = "420px"

const StyledImage = styled(Image)(() => ({
    transition: "none",
    maxWidth: "1060px",
    height: IMAGEHEIGHT,
}))

export const ImageViewerImage = memo<Props>(({ image, isLoading }) => {
    return isLoading ? (
        <Skeleton variant="rectangular" height={IMAGEHEIGHT} sx={{ ml: 8, mr: 8, mt: 2 }} />
    ) : (
        <StyledImage src={image.imageUrl} title={image.description} className="image-viewer__image" />
    )
})
