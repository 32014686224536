import { useQuery, useQueryClient, useMutation, UseMutateAsyncFunction } from "react-query"
import { Container } from "@tm/nexus"
import { RegisteredModels, PartsContainer, Note } from "@tm/models"
import { atom, useSetRecoilState } from "recoil"

const KEY_LOAD_ARTICLE_NOTE = "articleNote_load"

export const ArticleNotesState = atom<Note[]>({ key: "ArticleNotesState", default: [] })

export const useLoadNotes = (articleIds: string[]): { articleNotes: Note[]; isLoading: boolean; error: any } => {
    const { data, isLoading, error } = useQuery<Note[]>(
        [KEY_LOAD_ARTICLE_NOTE, articleIds.join(",")],
        () => {
            const container = Container.getInstance(RegisteredModels.Article_Notes) as PartsContainer
            return container.action("loadArticleNoteByNoteIds")(articleIds).then()
        },
        { staleTime: 60 * 1000, enabled: !!articleIds.length }
    )

    return { articleNotes: data || [], isLoading, error }
}

export const useSaveNote = (): { saveNote: UseMutateAsyncFunction<void, unknown, Note>; saveNoteInProgress: boolean } => {
    const queryClient = useQueryClient()
    const setNotes = useSetRecoilState(ArticleNotesState)
    const container = Container.getInstance(RegisteredModels.Article_Notes) as PartsContainer
    const mutation = useMutation<void, unknown, Note>(
        (articleNote: Note) => {
            return container.action("saveArticleNote")(articleNote)
        },
        {
            onSuccess: async (_, note) => {
                queryClient.resetQueries([KEY_LOAD_ARTICLE_NOTE])
                const savedNote = (await container.action("loadArticleNoteByNoteIds")([note.noteId]))[0]
                setNotes((prev) => {
                    const existing = prev.find((x) => x.noteId === savedNote.noteId)
                    return [...prev.filter((x) => x !== existing), savedNote]
                })
            },
        }
    )

    return { saveNote: mutation.mutateAsync, saveNoteInProgress: mutation.isLoading }
}

export const useDeleteNote = (): { deleteNote: UseMutateAsyncFunction<void, unknown, string>; deleteNoteInProgress: boolean } => {
    const queryClient = useQueryClient()
    const setNotes = useSetRecoilState(ArticleNotesState)
    const mutation = useMutation(
        (articleNoteId: string) => {
            const container = Container.getInstance(RegisteredModels.Article_Notes) as PartsContainer
            return container.action("deleteArticleNote")(articleNoteId)
        },
        {
            onSuccess: (_, noteId) => {
                queryClient.resetQueries([KEY_LOAD_ARTICLE_NOTE])
                setNotes((prev) => prev.filter((x) => x.noteId !== noteId))
            },
        }
    )

    return { deleteNote: mutation.mutateAsync, deleteNoteInProgress: mutation.isLoading }
}
