import { SelectChangeEvent, Typography, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { Dispatch, SetStateAction, forwardRef } from "react"
import { Icon } from "../../generics/Icons"
import { Select, SelectItem } from "../../generics/select"

const vehicles: Array<{ vehicleType: VehicleType; icon: string; translateId: number }> = [
    { vehicleType: VehicleType.PassengerCar, icon: "car-front", translateId: 1234 },
    { vehicleType: VehicleType.Motorcycle, icon: "bike", translateId: 730 },
    { vehicleType: VehicleType.CommercialVehicle, icon: "truck-simple", translateId: 1915 },
]

const StyledSelect = styled(Select)(() => ({
    width: "fit-content",
    minWidth: "93px",
    height: "32px",
    backgroundColor: "#e2e2e2",
    "& .MuiSelect-outlined": {
        alignItems: "center",
        padding: "8px 28px 8px 13px !important",
    },
    "& .MuiSelect-icon": {
        left: "inherit",
    },
}))

export const VehicleSelection = forwardRef<
    HTMLDivElement,
    {
        vehicleType: VehicleType
        setVehicleType: Dispatch<SetStateAction<VehicleType>>
    }
>((props, ref) => {
    const { vehicleType, setVehicleType } = props
    const { translateText } = useLocalization()

    function handleVehicleTypeChange(e: SelectChangeEvent<unknown>) {
        if (e.target && (e.target.value as number) >= 0) {
            setVehicleType(e.target.value as number)
        }
    }

    const defaultSelectValue = vehicleType
    return (
        <StyledSelect
            ref={ref}
            variant="outlined"
            SelectDisplayProps={{ style: { display: "flex", paddingBottom: 0, paddingTop: 0, paddingRight: 16 } }}
            value={defaultSelectValue}
            onChange={handleVehicleTypeChange}
            renderValue={(value) => {
                const vehicle = vehicles.find((v) => v.vehicleType === value)
                return (
                    vehicle && (
                        <>
                            <Icon name={vehicle.icon} size="16px" />
                            <Typography variant="body2" paddingLeft="0.5em">
                                {translateText(vehicle.translateId)}
                            </Typography>
                        </>
                    )
                )
            }}
        >
            {vehicles.map((vehicle) => {
                return (
                    <SelectItem key={`key-${vehicle.vehicleType}`} value={vehicle.vehicleType} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Icon name={vehicle?.icon} size="16px" /> <Typography paddingLeft=".5em">{translateText(vehicle.translateId)}</Typography>
                    </SelectItem>
                )
            })}
        </StyledSelect>
    )
})
