import { forwardRef, PropsWithChildren, ReactElement } from "react"
import { Box, Dialog as MuiDialog, DialogProps, dialogClasses, styled, useTheme, rgbToHex } from "@mui/material"
import { Theme } from "@mui/system"
import { Icon } from "../../generics/Icons"

export type TmDialogProps = Omit<DialogProps, "onClose"> & {
    onOutsideClick?(): void
    position?: "top" | "middle"
    skin?: "warning" | "error" | "info" | "success"
    customSkinIcon?: ReactElement
    icon?: string
    DialogActions?: ReactElement
}

const StyledDialog = styled(MuiDialog, {
    shouldForwardProp: (prop) => prop !== "position",
})<Pick<TmDialogProps, "position">>(({ position }) => ({
    [`.${dialogClasses.scrollPaper}`]: {
        height: position === "top" ? "inherit" : "100%",
        maxHeight: "inherit",
    },
    [`.${dialogClasses.paper}`]: {
        margin: position === "top" ? "0" : "32px",
    },
}))

const StyledInnerDialogBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "skin",
})<Pick<TmDialogProps, "skin">>(({ skin, theme }) => {
    switch (skin) {
        case "warning": {
            return {
                borderLeft: `7px solid ${theme.palette.warning.main}`,
            }
        }
        case "error": {
            return {
                borderLeft: `7px solid ${theme.palette.error.main}`,
            }
        }
        case "info": {
            return {
                borderLeft: `7px solid ${theme.palette.info.main}`,
            }
        }
        case "success": {
            return {
                borderLeft: `7px solid ${theme.palette.success.main}`,
            }
        }
        default: {
            return {}
        }
    }
})

const getMapping = (skin: "warning" | "error" | "info" | "success" | undefined, theme: Theme) => {
    const errorBlack = rgbToHex(theme.palette.error.contrastText).startsWith("#000")
    const infoBlack = rgbToHex(theme.palette.info.contrastText).startsWith("#000")
    const successBlack = rgbToHex(theme.palette.success.contrastText).startsWith("#000")
    const warningBlack = rgbToHex(theme.palette.warning.contrastText).startsWith("#000")

    switch (skin) {
        case "error": {
            return <Icon name={warningBlack ? "alert_B_attention" : "alert_W_attention"} color="error" size="24px" />
        }
        case "info": {
            return <Icon name={infoBlack ? "alert_B_infomation" : "alert_W_infomation"} color="info" size="24px" />
        }
        case "success": {
            return <Icon name={successBlack ? "alert_B_success" : "alert_W_success"} color="success" size="24px" />
        }
        case "warning": {
            return <Icon name={errorBlack ? "alert_B_warning" : "alert_W_warning"} color="warning" size="24px" />
        }
        default: {
            return ""
        }
    }
}

export const Dialog = forwardRef<HTMLDivElement, PropsWithChildren<TmDialogProps>>(
    ({ skin, onOutsideClick, DialogActions, children, customSkinIcon, ...rest }, ref) => {
        const theme = useTheme()
        const displayIcon = skin ? customSkinIcon || <Box mr={1.5}>{getMapping(skin, theme)}</Box> : ""

        return (
            <>
                <StyledDialog ref={ref} {...rest} onClose={onOutsideClick}>
                    <StyledInnerDialogBox skin={skin} px={4} py={3}>
                        <Box display="flex">
                            {displayIcon ?? <Box>{displayIcon}</Box>}
                            <Box flex={1}>{children}</Box>
                            {DialogActions && <Box ml={1.5}>{DialogActions}</Box>}
                        </Box>
                    </StyledInnerDialogBox>
                </StyledDialog>
            </>
        )
    }
)
