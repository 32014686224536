import { useCallback } from "react"
import { useHistory, useParams } from "react-router"
import { encodeUniqueId } from "../.."
import { renderRoute } from "../../helpers/url"

type InputParams = {
    moduleName?: string
    partsDetailsUrl: string
    workTaskId?: string
    openModal?(modalIndex: "1", url: string): void
}

// TODO:  When only 'useOpenArticleDetailsClickHandle' is used in ALL bundles instead of useOpenArticleDetails, rename it to useOpenArticleDetails
export function useOpenArticleDetailsClickHandle({ moduleName, partsDetailsUrl, workTaskId, openModal }: InputParams) {
    const history = useHistory()
    const matchParams = useParams<{ workTaskId?: string }>()

    const handleOpenArticleDetails = useCallback(
        (productGroupId?: number, supplierArticleNumber?: string, supplierId?: number, subPage?: string, scrollTo?: string) => {
            if (supplierId && supplierArticleNumber && productGroupId) {
                const url = renderRoute(partsDetailsUrl, {
                    ...matchParams,
                    module: moduleName,
                    productGroupId,
                    supplierId,
                    supplierArticleNo: supplierArticleNumber ? encodeURIComponent(supplierArticleNumber) : undefined,
                    partsDetailsSubPage: subPage ? encodeURIComponent(subPage) : undefined,
                    scrollTo: subPage && scrollTo ? encodeURIComponent(scrollTo) : undefined,
                    workTaskId: workTaskId ? encodeUniqueId(workTaskId) : matchParams.workTaskId,
                })
                if (openModal) {
                    openModal("1", url)
                } else {
                    history.push(url)
                }
            }
        },
        [history, matchParams, partsDetailsUrl, moduleName, workTaskId, openModal]
    )

    return handleOpenArticleDetails
}
