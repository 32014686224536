import { Box, styled } from "@mui/material"
import { ReactNode } from "react"

type ManufacturerCardProps = {
    children: ReactNode
    handleClick: () => void
}

const OuterBox = styled(Box)(() => ({
    margin: "0 7.5px 0 0",
    padding: 0,
    width: "61.5px",
    height: "32px",
    alignContent: "center",
    cursor: "pointer",
}))

const InnerBox = styled(Box)(({ theme }) => ({
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontSize: ".75rem",
    position: "relative",
    background: "#fafafa",
    border: `1px solid transparent`,
    borderRadius: "3px",
    boxShadow: `0 1px 3px 0 rgba(0, 0, 0, .27)`,
    transition: "all 0.2s ease",
    "&:hover": {
        borderColor: theme.colors?.primary,
    },
}))

export default function ManufacturerCard(props: ManufacturerCardProps) {
    const { handleClick } = props

    return (
        <OuterBox>
            <InnerBox onClick={handleClick}>{props.children}</InnerBox>
        </OuterBox>
    )
}
