import * as React from "react"
import { WorkTask } from "../model";
import { WorkTaskProviderContext } from "../model/context";
import { Omit } from "@tm/utils";

export type WithWorkTaskConflictProps = Pick<WorkTask, "triggerWorkTaskConflict" | "workTaskConflict">

export function withWorkTaskConflict<P extends WithWorkTaskConflictProps>(component: React.ComponentType<P>): React.ComponentClass<Omit<P, keyof WithWorkTaskConflictProps>> {
    return class extends React.Component<Omit<P, keyof WithWorkTaskConflictProps>> {

        showError() {
            console.error("withWorkTaskConflict: No wrapping WorkTask Provider was found!", component)
        }

        renderInnerComponent(workTask?: WorkTask) {

            const currentProps: any = this.props || {}

            const props: P & WithWorkTaskConflictProps = {
                ...currentProps
            }

            if (!workTask) {
                this.showError()
                props.triggerWorkTaskConflict = () => this.showError()
            }
            else {
                props.triggerWorkTaskConflict = workTask.triggerWorkTaskConflict
                props.workTaskConflict = workTask.workTaskConflict
                // props.workTaskLoading = workTask.workTaskLoading
            }

            return React.createElement(component, props)
        }

        render() {
            return (
                <WorkTaskProviderContext.Consumer>
                    {(workTask?: WorkTask) => this.renderInnerComponent(workTask)}
                </WorkTaskProviderContext.Consumer>
            )
        }
    }
}
