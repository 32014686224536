import * as Locales from "date-fns/locale"
import { Locale } from "date-fns"

export type LocalizationType = {
    locale: string
    region: string | null
}

export const getDateFnsLocale = ({ locale, region }: LocalizationType): Locale => {
    let searchLocal = ""
    let retValue
    if (region !== null) {
        searchLocal = locale.substring(0, 2) + region
        retValue = Object.entries(Locales).find((entry) => entry[0] === searchLocal)?.[1]
    } else {
        searchLocal = locale.substring(0, 2)
        retValue = Object.entries(Locales).find((entry) => entry[0].substring(0, 2) === searchLocal)?.[1]
    }
    return retValue || Locales.enGB
}
