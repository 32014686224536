import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps, styled } from "@mui/material"
import { ElementType, FC } from "react"

export type IconButtonProps = MuiIconButtonProps & {
    component?: ElementType
    variant?: "fill"
}

export const IconButton: FC<IconButtonProps> = styled(MuiIconButton)(({ color, theme, size }) => {
    const themeColor = color && (color === "inherit" ? undefined : theme.palette?.[color === "default" ? "primary" : color])
    return {
        color: themeColor?.main,
        verticalAlign: "top",

        "&.MuiButtonBase-root:hover": {
            backgroundColor: "inherit",
            ...(themeColor && {
                color: themeColor.light,
            }),
        },

        ...(size === "small" && {
            padding: "4px",

            svg: {
                width: "18px",
                height: "18px",
                minWidth: "18px",
                minHeight: "18px",
            },
        }),

        ...(size === "medium" && {
            padding: "6px",

            svg: {
                width: "20px",
                height: "20px",
            },
        }),

        ...(size === "large" && {
            padding: "9px",

            svg: {
                width: "20px",
                height: "20px",
            },
        }),

        ...(size === "extralarge" && {
            padding: "12px",

            svg: {
                width: "24px",
                height: "24px",
            },
        }),
    }
})
