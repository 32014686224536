import { IContext, IBundleWithTemplates, TemplateRenderer } from "..";

export function renderTemplate<R extends TemplateRenderer>(context: IContext, bundleName: string, name: string, renderer: R) {
    const target = renderer.rendererIdentifier
    if (!target) return

    const bundle = context.bundles[bundleName] as IBundleWithTemplates
    if (!bundle || !bundle.templates) return

    const template = bundle.templates.find(x => x.name == name)
    if (!template || template.targetComponent.bundle != target.bundle || template.targetComponent.component != target.component) return

    return template.render(renderer)
}
