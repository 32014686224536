import { Customer, RegistrationNoType, Vehicle, VehicleShortInfo } from "@tm/models"

export function mapVehicle(data: any): Vehicle {
    return {
        ...data,
        registrationTypeId:
            data.registrationTypeId === undefined && data.countryCode?.toLowerCase() === "de" ? RegistrationNoType.Kba : data.registrationTypeId, // dotnet removes the 0 in the response. so when country code is set and this field is empty we assume it's kba
        created: data.created ? new Date(data.created) : undefined,
        modified: data.modified ? new Date(data.modified) : undefined,
        initialRegistration: data.initialRegistration ? new Date(data.initialRegistration) : undefined,
        nextGeneralInspection: data.nextGeneralInspection ? new Date(data.nextGeneralInspection) : undefined,
        lastGeneralInspection: data.lastGeneralInspection ? new Date(data.lastGeneralInspection) : undefined,
        nextServiceDate: data.nextServiceDate ? new Date(data.nextServiceDate) : undefined,
        attachedOn: data.attachedOn ? new Date(data.attachedOn) : undefined,
        countryCode: data.countryCode?.toLowerCase(),
    }
}

export function mapVehicleShortInfo(data: any): VehicleShortInfo | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        attachedOn: data.attachedOn ? new Date(data.attachedOn) : undefined,
        initialRegistration: data.initialRegistration ? new Date(data.initialRegistration) : undefined,
    }
}

export function mapCustomer(data: any): Customer {
    return {
        ...data,
        birthDate: data.birthDate ? new Date(data.birthDate) : undefined,
        created: data.created ? new Date(data.created) : undefined,
        modified: data.modified ? new Date(data.modified) : undefined,
    }
}
