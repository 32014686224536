import { cssRaw } from "typestyle"

/**
 * Generate a classname using an array. The first item of the array is the base class and the rest are used as a modifier
 * @returns {string}
 */
export function bem(...classes: (string | false | undefined | null)[]): string {
    if (classes.length < 2) {
        return classes.first() || ""
    }
    return classes
        .filter((c) => c)
        .map((x, idx) => (idx && `${classes.first()}--${x}`) || x)
        .join(" ")
}

/**
 * Generate a classname using an array or an object as input. All inputs are coverted into a string with empty space as delimitator
 * @returns {string}
 */
export function classes(...classes: (string | false | undefined | null | { [className: string]: any })[]): string {
    return classes
        .map((c) => (c && typeof c === "object" ? Object.keys(c).map((key) => !!c[key] && key) : [c]))
        .reduce((flattened, c) => flattened.concat(c), [] as string[])
        .filter((c) => !!c)
        .join(" ")
}

/**
 * Inject css directly into dom.
 * It's recomend to add style using (useStyle or withStyle HOC from @tm/contextDistribution)
 * usage css\` body{display:none} \`
 * @param {string} validCss
 * @returns {void}
 */
export function css(validCss: TemplateStringsArray): void {
    cssRaw(validCss as unknown as string)
}
