import { Component, createElement, ReactNode, ComponentClass } from "react"
import { Dictionary } from "./models/dictionary"

export type LayoutClass = ComponentClass<LayoutProps>

export type LayoutProps<TProps = any> = {
    children: Dictionary<Array<ReactNode>>
    slotClassNames?: Array<string> | Dictionary<string>
    props?: TProps
    setModuleVisibility(visible: boolean): void
}

export class Layout<TProps = any> extends Component<LayoutProps<TProps>> {
    renderSlot(position: number | string): ReactNode {
        if (!this.props.children[position]) {
            return
        }

        let className = `layout__slot layout__slot-${position}`

        let { slotClassNames } = this.props

        if (slotClassNames) {
            // Legacy fallback:
            // If the position is supplied as string and the slotClassNames as array
            // the index signatures are not compatible and the array has to be mapped to an object
            // with the keys equal to the array indices
            if (typeof position == "string" && Array.isArray(slotClassNames)) {
                slotClassNames = { ...slotClassNames }
            }

            // Fix typescript index signature error
            slotClassNames = slotClassNames as Dictionary<string>

            if (slotClassNames[position]) {
                className += ` ${slotClassNames[position]}`
            }
        }

        return (
            createElement("div", { className },
                !!this.props.children[position].length && this.props.children[position].map(element => element)
            )
        )
    }
}
