import { ajax } from "@tm/utils"
import { DEFAULT_THEME, NextTheme } from "@tm/components"

export * from "./ThemeProvider"
export * from "./ThemeProviderContext"
export * from "./withTheme"


export const THEME_IDENTIFIER = "__NEXT_STYLETHEME__"
let _theme: NextTheme

/**
 * @deprecated Please use properties from the MUI theme instead by using `styled` from `@tm/components` or the `sx` property existing for MUI components.
 */
export function getStyleTheme(): NextTheme {
    if (typeof window != "undefined") {
        return (window as any)[THEME_IDENTIFIER]
    }
    return _theme
}


export function loadStyleThemes(themeUrl: string, themeMuiUrl: string) {

    const nextThemeJson = ajax ({ url: themeUrl })
    const muiThemeJson = ajax({ url: themeMuiUrl })
    const themes = Promise.allSettled([
        nextThemeJson,
        muiThemeJson
    ])

    themes.then(settledThemes => {
        const [next, mui] = settledThemes
        if (next.status === "rejected") {
            return
        }
        if (next.value && typeof next.value === "object") {
            setStyleTheme(next.value)
        }

        if (mui.status === "fulfilled" && mui.value && typeof mui.value === "object") {
            appendMuiTheme(mui.value)
        }
    })

    return themes
}


export function setStyleTheme(theme: NextTheme) {
    theme = {
        ...DEFAULT_THEME,
        ...theme,
        font: {
            ...DEFAULT_THEME.font,
            ...theme?.font,
        }
    }
    if (typeof window !== "undefined") {
        (window as any)[THEME_IDENTIFIER] = theme
    }
    _theme = theme
}


export function appendMuiTheme(muiTheme: NextTheme) {
    const theme = ((window || {}) as any)[THEME_IDENTIFIER] || _theme

    const fullTheme = {
        ...theme,
        ...muiTheme
    }

    if (typeof window !== "undefined") {
        (window as any)[THEME_IDENTIFIER] = fullTheme
    }
    _theme = fullTheme
}
