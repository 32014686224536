export const fillArray = (min: number, max: number, step: number) => {
    const values: number[] = []
    let nextVal = min
    while (nextVal < max + step) {
        const val = parseFloat(nextVal.toFixed(1))
        values.push(val)
        nextVal += step
    }
    return values
}
