import { shareCarteligence, shareDownload, shareEmail, shareWhatsApp } from "./helpers"
import { ShareOption, ShareType } from "./models"

export const DefaultShareOptions: Record<ShareType, ShareOption> = {
    [ShareType.Email]: {
        type: ShareType.Email,
        icon: "envelope",
        title: 109,
        shareActionButtonLabel: 642,
        handler: shareEmail,
        initialEmails: [""],
        subject: "",
        toastMessage: {
            failure: {
                message: 13808,
                icon: "error",
                skin: "warning",
            },
            success: {
                message: 13807,
                icon: "check",
                skin: "success",
            },
        },
    },
    [ShareType.WhatsApp]: {
        type: ShareType.WhatsApp,
        icon: "whatsapp",
        title: 12784,
        shareActionButtonLabel: 642,
        handler: shareWhatsApp,
    },
    [ShareType.Carteligence]: {
        type: ShareType.Carteligence,
        icon: "cartelligence-small",
        title: 13358,
        shareActionButtonLabel: 642,
        disabled: true,
        handler: shareCarteligence,
        toastMessage: {
            failure: {
                message: 13810,
                icon: "error",
                skin: "warning",
            },
            success: {
                message: 13809,
                icon: "check",
                skin: "success",
            },
        },
    },
    [ShareType.Download]: {
        type: ShareType.Download,
        icon: "download",
        title: 13293,
        shareActionButtonLabel: 13293,
        handler: shareDownload,
    },
}

export const DefaultShareOptionList = [
    DefaultShareOptions.WhatsApp,
    DefaultShareOptions.Email,
    DefaultShareOptions.Carteligence,
    DefaultShareOptions.Download,
]

export { Share } from "./components/Share"
export { ShareType } from "./models"
export type { ShareOption } from "./models"
