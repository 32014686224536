import { SearchtreeV2 } from "./components/SearchtreeV2"
import { Searchtree } from "./components/Searchtree"
import { SectionList } from "./components/SectionList"
import { ButtonControls } from "./components/Button"
import { ArticleList } from "./components/ArticleList"
import { Costestimation } from "./components/Costestimation"
import { Worktask } from "./components/Worktask"
import { TabsControls } from "./components/Tabs"
import { Settings } from "./components/Settings"

const UIA = {
    ...SearchtreeV2,
    ...Searchtree,
    ...SectionList,
    ...ButtonControls,
    ...ArticleList,
    ...Costestimation,
    ...Worktask,
    ...TabsControls,
    ...Settings,
}

type UIAKeys = keyof typeof UIA

/**
 * UIA "UI Automation" returns an html attribute object needed for the UI automation Testing
 * @param key component Key
 * @param data optional: add the attribute "data-value" with the given data string
 * @returns mimimum html data object with identifier and type
 */
export function getUIA(key: UIAKeys, data?: string | number) {
    if (data) {
        return { ...UIA[key], "data-value": data }
    }
    return UIA[key]
}
