import { CacheKey } from "."
import { CacheEntry } from "./response-cache"

export type CachingCounter = Record<
    CacheKey,
    {
        called: number
        totalResponseChars: number
        sharedRequests: number
        url: string
    }
>

const cachingCounter: CachingCounter = {}
window.cc = cachingCounter // Make stats available on window object to inspect them using the browser dev tools

export function logEntry(key: CacheKey, requestUrl: string, cacheEntry: CacheEntry) {
    if (window.logCaching) {
        setTimeout(() => {
            try {
                count(key, requestUrl, cacheEntry)
            } catch (e) {}
        }) // setTimeout - to be "non blocking"
    }
}

function count(key: CacheKey, requestUrl: string, cacheEntry: CacheEntry) {
    const response = JSON.stringify(cacheEntry.response || "")

    let entry = cachingCounter[key]

    if (!entry) {
        entry = {
            url: requestUrl,
            called: 1,
            sharedRequests: cacheEntry.finished ? 0 : 1,
            totalResponseChars: response.replace(/(\")/g, "").length,
        }
    } else {
        entry = {
            ...entry,
            called: entry.called + 1,
            sharedRequests: entry.sharedRequests + (cacheEntry.finished ? 0 : 1),
            totalResponseChars: entry.totalResponseChars + response.replace(/(\")/g, "").length,
        }
    }

    cachingCounter[key] = entry
}
