import { Box, styled, useMediaQuery, useTheme } from "@mui/material"
import { useMemo } from "react"
import { Modal } from "../../components/modal"
import { Button } from "../button"
import { Icon } from "../Icons"
import { ScrollContainer } from "../scrollContainer"

const StyledButtonWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.margin?.s ?? "0.5em",
}))

const ContentFlex = styled(Box)({
    padding: "2em",
    flex: 1,
    height: "100%",
})

type Props = {
    open: boolean
    onOutsideClick: () => void
    handleOkButton?: () => void
    disabledOkButon?: boolean
    children: React.ReactNode
    showCloseButtonOnTheLeft?: boolean
    smallCloseButton?: boolean
}

export const MuiCustomModal: React.FC<Props> = ({
    open,
    onOutsideClick,
    handleOkButton,
    disabledOkButon,
    children,
    showCloseButtonOnTheLeft,
    smallCloseButton,
}) => {
    const muitheme = useTheme()
    const matchesLg = useMediaQuery(muitheme.breakpoints.up("lg"))
    const matchesXl = useMediaQuery(muitheme.breakpoints.up("xl"))

    const percentWidth = useMemo(() => {
        if (matchesXl) {
            return 55
        }

        if (matchesLg) {
            return 70
        }

        return 85
    }, [matchesLg, matchesXl])

    return (
        <Modal
            keepMounted
            open={open}
            hideCloseButton={!showCloseButtonOnTheLeft}
            percentWidth={percentWidth}
            onOutsideClick={showCloseButtonOnTheLeft ? onOutsideClick : undefined}
            sx={{ "&&  .MuiPaper-root>.MuiBox-root": { flex: 1, display: "flex" } }}
            smallCloseButton={smallCloseButton}
        >
            <ScrollContainer>
                <ContentFlex>{children}</ContentFlex>
            </ScrollContainer>
            <StyledButtonWrapper>
                {!showCloseButtonOnTheLeft && <Button size="large" startIcon={<Icon name="close" />} onClick={onOutsideClick} />}
                {handleOkButton && (
                    <Button
                        sx={{ marginLeft: ".25em" }}
                        size="large"
                        color="success"
                        startIcon={<Icon name="check" />}
                        onClick={handleOkButton}
                        disabled={disabledOkButon}
                    />
                )}
            </StyledButtonWrapper>
        </Modal>
    )
}

export default MuiCustomModal
