
import { AvailabilityStatus, ErpInformation, ErpPrice, PriceDictionary, PriceType } from "@tm/models"
import { TmaEventTracking } from "../.."
import { ArticleListErpInfoShownPayload } from "./event-handle"
import { CatalogPartErpInfo } from "./models"

export const ArticleListErpInfoShownHelper = {
    async AddErpResponse(distributorId: number, erpInfo: ErpInformation[]) {
        if (!erpInfo) { return }

        try {
            const payload: ArticleListErpInfoShownPayload = {
                distributorId,
                erpInfo,
            }

            TmaEventTracking.dispatchTrack({
                tmaEvent: "ArticleList_ErpInfo_Shown",
                action: "add-erp-response",
                bundle: "erp",
                component: "erp-prices",
                source: "ajax",
                type: "request",
                payload,
            })
        }
        catch {}
    },
    async SendEvent(distributorId: number) {
        try {
            const payload: ArticleListErpInfoShownPayload = {
                distributorId,
            }

            TmaEventTracking.dispatchTrack({
                tmaEvent: "ArticleList_ErpInfo_Shown",
                action: "send-event",
                bundle: "erp",
                component: "erp-prices",
                source: "ajax",
                type: "request",
                payload,
            })
        }
        catch { }
    },
    async AddErpInfoAndSend(distributorId: number, erpInfo?: ErpInformation[]) {
        try {
            const payload: ArticleListErpInfoShownPayload = {
                distributorId,
                erpInfo,
            }

            TmaEventTracking.dispatchTrack({
                tmaEvent: "ArticleList_ErpInfo_Shown",
                action: ["add-erp-response", "send-event"],
                bundle: "erp",
                component: "erp-prices",
                source: "ajax",
                type: "request",
                payload,
            })
        }
        catch { }
    }
}

function mapPrices(erpPrices: ErpPrice[]) {
    const prices: PriceDictionary = {}

    erpPrices.forEach(price => {
        prices[price.type] = {
            currencyCode: price.currencyCode_Iso_4217,
            currencySymbol: price.currencySymbol,
            type: price.type,
            value: price.value,
            memos: price.memos,
            rebate: price.rebate,
            typeName: PriceType[price.type],
            unit: price.priceUnit,
            vat: price.vat,
        }
    })
    return prices
}

export function mapErpInformation(erpInfoItem: ErpInformation, productGroupId: number | undefined, positionNumber: number | undefined): CatalogPartErpInfo {
    return {
        id: erpInfoItem.itemId,
        catalogPart: {
            availabilityState: {
                description: erpInfoItem.availability.description ?? "",
                id: (erpInfoItem.availability.type ?? AvailabilityStatus.Unknown).toString(),
            },
            dataSupplierArticleNumber: erpInfoItem.dataSupplierArticleNumber,
            dataSupplierId: erpInfoItem.dataSupplierId,
            wholesalerArticleNumber: erpInfoItem.wholesalerArticleNumber,
            productGroupIds: productGroupId ? [productGroupId] : [],
        },
        hasImsChange: false,
        positionNumber: positionNumber ?? -1,
        prices: mapPrices(erpInfoItem.prices ?? []),
    }
}
