/**
 * Component to show a popup-image view
 * This Component can handle multiple images
 */

import { Component } from "react"
import Button from "../button";

export type ImageZoomProps = {
    images: Array<Image>,
    onCloseZoom?(): void;
}

export interface ImageZoomState {
    isVisible?: boolean;
    activeImage?: number;
    nextClassName?: string;
    prevClassName?: string;
}

export type Image = {
    url: string;
    id: number;
}

const DEFAULTACTIVEIMAGE = 0;

export default class ImageZoom extends Component<ImageZoomProps, ImageZoomState> {

    constructor(props: ImageZoomProps) {
        super(props);
        this.state = {
            isVisible: false,
            activeImage: DEFAULTACTIVEIMAGE,
            nextClassName: "imageZoom__arrow imageZoom__next",
            prevClassName: "imageZoom__arrow imageZoom__prev"
        };
    }

    /**
     * Show the zoom when the props are filled
     */
    componentDidMount() {
        if (this.props.images.length > 0) {
            this.showZoom();

            // Listen to keypress events
            const self = this;
            document.onkeyup = function(evt) {
                switch (evt.keyCode) {
                    // ESC pressed
                    case 27:
                        self.hideZoom();
                        break;
                    // Arrow right pressed
                    case 39:
                        self.next();
                        break;
                    // Arrow left pressed
                    case 37:
                        self.prev();
                        break;
                }
            }
        }
    }

    /**
     * Close the zoom by set a state
     */
    hideZoom() {
        this.setState( { isVisible: false });
        if (this.props.onCloseZoom) {
            this.props.onCloseZoom();
        }
    }

    /**
     * Show the zoom by set a state
     */
    showZoom() {
        this.setState({ isVisible: true});
    }

    /**
     * Set the active image. this image will show as the big image
     * @param image the clicked image
     */
    setActiveImage(image: Image) {
        this.setState({ activeImage: image.id })
    }

    /**
     * View for the big image
     */
    renderBigImage() {

        const activeImage = this.props.images[(this.state.activeImage as number)];

        return (
            <div className="imageZoom__hero">
                <img src={activeImage.url} />
            </div>
        );
    }

    /**
     * View for the thumbnails
     * @return a list with thumbnails
     */
     renderThumbnails() {
         const self = this;
         const thumbnails = this.props.images.map(function(image) {
             return self.renderThumbnail(image);
         });

         if (this.props.images.length > 0) {
            return (
                <div>
                    <h2 className="imageZoom__headline">Weitere Bilder</h2>
                    <div className="imageZoom__thumbnails">
                        {thumbnails}
                    </div>
                </div>
            )
        }
         return null;
     }

     /**
      * View for each single thumbnail item
      * @param image
      */
     renderThumbnail(image: Image) {
        let className = "imageZoom__thumb";

        if (image.id == this.state.activeImage) {
            className += " is-active";
        }

        return (
            <div className={className} key={image.id}>
                <img src={image.url} alt="" onClick={() => this.setActiveImage(image)}/>
            </div>
        );
     }

     /**
      * Save the next image in the state
      */
     next() {
         const self = this;
         const { activeImage } = this.state;
         const activeStateImage = (activeImage as number);

         if ((activeStateImage + 1) < this.props.images.length) {
            this.setState({activeImage: activeStateImage + 1});
         } else {
             this.setState({activeImage: 0});
         }

         this.setState({nextClassName: "imageZoom__arrow imageZoom__next icon--highlight"});
         window.setTimeout(function() {
            self.setState({nextClassName: "imageZoom__arrow imageZoom__next"});
         }, 200 );
     }

     /**
      * Save the prev image in the state
      */
     prev() {
         const { activeImage } = this.state;
         const self = this;
         const activeStateImage = (activeImage as number);

         if (activeStateImage > 0) {
            this.setState({activeImage: activeStateImage - 1});
         } else {
             this.setState({activeImage: this.props.images.length - 1})
         }

         this.setState({prevClassName: "imageZoom__arrow imageZoom__prev icon--highlight"});
         window.setTimeout(function() {
            self.setState({prevClassName: "imageZoom__arrow imageZoom__prev"});
         }, 200 );
     }

    render() {
        if (this.state.isVisible) {
            return (
                <div className="imageZoom">
                    <Button onClick={this.hideZoom.bind(this)} className="imageZoom__close" icon="close"/>

                    <div className="imageZoom__wrapper">

                        <Button layout={["ghost"]} onClick={this.next.bind(this)} className={this.state.nextClassName} icon="next"/>
                        <Button layout={["ghost"]} onClick={this.prev.bind(this)} className={this.state.prevClassName} icon="prev"/>

                        {this.renderBigImage()}
                    </div>
                    {this.renderThumbnails()}
                </div>
            )
        }
        return null;
    }
}
