import isEqual from "react-fast-compare"
import get from "get-value"
import cloneDeep from "clone-deep"
import set from "set-value"

export function clone<T>(obj: T): T {
    return cloneDeep(obj)
}

export function equals(actual: any, expected: any): boolean {
    return isEqual(actual, expected)
}

export function getValue(obj: object, path: Array<string>, fallback?: any) {
    if (!obj) {
        return fallback
    }

    return get(obj, path, { default: fallback })
}

export function setValue(obj: object, path: Array<string>, value: any): any {
    let pathString = ""
    path.forEach((x, idx) => {
        if (idx > 0) {
            pathString += "."
        }
        pathString += x.replace(/\./g, "\\.")
    })
    return set(obj, pathString, value)
}
