import { useEffect, useState } from "react"
import { Alert, Box, Stack } from "@tm/components"
import { GetNotificationRequest, GetNotificationResponse, Notification } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { add, isWithinInterval, sub } from "date-fns"
import { QueryFunctionContext, useQuery } from "react-query"

type QueryKey = ["alertNotification", GetNotificationRequest, string]

export function AlertNotification({ erpServiceUrl, telesalesCustomerNo }: { erpServiceUrl: string, telesalesCustomerNo?: string }) {
    const [rerenderTrigger, setRerenderTrigger] = useState(0)
    const date = new Date()
    const queryKey: QueryKey = [
        "alertNotification",
        {
            notificationContextId: 4, // will allways be 4 here
            telesalesCustomerNo,
        },
        erpServiceUrl,
    ]

    const { data: notifications } = useQuery(
        queryKey,
        getNotification,
        {
            refetchInterval: 10 * 60 * 1000,
            refetchIntervalInBackground: true,
            select: (response) => response?.notifications ?? []
        }
    )

    useEffect(() => {
        if (!notifications) {
            return
        }

        const notificationTimeout = getNotificationTimeout(notifications)
        if (notificationTimeout === undefined) {
            return
        }

        const timeoutId = window.setTimeout(() => setRerenderTrigger((rerenderTrigger) => rerenderTrigger + 1), notificationTimeout)

        return () => window.clearTimeout(timeoutId)
    }, [notifications, rerenderTrigger])

    if (!notifications || !erpServiceUrl) {
        return null
    }

    return (
        <Stack>
            {
                notifications
                    .filter(notification => !!notification?.memo?.text && checkDateInActiveRange(notification, date))
                    .map((notification, idx) => (
                        <Box margin={0.5} key={notification.id ?? idx}>
                            <Alert size="small" severity="warning" >
                                {notification.memo.text}
                            </Alert>
                        </Box>
                    ))
            }
        </Stack>
    )
}

function getNotification({ queryKey: [, notificationRequest, erpServiceUrl] }: QueryFunctionContext<QueryKey>): Promise<GetNotificationResponse | undefined> {
    return ajax({
        url: `${erpServiceUrl}/GetNotifications`,
        authorization: getStoredAuthorization(),
        body: notificationRequest,
    }, undefined, undefined, true)
}

function getNotificationTimeout(notifications: Notification[]): number | undefined {
    const currentTime = new Date()
    const validFromArray = notifications.orderBy((x) => x.validFrom ?? sub(currentTime, { hours: 1 }))

    for (const notification of validFromArray) {
        if (notification.validFrom && notification.validFrom >= currentTime) {
            return notification.validFrom.getTime() - currentTime.getTime()
        }
    }
}

function checkDateInActiveRange(notification: Notification, date: Date):boolean {
    return isWithinInterval(date, {
        start: notification.validFrom?? sub(date, { hours: 1 }), // If validFrom is not set, we still want to show the notification.
        end: notification.validTo ?? add(date, { months: 1 }) // If validTo is not set, we also want the notification to be shown.
    })
}
