import { MenuItem, Stack } from "@mui/material"
import { ArticleAction } from "@tm/models"
import { Icon } from "../../../generics/Icons"
import { Typography } from "../../../generics/typographie"

export function ActionMenuItem(props: { action: ArticleAction; handleCloseMore: () => void }) {
    const { id, text, icon, handler, isHighlight } = props.action

    return (
        <MenuItem key={id} onClick={mergeHandler(props.handleCloseMore, handler)}>
            <Stack direction="row" gap={1}>
                <Icon name={icon} color={isHighlight ? "primary" : undefined} />
                <Typography variant="body2" color={isHighlight ? "primary" : undefined}>
                    {text}
                </Typography>
            </Stack>
        </MenuItem>
    )
}

function mergeHandler(...handler: (() => void)[]) {
    return () => handler.forEach((handle) => handle())
}
