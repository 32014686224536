import type { History } from "history"

let mainHistory: History

export function initMainHistory(history: History) {
    if (!mainHistory && history) {
        mainHistory = history
    }
}

export function useMainHistory() {
    return mainHistory
}
