import { UserContext } from "../../user-context";
import { channel } from "..";

/**
 * @memberof Models
 * @deprecated models should not implement any logic, also dont use hermes, take the correct hook or getter
 * @param handler
 */
export function getUserContext(handler: (userContext: UserContext) => void) {
    channel("GLOBAL").subscribeOnce("USER/CONTEXT_LOADED", payload => {
        handler(payload.context)
    }, true)
}

