import {
    Switch as MuiSwitch,
    SwitchProps as MuiSwitchProps,
    styled,
    switchClasses,
    FormControlLabel as MuiFormControlLabel,
    Typography,
    FormControlLabelProps,
    TypographyProps,
    colors,
} from "@mui/material"

export type SwitchProps = MuiSwitchProps & {
    label?: any
    labelPlacement?: "start" | "end" | "top" | "bottom"
    size?: "small" | "medium"
    formControlLabelSx?: FormControlLabelProps["sx"]
    typographySx?: TypographyProps["sx"]
}

const StyledFormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    marginLeft: -7,
    ":hover": {
        ".MuiFormControlLabel-label": {
            color: theme.palette.primary.main,
        },
    },
}))

const StyledSwitch = styled(MuiSwitch)<SwitchProps>(({ theme }) => ({
    padding: "6px",
    fontSize: "20px",
    height: "34px",
    [`.${switchClasses.switchBase}`]: {
        "&.Mui-checked": {
            "& + .MuiSwitch-track": {
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
            [`.${switchClasses.thumb}`]: {
                color: theme.palette.common.white,
            },
        },
    },
    [`.${switchClasses.track}`]: {
        backgroundColor: theme.palette.common.white,
        borderRadius: theme.radius?.default,
        border: `1px solid ${theme.palette.grey[400]}`,
        opacity: 1,
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
        },
        "&:before": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main)
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        "&:after": {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><circle cx="14" cy="13" r="6" stroke="black" stroke-width="1" fill="white" /></svg>')`,
            right: 12,
        },
    },
    "&:hover": {
        [`.${switchClasses.track}`]: {
            border: `1px solid ${theme.colors?.primary}`,
        },
    },
    // TODO: small flag does not work, we have to check this again
    [`.${switchClasses.thumb}`]: {
        boxShadow: "none",
        width: 16,
        height: 16,
        marginLeft: 2,
        color: theme.palette.grey[300],
        borderRadius: theme.radius?.default,
    },
    [`&.${switchClasses.sizeSmall}`]: {
        width: "58px",
        height: "34px",

        [`.${switchClasses.track}`]: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.radius,
            border: `1px solid ${colors.grey[400]}`,
            opacity: 1,

            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 16,
            },
            "&:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main)
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            "&:after": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><circle cx="14" cy="13" r="6" stroke="black" stroke-width="1" fill="white" /></svg>')`,
                right: 12,
            },
        },
        [`.${switchClasses.switchBase}`]: {
            padding: "6px",

            "&.Mui-checked": {
                transform: "translateX(20px)",

                "& + .MuiSwitch-track": {
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
                [`.${switchClasses.thumb}`]: {
                    color: theme.palette.common.white,
                },
            },
        },
    },
}))

export const Switch = ({ label, labelPlacement, size, formControlLabelSx, typographySx, ...switchProps }: SwitchProps) => {
    return (
        <StyledFormControlLabel
            sx={formControlLabelSx}
            control={<StyledSwitch {...switchProps} />}
            label={
                <Typography sx={typographySx} variant={size === "small" ? "body3" : "body1"}>
                    {label}
                </Typography>
            }
            labelPlacement={labelPlacement ?? "end"}
        />
    )
}
