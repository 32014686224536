import { useLocalization } from "@tm/localization"
import { ButtonProps } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { ShareButton } from "./ShareButton"

export function DownloadButton(props: ButtonProps) {
    const { translate } = useLocalization()

    return (
        <ShareButton target="_blank" startIcon={<Icon name="download" />} {...props}>
            {translate(13293)}
        </ShareButton>
    )
}
