import { styled } from "@mui/material"
import { FC } from "react"

export type SeparatorProps = {
    char?: string
    className?: string
}

export const Separator: FC<SeparatorProps> = styled("span")<SeparatorProps>(({ char }) => ({
    padding: "0 0.4em",
    "&:before": {
        content: `"${(char || "|")[0]}"`,
    },
}))
