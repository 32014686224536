import { memo, SVGProps } from "react"

export const CarFallback = memo<SVGProps<SVGSVGElement>>((props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
        <path
            fillRule="evenodd"
            d="M25.195 15.889a2.3 2.3 0 010 4.598 2.302 2.302 0 01-2.298-2.3 2.3 2.3 0 012.298-2.298zm-16.716 0a2.3 2.3 0 010 4.598 2.302 2.302 0 01-2.298-2.3 2.3 2.3 0 012.298-2.298zm16.716.874c-.785 0-1.425.639-1.425 1.424 0 .786.64 1.426 1.425 1.426a1.425 1.425 0 000-2.85zm-16.716 0a1.426 1.426 0 000 2.85 1.426 1.426 0 000-2.85zM14.872 11c5.197 0 7.97 2.985 7.97 2.985h2.288c2.367 0 4.224 1.23 4.557 1.417.543.304.214.969.214.969s-.717 1.823-.854 2.153c-.136.329-.465.405-.465.405h-.6a2.885 2.885 0 00-2.787-3.624 2.886 2.886 0 00-2.883 2.882c0 .455.131.887.32 1.27h-11.55c.18-.375.28-.827.28-1.27a2.885 2.885 0 00-2.883-2.882 2.886 2.886 0 00-2.722 3.834c-.465.014-1.317-.15-1.841-.402-.524-.253-.834-.93-.834-.93s-.185-1.31 0-1.493c.184-.185.38-.854.38-.854s-.07-.893-.07-1.357c0-.467.446-.446.446-.446h1.687c.349 0 1.222-.485 1.222-.485S9.675 11 14.872 11zm1.055 6.035H14.69v1.212h1.236v-1.212zm-.591-4.541c-.289 0-.55.048-.783.144a1.772 1.772 0 00-.599.405 1.88 1.88 0 00-.385.615c-.092.235-.14.497-.146.779h1.158c0-.14.015-.275.046-.398a.96.96 0 01.146-.322.736.736 0 01.252-.221.773.773 0 01.366-.082c.21 0 .374.058.492.173.118.116.177.294.177.535a.634.634 0 01-.075.355c-.054.093-.127.18-.216.26-.09.078-.187.156-.292.235-.105.08-.204.173-.299.28a1.563 1.563 0 00-.377.956v.354h1.063v-.298a.784.784 0 01.153-.393c.081-.106.174-.199.28-.28a9.41 9.41 0 01.334-.245 1.548 1.548 0 00.567-.712 1.67 1.67 0 00.098-.622 1.407 1.407 0 00-.424-.984 1.859 1.859 0 00-.603-.382c-.25-.103-.56-.152-.933-.152z"
        />
    </svg>
))
