import { IContainerBundleEntity, IActions, IMergable } from "../../models";
import { InternalEntity } from "../proxy/container-internal-entity";
import { ActionResolver } from "../proxy/unregistered-container";
import CreateKey from "../../tools/CreateKey";

export interface IRegisteredContainerActionsMergeObject {
    pendingActionRequests: { [key: string]: Array<ActionResolver> }
}

export default class RegisteredActionsContainer {
    internalStorage: Map<string, IContainerBundleEntity<any>>
    originActions: IActions

    constructor(actions: IActions) {
        this.internalStorage = new Map<string, IContainerBundleEntity<any>>()
        this.originActions = {}
        this.registerActions(actions)
    }

    callAction(name: string, ...params: Array<any>): Promise<unknown> {
        if (!this.originActions[name]) {
            return Promise.reject()
        }

        const storageKey = CreateKey.forRegisteredActionsStorage(name, params)
        const storedEntity = this.internalStorage.get(storageKey) || new InternalEntity<any>()

        return new Promise((resolve, reject) => {

            if (!storedEntity.result || storedEntity.isExpired(.05)) {
                // not cached
                this.originActions[name](...params).then(

                    (response: unknown) => {

                        storedEntity.result = response

                        // store result
                        this.internalStorage.set(storageKey, storedEntity)
                        resolve(storedEntity.result)
                    },
                    (error: unknown) => {
                        const mappedParams = params.map(param => {
                            const isString = typeof(param) === "string"
                            return `${isString ? `"${param}"`: `${param ? JSON.stringify(param) : param}` }`
                        })
                        let errorMsg = `Error: ${error}, failed at function ${name}(${storageKey}`;
                        errorMsg += mappedParams.length > 0 ? `, ${mappedParams.join(", ")})` : ")"
                        reject(errorMsg)
                    }
                )
            }
        })
    }

    action(name: any) {
        return (...args: Array<unknown>) => this.callAction(name, ...args)
    }

    registerActions = (actions: IActions) => {
        for (let key in actions) {
            this.originActions[key] = actions[key]
        }
    }

    merge = (source: RegisteredActionsContainer): RegisteredActionsContainer => {

        const duplicatedKeys: Array<string> = []
        const concreteSource = source as RegisteredActionsContainer

        for (var key in this.originActions) {
            if (!this.originActions.hasOwnProperty(key)) { continue; }

            if (concreteSource.originActions.hasOwnProperty(key)) {
                duplicatedKeys.push(key)
            }
        }

        if (duplicatedKeys.length > 0) {
            console.info("Nexus found duplicated keys on a RegisteredActionContainer", "Skipping: [" + duplicatedKeys.join(', ') + "]");

            for (var key in duplicatedKeys) {
                concreteSource.internalStorage.delete(key)
            }
        }

        concreteSource.internalStorage.forEach((item, key) => {
            if (!this.internalStorage.has(key)) {
                this.internalStorage.set(key, item)
            }
        })

        this.originActions = { ...this.originActions, ...concreteSource.originActions }

        return this
    }
}
