export {}

declare global {
    interface Number {
        format(decimalLength: number, sectionsLength?: number, sectionsDelimiter?: string, decimalDelimiter?: string): string
        pad(length: number, char?: string): string
        add(value: number): number
        round(step: number): number
        /**
         * ceil a number to the next interval
         * @param interval use positive numbers only, <= 0 will be handled as 1
         */
        ceil(interval: number): number
    }
}

Number.prototype.format = function format(
    decimalLength: number,
    sectionsLength?: number,
    sectionsDelimiter?: string,
    decimalDelimiter?: string
): string {
    const pattern = `\\d(?=(\\d{${sectionsLength || 3}})+${decimalLength > 0 ? "\\D" : "$"})`
    const num = this.toFixed(Math.max(0, ~~decimalLength))

    return (decimalDelimiter ? num.replace(".", decimalDelimiter) : num).replace(new RegExp(pattern, "g"), `$&${sectionsDelimiter || ","}`)
}

Number.prototype.pad = function pad(length: number, char = "0"): string {
    let val = this.toString()
    const amount = length - val.length
    for (let i = 0; i < amount; i++) {
        val = char + val
    }

    return val
}

Number.prototype.add = function add(value: number) {
    const cf = maxFloatToIntFactor(this as number, value)
    return (parseInt(((this as number) * cf).toString()) + parseInt((value * cf).toPrecision())) / cf
}

Number.prototype.round = function round(step: number) {
    const test = step.toString()
    return parseFloat((Math.round((this as number) / step) * step).toFixed(test.substr(test.indexOf(".")).length - 1))
}

Number.prototype.ceil = function ceil(interval: number) {
    if (interval <= 0) {
        return Math.ceil(this as number)
    }

    return Math.ceil((this as number) / interval) * interval
}

function maxFloatToIntFactor(...values: Array<number>) {
    let result = 1
    values.forEach((val) => {
        const test = floatToIntFactor(val)
        result = test > result ? test : result
    })
    return result
}

function floatToIntFactor(value: number) {
    const test = value.toString()
    return 10 ** (test.substring(test.indexOf(".")).length - 1)
}
