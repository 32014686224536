export function shareWhatsApp(language: string, content: string, recipient?: string): boolean {
    if (!content) {
        return false
    }

    const url = `https://api.whatsapp.com/send?${recipient ? `phone=${recipient}&` : ""}text=${encodeURIComponent(content)}&lang=${language}`

    window.open(url, "_blank", "noreferrer")

    return true
}

export function shareEmail(subject: string, content: string, emails: string[], bcc?: string[]): boolean {
    if (!content || !emails?.length) {
        return false
    }

    try {
        let mailTo = `mailto:${emails.join(",") ?? ""}?body=${encodeURIComponent(content)}`

        const params = new URLSearchParams()
        params.append("subject", subject)

        if (bcc) {
            params.append("bcc", bcc.join(",") ?? "")
        }

        mailTo += params.toString()
        window.open(mailTo, "_blank", "noreferrer")
    } catch (error) {
        return false
    }

    return true
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function shareCarteligence(content: string): boolean {
    // Carteligence has all it's hooks inside the notifications bundle, renderMicro can't be used from components
    // Needs to be set from where the share it's used because of this limitations
    return false
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function shareDownload(content: string): boolean {
    // Needs to be set from where the share it's used
    return false
}
