import { ModuleMode } from "@tm/models"

export function getMvcComponentModuleMode(moduleMode?: ModuleMode | string | undefined) {
    switch (moduleMode) {
        case "0": // autodata
        case "SD": {
            return "_inspection"
        }

        case "1": // autodata
        case "2":
        case "TD":
        case "GPI":
        case "RT":
        default: {
            return ""
        }
    }
}
