import { ArticleItemState } from "@tm/models"
import { useContextSelector, createContext } from "use-context-selector"

/**
 * This this the generic /general provider hook !!!
 */

/**
 * Shall not be used directly outside of this file.
 * Use export `useArticleItemState` instead.
 */

const ArticleItemContext = createContext<ArticleItemState>({} as unknown as ArticleItemState)

export const ArticleItemStateProvider = ArticleItemContext.Provider

export function useArticleItemState<TContext extends ArticleItemState>(): TContext
export function useArticleItemState<TContext extends ArticleItemState, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function useArticleItemState<TContext extends ArticleItemState, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticleItemContext, (context) => {
        const validatedContext = getValidatedContext(context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}

function getValidatedContext<TContext extends ArticleItemState>(context: TContext | undefined): TContext {
    if (!context) {
        throw new Error(`Used ${useArticleItemState.name} outside of ${ArticleItemStateProvider.name}`)
    }

    return context
}
