import { Box } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ErpInformation } from "@tm/models"
import { concat } from "@tm/utils"
import { useMemo } from "react"
import { Typography } from "../../../generics/typographie"
import { ErpPriceWithAdditionalInfo } from "./Prices"

type PriceUnitProps = {
    purchasePrice: ErpPriceWithAdditionalInfo
}

export function PriceUnit({ purchasePrice }: PriceUnitProps) {
    const { translateText } = useLocalization()

    if (!purchasePrice?.priceUnit || purchasePrice.priceUnit === 1) {
        return null
    }

    return (
        <Box textAlign="right">
            <Typography variant="body3">{concat(" ", translateText(1312), purchasePrice.priceUnit)}</Typography>
        </Box>
    )
}
