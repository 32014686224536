import { Component } from "react"
import { DateUnit } from "@tm/utils";
import { LocalizationProps } from "@tm/localization";
import Icon from "../icon";

export default class YearDropdown extends Component<YearDropdownProps, any> {

    private _ref: HTMLElement;
    private _handleOutsideClick: any;
    private onMouseDownTimeout: any;
    private onMouseDownInterval: any;

    constructor(props: YearDropdownProps) {
        super(props)
        this.state = {
            open: false,
            yearsList: undefined,
        };
        this.renderDropdown = this.renderDropdown.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.renderMonth = this.renderMonth.bind(this);

        const { year, minDate, maxDate } = this.props

        this.state = {
            open: false,
            yearsList: generateYears(year, minDate, maxDate),
        }
    }

    UNSAFE_componentWillMount() {
        const { year, minDate, maxDate } = this.props;
    }

    componentDidMount() {
        this._handleOutsideClick = this.handleClickOutside.bind(this);
        document.body.addEventListener("click", this._handleOutsideClick);
    }

    UNSAFE_componentWillReceiveProps(newProps: YearDropdownProps) {
        const { year, minDate, maxDate } = newProps;
        if (this.props.year !== year) {
            this.setState({
                yearsList: generateYears(year, minDate, maxDate)
            });
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this._handleOutsideClick);
    }

    isPreviousYearDisabled() {
        const { minDate } = this.props;
        const firstDayOfCurrentYear = new Date(this.state.yearsList.last(), 0, 1);

        return minDate && firstDayOfCurrentYear.subtract(1, DateUnit.Day).isBefore(minDate, DateUnit.Day);
    }

    isNextYearDisabled() {
        const { maxDate } = this.props;
        const lastDayOfCurrentYear = new Date(this.state.yearsList.first(), 11, 31);

        return maxDate && lastDayOfCurrentYear.add(1, DateUnit.Day).isAfter(maxDate, DateUnit.Day);
    }

    toggleDropdown(): void {
        this.setState({
            open: !this.state.open
        });
    }

    shiftYears(amount: number): void {
        this.setState({
            yearsList: this.state.yearsList.map((year: number) => {
                return year + amount;
            })
        });
    }

    incrementYears(): void {
        this.shiftYears(1);
    }

    onMouseDown(type: string): void {
        this.onMouseDownTimeout = window.setTimeout(() => {
            this.onMouseDownInterval = window.setInterval(() => {
                if (type === "up") {
                    this.incrementYears();
                } else {
                    this.decrementYears();
                }
            }, 100)
        }, 500);
    }

    onMouseUp(): void {
        clearTimeout(this.onMouseDownTimeout);
        clearInterval(this.onMouseDownInterval);
    }

    decrementYears(): void {
        this.shiftYears(-1);
    }

    handleClickOutside(e: any): void {
        if (!this.state.open) {
            return;
        }

        for (let i = 0; i < e.path.length; i++) {
            if (e.path[i] == this._ref) {
                return;
            }
        }

        this.setState({
            open: false
        });
    }

    handleChange(year: number): void {
        this.setState({
            open: false
        });

        if (this.props.onChangeYear && year !== this.props.year) {
            this.props.onChangeYear(year);
        }
    }

    handleMonthChange(month: number): void {
        this.props.onChangeMonth(month);
    }

    handleMouseWheel(e: any) {
        e.preventDefault();
        e.stopPropagation();

        if (e.deltaY < 0) {
            this.decrementYears()
        } else {
            this.incrementYears()
        }
    }

    renderMonth(): JSX.Element {
        // 542:Jan - 553:Dec
        const monthsShort = { January: 542, Februar: 543, March: 544, April: 545, Mai: 546, June: 547, July: 548, August: 549, September: 550, October: 551, November: 552, December: 553 };
        const {translate} = this.props.localization;
        return (
            <div className="options-month" >
                <ul>
                    <li className={this.props.month ===  1 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 1)}>{translate(monthsShort.January)}</li>
                    <li className={this.props.month ===  2 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 2)}>{translate(monthsShort.Februar)}</li>
                    <li className={this.props.month ===  3 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 3)}>{translate(monthsShort.March)}</li>
                    <li className={this.props.month ===  4 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 4)}>{translate(monthsShort.April)}</li>
                    <li className={this.props.month ===  5 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 5)}>{translate(monthsShort.Mai)}</li>
                    <li className={this.props.month ===  6 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 6)}>{translate(monthsShort.June)}</li>
                    <li className={this.props.month ===  7 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 7)}>{translate(monthsShort.July)}</li>
                    <li className={this.props.month ===  8 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 8)}>{translate(monthsShort.August)}</li>
                    <li className={this.props.month ===  9 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 9)}>{translate(monthsShort.September)}</li>
                    <li className={this.props.month === 10 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 10)}>{translate(monthsShort.October)}</li>
                    <li className={this.props.month === 11 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 11)}>{translate(monthsShort.November)}</li>
                    <li className={this.props.month === 12 ? "is-active" : ""} onClick={this.handleMonthChange.bind(this, 12)}>{translate(monthsShort.December)}</li>
                </ul>
            </div>
        )
    }

    renderDropdown(): JSX.Element {
        return (
            <div className="options">
                {this.renderMonth()}
                <div className="options-year" onWheel={this.handleMouseWheel.bind(this)}>
                    <button className="option icon-expand" onClick={this.incrementYears.bind(this)} onMouseUp={this.onMouseUp.bind(this)} onMouseDown={() => this.onMouseDown("up")} disabled={this.isNextYearDisabled()}>
                        <Icon name="next" size="xs" />
                    </button>
                    {
                        this.state.yearsList.map((year: number) => {
                            return (
                                <div key={year} className="option" onClick={this.handleChange.bind(this, year)}>
                                    {
                                        this.props.year == year ?
                                            <strong className="is-active">{year}</strong>
                                            :
                                            year
                                    }
                                </div>
                            );
                        })
                    }
                    <button className="option icon-reduce" onClick={this.decrementYears.bind(this)} disabled={this.isPreviousYearDisabled()} onMouseUp={this.onMouseUp.bind(this)} onMouseDown={() => this.onMouseDown("down")}>
                        <Icon name="prev" size="xs" />
                    </button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="calendar__year-dropdown" ref={(ref) => {if (ref) {this._ref = ref}}}>
                {
                    this.state.open &&
                    this.renderDropdown()
                }
                <button className={"icon-" + (this.state.open ? "reduce" : "expand")} onClick={this.toggleDropdown.bind(this)}>
                    <Icon name={(this.state.open ? "close" : "down")} size="xs" />
                </button>
            </div>
        );
    }
}

function generateYears(year: number, minDate?: Date, maxDate?: Date): number[] {
    const list: number[] = [];
    let startYear = year - 2;
    let endYear = year + 3;

    if (minDate && startYear < minDate.getFullYear()) {
        const diff = minDate.year() - startYear;
        startYear += diff;
        endYear += diff;
    }

    if (maxDate && endYear > maxDate.getFullYear()) {
        endYear = maxDate.year();
    }

    for (let i = endYear - 1; i >= startYear; i--) {
        list.push(i);
    }

    return list;
}

export type YearDropdownProps = LocalizationProps & {
    year: number;
    month: number;
    minDate?: Date;
    maxDate?: Date;
    onChangeYear(year: number): void;
    onChangeMonth(month: number): void;
}
