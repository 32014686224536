import * as uuid from "uuid"
import baseX from "base-x"
import { Buffer } from "buffer"

let lastId = 0
export function elementId(prefix = "el") {
    return `${prefix}${lastId++}`
}

export function uniqueId() {
    return uuid.v4()
}

const base62 = baseX("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ")
const encoding = "hex"
const encodedLength = 22
const uuidLength = 32

export function encodeUniqueId(input: string | Buffer): string {
    if (typeof input === "string") {
        // remove the dashes to save some space
        input = Buffer.from(input.replace(/-/g, ""), encoding)
    }

    return ensureLength(base62.encode(input), encodedLength)
}

export function decodeUniqueId(b62Str: string) {
    if (!/^[a-z0-9]+$/i.test(b62Str)) {
        return
    }

    const res = ensureLength(Buffer.from(base62.decode(b62Str)).toString(encoding), uuidLength)

    // re-add the dashes so the result looks like an uuid
    const resArray = res.split("")
    ;[8, 13, 18, 23].forEach((idx) => {
        resArray.splice(idx, 0, "-")
    })

    return resArray.join("")
}

/**
 * ensureLength
 *
 * @api private
 */
function ensureLength(str: string | Buffer, maxLen: number): string {
    // eslint-disable-next-line operator-assignment, prefer-template
    str = str + ""

    if (str.length < maxLen) {
        return padLeft(str, maxLen)
    }
    if (str.length > maxLen) {
        return trimLeft(str, maxLen)
    }

    return str
}

/**
 * padLeft
 *
 * @api private
 */
function padLeft(str: string, padding: number): string {
    str += ""
    let pad = ""

    for (let i = str.length; i < padding; i++) {
        pad += "0"
    }

    return pad + str
}

/**
 * trimLeft
 *
 * @api private
 */
function trimLeft(str: string, maxLen: number): string {
    str += ""
    let trim = 0

    while (str[trim] === "0" && str.length - trim > maxLen) {
        trim++
    }

    return str.slice(trim)
}
