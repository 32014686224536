import { Quantity } from "@tm/models"
import { mapTour } from ".."

export function mapQuantity(data: any): Quantity | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        expectedDeliveryTime: data.expectedDeliveryTime ? new Date(data.expectedDeliveryTime) : undefined,
        tour: mapTour(data.tour),
    }
}
