import { DMS } from "@tm/models"
import { FC } from "react"

type Props = {
    className?: string
    availability: DMS.Availability
    invoiced: boolean
}

export const AvailabilityIcon: FC<Props> = (props) => {
    const renderBubble = () => {
        if (props.availability === DMS.Availability.Available) {
            return (
                <path
                    fill="#C2E617"
                    d="M15.905 3C10.444 3 6 7.444 6 12.905c0 5.461 5.75 11.687 9.905 15.25 4.152-3.56 9.905-9.788 9.905-15.25C25.81 7.444 21.366 3 15.905 3"
                />
            )
        }

        return (
            <path
                fill="#DD2C00"
                d="M15.905 24.856c-2.638-2.472-7.43-7.82-7.43-11.952 0-4.097 3.333-7.429 7.43-7.429 4.096 0 7.428 3.332 7.428 7.429 0 4.132-4.791 9.48-7.428 11.952m0-21.857C10.443 2.999 6 7.442 6 12.904c0 5.46 5.75 11.687 9.905 15.25 4.152-3.56 9.904-9.788 9.904-15.25s-4.443-9.905-9.904-9.905"
            />
        )
    }

    const renderWrench = () => {
        if (!props.invoiced) {
            return
        }
        return (
            <path
                fill="#202020"
                d="M18.086 10.549c-.432-.458-.392-1.163.09-1.575l1.066-.91c-.768-.236-1.642-.097-2.286.453-.811.693-.988 1.8-.508 2.681l-1.869 1.595c-.869-.549-2.044-.502-2.856.192-.645.55-.884 1.36-.72 2.113l1.066-.91c.482-.412 1.223-.374 1.655.084.433.459.392 1.164-.09 1.575l-1.066.91c.769.237 1.642.097 2.286-.452.812-.694.988-1.802.508-2.682l1.869-1.595c.868.549 2.044.502 2.856-.191.644-.55.884-1.36.72-2.113l-1.066.91c-.481.411-1.222.373-1.655-.085"
            />
        )
    }

    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <g fill="none" fillRule="evenodd">
                {renderBubble()}
                {renderWrench()}
            </g>
        </svg>
    )
}
