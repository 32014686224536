import { Transition } from "@tm/morpheus"

export const transitionsToWorkTask: Array<Transition> = [
    {
        transmit: { bundle: "crm", action: "ATTACH_TO_NEW_WORKTASK" },
        receive: { bundle: "worktask", action: "ATTACH_TO_NEW_WORKTASK" },
    },
    {
        transmit: { bundle: "crm", action: "ATTACH_TO_WORKTASK" },
        receive: { bundle: "worktask", action: "ATTACH_TO_WORKTASK" },
    },
    {
        transmit: { bundle: "parts", action: "MODULE_OPENED" },
        receive: { bundle: "worktask", action: "MODULE_OPENED" },
    },
    {
        transmit: { bundle: "orders", action: "MODULE_OPENED" },
        receive: { bundle: "worktask", action: "MODULE_OPENED" },
    },
    {
        transmit: { bundle: "parts", action: "SET_MODULE_FILTER" },
        receive: { bundle: "worktask", action: "SET_MODULE_FILTER" },
    },
    {
        transmit: { bundle: "tyres", action: "MODULE_OPENED" },
        receive: { bundle: "worktask", action: "MODULE_OPENED" },
    },
    {
        transmit: { bundle: "tyres", action: "SET_MODULE_FILTER" },
        receive: { bundle: "worktask", action: "SET_MODULE_FILTER" },
    },
    {
        transmit: { bundle: "vehicle", action: "MODULE_OPENED" },
        receive: { bundle: "worktask", action: "MODULE_OPENED" },
    },
    {
        transmit: { bundle: "repair-times", action: "MODULE_OPENED" },
        receive: { bundle: "worktask", action: "MODULE_OPENED" },
    },
    {
        transmit: { bundle: "misc", action: "PAGE_OPENED" },
        receive: { bundle: "worktask", action: "PAGE_OPENED" },
    },
    {
        transmit: { bundle: "telesales", action: "RESET_MODULE_NAVIGATION" },
        receive: { bundle: "worktask", action: "RESET_MODULE_NAVIGATION" },
    },
]
