import { transitionsToBasket } from "./basket/transitions"
import { transitionsToParts } from "./parts/transitions"
import { transitionsToSchedules } from "./schedules/transitions"
import { transitionsToVehicle } from "./vehicle/transitions"
import { transitionsToWorkTask } from "./workTask/transitions"

const transitions = transitionsToBasket.concat(
    transitionsToParts,
    transitionsToSchedules,
    transitionsToVehicle,
    transitionsToWorkTask,
)

export default transitions