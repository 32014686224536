export enum SearchFilterType {
    /// Also known as EArtNr, Einspeiserartikelnummer
    SupplierArticleNo = 1,

    /// Also known as OE Referenz
    OeReference = 2,

    /// Also known as Gebrauchsnummer
    UtilityNo = 4,

    /// Also known as Ersatznummer
    ReplacementNo = 8,

    /// Manufacturer (Default value for all above)
    /// Also known as Hersteller
    Manufacturer = 15,

    /// Also known as EAN, EANNr, Europäische Artikelnummer
    EuropeanArticleNumber = 16,

    /// Also known as Handelsreferenz
    TradeReference = 96,

    SupplierArticleNoAndTradeReference = 97,

    /// For internal use only!
    ALL = 2047,
}

export enum FittingSideType {
    None = 0,
    Vorne = 1,
    Hinten = 2,
    Links = 16,
    Rechts = 32,

    VorneLinks = Vorne | Links,
    VorneRechts = Vorne | Rechts,
    HintenLinks = Hinten | Links,
    HintenRechts = Hinten | Rechts,
    VorneHintenLinks = Vorne | Hinten | Links,
    VorneHintenRechts = Vorne | Hinten | Rechts,
}

export enum ArticleListViewOptionsType {
    Undefined = 0,
    Compact = 1,
    Detailed = 2,
    Images = 4,
}

export interface SearchStepDto {
    processId: string
    number: number
}

export interface PagingDto {
    /**
     * @property [optional] The Page Index.
     */
    pageIndex?: number

    /**
     * @property [optional] The Page Size.
     */
    pageSize?: number

    /**
     * @property [optional] The Page Index of the last page.
     */
    pageIndexMax?: number
}
