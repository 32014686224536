import { useState, useEffect } from "react"
import { useQuery } from "react-query"

export const useIsUrlAvailable = (url?: string) => {
    const [isAvailable, setIsAvailable] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const timeout = () => {
            return new Promise((_resolve, reject) => {
                setTimeout(reject, 2000, "Request timed out")
            })
        }
        const fetchUrl = async () => {
            if (!url) {
                setIsLoading(false)
                return
            }
            try {
                await Promise.race([timeout(), fetch(url)]).then((response: any) => {
                    if (response?.ok) {
                        setIsLoading(false)
                        setIsAvailable(true)
                    } else {
                        throw new Error("Url was not available or request timed out.")
                    }
                })
            } catch (e) {
                setIsLoading(false)
                setIsAvailable(false)
            }
        }

        fetchUrl()
    }, [url])
    return { isAvailable, isLoading }
}

export const useIsUrlAvailableCached = (url = "") => {
    const timeout = () => {
        return new Promise((_resolve, reject) => {
            setTimeout(reject, 2000, "Request timed out")
        })
    }

    function fetchUrl() {
        return Promise.race([timeout(), fetch(url)])
            .then((response: any) => !!response?.ok)
            .catch(() => false)
    }

    const { data, isLoading } = useQuery<boolean>(url, fetchUrl, {
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: !!url,
    })

    return {
        isAvailable: !!data,
        isLoading,
    }
}
