import { Box } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { Button, ButtonProps } from "../../../generics/button"
import { Tooltip } from "../../../generics/tooltip"

export function ShareButton({ noDisabledTooltip, ...buttonProps }: ButtonProps & { noDisabledTooltip?: boolean }) {
    const { translate } = useLocalization()

    const button = (
        <Button variant="outlined" size="extralarge" {...buttonProps}>
            {buttonProps.children}
        </Button>
    )

    if (buttonProps.disabled && !noDisabledTooltip) {
        return (
            <Tooltip placement="top" title={translate(13299) ?? ""} enterDelay={1000}>
                <Box>{button}</Box>
            </Tooltip>
        )
    }

    return button
}
