import * as React from "react"
import { PromptProps } from "./models";
import Dialog from ".";

export type QuestionPromptProps = Omit<PromptProps, "ref">

export default class QuestionPrompt extends React.PureComponent<QuestionPromptProps>{
    dialogRef: React.RefObject<Dialog> = React.createRef()

    show() {
        this.dialogRef.current?.show()
    }

    render() {
        return <Dialog
            {...this.props}
            skin="primary"
            confirmButtonText={this.props.confirmationButtonText}
            cancelButtonText={this.props.cancelButtonText}
            iconName="question"
            closeOnlyWithDialogInteraction={true}
            ref={this.dialogRef}
        >
            {this.props.children}
        </Dialog>
    }
}
