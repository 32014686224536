import { LoggedIn } from "./event-handle"

export type LoggedInTrackEvent = {
    action: string // "parts-search"
    bundle: string // "parts"
    component: string // "tree"
    payload: "hidden" | "form"
    tmaEvent: string // "ARTICLE_LIST_FILTERED"
    type: string // "search"
}

export default new LoggedIn()
