import { Article, ErpInformation, GetArticlesRequest, GetArticlesResponse } from "@tm/models"
import { CatalogPartErpInfo } from "./models"
import { TmaSender } from "../tma-sender"

import { TmaEventTracking } from "../.."
import { TmaEvent, TrackingModel } from "../../models/models"
import { PagingDto, SearchStepDto } from "../../models/requests"
import { mapErpInformation } from "./event-helper"

type RequestBody = {
    distributorId?: number
    catalogPartErpInfos: CatalogPartErpInfo[]
    paging?: PagingDto
    searchStep?: SearchStepDto
}

const InitialRequestBody: RequestBody = {
    catalogPartErpInfos: [],
}

type GetArticlesPayload = {
    request: GetArticlesRequest
    result: GetArticlesResponse | undefined
}

type AddErpResponsePayload = {
    distributorId: number
    erpInfo: ErpInformation[]
}

type SendEventPayload = {
    distributorId: number
}

type Payload = GetArticlesPayload | AddErpResponsePayload | SendEventPayload
export type { Payload as ArticleListErpInfoShownPayload }

export class ArticleListErpInfoShownEvent extends TmaSender<RequestBody> implements TmaEvent {
    public key = "ArticleList_ErpInfo_Shown"

    /** Contain the latest data for the current article list. */
    articleList: Article[]

    /** Contains the latest data for each configured erp system. */
    erpData: Map<number, ErpInformation[]>

    constructor() {
        super(`/articles/articleListErpInfoShown`)
        this.requestBody = InitialRequestBody
        this.articleList = []
        this.erpData = new Map()
    }

    public handle = (trackingData: TrackingModel<Payload>): boolean => {
        const { tmaEvent, action, payload } = trackingData

        if (tmaEvent !== this.key || !payload) {
            return false
        }

        if (Array.isArray(action)) {
            action.forEach((value) => this.processEventHandle(value, payload))
        } else {
            this.processEventHandle(action, payload)
        }

        return false
    }

    private processEventHandle = (action: string, payload: Payload): void => {
        switch (action) {
            /** This will be called when the article list was loaded so we can save the current article data. */
            case "get-articles": {
                const { request, result } = payload as GetArticlesPayload

                if (typeof request === "object") {
                    this.articleList = result?.articles?.filter(x => x.requestErpInfo) ?? [] // Only consider articles for which erp requests should be made.
                    this.erpData.clear() // Reset the stored erp data as it is not valid for this article list anymore.
                }

                break
            }

            /** This will be called when the erp information was loaded. */
            case "add-erp-response": {
                const { distributorId, erpInfo } = payload as AddErpResponsePayload

                // Sanity check: When there is no erp information we don't have to do anything.
                if (erpInfo.length == 0) {
                    break
                }

                const erpData = this.erpData.get(distributorId)
                if (erpData) {
                    erpData.push(...erpInfo)
                }
                else {
                    this.erpData.set(distributorId, [...erpInfo])
                }

                break
            }

            /** This will be called when all erp information for a distributor was loaded and the event should be sent. */
            case "send-event": {
                const { distributorId } = payload as SendEventPayload

                const erpData = this.erpData.get(distributorId)

                // Sanity check: When there is no erp data for this distributor we don't have to do anything.
                if (!erpData) {
                    break
                }

                // Get the latest data for the "ARTICLE_LIST_FILTERED" event because we also need that data for this event.
                const articleListFilteredEvent = TmaEventTracking.getRequestData("ARTICLE_LIST_FILTERED")

                this.requestBody = {
                    distributorId: distributorId > 0 ? distributorId : undefined, // Only send valid values for distributorId
                    catalogPartErpInfos: erpData.map((x) => {
                        const { productGroup, articleListPosition } = this.articleList.find(article => article.id == x.itemId) ?? {}
                        return mapErpInformation(x, productGroup?.id, articleListPosition)
                    }),
                    paging: articleListFilteredEvent?.articleList?.paging,
                    searchStep: articleListFilteredEvent?.searchStep,
                }

                this.sendEvent()
                this.resetEvent(InitialRequestBody)

                this.erpData.delete(distributorId)

                break
            }
        }
    }
}

export default new ArticleListErpInfoShownEvent()
