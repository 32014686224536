import { memo, SVGProps } from "react"

export const ArticleFallback = memo<SVGProps<SVGSVGElement>>((props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126 121" {...props}>
        <path
            fill="#767676"
            fillRule="evenodd"
            opacity=".2"
            d="M24 100h78V51H24v49zm17-78h17v20H28l13-20zm57 20H68V22h17l13 20zm14 4.2a4.8 4.8 0 00-.4-1.6l-.1-.4-.3-.6-19.6-29.4a4.9 4.9 0 00-4.1-2.2h-49a5 5 0 00-4 2.2L14.7 43.6l-.3.6-.1.4a4.8 4.8 0 00-.4 1.6v58.9c0 2.7 2.2 4.9 4.9 4.9h88.2c2.7 0 4.9-2.2 4.9-4.9V46.3z"
        />
    </svg>
))
