import { TmaSender } from "../../tma-sender"
import { DamageCalculationModuleCalledRequest } from "../models"
import { TmaEvent, BaseTrackingModel } from "../../../models/models"

export type AllDataTechDataModuleCalledRequest = {
    vehicleId?: string
    allDataVehicleId?: string
}

export class AllDataTechDataModuleCalled extends TmaSender<AllDataTechDataModuleCalledRequest> implements TmaEvent {
    key = "td/alldata"

    constructor() {
        // when extending tma sender we need to set the endpoint
        super("/alldata/technicalDataModuleCalled")
        this.requestBody = {}
    }

    handle = (trackingData: BaseTrackingModel<DamageCalculationModuleCalledRequest>): Promise<void> | void => {
        if (trackingData.payload) {
            const { vehicleId } = trackingData.payload
            this.requestBody.vehicleId = trackingData.payload.vehicleId
        }

        return this.sendEvent()
    }
}
