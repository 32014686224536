import { useMemo } from "react"
import { Stack } from "@mui/material"
import { AddToBasket } from "./AddToBasket"
import { PartsListButton } from "./PartsListButton"
import { CostEstimationButton } from "./CostEstimationButton"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { ReplaceButton } from "./ReplaceButton"

export function Transactions() {
    const { hideCostEstimationButton, highlightCostEstimationButton, hideAddToBasketButton } = useDefaultArticleItemState((x) => x.options)
    const showAddToBasket = useDefaultArticleItemState((x) => x.article.showAddToBasket)
    const addToCostEstimationButtonDisabled = useDefaultArticleItemState((x) => x.addToCostEstimationButtonDisabled)
    const addToBasketButtonDisabled = useDefaultArticleItemState((x) => x.addToBasketButtonDisabled)
    const isPartsListAvailable = useDefaultArticleItemState((x) => x.article.isPartsListAvailable)
    const originalQuantity = useDefaultArticleItemState((x) => x.originalQuantity)
    const hasSuggestedQuantity = useDefaultArticleItemState((x) => x.options.hasSuggestedQuantity)
    const handleChangeQuantity = useDefaultArticleItemState((x) => x.handleChangeQuantity)
    const handleAddToBasket = useDefaultArticleItemState((x) => x.handleAddToBasket)
    const handleReplaceArticle = useDefaultArticleItemState((x) => x.handleReplaceArticle)
    const handleRemoveFromBasket = useDefaultArticleItemState((x) => x.handleRemoveFromBasket)
    const basketQuantity = useDefaultArticleItemState((x) => x.basketQuantity?.articleQuantities?.totalQuantity)
    const handleAddToCostEstimation = useDefaultArticleItemState((x) => x.handleAddToCostEstimation)
    const defaultArticleErpInfo = useDefaultArticleItemState((x) => x.articleErpInfos?.default)
    const showReplaceButton = useDefaultArticleItemState((x) => x.showReplaceButton)

    const division = useMemo(
        () => (defaultArticleErpInfo?.state === "success" ? defaultArticleErpInfo.response.quantity?.division : 1) || 1,
        [defaultArticleErpInfo]
    )

    return (
        <Stack spacing={0.5} direction="row" justifyContent="end" className="transactions">
            {showAddToBasket && !hideAddToBasketButton && !showReplaceButton ? (
                <AddToBasket
                    originalQuantity={originalQuantity}
                    division={division}
                    basketQuantity={basketQuantity}
                    onAddToBasket={handleAddToBasket}
                    handleChangeQuantity={handleChangeQuantity}
                    onRemoveFromBasket={handleRemoveFromBasket}
                    hasSuggestedQuantity={hasSuggestedQuantity}
                    disableButton={addToBasketButtonDisabled}
                />
            ) : (
                isPartsListAvailable && <PartsListButton />
            )}
            {showReplaceButton && handleReplaceArticle && <ReplaceButton onClick={handleReplaceArticle} />}
            {!hideCostEstimationButton && (
                <CostEstimationButton
                    onAddToCostEstimation={handleAddToCostEstimation}
                    highlightCostEstimationButton={highlightCostEstimationButton}
                    disabled={!showAddToBasket || addToCostEstimationButtonDisabled}
                />
            )}
        </Stack>
    )
}
