import { TmaSender } from "../../tma-sender"
import { GPIModuleCalledRequest } from "../models"
import { TmaEvent, BaseTrackingModel } from "../../../models/models"

export class GPIModuleCalled extends TmaSender<GPIModuleCalledRequest> implements TmaEvent {
    key = "eurotax"

    constructor() {
        // when extending tma sender we need to set the endpoint
        super("/gpi/gpiModuleCalled")
        this.requestBody = {}
    }

    handle = (trackingData: BaseTrackingModel<GPIModuleCalledRequest>): Promise<void> | void => {
        if (trackingData.payload?.vehicleId) {
            this.requestBody.vehicleId = trackingData.payload.vehicleId
        }

        return this.sendEvent() // if it's called from the module itself cdm send the event
    }
}
