
import { useDispatch } from "react-redux"
import { Action, bindActionCreators } from "redux"
/**
 * helper for using actions easily inside a REACT.FC<{}>
 * @param {object} actions the object which containts all the actions
 * @param {string[]} args pick only the actions you want from the main actions object
 *
 * @example
 * before:
 * cosnt dispatch = useDispatch()
 * const actions = bindActionsCreators({BundleActions.setVehicle}, dispatch)
 * actions.setVehicle(...)
 *
 * after:
 * const actions = useActions(BundleActions,"setVehicle")
 * actions.setVehicle(...)
 */

export function useActions<T extends { [key: string]: any }, S extends string & keyof T>(actions: T, ...args: Array<S>) {
    type ActionsType = { [k in S]: T[k] }
    type Dispatch<A extends Action> = { <T extends A>(action: T): T }
    const dispatch = useDispatch<Dispatch<any>>()

    const res = (args.length ? args : Object.keys(actions as any)).reduce((obj: any, key: string) => ({
        ...obj, [key]: bindActionCreators(actions[key], dispatch)
    }), {} as ActionsType)
    return res as typeof args extends undefined ? T : ActionsType
}
