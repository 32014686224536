import { ChannelClass } from "./channel"
import { TMessageKeys, MessageContentType } from "./models/internal"

export class MessageBus<MBC> {
    private channels: {[key: string]: ChannelClass<MBC, any>} = {}

    private errorHandlers: Array<ErrorHandler> = []

    channel<C extends keyof MBC>(type: C, id?: string): ChannelClass<MBC, C> {
        const ident = `${type as string}${id ? `/${id}` : ""}`
        let channel = this.channels[ident]
        if (!channel) {
            this.channels[ident] = channel = new ChannelClass<MBC, C>(type, this)
        }
        
        return channel
    }

    addErrorHandler(handler: ErrorHandler): () => void {
        this.errorHandlers.push(handler)
        return () => this.errorHandlers.splice(this.errorHandlers.indexOf(handler))
    }

    throwError(channel: string, topic: string, content: any, error: any) {
        if (!this.errorHandlers.length) {
            console.error(
                `The following error was thrown out of the MessageBus of @tm/hermes.\n`+
                `Please make sure that at least one ErrorHandler is set with addErrorHandler(errorHandler).\n`)
            console.error(`Channel: ${channel}`)
            console.error(`Topic: ${topic}`)
            console.error("Content:", content)
            console.error(error)
            return
        }
        try {
            this.errorHandlers.forEach(handle => handle(channel, topic, content, error))
        } catch (ex) {
            console.error("Error in the error handler of @tm/hermes.", ex)
        }
    }
}

type ErrorHandler = (channel: string, topic: string, content: any, error: any) => void