import { Component } from "react"
import { IWithLocalization } from "@tm/localization"
import { bindMethodsToContext } from "../../../helper"
import { Checkbox, Text } from "../../"
import { ListItem } from "../"
import { getUIA } from "@tm/utils"

export type Props = {
    item: ListItem
    isSelected: boolean
    onToggle(item: ListItem, exlusive: boolean): void
    localization: IWithLocalization
    size?: "s" | "m"
}

export default class SelectionListItem extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)
    }

    handleToggle() {
        this.props.onToggle(this.props.item, false)
    }

    handleToggleExclusive() {
        this.props.onToggle(this.props.item, true)
    }

    renderValue() {
        const { item, size, localization: {translateText} } = this.props

        return (
            <Text className="selection-list__item__value" size={size} modifiers="block" title={`${item.name}\u000a(${translateText(936)})`}  {...getUIA("SectionListLabel")}>
                <div onClick={this.handleToggleExclusive}>
                    {item.name} {item.resultCount != undefined && `(${item.resultCount})`}
                </div>
            </Text>
        )
    }

    renderDescription() {
        const { item } = this.props

        if (!item.description) return

        return (
            <Text className="selection-list__item__description" size="xs" modifiers="block" {...getUIA("SectionListDescription")}>
                {item.description}
            </Text>
        )
    }

    render() {
        const { item, isSelected, size } = this.props

        let className = "is-clickable selection-list__item"
        className += ` selection-list__item--${item.priority || "normal"}-priority`

        return (
            <li className={className} {...getUIA("SectionListCheckbox", item.name)}>
                <Checkbox checked={isSelected} onToggle={this.handleToggle} size={size}/>

                <div className="selection-list__item__value-wrapper">
                    {this.renderValue()}
                    {this.renderDescription()}
                </div>
            </li>
        )
    }
}
