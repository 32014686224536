import { AvailabilityStatus } from "@tm/models"
import { TmaEvent, TrackingModel } from "../../models/models"
import { TmaSender } from "../tma-sender"
import { ArticleImageClick } from "./models"

export class ArticleEvent extends TmaSender<ArticleImageClick> implements TmaEvent {
    key = "ARTICLE_IMAGE_CLICKED"

    static serviceEndpoint = "/articles/imageClick"

    constructor() {
        super(ArticleEvent.serviceEndpoint)
        this.requestBody = {}
    }

    handle = (trackingData: TrackingModel<unknown>): boolean => {
        if (trackingData.tmaEvent && trackingData.tmaEvent == this.key) {
            if (trackingData.payload && trackingData.bundle == "parts") {
                if (Array.isArray(trackingData.action)) {
                    trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
                } else {
                    this.fillRequest(trackingData, trackingData.action)
                }
            }

            return false
        }

        return false
    }

    fillRequest = (trackingData: TrackingModel<any>, action: string) => {
        const { article, quantityModifiers, vehicleId } = trackingData.payload

        switch (action) {
            case "article-image-click": {
                this.requestBody = {}
                if (article) {
                    this.requestBody.catalogPart = {
                        dataSupplierArticleNumber: article.supplierArticleNo,
                        dataSupplierId: article.supplier.id,
                        productGroupIds: [article.productGroup.id],
                        wholesalerArticleNumber: article.traderArticleNo,
                        availabilityState: {
                            id:
                                (quantityModifiers?.availability &&
                                    quantityModifiers.availability !== AvailabilityStatus.Unknown &&
                                    AvailabilityStatus[quantityModifiers.availability]) ||
                                "",
                            description: quantityModifiers?.availability?.toString() || "",
                        },
                    }
                }

                if (vehicleId) {
                    this.requestBody.vehicleId = vehicleId
                }
                this.sendEvent()
                break
            }
        }
    }
}
