import { Box, BoxProps } from "@mui/material"
import { forwardRef } from "react"

export const AdditionalPopoverContent = forwardRef<typeof Box, BoxProps>((props, ref) => {
    return (
        <Box mt={2} {...props} ref={ref}>
            {props.children}
        </Box>
    )
})
