import { Version } from "@tm/models";

export type RepairTimeOptions = {
    id: string
    version?: Version
    useManufacturerRepairTimeDivision: boolean
    division: ERepairTimeDivision
    useRepairTimeCalculation?: boolean
}

export enum ERepairTimeDivision {
    Hours = 1,
    Division10 = 10,
    Division12 = 12,
    Division100 = 100
}