import { Box, BoxProps, styled } from "@mui/material"
import { ComponentType } from "react"
import { ImageStylings } from "./Image"

type ImageContainerProps = BoxProps & {
    imageStylings?: ImageStylings
}
export const ImageContainer = styled(Box)<ImageContainerProps>(({ theme, imageStylings }) => ({
    boxSizing: "border-box",
    border: `1px solid transparent`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: imageStylings ? imageStylings.size : undefined,
    height: imageStylings ? imageStylings.size : undefined,
    zIndex: 2,
    marginTop: imageStylings ? `${imageStylings.marginTop}px` : undefined,
    "&[data-clickable]:hover": {
        borderColor: `${theme.palette.primary.main}`,
        cursor: "pointer",
    },
    img: {
        objectFit: "contain",
    },
})) as ComponentType<ImageContainerProps>
