import { channel } from "@tm/models"
import { createContext } from "react"
import { Action, Dispatch, Store } from "redux"
import { PayloadAction } from "@tm/morpheus"

export type CommunicationChannel = [(action: Action, dispatch: Dispatch<Action>, getState: () => unknown) => void, Store][]
export type Receiver = (action: Action, dispatch: Dispatch<Action>, getState: () => unknown) => void

export const CommunicationContext = createContext<CommunicationChannel | undefined>(undefined)

export function onModuleOpened(action: PayloadAction) {
    if (action.type === "MODULE_OPENED") {
        // eslint-disable-next-line no-underscore-dangle
        channel("WORKTASK", window.__NEXT_WORKTASKID__).publish("MODULE/OPENED", action.payload)
    }
}
