import * as React from "react"
import { PromptProps } from "./models";
import Dialog from ".";

export type DangerPromptProps = Omit<PromptProps, "ref">

export default class DangerPrompt extends React.PureComponent<DangerPromptProps>{
    dialogRef: React.RefObject<Dialog> = React.createRef()

    show() {
        this.dialogRef.current?.show()
    }

    render() {
        return <Dialog
            {...this.props}
            skin="danger"
            confirmButtonText={this.props.confirmationButtonText}
            cancelButtonText={this.props.cancelButtonText}
            iconName="alert"
            ref={this.dialogRef}
        >
            {this.props.children}
        </Dialog>
    }
}
