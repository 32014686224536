import { channel, getCurrentWorkTaskId } from "@tm/models"
import { BaseTrackingModel, TmaEvent, TrackingModel, EventRequestType } from "./models/models"

import { getWorkTaskIdFromLocation } from "./helper/context"
import { ArticleListFilteredEvent } from "./events/article-list-filtered/event-handle"
import { TmaSender } from "./events/tma-sender"
import { EventStorage, StoreContext } from "./events/event-storage"

export class TmaEventTracker {
    private static instance: TmaEventTracker

    private tmaEnabled: boolean

    private worktaskChangedEventHandler: ((id?: string, previousId?: string) => void)[]

    private latestWorkTaskChangedData: { id?: string; previousId?: string } = {}

    private constructor(worktaskId?: string) {
        this.tmaEnabled = true
        this.worktaskChangedEventHandler = []
        this.latestWorkTaskChangedData.id = getCurrentWorkTaskId() || worktaskId
        channel("GLOBAL").subscribe("WORKTASK/ID_CHANGED", this.handleWorkTaskChange, true)
    }

    static getInstance() {
        if (!TmaEventTracker.instance) {
            // workaround to get the worktaskId even if the user refreshes the page
            const workTaskId = getWorkTaskIdFromLocation()
            TmaEventTracker.instance = new TmaEventTracker(workTaskId)
        }
        return TmaEventTracker.instance
    }

    private tmaEventHandlerCollection: Array<TmaEvent> = []

    addEvent(event: TmaEvent & TmaSender<unknown>) {
        if (!event) {
            return
        }
        this.tmaEventHandlerCollection.push(event)
        this.addToWorktaskChangeSubscription(event)
    }

    addCollection(events: Array<TmaEvent>) {
        if (!events) {
            return
        }
        events.forEach((event) => {
            this.addToWorktaskChangeSubscription(event)
        })
        this.tmaEventHandlerCollection.push(...events)
    }

    addToWorktaskChangeSubscription(event: TmaEvent) {
        const possibleHandler = (event as any).handleWorkTaskChange
        if (possibleHandler && typeof possibleHandler == "function") {
            possibleHandler(this.latestWorkTaskChangedData.id, this.latestWorkTaskChangedData.previousId)
            this.worktaskChangedEventHandler.push(possibleHandler)
        }
    }

    handleWorkTaskChange = (content: { id?: string; previousId?: string }) => {
        this.latestWorkTaskChangedData = content
        this.worktaskChangedEventHandler.forEach((handler) => {
            try {
                handler(content.id, content.previousId)
            } catch (e) {
                TmaErrors.log(e)
            }
        })
    }

    /**
     * could be called out of this context?
     * @param data the data which comes from @track or dispatchTrack
     */
    private notifyEventHandlers(data: TrackingModel<any> | BaseTrackingModel<any>): void | Promise<void> {
        if (!data.tmaEvent || !this.tmaEnabled) {
            return
        }
        const eventHandlers = this.tmaEventHandlerCollection
        const concreteHandler = eventHandlers.find((event) => event.key.toLocaleLowerCase() == data.tmaEvent.toLocaleLowerCase())

        if (!concreteHandler) {
            return
        }

        // handle event and remove if it's completed
        const eventIsCompleted = concreteHandler.handle(data as TrackingModel<any>)

        if (typeof eventIsCompleted === "object" && (eventIsCompleted as Promise<void>).then) {
            return eventIsCompleted as void | Promise<void>
        }
    }

    /**
     * this function is used to override the react-tracking default dispatch
     * will be called out of this context
     * @param data information which should be tracked
     */
    dispatchTrack = (data: BaseTrackingModel<any>): void | Promise<void> => {
        const instance = TmaEventTracker.getInstance()
        return instance.notifyEventHandlers(data)
    }

    disableTracker = () => {
        this.tmaEnabled = false
    }

    enableTracker = () => {
        this.tmaEnabled = true
    }

    getRequestData: EventRequestType = (key: string) => {
        const event = this.tmaEventHandlerCollection.find((event) => event.key === key) as TmaRequestHandle

        if (!event) { return }

        if (event.temporaryRequestBody) {
            const foundContext = event.temporaryRequestBody.context?.contextId.replace(/\s/g, '+')
            const currentContextId = event.storage?.getContextId().replace(/\s/g, '+')

            if (foundContext === currentContextId) {
                return event.temporaryRequestBody as any
            }

            if (!foundContext) {
                return event.temporaryRequestBody
            }
        }

        if (event && event.requestBody) {
            return event.requestBody as any
        }

        // vehicle_selection_log does not use tmasender, has a fake sessionStorage implementation, maybe temporaryRequestBody
        if (event && event.data) {
            return event.data as any
        }
    }
}


type TmaRequestHandle = TmaEvent & Pick<TmaSender<unknown>, "requestBody" | "storage"> & { temporaryRequestBody?: { context: StoreContext }, data?: unknown }
class TmaErrors {
    static instance?: TmaErrors

    static getInstance() {
        if (!this.instance) {
            this.instance = new TmaErrors()
        }

        return this.instance
    }

    static log(e: any) {
        const errors = JSON.parse(sessionStorage.getItem("errors") || "{}")
        errors.tma = errors.tma || {}
        errors.tma = e
        sessionStorage.setItem("errors", JSON.stringify(errors))
    }

    static showlog() {
        const errors = JSON.parse(sessionStorage.getItem("errors") || "{}")
        errors.tma = errors.tma || {}
        console.warn(errors)
    }
}
