import { useCallback, useRef } from "react"

export function useResizeRef(callback?: () => void) {
    const currentRef = useRef<Element | null>(null)
    const ref = useCallback((node: Element) => {
        const resizeObserver = new ResizeObserver(() => {
            callback?.()
        })

        if (currentRef.current) {
            resizeObserver.disconnect()
        }

        if (node) {
            resizeObserver.observe(node)
        }

        currentRef.current = node
    }, [])

    return ref
}
