import { PureComponent } from "react"
import {SubTitle} from "../.."
export default class ToolbarTitle extends PureComponent {

    constructor(props: any) {
        super(props);
    }

    render() {
        const baseName = "toolbar__title"
        const className = `${baseName}`

        return (
            <SubTitle size={"xs"} className={className}>
                {this.props.children}
            </SubTitle>
        );
    }
}
