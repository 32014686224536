import { Stack, Switch, Typography, styled, switchClasses, SwitchProps as MuiSwitchProps } from "@mui/material"
import { classes } from "@tm/utils"
import { ReactNode, forwardRef, useEffect, useMemo, useState } from "react"

type ToggleSwitchItem<T = unknown> = {
    key: string | number | boolean
    value: T
}

export type ToggleSwitchProps<T extends Record<string, unknown> | number | string = any> = {
    size?: MuiSwitchProps["size"]
    left?: ToggleSwitchItem<T>
    right?: ToggleSwitchItem<T>
    className?: string
    disabled?: boolean
    selected?: ToggleSwitchItem["key"]
    renderLabel?: (item: ToggleSwitchItem<T>) => ReactNode
    onChange: (item?: ToggleSwitchItem<T>) => unknown
}

const ToggleSwitchStack = styled(Stack)(({ theme }) => ({
    "&.Mui-disabled": {
        pointerEvents: "none",
        color: theme.palette.grey[300],
        ".ToggleSwitchLabel": {
            color: theme.palette.grey[300],
        },
        [`.${switchClasses.switchBase}`]: {
            "&.Mui-checked": {
                "& + .MuiSwitch-track": {
                    backgroundColor: theme.palette.grey[300],
                },
            },
        },
    },
}))

const StyledToggleSwitch = styled(Switch)(({ theme }) => ({
    padding: 6,
    fontSize: 20,
    height: 34,
    [`&.${switchClasses.sizeSmall}`]: {
        width: 50,
        height: 30,
        [`.${switchClasses.switchBase}`]: {
            padding: 8,
        },
        [`.${switchClasses.thumb}`]: {
            width: 14,
            height: 14,
        },
    },
    [`.${switchClasses.switchBase}`]: {
        "&.Mui-checked": {
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
                border: 0,
            },
            [`.${switchClasses.thumb}`]: {
                color: theme.palette.common.white,
            },
        },
    },
    [`.${switchClasses.track}`]: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: theme.radius?.default,
        opacity: 1,
        "&.Mui-checked": {},
        "&:before, &:after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            width: 16,
            height: 16,
        },
        "&:before": {
            left: 12,
        },
        "&:after": {
            right: 12,
        },
    },
    [`.${switchClasses.thumb}`]: {
        boxShadow: "none",
        width: 16,
        height: 16,
        marginLeft: 2,
        borderRadius: theme.radius?.default,
    },
    ".MuiSwitch-switchBase:hover": {
        backgroundColor: "transparent",
    },
}))

const ToggleSwitchLabel = styled(Typography, { shouldForwardProp: (prop: string) => prop !== "size" })<Pick<ToggleSwitchProps, "size">>(
    ({ size }) => ({
        cursor: "pointer",
        "&:hover": {
            opacity: 1,
        },

        ...(size === "small" && { fontSize: 12 }),
    })
)

export const ToggleSwitch = forwardRef<HTMLDivElement, ToggleSwitchProps>((props, ref) => {
    const { left, right, size, selected, className, disabled, onChange } = props

    const [isRightSelected, setIsRightSelected] = useState(right?.key === selected)

    useEffect(() => {
        setIsRightSelected(right?.key === selected)
    }, [selected, right])

    const renderLabel = useMemo(() => {
        if (!props.renderLabel) {
            return (item: ToggleSwitchItem) => String(item.value)
        }
        return props.renderLabel
    }, [props.renderLabel])

    const leftLabel = useMemo(() => (left ? renderLabel(left) : undefined), [left, renderLabel])
    const rightLabel = useMemo(() => (right ? renderLabel(right) : undefined), [right, renderLabel])

    const handleClickButtonButton = (item: unknown) => {
        if (left === item && isRightSelected) {
            onChange(left)
        } else if (right === item && !isRightSelected) {
            onChange(right)
        }
    }

    const handleClickSwitch = (_: unknown, checked: boolean) => {
        onChange(checked ? right : left)
    }

    return (
        <ToggleSwitchStack
            className={classes("ToggleSwitch-root", className, isRightSelected && "Mui-checked", disabled && "Mui-disabled")}
            direction="row"
            alignItems="center"
            ref={ref}
        >
            <ToggleSwitchLabel className="ToggleSwitchLabel ToggleSwitchLabel-left" size={size} onClick={() => handleClickButtonButton(left)}>
                {leftLabel}
            </ToggleSwitchLabel>
            <StyledToggleSwitch size={size} checked={isRightSelected} disabled={disabled} onChange={handleClickSwitch} />
            <ToggleSwitchLabel className="ToggleSwitchLabel ToggleSwitchLabel-right" size={size} onClick={() => handleClickButtonButton(right)}>
                {rightLabel}
            </ToggleSwitchLabel>
        </ToggleSwitchStack>
    )
})
