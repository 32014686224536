import { ComponentType, ReactNode } from "react"
import escapeStringRegexp from "escape-string-regexp"
import reactStringReplace from "react-string-replace"

/**
 * Returns an array of individual `ReactNode` elements for each match surrounded by the pre and post tags included in the given input.
 *
 * @param text The raw string containing the content surrounded by the pre and post tags.
 * @param preTag The string that will be used to identify the start of an escape sequence.
 * @param postTag The string that will be used to identify the end of an escape sequence.
 * @param replacer The replacement function that will be called with
 *                 the match (the content inside the pre and post tags),
 *                 the index (can be used for individualisation when multiple matches are found)
 *                 and the offset (the number of characters from the beginning of the text).
 *                 It should return the desired JSX (`ReactNode`) used for one match.
 *
 * @returns An array of the individual results of the replacement function.
 */
export function convertStringToReactNodes(
    text: string,
    preTag: string,
    postTag: string,
    replacer: (match: string, index: number, offset: number) => ReactNode
): ReactNode[] {
    return reactStringReplace(text, new RegExp(`${escapeStringRegexp(preTag)}(.*?)${escapeStringRegexp(postTag)}`, "g"), replacer)
}

const HitHighlightPreTag = "<span class='highlight'>"
const HitHighlightPostTag = "</span>"

/**
 * Returns an array of components (type depending on the value of the wrapper argument) for each highlight match of this autosuggestion hit.
 *
 * @param text The raw string containing the autosuggestion value.
 * @param wrapper The React component used as a wrapper for each highlight match.
 *
 * @returns An array of the wrapper components for each highlight match or undefined if text is empty.
 */
export function convertAutosuggestHit(text: string | undefined, wrapper: ComponentType): ReactNode[] | undefined {
    if (!text) {
        return undefined
    }

    const Wrapper = wrapper

    return convertStringToReactNodes(text, HitHighlightPreTag, HitHighlightPostTag, (match, i) => <Wrapper key={i}>{match}</Wrapper>)
}
