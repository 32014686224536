import { Component } from "react"

const SPINNER = "spinner";
const CONNECTION = "connection"

export type LoaderProps = {
    visible?: boolean
    delay?: number
    type?: "spinner" | "connection"
    className?: string
    onRef?(ref: HTMLDivElement): void
}

export interface LoaderState {
    visible: boolean
    timer?: any
}

export default class Loader extends Component<LoaderProps, LoaderState> {
    public static defaultProps: LoaderProps = {
        delay: 250,
        type: SPINNER
    }
    /**
     *
     */
    constructor(props: LoaderProps) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    UNSAFE_componentWillMount() {
        this.setVisibility(this.props.visible !== undefined ? this.props.visible : true)
    }

    UNSAFE_componentWillReceiveProps(nextProps: LoaderProps) {
        if (nextProps.visible !== undefined && this.props.visible !== nextProps.visible) {
            this.setVisibility(nextProps.visible)
        }
    }

    componentWillUnmount() {
        this.clearTimer()
    }

    setVisibility(visible: boolean) {
        const { delay } = this.props

        this.clearTimer()

        if (visible) {
            if (delay !== undefined && delay > 0) {
                const timer = setTimeout(() => {
                    this.setState({ visible });
                }, delay)

                this.setState({ timer })
            } else {
                this.setState({ visible })
            }
        } else {
            this.setState({ visible })
        }
    }

    clearTimer() {
        const { timer } = this.state;

        if (timer) {
            clearTimeout(timer)
        }
    }

    renderConnectionLoader() {
        const { className, onRef } = this.props

        let loaderClassName = "loader loader-connection"
        loaderClassName += this.state.visible ? " loader-connection--visible" : ""
        loaderClassName += className ? ` ${className}` : ""

        return (
            <div className={loaderClassName} ref={onRef}>
                <div className="loader-connection__line" />
                <div className="loader-connection__dots">
                    <div /><div /><div /><div /><div /><div />
                </div>
            </div>
        )
    }

    renderSpinnerLoader() {
        const { className, onRef } = this.props

        let loaderClassName = "loader loader-spinner"
        loaderClassName += this.state.visible ? " loader-spinner--visible" : ""
        loaderClassName += className ? ` ${className}` : ""

        return (
            <div className={loaderClassName} ref={onRef} />
        )
    }

    render() {
        const { type } = this.props

        switch (type) {
            case CONNECTION: {
                return this.renderConnectionLoader()
            }
            default: {
                return this.renderSpinnerLoader()
            }
        }
    }
}
