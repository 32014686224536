import { memo, ReactElement, ReactNode } from "react"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { AllowedFileSelectionTypes, getAcceptedFileTypes } from "./helper"

interface ProvidesHeightInjectedProps {
    isDragActive: boolean
}

type Props = Omit<DropzoneOptions, "allowedFileTypes"> & {
    allowedFileTypes: AllowedFileSelectionTypes
    children(props: ProvidesHeightInjectedProps): ReactElement<ReactNode>
}

export const Dropzone = memo<Props>(({ allowedFileTypes, children, ...rest }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: getAcceptedFileTypes(allowedFileTypes),
        ...rest,
    })

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children({ isDragActive })}
        </div>
    )
})
