import { Box, styled, SxProps, Typography } from "@mui/material"
import { BonusSystem } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Tooltip } from "../../generics/tooltip"
import { BonusPointsTypes } from "./BonusPointsTypes"

type BonusPointsStyleProps = {
    isGoldenPoints?: boolean
    noLabel?: boolean
}

type Props = {
    bonusSystem: BonusSystem
    tooltipLabel?: string
    /** @default "two-line-label" */
    variant?: "two-line-label" | "single-line-label" | "no-label"
    sx?: SxProps
}

const BonusPointsBox = styled(Box, { shouldForwardProp: (prop) => prop !== "noLabel" && prop !== "isGoldenPoints" })<BonusPointsStyleProps>(
    ({ isGoldenPoints, noLabel, theme }) => {
        const bonusPointsColor = !isGoldenPoints ? "#085cf0" : "linear-gradient(135deg, #b8993d, #f3c01a, #b8993d)"

        return {
            border: `solid 1px ${bonusPointsColor}`,
            borderRadius: theme.radius?.default,
            padding: noLabel ? theme.spacing(0.25, 0) : theme.spacing(0.25, 1),
            textAlign: "center",
            textTransform: "uppercase",
            background: bonusPointsColor,

            "& .MuiTypography-root": {
                color: !isGoldenPoints ? theme.palette.getContrastText("#085cf0") : theme.palette.getContrastText("#f3c01a"),
                minWidth: "3em",
                lineHeight: 1,
                fontSize: noLabel ? theme.font?.textSize.xs : theme.font?.textSize.s,
            },
        }
    }
)

export const BonusPoints: React.FC<Props> = ({ bonusSystem, sx, tooltipLabel, variant }) => {
    const isGoldenPoints = bonusSystem.id === BonusPointsTypes.GoldenPoints
    const { translateText } = useLocalization()

    function renderLabel() {
        const label = translateText(1826)

        if (variant === "no-label") {
            return
        }
        if (variant === "single-line-label") {
            return ` ${label}`
        }
        return (
            <>
                <br />
                {label}
            </>
        )
    }

    return (
        <Tooltip title={tooltipLabel ?? bonusSystem.name} noDelay>
            <BonusPointsBox isGoldenPoints={isGoldenPoints} noLabel={variant === "no-label"} sx={sx}>
                <Typography>
                    <strong>{bonusSystem.value?.toLocaleString()}</strong>
                    {renderLabel()}
                </Typography>
            </BonusPointsBox>
        </Tooltip>
    )
}
