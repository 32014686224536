import { AvailabilityStatus, PriceType } from "@tm/models"

export function mapAvailabilityStatusToIconName(availabilityStatus: AvailabilityStatus | undefined, mappingScheme?: "stg"): string {
    switch (availabilityStatus) {
        case AvailabilityStatus.Available:
            return "available"
        case AvailabilityStatus.AvailableInAlternativeWarehouse:
        case AvailabilityStatus.PartiallyAvailableInAlternativeWarehouse:
            return "alternative-storage"
        case AvailabilityStatus.AvailableInCentralWarehouse:
        case AvailabilityStatus.PartiallyAvailableInCentralWarehouse:
            return "store-request"
        case AvailabilityStatus.PartiallyAvailable:
            return "partially-available"
        case AvailabilityStatus.NotAvailable:
        case AvailabilityStatus.NoLongerAvailable:
            return "not-available"
        case AvailabilityStatus.NotYetDefined:
            return "refresh"
        case AvailabilityStatus.NightExpress:
            return "night-express"
        case AvailabilityStatus.ManufacturerAvailable:
            return "manufacturer-available"
        case AvailabilityStatus.ManufacturerUnavailable:
            if (mappingScheme === "stg") {
                return "not-available"
            }
            return "manufacturer-unavailable"
        case AvailabilityStatus.ManufacturerNotYetRequested:
            if (mappingScheme === "stg") {
                return "manufacturer-unavailable"
            }
            return "question"
        case AvailabilityStatus.Undefined:
        case AvailabilityStatus.Unknown:
        default:
            return "question"
    }
}

export function mapAvailabilityStatusToTextId(availabilityStatus: AvailabilityStatus | undefined): number {
    switch (availabilityStatus) {
        case AvailabilityStatus.Available:
            return 423
        case AvailabilityStatus.AvailableInAlternativeWarehouse:
            return 954
        case AvailabilityStatus.PartiallyAvailableInAlternativeWarehouse:
            return 957
        case AvailabilityStatus.AvailableInCentralWarehouse:
            return 955
        case AvailabilityStatus.PartiallyAvailableInCentralWarehouse:
            return 958
        case AvailabilityStatus.PartiallyAvailable:
            return 953
        case AvailabilityStatus.NotAvailable:
        case AvailabilityStatus.NoLongerAvailable:
            return 956
        case AvailabilityStatus.NightExpress:
            return 1288
        case AvailabilityStatus.NotYetDefined:
        case AvailabilityStatus.Undefined:
        case AvailabilityStatus.Unknown:
        default:
            return 171
    }
}

export function mapAvailabilityStatusToLevel(availabilityStatus: AvailabilityStatus | undefined): number {
    let mappedStatus = 0

    Object.keys(statusMap).forEach((key) => {
        if (statusMap[key].indexOf(availabilityStatus || 0) >= 0) {
            mappedStatus = parseInt(key)
        }
    })

    return mappedStatus
}

// TODO: status 11 moved from NotAvalible to Available (STG)
const statusMap: { [index: string]: Array<number> } = {
    "1": [2, 5, 13], // Available
    "2": [7, 11], // CentralWareHouseAvailable
    "3": [3, 6, 8], // PartiallyAvailable
    "4": [4, 14, 12], // NotAvailable
    "5": [10, 0, 1, 9], // Unknown
}

export function mapPriceTypeToTextIdShort(priceType: PriceType): number {
    switch (priceType) {
        case PriceType.RecommendedRetail:
            return 1144
        case PriceType.Purchase:
            return 55
        case PriceType.Retail:
            return 56
        default:
            return 53 // "Preis"
    }
}

export function mapPriceTypeToTextIdLong(priceType: PriceType): number {
    switch (priceType) {
        case PriceType.RecommendedRetail:
            return 1144
        case PriceType.Purchase:
            return 1132
        case PriceType.Retail:
            return 882
        default:
            return 53 // "Preis"
    }
}
