export type ModuleCalledRequest = {
    route: string
}
export interface DamageCalculationModuleCalledRequest {
    vehicleId?: string
}

export interface GPIModuleCalledRequest {
    vehicleId?: string
}

export interface RepairTimesModuleCalledRequest {
    vehicleId?: string
    productGroupIds?: number[]
}

export const TMA_EVENT = "MODULE_CALLED"
