import { channel } from "..";

/**
 * @memberof Models
 * @deprecated models should not implement any logic, also dont use hermes, take the correct hook or getter
 * @param handler
 */
export function getWorkTaskId(handler: (id?: string) => void) {
    channel("GLOBAL").subscribeOnce("WORKTASK/ID_CHANGED", (payload) => {
        handler(payload.id)
    }, true)
}

/***
 * @deprecated models should not implement any logic, also dont use hermes, use window.__worktask__ or implement getWorktask() { return window.__WORKTASK__ }
 * Returns the currently active worktask id if available.
 * Returns undefined if no worktask is active.
 */
export function getCurrentWorkTaskId(): string | undefined {
    return channel("GLOBAL").last(1, "WORKTASK/ID_CHANGED")[0]?.content?.id
}
