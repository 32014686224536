import jwtDecode from "jwt-decode"
import { Authorization, StoredLogin } from ".."

const CoopMemberIdKey = "CoopMemberId"

export function decodeJwtToken<T>(token: string) {
    return jwtDecode<T>(token)
}

export function removeStoredLogin() {
    sessionStorage.removeItem("login")
    sessionStorage.removeItem("readConfigNews")
    sessionStorage.removeItem("IFRAME_WIDGET_TOKEN_SeatbeltCrm")
    sessionStorage.removeItem(CoopMemberIdKey)

    // NEXT Shell should also be informed that the user is logged out
    if (typeof tmJSEvents != "undefined" && typeof tmJSEvents.logout == "function") {
        tmJSEvents.logout()
    }
}

export function setStoredLogin(login: StoredLogin) {
    sessionStorage.setItem("login", JSON.stringify(login))
}

export function hasValidStoredLogin() {
    const login = getStoredLogin()
    if (login) {
        login.expireDate = login.expireDate && new Date(login.expireDate)
    }

    return login && login.token && (!login.expireDate || login.expireDate > new Date())
}

export function getStoredLogin(): StoredLogin | undefined {
    const storedLogin = sessionStorage.getItem("login")

    if (storedLogin) {
        return JSON.parse(storedLogin)
    }
}

export function getStoredAuthorization(): Authorization | undefined {
    const login = getStoredLogin()
    return login && login.token ? { type: "Bearer", credentials: login.token } : undefined
}

export function setLocaleToLocalStorage(language: string, languageId: string): void {
    localStorage.setItem("language", language)
    localStorage.setItem("languageId", languageId)
}

export function getLanguageFromLocalStorage(): string | null {
    return localStorage.getItem("language")
}

export function getLanguageIdFromLocalStorage(): string | null {
    return localStorage.getItem("languageId")
}

export function hasLocaleInLocalStorage(): boolean {
    const language = getLanguageFromLocalStorage()
    const languageId = getLanguageIdFromLocalStorage()
    return !!language && !!languageId
}

export function getCoopMemberId(): string | null {
    return sessionStorage.getItem(CoopMemberIdKey)
}

export function setCoopMemberId(memberId: string | undefined): void {
    if (memberId) {
        sessionStorage.setItem(CoopMemberIdKey, memberId)
    } else {
        sessionStorage.removeItem(CoopMemberIdKey)
    }
}

export type OAuthData = {
    state?: string
    authorizationCode?: string
    redirectUri: string
    codeVerifier: string
}

export function storeOAuthData(data: OAuthData) {
    sessionStorage.setItem("oauthData", JSON.stringify(data))
}

export function getStoredOAuthData(): OAuthData | undefined {
    const storedData = sessionStorage.getItem("oauthData")

    if (storedData) {
        return JSON.parse(storedData)
    }
}
