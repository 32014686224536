import { AvailabilityStatus } from "@tm/models";
import { Icon, Image } from "../..";
import { mapAvailabilityStatusToLevel } from "@tm/utils";

export type AvailabilityIconWithTourProps = {
    status: AvailabilityStatus
    iconname?: string
    imageUrl?: string
    tourDate?: string
    tourName?: string
    onClick?(): void
}

export default function AvailabilityIconWithTour({ status, iconname, imageUrl, tourDate, tourName, onClick }: AvailabilityIconWithTourProps) {
    const baseName = "availability-icon-with-tour"

    let className = `${baseName} ${baseName}--level-${mapAvailabilityStatusToLevel(status)} ${baseName}--status-${status}`

    if (onClick)
        className += ` ${baseName}--clickable`

    let imageElement
    if (imageUrl)
        imageElement = <Image url={imageUrl} className={baseName + "__image"} />
    else if (iconname)
        imageElement = <Icon name={iconname} size="xl" />

    return (
        <div className={className} onClick={onClick}>
            <div className={baseName + "__icon"}>
                {imageElement}
            </div>
            {
                (tourDate || tourName) &&
                <div className={baseName + "__tour"}>
                    <div className={baseName + "__text"}>
                        {tourDate}
                        {tourDate && tourName && <br />}
                        {tourName}
                    </div>
                </div>
            }
        </div>
    )
}