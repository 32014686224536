import * as Appointments from "./appointments"
import * as Customers from "./customers"
import * as Parts from "./parts"
import * as RepairTimes from "./repair-times"
import * as Settings from "./settings"
import * as Stocks from "./stocks"
import * as Vehicles from "./vehicles"
import * as Vouchers from "./vouchers"

export type Paged = {
    pageIndex?: number
    pageSize?: number
}

export type Memo = {
    description: string
    value: string
    type: MemoType
    isVisible: boolean
}

export enum MemoType {
    Text = 0,
    Xml = 1,
}

export enum ClientType {
    TecDoc = 0,
}

export type MessageType =
    | "showConnectionStatus"
    | "showSettings"
    | "findAppointments"
    | "saveAppointment"
    | "deleteAppointment"
    | "findCustomers"
    | "showCustomer"
    | "saveCustomer"
    | "findVehicles"
    | "showVehicle"
    | "saveVehicle"
    | "findParts"
    | "findRepairTimes"
    | "showRepairTime"
    | "showAvailability"
    | "findVouchers"
    | "findVoucherTypes"
    | "showVoucher"
    | "saveVoucher"
    | "deleteVoucher"
    | "showVoucherHistory"

type Request<TType extends MessageType, TRequest> = {
    processId: string
    type: TType
    request: TRequest
}

type Response<TType extends MessageType, TRequest, TResponse> = Request<TType, TRequest> & {
    response?: TResponse & Error
    status: number
}

type Error = {
    exceptionMessage: string
    exceptionType?: string
    stackTrace?: string
}

export type DmsRequest =
    | Request<"findAppointments", Appointments.FindAppointmentsRequest>
    | Request<"saveAppointment", Appointments.SaveAppointmentRequest>
    | Request<"deleteAppointment", Appointments.DeleteAppointmentRequest>
    | Request<"findCustomers", Customers.FindCustomersRequest>
    | Request<"showCustomer", Customers.ShowCustomerRequest>
    | Request<"saveCustomer", Customers.SaveCustomerRequest>
    | Request<"findVehicles", Vehicles.FindVehiclesRequest>
    | Request<"showVehicle", Vehicles.ShowVehicleRequest>
    | Request<"saveVehicle", Vehicles.SaveVehicleRequest>
    | Request<"findParts", Parts.FindPartsRequest>
    | Request<"findRepairTimes", RepairTimes.FindRepairTimesRequest>
    | Request<"showRepairTime", RepairTimes.ShowRepairTimeRequest>
    | Request<"showAvailability", Stocks.ShowAvailabilityRequest>
    | Request<"findVouchers", Vouchers.FindVouchersRequest>
    | Request<"findVoucherTypes", undefined>
    | Request<"showVoucher", Vouchers.ShowVoucherRequest>
    | Request<"saveVoucher", Vouchers.SaveVoucherRequest>
    | Request<"deleteVoucher", Vouchers.DeleteVoucherItemRequest>
    | Request<"showVoucherHistory", Vouchers.ShowVoucherHistoryRequest>

export type DmsResponse =
    | Response<"findAppointments", Appointments.FindAppointmentsRequest, Appointments.FindAppointmentsResponse>
    | Response<"saveAppointment", Appointments.SaveAppointmentRequest, undefined>
    | Response<"deleteAppointment", Appointments.DeleteAppointmentRequest, undefined>
    | Response<"findCustomers", Customers.FindCustomersRequest, Customers.FindCustomersResponse>
    | Response<"showCustomer", Customers.ShowCustomerRequest, Customers.ShowCustomerResponse>
    | Response<"saveCustomer", Customers.SaveCustomerRequest, Customers.SaveCustomerResponse>
    | Response<"findVehicles", Vehicles.FindVehiclesRequest, Vehicles.FindVehiclesResponse>
    | Response<"showVehicle", Vehicles.ShowVehicleRequest, Vehicles.ShowVehicleResponse>
    | Response<"saveVehicle", Vehicles.SaveVehicleRequest, Vehicles.SaveVehicleResponse>
    | Response<"findParts", Parts.FindPartsRequest, Parts.FindPartsResponse>
    | Response<"findRepairTimes", RepairTimes.FindRepairTimesRequest, RepairTimes.FindRepairTimesResponse>
    | Response<"showRepairTime", RepairTimes.ShowRepairTimeRequest, RepairTimes.ShowRepairTimeResponse>
    | Response<"showAvailability", Stocks.ShowAvailabilityRequest, Stocks.ShowAvailabilityResponse>
    | Response<"findVouchers", Vouchers.FindVouchersRequest, Vouchers.FindVouchersResponse>
    | Response<"findVoucherTypes", undefined, Vouchers.FindVoucherTypesResponse>
    | Response<"showVoucher", Vouchers.ShowVoucherRequest, Vouchers.ShowVoucherResponse>
    | Response<"saveVoucher", Vouchers.SaveVoucherRequest, Vouchers.SaveVoucherResponse>
    | Response<"deleteVoucher", Vouchers.DeleteVoucherItemRequest, undefined>
    | Response<"showVoucherHistory", Vouchers.ShowVoucherHistoryRequest, Vouchers.ShowVoucherHistoryResponse>

export function isValidDmsMessageType(type: MessageType): boolean {
    if (typeof type !== "string") {
        return false
    }

    return (
        type === "showConnectionStatus" ||
        type === "showSettings" ||
        type === "findAppointments" ||
        type === "saveAppointment" ||
        type === "deleteAppointment" ||
        type === "findCustomers" ||
        type === "showCustomer" ||
        type === "saveCustomer" ||
        type === "findVehicles" ||
        type === "showVehicle" ||
        type === "saveVehicle" ||
        type === "findParts" ||
        type === "findRepairTimes" ||
        type === "showRepairTime" ||
        type === "showAvailability" ||
        type === "findVouchers" ||
        type === "findVoucherTypes" ||
        type === "showVoucher" ||
        type === "saveVoucher" ||
        type === "deleteVoucher" ||
        type === "showVoucherHistory"
    )
}
