import { IContainerBundleEntity } from "../../models";

/**
 * @property loading not sure what i wanted to indicate with this, needs rename or clarification or new
 */
export class InternalEntity<SModel> implements IContainerBundleEntity<SModel> {
    private entity?: SModel
    ts: number
    loading: boolean
    result?: SModel

    constructor() {
        this.ts = Date.now()
        this.loading = true

        Object.defineProperty(this, "result", {
            set(value: SModel) {
                this.update(value)
            },
            get() {
                return this.entity
            },
            configurable: true
        })
    }

    /**
     * @param duration cache expire duration in ms
     */
    isExpired = (duration: number) => {
        if((Date.now() - this.ts ) / 1000  > duration){
            this.reset()
            return true
        }

        return false
    }

    update = (model: SModel) => {
        this.entity = model
        this.loading = false
        this.ts = Date.now()
    }

    reload = () => {
        this.loading = true
        this.entity = undefined
    }

    reset = () => {
        this.loading = false
        this.entity = undefined
    }
}
