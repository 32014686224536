import { CheckHasMemoResponse, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"

export const KEY = "memotool_useCheckHasMemo"

const cacheTime = {
    staleTime: 30 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    retry: 3,
}

const getData = async (memoToolEnabled: boolean, manufacturerName: string, tecDocTypeId: number, engineCode?: string) => {
    const response: CheckHasMemoResponse = await Container.getInstance<CheckHasMemoResponse | undefined>(RegisteredModels.MemoTool).action(
        "checkHasMemo"
    )(manufacturerName, tecDocTypeId, engineCode)

    return {
        ...response,
        isEnabled: memoToolEnabled,
    }
}

export function useCheckHasMemo(memoToolEnabled?: boolean, manufacturerName?: string, tecDocTypeId?: number, engineCode?: string) {
    const queryEnabled = !!memoToolEnabled && !!manufacturerName && !!tecDocTypeId

    const { data, isLoading, error } = useQuery(
        [KEY, tecDocTypeId, engineCode],
        () => getData(!!memoToolEnabled, manufacturerName!, tecDocTypeId!, engineCode),
        { ...cacheTime, enabled: queryEnabled }
    )

    return { checkHasMemo: data, isMemoLoading: isLoading, error }
}

export function useFetchCheckHasMemo(memoToolEnabled?: boolean, manufacturerName?: string, tecDocTypeId?: number, engineCode?: string) {
    const queryClient = useQueryClient()
    const queryEnabled = !!memoToolEnabled && !!manufacturerName && !!tecDocTypeId

    return useCallback(() => {
        if (!queryEnabled) {
            return Promise.resolve({
                isEnabled: !!memoToolEnabled,
                hasMemoInfo: false,
                hasTechnicalNotes: false,
            })
        }

        return queryClient.fetchQuery(
            [KEY, tecDocTypeId, engineCode],
            () => getData(!!memoToolEnabled, manufacturerName!, tecDocTypeId!, engineCode),
            { ...cacheTime }
        )
    }, [queryEnabled, engineCode, memoToolEnabled])
}
