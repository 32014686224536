import { ReactNode } from "react"
import { styled, SxProps } from "@mui/material"

interface TabPanelProps {
    children?: ReactNode
    index: number | string
    value: number | string
    sx?: SxProps
    variant?: "standard" | "scrollable"
}

const StyledTabPanel = styled("div")<TabPanelProps>(({ theme, variant }) => ({
    paddingLeft: theme.spacing(1),

    ...(variant === "standard" && {
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        backgroundColor: "white",
        borderBottom: "1px solid lightGray",
        borderLeft: "1px solid lightGray",
        borderRight: "1px solid lightGray",
        borderBottomLeftRadius: theme.radius?.default || "4px",
        borderBottomRightRadius: theme.radius?.default || "4px",
    }),
}))

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <StyledTabPanel {...props} hidden={value !== index} {...other}>
            {children}
        </StyledTabPanel>
    )
}
