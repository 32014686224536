import { Component } from "react"
import Icon from "../icon"

import ToolbarTitle from "./components/toolbar-title";
import ToolbarContent from "./components/toolbar-content";

export type ToolbarProps = {
    fixed?: boolean
    title?: string
    modifiers?: Array<
        "no-border" |
        "border-left" |
        "align-right" |
        "title-before" |
        "title-bottom" |
        "stretch" |
        "highlight"
    >
    className?: string
}

/**
 * @description Use this to force a vertical toolbar group layout
 */
export default class Toolbar extends Component<ToolbarProps, any> {
    constructor(props: ToolbarProps) {
        super(props)
    }

    render() {
        const baseName: string = `toolbar`
        const { title, className, modifiers, fixed } = this.props
        let fullClassName = `${baseName}`

        if(modifiers) {
            modifiers.forEach((modifier: string) => {
                fullClassName = `${fullClassName} ${baseName}--${modifier}`
            });
        }

        if(className) {
            fullClassName = `${fullClassName} ${className}`
        }

        let ToolbarElement = (
            <div className={fullClassName}>
                <div className={`${baseName}__inner`}>
                    {title && <ToolbarTitle>{title}</ToolbarTitle>}
                    <ToolbarContent>
                        {this.props.children}
                    </ToolbarContent>
                </div>
            </div>
        )

        if(fixed) {
            ToolbarElement = <FixedToolbar>{ToolbarElement}</FixedToolbar>
        }

        return ToolbarElement;
    }
}

const FixedToolbar = (props: any) => {
    return <div className="fixed-toolbar">{props.children}</div>
}
