import { Stack, styled } from "@mui/material"
import { TreeNode } from "@tm/models"
import { useEffect, useState } from "react"
import { TreeCategory } from "./TreeCategory"

const Overview = styled(Stack)(() => ({
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "1em 1em 0.5em 1em",
    height: "394px",
}))

type Props = {
    initialTree: TreeNode[]
    hideSecondLevel?: boolean
    nodeVariant?: "fastClick" | "default"
    onNodeClick: (node: TreeNode) => void
}

export function OverviewFlex(props: Props) {
    // in the searchtree is vertical space for 12 nodes where parent nodes count double for taking double the space
    const [nodeLimit, setNodeLimit] = useState(12)
    const PARENT_NODE_WEIGHT = 2
    // have always 4 chunks/columns
    const CHUNK_LIMIT = 4
    const [treeChunks, setTreeChunks] = useState<TreeNode[][]>([])

    const isWithinChunkLimit = (nodes?: Array<TreeNode>) => {
        if (nodes) {
            const totalNodesInChunk = nodes.reduce((total, node) => {
                return total + PARENT_NODE_WEIGHT + (node.childNodes?.length ?? 0)
            }, 0)
            return totalNodesInChunk <= nodeLimit
        }
        return false
    }

    const chunkItems = () => {
        const tree = props.initialTree
        let chunks: Array<Array<TreeNode>> = []

        let i = 0
        while (i < tree.length) {
            // check if there is space left in chunk/column
            if (chunks.length > 0 && isWithinChunkLimit([...chunks.last()!, tree[i]])) {
                chunks.last()?.push(tree[i])
            } else {
                chunks.push([tree[i]])
            }
            i += 1
        }

        if (chunks.length > CHUNK_LIMIT) {
            setNodeLimit((prev) => prev + 1)
            chunks = []
        }

        return chunks
    }

    useEffect(() => {
        setTreeChunks(chunkItems())
    }, [props, nodeLimit])

    return (
        <Overview>
            {treeChunks.map((chunk) => {
                return (
                    <Stack key={`chunkKey${chunk.first()?.id}`}>
                        {chunk.map((node, index) => (
                            <TreeCategory
                                key={`nodeKey_${node.id}`}
                                category={node}
                                onNodeClick={props.onNodeClick}
                                hideSecondLevel={props.hideSecondLevel}
                                nodeVariant={props.nodeVariant}
                                anonymousProps={{ isAnonymous: true, isFirstInChunk: index === 0 }}
                            />
                        ))}
                    </Stack>
                )
            })}
        </Overview>
    )
}
