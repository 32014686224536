import { classes, getStyleTheme, useSize, useStyle, } from "@tm/context-distribution"
import { getValue } from "@tm/utils"
import { percent } from "csx"
import * as React from "react"

const bem = (...classes: (string | false | undefined | null)[]): string => {
    if (classes.length < 2) return classes.first() || ""
    return classes.filter(c => c).map((x, idx) => idx && `${classes.first()}--${x}` || x).join(" ")
}

type SizeValues = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export type GridProps = {
    xs?: SizeValues
    s?: SizeValues
    m?: SizeValues
    l?: SizeValues
    xl?: SizeValues
    container?: boolean
    center?: boolean
    auto?: boolean
    className?: string
    children?: React.ReactNode
}


const Grid: React.FC<GridProps> = (props) => {
    const { children, container, center, className, xl, l, m, s, xs, auto } = props
    const screenSize = useSize()
    const getPrevSize = (items: string[]): number => {
        if (!items.length)
            return 12
        let firstItem = items.shift()
        if (firstItem && getValue(props, [firstItem]))
            return getValue(props, [firstItem])
        else return getPrevSize(items)
    }

    const getSize = () => {
        switch (screenSize) {
            case "xl":
                return getPrevSize(["xl", "l", "m", "s", "xs"])
            case "l":
                return getPrevSize(["l", "m", "s", "xs"])
            case "m":
                return getPrevSize(["m", "s", "xs"])
            case "s":
                return getPrevSize(["s", "xs"])
            case "xs":
                return getPrevSize(["xs"])
            default:
                return 12
        }
    }

    let gridClassName = ""
    if (container)
        gridClassName = bem(style.container, center && "center")

    if (xl || l || m || s || xs || auto)
        gridClassName = classes(gridClassName, bem(style.item, getSize().toString(), auto && "auto"))

    return (
        <div className={classes(gridClassName, className)} >
            {children}
        </div>
    )
}


const theme = getStyleTheme()
const style = useStyle({
    container: {
        display: "flex",
        width: percent(100),
        flexWrap: "wrap",
        boxSizing: "border-box",
        $nest: {
            "&--center": {
                justifyContent: "center"
            },

        }
    },
    item: {
        $nest: {
            "&--auto": {
                flex: 1
            },
            ...Array.from(Array(12).keys()).reduce((obj, _, idx) => ({
                ...obj,
                [`&--${idx + 1}`]: { flexBasis: percent(((idx + 1 ?? 12) / 12) * 100) }
            }), {}),
        }
    }
})(Grid)



export default Grid
