import { KeyValueStateContainer, RegisteredModels, UseKeyValueRequest } from "@tm/models"
import { Container } from "@tm/nexus"
import { useMemo } from "react"
import { atomFamily, selectorFamily, useRecoilStateLoadable } from "recoil"

const keyValueAtom = atomFamily<string | undefined, UseKeyValueRequest | undefined>({
    key: "statemanagement_keyValuesAtom",
    default: selectorFamily({
        key: "statemanagement_keyValuesAtom/defaultSelector",
        get: (request) => () => {
            if (!request || request.key.includes("undefined")) {
                return undefined
            }

            const container: KeyValueStateContainer = Container.getInstance(RegisteredModels.KeyValueStore)
            return container.action("loadKeyValue")(request.key, request.ownedByRepairShop).catch()
        },
    }),
})

/** Only works correctly if the value of this key is always set using this hook! */
export function useKeyValue(request: UseKeyValueRequest | undefined) {
    const [state, setState] = useRecoilStateLoadable(keyValueAtom(request))

    const saveKeyValue = useMemo(() => {
        if (!request) {
            return
        }

        return (value: string | undefined) => {
            const container: KeyValueStateContainer = Container.getInstance(RegisteredModels.KeyValueStore)
            return container
                .action("saveKeyValue")(request.key, value, request.ownedByRepairShop)
                .then(() => {
                    setState(value)
                })
        }
    }, [request?.key, request?.ownedByRepairShop])

    let value
    if (state.state === "hasValue") {
        value = state.contents
    }

    return [value, saveKeyValue, state.state] as [typeof value, typeof saveKeyValue, typeof state.state]
}
