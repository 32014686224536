import { RegisteredModels, ShowCisOptionsRequest, ShowCisOptionsResponse } from "@tm/models"
import { Container } from "@tm/nexus"
import { QueryFunctionContext, useQuery } from "react-query"
import { useTelesalesCustomerNumber } from "../.."
import { useHasCis } from "./useHasCis"

const QUERY_KEY = "cis_useShowCisOptions"

type QueryKey = [typeof QUERY_KEY, string | undefined]

/**
 * Returns the options for the current CIS customer.
 */
export function useShowCisOptions() {
    const hasCis = useHasCis()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const { data, isLoading } = useQuery(
        [QUERY_KEY, telesalesCustomerNo],
        fetchCisOptions,
        {
            enabled: hasCis && enableServiceCalls,
            staleTime: Infinity,
            cacheTime: Infinity
        }
    )

    return {
        cisOptionsLoading: isLoading,
        cisOptions: data,
    }
}

function fetchCisOptions({ queryKey: [, customerNo]}: QueryFunctionContext<QueryKey>) {
    const request: ShowCisOptionsRequest = { customerNo }

    return Container.getInstance<ShowCisOptionsResponse | undefined>(RegisteredModels.CIS_ShowCisOptions)
        .subscribe(request).load()
}
