import { RegistrationNoType } from "../../enumerations"
import { RegistrationNoDetails } from "./cars"

export * from "./bikes"
export * from "./cars"
export * from "./trucks"

export type ManufacturerBase = {
    id: number
    shortCode: string
    description: string
    priority: number
    thumbnail: string
}

export type ModelSeriesBase = {
    id: number
    manufacturerId: number
    description: string
    constructionYearFrom?: ConstructionYear
    constructionYearTo?: ConstructionYear
    sortId: number // not used
    manufacturerThumbnail: string
    modelSeriesThumbnail: string
    modelSeriesImage: string
    priority: number // not used
}

export type VehicleModel = {
    id: number
    manufacturerId: number
    modelSeriesId: number
    description: string
    fullDescription: string
    constructionYearFrom?: ConstructionYear
    constructionYearTo?: ConstructionYear
    sortId: number // not used
    engineCodes?: Array<string>
    engineCapacityCcm?: number
    hasAwDocRepairTimes: boolean // not used
    manufacturerThumbnail: string
    modelSeriesThumbnail: string
    modelSeriesImage: string
    thumbnail: string
    image: string
    dataSourceId?: number
    /** MLE: 22.02.21 - Currently only supplied for `CarModel` */
    registrationNoDetails?: RegistrationNoDetails
    /** Added because of TMF: If this is filled it will always be the TecDoc ID. The `id` property will be the Online ID. */
    tecdocTypeId?: number
    /** Added because of TMF: If this is filled it will always be the Topmotive ID. The `id` property will be the Online ID. */
    topmotiveTypeId?: number
    countryCode?: string
    registrationNoTypeId?: RegistrationNoType

}

export type ConstructionYear = {
    month: number
    year: number
}

export type TirePressure = {
    frontLeft: string
    frontRight: string
    rearLeft: string
    rearRight: string
    unit: string
}

export enum EFuelTypes {
    None = 0,
    //  Benzin
    Petrol = 1,
    Diesel = 2,
    //  Gas
    Gas = 3,
    //  Gas
    NaturalGas = 6,
    //  E-motor
    Electric = 11,
    //  Hybrid
    PetrolElectric = 13,
    //  Hybrid
    DieselElectric = 14,
}
export type FuelType = {
    id: EFuelTypes
    value: string
}
