import * as React from "react"
import { ThemeProviderContext } from ".";

export type WithThemeProps = {
    theme: {}
}

export function withTheme<P extends WithThemeProps>(component: React.ComponentType<P>): React.ComponentClass<Omit<P, keyof WithThemeProps>> {
    return class extends React.Component<Omit<P, keyof WithThemeProps>> {

        renderInnerComponent(theme: {}) {

            const currentProps: any = this.props || {}

            const props: P & WithThemeProps = {
                ...currentProps,
                theme,
            }

            return React.createElement(component, props)
        }

        render() {
            return (
                <ThemeProviderContext.Consumer>
                    {(theme: {}) => this.renderInnerComponent(theme)}
                </ThemeProviderContext.Consumer>
            )
        }
    }
}

