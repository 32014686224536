import { ChangeEvent, KeyboardEvent, FocusEvent, useCallback, useRef, useState, useMemo, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { styled } from "@mui/material"
import AmountInfo from "./AmountInfo"
import { TextField } from "../../../../generics/textfield"

const MAX_AMOUNT = 9999

type BasketAmountFieldProps = {
    originalQuantity: number
    division: number
    handleChangeQuantity: (value: number, loadErpInfo: boolean) => void
    hasSuggestedQuantity?: boolean
    disabled?: boolean
    loadErpInfos?: boolean
}

const AmountField = styled(TextField)({
    marginTop: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    ".MuiInputBase-formControl": {
        paddingRight: 0,
    },
    ".MuiInputAdornment-positionEnd": {
        marginLeft: 0,
    },

    ".MuiInputBase-input": {
        boxSizing: "border-box",
        height: 30,
        padding: 8,
        paddingRight: 4,
        width: 82,
    },
    "&[data-has-amountinfo] .MuiInputBase-input": {
        paddingRight: 0,
        width: 58,
    },
})

export function BasketAmountField(props: BasketAmountFieldProps) {
    const { division, originalQuantity, handleChangeQuantity, hasSuggestedQuantity, disabled, loadErpInfos } = props

    const { translateText } = useLocalization()

    const [quantity, setQuantity] = useState(division ? originalQuantity.ceil(division) : originalQuantity)

    const inputRef = useRef<HTMLInputElement>()
    const keyPressed = useRef<string>()

    useEffect(() => {
        const updatedQuantity = division ? originalQuantity.ceil(division) : originalQuantity
        setQuantity(updatedQuantity)

        if (updatedQuantity !== originalQuantity) {
            handleChangeQuantity(updatedQuantity, !!loadErpInfos)
        }
    }, [loadErpInfos, originalQuantity, division])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const quantityValue = parseInt(e.target.value)
            if (Number.isNaN(quantityValue)) {
                setTimeout(() => {
                    e.target.value = originalQuantity.toString()
                    e.target.select()
                }, 0)
            }
            setQuantity(quantityValue)
        },
        [division, originalQuantity]
    )

    const handleBlur = useCallback(() => {
        if (keyPressed.current === "Escape") {
            setQuantity(originalQuantity.ceil(division))
            return
        }

        let noZeroQuantity = quantity || 1

        if (division > 1) {
            noZeroQuantity = noZeroQuantity.ceil(division)
        }

        if (originalQuantity !== noZeroQuantity || quantity < originalQuantity) {
            setQuantity(noZeroQuantity)
            handleChangeQuantity(noZeroQuantity, true)
        }
    }, [handleChangeQuantity, originalQuantity, division, quantity])

    const handleFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
        e.target.select()
    }, [])

    const handleKeyUp = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        keyPressed.current = e.code
        switch (e.code) {
            case "Escape":
            case "NumpadEnter":
            case "Enter": {
                ;(e.target as HTMLInputElement).blur()
                break
            }
            default:
        }
    }, [])

    const maxAmountValue = useMemo(() => {
        return MAX_AMOUNT - (MAX_AMOUNT % division)
    }, [division])

    const amountInfo = useMemo(() => {
        if (division > 1) {
            return translateText(13627)
        }

        if (hasSuggestedQuantity) {
            return translateText(13670)
        }
    }, [division, hasSuggestedQuantity, translateText])

    return (
        <AmountField
            {...(amountInfo && { "data-has-amountinfo": 1 })}
            className="basketAmountField"
            inputRef={inputRef}
            type="number"
            inputProps={{
                min: division,
                max: maxAmountValue,
                step: division,
                onScroll: (e) => e.stopPropagation(),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
                endAdornment: !!amountInfo && <AmountInfo text={amountInfo} />,
            }}
            value={quantity}
            disabled={disabled || false}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={() => inputRef?.current?.focus()} // fix firefox not running into onBlur - NEXT-28029
        />
    )
}
