import { TmaEventTracking } from "../.."
import { BaseTrackingModel } from "../../models/models"
import { ModuleCalledRequest, RepairTimesModuleCalledRequest, DamageCalculationModuleCalledRequest, TMA_EVENT, GPIModuleCalledRequest } from "./models"

export const ModuleCalledRequestHelper = (data: ModuleCalledRequest & (RepairTimesModuleCalledRequest | DamageCalculationModuleCalledRequest | GPIModuleCalledRequest)) => {
    const trackingData: BaseTrackingModel<ModuleCalledRequest & (RepairTimesModuleCalledRequest | DamageCalculationModuleCalledRequest | GPIModuleCalledRequest)> = {
        action: "module-called",
        bundle: "any",
        tmaEvent: TMA_EVENT,
        payload: {
            ...data,
        },
        component: "widget-navigation-dropdown",
        source: "any",
        type: "click",
    }

    TmaEventTracking.dispatchTrack(trackingData)
}
