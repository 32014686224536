import { TmaEvent, BaseTrackingModel } from "../../models/models"
import { DamageCalculationModuleCalledRequest, GPIModuleCalledRequest, ModuleCalledRequest, RepairTimesModuleCalledRequest, TMA_EVENT } from "./models"
import AllDataTechDataModuleCalled from "./alldata-techdata"
import DamageCalculationDatModuleCalled from "./damage-calculation-dat"
import DamageCalculationEurotaxModuleCalled from "./damage-calculation-eurotax"
import GPIModuleCalled from "./gpi"
import RepairTimesAutoDataModuleCalled from "./repairtimes-autodata"
import RepairTimesAwDocModuleCalled from "./repairtimes-awdoc"

export * from "./event-helper"

export class ModuleCalledSwitch implements TmaEvent {
    key = TMA_EVENT

    modules = [
        RepairTimesAutoDataModuleCalled,
        RepairTimesAwDocModuleCalled,
        DamageCalculationDatModuleCalled,
        AllDataTechDataModuleCalled,
        DamageCalculationEurotaxModuleCalled,
        GPIModuleCalled
    ]

    handle = (
        trackingData: BaseTrackingModel<ModuleCalledRequest & (RepairTimesModuleCalledRequest | DamageCalculationModuleCalledRequest | GPIModuleCalledRequest)>
    ): Promise<void> | void => {
        if (trackingData.tmaEvent && trackingData.tmaEvent.toLocaleLowerCase() != this.key.toLocaleLowerCase()) {
            return
        }
        if (!trackingData.payload) {
            return
        }

        const { route } = trackingData.payload
        const module = this.getModule(route)

        module?.handle(trackingData)
    }

    getModule(route: string) {
        return this.modules.find((module) => {
            return route && route.indexOf(module.key) >= 0 // called url could look like this "/4cv6UeZpovkZCIgjEVo8zF/repair-times/awdoc/select"
        })
    }
}

export default new ModuleCalledSwitch()
