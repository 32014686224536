import { useCallback } from "react"
import { atomFamily, useRecoilState } from "recoil"
import { Article, ErpInformation } from "@tm/models"
import "../../extensions/array"

const detailedErpResponses = atomFamily<ErpInformation[], string | undefined>({ key: "detailedErpResponses", default: [] })

export function useDetailedErpInfoStore(workTaskId?: string) {
    const [infos, setInfos] = useRecoilState(detailedErpResponses(workTaskId))

    const addInfo = useCallback(
        (erpInfo: ErpInformation) => {
            const index = infos.findIndex((x) => equalsInfo(erpInfo, x))
            if (index !== -1) {
                setInfos(infos.with(index, erpInfo))
                return
            }
            setInfos([...infos, erpInfo])
        },
        [infos]
    )

    const addInfos = useCallback(
        (erpInfos: ErpInformation[]) => {
            erpInfos.forEach((erpInfo) => {
                addInfo(erpInfo)
            })
        },
        [addInfo]
    )

    const findInfo = useCallback(
        (article: Article, quantity?: number) => {
            return infos.find(
                (x) =>
                    x.dataSupplierArticleNumber === article.supplierArticleNo &&
                    x.dataSupplierId === article.supplier.id &&
                    x.quantity?.requestedValue === (quantity ?? article.quantity)
            )
        },
        [infos]
    )

    return { infos, addInfo, addInfos, findInfo }
}

function equalsInfo(erpInfo1: ErpInformation, erpInfo2: ErpInformation) {
    return (
        erpInfo1.wholesalerArticleNumber === erpInfo2.wholesalerArticleNumber &&
        erpInfo1.dataSupplierArticleNumber === erpInfo2.dataSupplierArticleNumber &&
        erpInfo1.dataSupplierId === erpInfo2.dataSupplierId &&
        erpInfo1.quantity?.value === erpInfo2.quantity?.value
    )
}
