import { ModelState } from "../types"

export function getFieldErrors(modelState: ModelState, path: string | Array<string>): Array<string> {
    if (!modelState) {
        return []
    }

    let fieldName: string
    if (Array.isArray(path)) {
        fieldName = path.join(".")
    } else {
        fieldName = path
    }

    const list = modelState[fieldName]

    if (!list) {
        return []
    }

    return list
}
