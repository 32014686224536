/** *
 * ce 08.05.2020 - maybe we need this script global. currently just saving, but not exporting. mb it can be deleted or exported 😘
 */
export {}

declare global {
    interface String {
        hashCode(): number
    }
}

String.prototype.hashCode = function hashCode() {
    return generateHash(this as string)
}

/** *
 * @description simple and fast hashing method, for non-sensitive data
 * @source {1} - https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 * @source {2} - https://jsperf.com/string-hashing-methods
 */
function generateHash(value: string) {
    let hash = 0

    if (!value.length) {
        return hash
    }

    for (let i = 0, imax = value.length, n; i < imax; ++i) {
        n = value.charCodeAt(i)

        hash = (hash << 5) - hash + n
        hash &= hash // Convert to 32-bit integer
    }

    return Math.abs(hash)
}
