import { TmaEModule, getCurrentModuleMode } from "@tm/models"
import { getWorkTaskId } from "./context"
import { OriginTypes } from "../models/originTypes"

export function getCatalogOrigin(): TmaEModule {
    const path = decodeURIComponent(document.location.pathname)
    const workTaskId = getWorkTaskId()
    
    if (path == "/") {
        return TmaEModule.START_PAGE
    } else if (path == "/" + workTaskId) {
        return TmaEModule.DASHBOARD
    }

    let origin: TmaEModule = TmaEModule.UNDEFINED

    for (const originType of OriginTypes) {
        for (const cPath of originType.paths) {
            if (path.indexOf(cPath) >= 0) {
                origin = originType.origin
                break
            }
        }
    }

    return origin
}