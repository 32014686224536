import { RepairShop, OpeningTime } from "../model"
import { OrderingType, UserContext, UserContextParameters } from "@tm/models"

export function mapRepairShop(response?: any): RepairShop | undefined {
    if (!response)
        return undefined

    return {
        ...response,
        openingTimes: mapOpeningTimes(response.openingTimes),
        logo: mapLogo(response.logo),
        printLogo: response.printLogo
    }
}

function mapOpeningTimes(openingTimes?: any): OpeningTime | undefined {
    if (!openingTimes)
        return undefined

    return {
        ...openingTimes,
        from: openingTimes.from ? new Date(openingTimes.from) : undefined,
        until: openingTimes.until ? new Date(openingTimes.until) : undefined
    }
}

function mapLogo(logo?: Array<number>): string | undefined {
    if (!logo)
        return undefined

    return "data:image/*;base64," + logo
}

export function mapUserContext(response?: any): UserContext {
    return {
        ...response,
        externalAuthenticationTokens: mapExternalAuthenticationTokens(response.externalAuthenticationTokens),
        parameter: mapParameters(response.parameter)
    }
}

type KeyValue = {
    key: string
    value?: string
}

function mapExternalAuthenticationTokens(response?: Array<KeyValue>): { [key: string]: string } {
    const tokens: { [key: string]: string } = {}

    if (response) {
        response.forEach(x => {
            if (x.value)
                tokens[x.key] = x.value
        })
    }

    return tokens
}

function mapParameters(response?: Array<KeyValue>): UserContextParameters {
    const parameters: UserContextParameters = {
        purchasePricesDisabled: false,
        orderDisabled: false,
        hideBestsellers: false,
        catalogLight: false,
        urlLogoMoodPicture: undefined,
        orderingType: OrderingType.NoCentralOrdering,
        clientErpCachingTimespan: 0,
        positionChangeEArtNrHArtNr: false,
        hideDealerPartNumber: false,
        showCloseWorktaskButton: false,
        showVoucherKVA: true,
        showVoucherOrder: true,
        showVoucherReturn: false,
        showDataSupplierLogos: false
    }

    if (!response) return parameters

    response.forEach(x => {
        switch (x.key) {
            case "PurchasePricesDisabled":
                parameters.purchasePricesDisabled = true
                break
            case "OrderDisabled":
                parameters.orderDisabled = true
                break
            case "HideBestsellers":
                parameters.hideBestsellers = true
                break
            case "CatalogLight":
                parameters.catalogLight = true
                break
            case "UrlLogoMoodPicture":
                if (x.value) {
                    parameters.urlLogoMoodPicture = x.value
                }
                break
            case "TraderAddress":
                parameters.traderAddress = x.value
                break
            case "CollectiveCustomer": {
                parameters.collectiveCustomer = x.value
                break
            }
            case "CustomerReferenceNumber":
                parameters.customerReferenceNumber = x.value
                break
            case "CentralOrdering":
                parameters.orderingType = parseNumber(x.value) ?? parameters.orderingType
                break
            case "AddressGVS":
                parameters.addressGVS = x.value
                break
            case "ErpCacheTimeSpan":
                parameters.clientErpCachingTimespan = parseNumber(x.value) ?? parameters.clientErpCachingTimespan
                break
            case "CacheFrontendArticleListSec":
                parameters.cacheFrontendArticleListSec = parseNumber(x.value)
                break
            case "PositionChangeEArtNrHArtNr":
                parameters.positionChangeEArtNrHArtNr = true
                break
            case "HideDealerPartNumber":
                parameters.hideDealerPartNumber = true
                break
            case "ShowCloseWorktaskButton":
                parameters.showCloseWorktaskButton = true
                break
            case "ShowReceiptWidget_KVA":
                parameters.showVoucherKVA = x.value === "1"
                break
            case "ShowReceiptWidget_Order":
                parameters.showVoucherOrder = x.value === "1"
                break
            case "ShowReceiptWidget_Return":
                parameters.showVoucherReturn = x.value === "1"
                break
            case "Hotline": {
                parameters.hotline = x.value
                break
            }
            case "ShowDataSupplierLogos":
                parameters.showDataSupplierLogos = true
                break
            default: {
                const key = `${x.key[0].toLowerCase()}${x.key.substring(1, x.key.length)}`
                parameters[key] = parseBoolean(x.value) ?? parseNumber(x.value) ?? x.value
                break
            }
        }
    })

    return parameters
}

function parseBoolean(value?: string): boolean | undefined {
    value = value?.toLowerCase()
    if (value == "false") {
        return false
    }

    if (value == "true") {
        return true
    }
}

function parseNumber(value?: string) {
    return value && /^-?\d+/.test(value) ? parseFloat(value) : undefined
}
