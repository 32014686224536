import { ECounterType } from "@tm/models"
import { TmaEventTracking, TmaHelper } from "../.."
import { OriginTypes } from "../../models/originTypes"

export const GeneralCountEventHelper = {
    Call: async (counterType?: ECounterType, toggleValue?: boolean) => {
        TmaEventTracking.dispatchTrack({
            tmaEvent: "GENERAL_COUNT",
            action: "general_count_called",
            bundle: "general",
            component: "repository", 
            payload: { counterType, toggleValue },
            source: "general",
            type: "click",
        })
    },

    CallModule: async (url: string, toggleValue?: boolean) => {
        const counterType = getModuleOrigin(url)

        if (!counterType) { return }
        
        TmaHelper.GeneralCountEvent.Call(counterType, toggleValue)
    },
}

function getModuleOrigin(url:string): ECounterType | undefined {
    let counter: ECounterType | undefined = ECounterType.Undefined

    for (const generalCounterType of OriginTypes) {
        for (const cPath of generalCounterType.paths) {
            if (url.indexOf(cPath) >= 0) {
                counter = generalCounterType.counterType
                break
            }
        }
    }
    return counter
}
