import { memo } from "react"
import { AmountItem } from "..";
import { fixPrecisionSymbol } from "@tm/utils";

export const AmountFieldItem = memo<AmountItem>((props) => {
    const { value, unit, division } = props

    let numberDecimalPlaces = 0
    if (division.toString().indexOf('.') >= 0) {
        const decimalPlaces = division.toString().split('.').last()
        if (decimalPlaces && decimalPlaces.length > 0) {
            numberDecimalPlaces = decimalPlaces.length
        }
    }

    let showValue = numberDecimalPlaces == 0 ? value : value.toFixed(numberDecimalPlaces)
    showValue = fixPrecisionSymbol(showValue.toString())

    return (
        <div className="amount-field__item">
            {showValue} {!!unit && unit}
        </div>
    )
})
