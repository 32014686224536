import { UIAHtmlAttribut, UITypes } from "../types"

const SearchtreeV2Overviewgrid: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtreeV2.overviewgrid",
}

const SearchtreeV2TreeNodeContainer: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtreeV2.treeNode.Container",
}

const SearchtreeV2TreeNode: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtreeV2.TreeNode",
}

const SearchtreeV2TreeNodeIcon: UIAHtmlAttribut = {
    "data-type": UITypes.Icon,
    "data-id": "searchtreeV2.TreeNode.Icon",
}

const SearchtreeV2TreeNodeFastClick: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "searchtreeV2.TreeNode.Fastclick",
}

const SearchtreeV2TreeNodeName: UIAHtmlAttribut = {
    "data-type": UITypes.Link,
    "data-id": "searchtreeV2.TreeNodeName",
}

const SearchtreeV2BestsellerContainer: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtreeV2.bestseller",
}

const SearchtreeV2BestsellerLabel: UIAHtmlAttribut = {
    "data-type": UITypes.label,
    "data-id": "searchtreeV2.bestseller.label",
}

const SearchtreeV2BestsellerCheckbox: UIAHtmlAttribut = {
    "data-type": UITypes.Checkbox,
    "data-id": "searchtreeV2.bestseller.checkbox",
}

const SearchtreeV2BestsellerCalculate: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "searchtreeV2.bestseller.calculate",
}

const SearchtreeV2BestsellerApply: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "searchtreeV2.bestseller.apply",
}

export const SearchtreeV2 = {
    SearchtreeV2Overviewgrid,
    SearchtreeV2TreeNodeContainer,
    SearchtreeV2TreeNode,
    SearchtreeV2TreeNodeIcon,
    SearchtreeV2TreeNodeFastClick,
    SearchtreeV2TreeNodeName,
    SearchtreeV2BestsellerContainer,
    SearchtreeV2BestsellerLabel,
    SearchtreeV2BestsellerCheckbox,
    SearchtreeV2BestsellerCalculate,
    SearchtreeV2BestsellerApply,
}
