import { EventInterruptionHandler, EventListenerEvents } from "../models"

export class PreventEventForPreviouslyShownModals extends EventInterruptionHandler {
    ev = "outsideClick"

    process = (ev: EventListenerEvents, currentElement: Element, sourceElement: Element) => {
        return !this.isModalTheCurrentlyShownModal(sourceElement)
    }

    /**
     * When a modal is opened from within another modal (for example the "GVA" modal from the "Article Details" modal),
     * we have this html structure:
     *
     * <div id="modals">
     *   <div class="modal__container open">
     *     <div class="modal"></div>
     *   </div>
     *   <div class="modal__container open">
     *     <div class="modal"></div>
     *   </div>
     * </div>
     *
     * If we now click anywhere in the second modal, the outside click handler of the first modal is triggered and
     * both modals get closed.
     *
     * This method does two things:
     * 1. First it checks if the sourceElement is a modal. If no return true and the other checks in the calling method continue.
     * 2. If yes, it checks that the parent "modal__container" is the last open modal container.
     */
    private isModalTheCurrentlyShownModal = (sourceElement: Element) => {
        if (sourceElement.classList.contains("modal") && sourceElement.parentElement && sourceElement.parentElement.parentElement) {
            const openModalContainers = sourceElement.parentElement.parentElement.querySelectorAll(".modal__container.open")
            const modalContainerLastChild = openModalContainers[openModalContainers.length - 1]

            if (modalContainerLastChild && sourceElement != modalContainerLastChild.firstElementChild) {
                return false
            }
        }

        return true
    }
}
