import { MileType, Paging, RegistrationNoType, TitleType, VehicleDataAvailabilities, VehicleType } from ".."
import { Version } from ".."

export type Vehicle = {
    id: string
    refId?: string

    tecDocTypeId: number
    tecDocManufacturerId: number
    tecDocModelId: number
    manufacturer: string
    modelSeries: string
    model: string
    vehicleType: VehicleType
    manufacturerThumbnail?: string
    modelSeriesThumbnail?: string
    modelSeriesImage?: string
    modelThumbnail?: string

    /** Not supplied by service - added by frontend */
    dataAvailabilities?: VehicleDataAvailabilities

    additionalData?: Array<AdditionalData>
    thirdPartyIds?: Record<ThirdPartyIdType, string>

    plateId?: string
    initialRegistration?: Date
    vin?: string
    engineCode?: string
    registrationNo?: string
    /** Sometimes also named `dataSourceId` */
    registrationTypeId?: RegistrationNoType
    countryCode?: string
    mileAge?: number
    mileType?: MileType
    lastGeneralInspection?: Date
    nextGeneralInspection?: Date
    nextServiceDate?: Date
    longlife?: boolean
    retailPurchasePrice?: number
    retailSalesPrice?: number

    memo?: string

    // telematicsProvider: string
    telematicsProviderId?: TelematicsProvider
    telematicsRefId?: string

    customerId?: string
    attachedOn?: Date

    created?: Date
    modified?: Date
    version?: Version
}

export enum ThirdPartyIdType {
    Unknown = 0,
    DatECode = 1,
}

export type AdditionalData = {
    dataSourceId: number
    query?: string
    dateOfLastAccess?: Date
}

export enum TelematicsProvider {
    None = 0,
    BmwCarData = 1,
    AutoAid = 2,
    Geotab = 6,
}


export enum VehicleSearchType {
    All = 0,
    PlateIdOrVin = 1,
    PlateIdVinOrRegNumber = 2
}

export type FindVehiclesRequest = {
    query: string
    orderBy?: string
    orderByDescending?: boolean
    pageIndex?: number
    pageSize?: number
    searchType: VehicleSearchType
}

export type FindVehiclesResponse = Paging & {
    vehicles: Array<VehicleShortInfo>
}

export type VehicleShortInfo = {
    id: string
    refId?: string

    tecDocTypeId: number
    tecDocManufacturerId: number
    tecDocModelId: number
    manufacturer: string
    modelSeries: string
    model: string
    vehicleType: VehicleType

    plateId?: string
    initialRegistration?: Date
    vin?: string

    customerId?: string
    customerNo?: string
    title?: string
    titleType?: TitleType
    firstName?: string
    lastName?: string
    companyName?: string
    attachedOn?: Date

    dataSourceId?: number
    countryCode?: string
    registrationNoTypeId?: RegistrationNoType
}

export type CustomerVehicleShortInfo = {
    id: string
    refId?: string
    tecDocTypeId: number
    tecDocManufacturerId: number
    tecDocModelId: number
    manufacturer: string
    model: string
    modelSeries: string
    vehicleType: VehicleType
    plateId?: string
    initialRegistration?: Date
    vin?: string
    customerId: string
    attachedOn?: Date
    manufacturerThumbnail?: string
    modelSeriesThumbnail?: string
    modelThumbnail?: string
    version: Version
    countryCode?: string
}

export enum LicensePlateComponentInitiator {
    PersonalData,
    VehicleWidget,
    VehicleEditor,
    VehicleSelected,
    HistoryItem,
    VehicleSelectorView,
    VehicleInfo,
    VehicleSearchOption,
    CustomerList,
    WorkTaskHistory,
    CentralOrder,
    WorkTaskSearch
}
