import { Note, RegisteredModels, WorkTaskContainer } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery, UseMutateAsyncFunction, useQueryClient, useMutation } from "react-query"

const KEY_LOAD_WORKTASKNOTE = "workTaskNote_Load"

export type SaveWorkTaskNoteRequest = { noteId: string; text: string }

export const useLoadWorkTaskNote = (workTaskIds: string[]): { workTaskNote: Note[]; isLoading: boolean } => {
    const { data, isLoading } = useQuery<Note[]>(
        [KEY_LOAD_WORKTASKNOTE, workTaskIds.join(",")],
        () => {
            const container = Container.getInstance(RegisteredModels.WorkTask_Notes) as WorkTaskContainer
            return container.action("loadWorkTaskNotesByNoteId")(workTaskIds).then()
        },
        { enabled: workTaskIds.length > 0 }
    )
    return { workTaskNote: data || [], isLoading }
}

export const useSaveWorkTaskNote = (): { saveWorkTaskNote: UseMutateAsyncFunction<void, unknown, Note>; saveInProgress: boolean } => {
    const queryClient = useQueryClient()
    const mutation = useMutation<void, unknown, Note>(
        (request: Note) => {
            const container = Container.getInstance(RegisteredModels.WorkTask_Notes) as WorkTaskContainer
            return container.action("saveWorkTaskNote")(request).then()
        },
        {
            onSuccess: () => {
                queryClient.resetQueries(KEY_LOAD_WORKTASKNOTE)
            },
        }
    )
    return { saveWorkTaskNote: mutation.mutateAsync, saveInProgress: mutation.isLoading }
}

export const useDeleteWorkTaskNote = (): { deleteWorkTaskNote: UseMutateAsyncFunction<void, unknown, string>; deleteInProgress: boolean } => {
    const queryClient = useQueryClient()
    const mutation = useMutation<void, unknown, string>(
        (noteId: string) => {
            const container = Container.getInstance(RegisteredModels.WorkTask_Notes) as WorkTaskContainer
            return container.action("deleteWorkTaskNote")(noteId).then()
        },
        {
            onSuccess: () => {
                queryClient.resetQueries(KEY_LOAD_WORKTASKNOTE)
            },
        }
    )
    return { deleteWorkTaskNote: mutation.mutateAsync, deleteInProgress: mutation.isLoading }
}
