import * as React from "react"
import { BundleMicroContext } from "../lib/createComponent"
import { WithMicroProps } from "../models/props"

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function importMicro<P extends React.ComponentType<any>>(superClass: P) {
    return withMicro(superClass) as P
}

export function withMicro<P extends WithMicroProps<any>>(component: React.ComponentType<P>): React.FC<Omit<P, keyof WithMicroProps>> {
    const withMicro: React.FC<Omit<P, keyof WithMicroProps>> = (currentProps) => {
        const context = React.useContext(BundleMicroContext)
        if (!context) throw ("The bundle component doesn't provide a MicroContext")
        return React.createElement(component, {
            ...currentProps as any,
            renderMicro: context
        })
    }
    return withMicro
}


