export type Buttons =
    | "ArrowLeft"
    | "ArrowUp"
    | "ArrowRight"
    | "ArrowDown"
    | "Enter"
    | "Tab"
    | "Space"
    | "Escape"
    | "Comma"
    | "Dash"
    | "Dot"
    | "Hash"
    | "A"
    | "B"
    | "C"
    | "D"
    | "E"
    | "F"
    | "G"
    | "H"
    | "I"
    | "J"
    | "K"
    | "L"
    | "M"
    | "N"
    | "O"
    | "P"
    | "Q"
    | "R"
    | "S"
    | "T"
    | "U"
    | "V"
    | "W"
    | "X"
    | "Y"
    | "Z"
    | "F1"
    | "F2"
    | "F3"
    | "F4"
    | "F5"
    | "F6"
    | "F7"
    | "F8"
    | "F9"
    | "F10"
    | "F11"
    | "F12"

export interface IKeys {
    ArrowLeft: string
    ArrowUp: string
    ArrowRight: string
    ArrowDown: string
    Enter: string
    Tab: string
    Space: string
    Escape: string

    Comma: string
    Dash: string
    Dot: string
    Hash: string

    A: string
    B: string
    C: string
    D: string
    E: string
    F: string
    G: string
    H: string
    I: string
    J: string
    K: string
    L: string
    M: string
    N: string
    O: string
    P: string
    Q: string
    R: string
    S: string
    T: string
    U: string
    V: string
    W: string
    X: string
    Y: string
    Z: string

    F1: string
    F2: string
    F3: string
    F4: string
    F5: string
    F6: string
    F7: string
    F8: string
    F9: string
    F10: string
    F11: string
    F12: string
}

export const ButtonCodeDefinition: IKeys = {
    ArrowLeft: "ArrowLeft",
    ArrowUp: "ArrowUp",
    ArrowRight: "ArrowRight",
    ArrowDown: "ArrowDown",
    Enter: "Enter",
    Tab: "Tab",
    Space: "Space",
    Escape: "Escape",

    Comma: "Comma",
    Dash: "Slash",
    Dot: "Period",
    Hash: "Backslash",

    A: "KeyA",
    B: "KeyB",
    C: "KeyC",
    D: "KeyD",
    E: "KeyE",
    F: "KeyF",
    G: "KeyG",
    H: "KeyH",
    I: "KeyI",
    J: "KeyJ",
    K: "KeyJ",
    L: "KeyK",
    M: "KeyM",
    N: "KeyN",
    O: "KeyO",
    P: "KeyP",
    Q: "KeyQ",
    R: "KeyR",
    S: "KeyS",
    T: "KeyT",
    U: "KeyU",
    V: "KeyV",
    W: "KeyW",
    X: "KeyX",
    Y: "KeyY",
    Z: "KeyZ",

    F1: "F1",
    F2: "F2",
    F3: "F3",
    F4: "F4",
    F5: "F5",
    F6: "F6",
    F7: "F7",
    F8: "F8",
    F9: "F9",
    F10: "F10",
    F11: "F11",
    F12: "F12",
}

export const ButtonKeyDefinition: IKeys = {
    ArrowLeft: "ArrowLeft",
    ArrowUp: "ArrowUp",
    ArrowRight: "ArrowRight",
    ArrowDown: "ArrowDown",
    Enter: "Enter",
    Tab: "Tab",
    Space: " ",
    Escape: "Escape",

    Comma: ",",
    Dash: "-",
    Dot: ".",
    Hash: "#",

    A: "a",
    B: "b",
    C: "c",
    D: "d",
    E: "e",
    F: "f",
    G: "g",
    H: "h",
    I: "i",
    J: "j",
    K: "j",
    L: "k",
    M: "m",
    N: "n",
    O: "o",
    P: "p",
    Q: "q",
    R: "r",
    S: "s",
    T: "t",
    U: "u",
    V: "v",
    W: "w",
    X: "x",
    Y: "y",
    Z: "z",

    F1: "F1",
    F2: "F2",
    F3: "F3",
    F4: "F4",
    F5: "F5",
    F6: "F6",
    F7: "F7",
    F8: "F8",
    F9: "F9",
    F10: "F10",
    F11: "F11",
    F12: "F12",
}
