import { SortDirection } from "@mui/material"
import { ReactNode } from "react"
import { ScrollContainerProps } from "../../generics/scrollContainer"

// small = widget
// compact = list for master-details view
export type TableVariants = "normal" | "small" | "compact"

export enum CellContentPosition {
    left = "start",
    right = "end",
    center = "center",
}

export type TableCellData = {
    id: string
    displayValue: ReactNode | string | number
    alignContent?: CellContentPosition
    maxWidth?: number
    className?: string
}

export type TableRowData = {
    id: string
    cells: TableCellData[]
    extendedContent?: ReactNode | string | number
    extendedContentHidden?: boolean
    customRow?: ReactNode | string | number
    replaceCustomRow?: boolean
    active?: boolean
    leftRowIndicatorColor?: string
    rowIndicatorWidth?: number
    borderOnlyOnTheLeft?: boolean
}

export type TableColumnData = {
    header?: ReactNode
    gridSize?: string
    minWidth?: number | string
    alignHeader?: CellContentPosition
    alignContent?: CellContentPosition
    onSort?(sortOrder: SortDirection): void
    startSortDirection?: SortDirection
    overflowCell?: boolean
}

export type TableProps = {
    columns: TableColumnData[]
    rows?: TableRowData[]
    onScrollBottom?(): void
    onRowClick?: (row: TableRowData) => void
    onRowDoubleClick?: (row: TableRowData) => void
    rowCap?: number
    overflowY?: ScrollContainerProps["overflowY"]
    variant?: TableVariants
    children?: ReactNode
    headerStyle?: "bold" | "default" | "hidden"
    rowStyle?: "stripped" | "uniform" | "transparent"
    headerBackground?: "default" | "paper" | string
    className?: string
    textContrast?: "black" | "white"
    transparentHighlight?: boolean
    /* The property font size can be used to provide number of pixels */
    fontSize?: number
    scrollbar?: "no" | "system" | "styled"
}
