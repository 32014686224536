export const fileTypeMapping = {
    jpg: { accept: [".jpg", ".jpeg"], mime: ["image/jpeg"] },
    png: { accept: [".png"], mime: ["image/png"] },
    gif: { accept: [".gif"], mime: ["image/gif"] },
    pdf: { accept: [".pdf"], mime: ["application/pdf"] },
    audioMP3: { accept: [".mp3"], mime: ["audio/mpeg"] },
    audioOgg: { accept: [".ogg"], mime: ["audio/ogg"] },
    audioWAV: { accept: [".wav"], mime: ["audio/wav"] },
    videoMP4: { accept: [".mp4"], mime: ["video/mp4"] },
    videoWebM: { accept: [".webm"], mime: ["video/webm"] },
    videoOgg: { accept: [".ogg"], mime: ["video/ogg"] },
    textTxt: { accept: [".txt"], mime: ["text/plain"] },
    textCsv: { accept: [".csv"], mime: ["text/csv"] },
    blobBin: { accept: [".bin"], mime: ["application/octet-stream"] }
}

type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};

export type FileTypeMaxSize = PartialRecord<keyof typeof fileTypeMapping, { size: number }>

export type AllowedFileSelectionTypes = Array<keyof typeof fileTypeMapping>

export const getAcceptAttribute = (allowedFileTypes: AllowedFileSelectionTypes) => {
    const accept: Array<string> = []
    allowedFileTypes.forEach(type => accept.push(...fileTypeMapping[type].accept))
    return accept.join(", ")
}

export const getMimeTypes = (allowedFileTypes: AllowedFileSelectionTypes) => {
    const mime: Array<string> = []
    allowedFileTypes.forEach(type => mime.push(...fileTypeMapping[type].mime))
    return mime
}

export const getMaxFileSizeByType = (
    maxFileSizesByType?: FileTypeMaxSize
): undefined | Array<{ mime: string[], size: number }> => {
    if (!maxFileSizesByType) {
        return
    }

    const normalizedMimeType: Array<{ mime: string[], size: number }> = []

    Object.keys<PartialRecord<keyof typeof fileTypeMapping, unknown>>(fileTypeMapping).forEach((fileTypeKey) => {
        const fileSize = maxFileSizesByType[fileTypeKey]
        if (fileSize) {
            normalizedMimeType.push({
                mime: fileTypeMapping[fileTypeKey].mime,
                size: fileSize.size
            })
        }
    })

    return normalizedMimeType
}


export type FileSelectError = "WRONG_FILE_TYPE" | "FILE_TOO_BIG";
