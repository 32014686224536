import { ComponentType, memo, useMemo } from "react"
import { BoxProps } from "@mui/system"
import { Box, Typography, styled } from "@mui/material"
import { useWholesalerArticleNumbersItemState } from "../ContextProviderStates/TraderArticleNosItemState"
import { AddToBasket } from "../Transactions/AddToBasket"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { DefaultArticleErpContainer as WholesalerArticleErpContainer } from "../ErpContainer"

export const ArticleContainer = styled(Box)(() => ({
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "100%",
    borderBottom: "3px #c3c3c3 solid",
    minHeight: "110px",
    marginBottom: ".5em",
    display: "flex",
})) as ComponentType<BoxProps>

const ImageContainer = styled(Box)(({ theme }) => ({
    boxSizing: "border-box",
    border: `1px solid transparent`,
    backgroundColor: theme.palette.background.default,
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "60px",
    height: "60px",
    zIndex: 2,
    img: {
        objectFit: "contain",
    },
    margin: "auto 2.5em",
    padding: "3px",
}))

const StyledButton = styled(Button)(() => ({}))
StyledButton.defaultProps = { variant: "outlined" }

export const WholesalerArticleNumberItem = memo(() => {
    const quantity = useWholesalerArticleNumbersItemState((x) => x.quantity)
    const articleErpInfo = useWholesalerArticleNumbersItemState((x) => x.articleErpInfos?.default)
    const division = useMemo(() => (articleErpInfo?.state === "success" ? articleErpInfo.response.quantity?.division : 1) || 1, [articleErpInfo])
    const basketQuantity = useWholesalerArticleNumbersItemState((x) => x.basketQuantity?.articleQuantities?.totalQuantity)
    const handleChangeQuantity = useWholesalerArticleNumbersItemState((x) => x.handleChangeQuantity)
    const handleRemoveFromBasket = useWholesalerArticleNumbersItemState((x) => x.handleRemoveFromBasket)
    const handleAddToBasket = useWholesalerArticleNumbersItemState((x) => x.handleAddToBasket)
    const wholesalerArticleNumber = useWholesalerArticleNumbersItemState((x) => x.wholesalerArticleNumber)
    const addToBasketButtonDisabled = useWholesalerArticleNumbersItemState((x) => x.addToBasketButtonDisabled)

    return (
        <ArticleContainer>
            <ImageContainer>
                <Icon name="supplier" width="100%" height="100%" />
            </ImageContainer>
            <Typography m="auto 3em" sx={{ color: "primary.main", fontWeight: "bold" }}>
                {wholesalerArticleNumber}
            </Typography>

            <Box m="auto 4em auto auto">
                <AddToBasket
                    originalQuantity={quantity}
                    division={division}
                    basketQuantity={basketQuantity}
                    onAddToBasket={handleAddToBasket}
                    handleChangeQuantity={handleChangeQuantity}
                    onRemoveFromBasket={handleRemoveFromBasket}
                    disableButton={addToBasketButtonDisabled}
                />
                <Box mt=".5em">
                    <WholesalerArticleErpContainer />
                </Box>
            </Box>
        </ArticleContainer>
    )
})
