import { MessageType } from "@tm/models"

// Used in Basket - SendOrder and cis - VoucherItem
// response like in components: AlertColor = 'success' | 'info' | 'warning' | 'error'
export function getAlertColor(type: MessageType): "success" | "info" | "warning" | "error" {
    switch (type) {
        case MessageType.Error:
            return "warning"
        case MessageType.TechnicalError:
            return "error"
        case MessageType.Warning:
            return "warning"
        case MessageType.Information:
            return "success"
        default:
            return "info"
    }
}
