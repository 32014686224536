import { ClickAwayListener, Typography } from "@mui/material"
import { Box, styled } from "@mui/system"
import { ReactChild, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Icon } from "../../generics/Icons"
import { Tooltip } from "../../generics/tooltip"

type Props = {
    text: ReactChild
    variant?: "dark" | "light"
    width?: number
    withoutBorderColor?: boolean
    enableTipOnTouchDevice?: boolean
    className?: string
}

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
})

export const Tip = ({ text, variant = "dark", width, withoutBorderColor = true, enableTipOnTouchDevice, className }: Props) => {
    const { translateText } = useLocalization()
    const [tooltipOpen, setTooltipOpen] = useState(false)

    const handleTooltipClickOpen = () => {
        setTooltipOpen((prevTooltipOpen) => !prevTooltipOpen)
    }

    const handleTooltipClickClose = () => {
        setTooltipOpen(false)
    }

    return (
        <>
            {enableTipOnTouchDevice ? (
                <ClickAwayListener onClickAway={handleTooltipClickClose}>
                    <StyledBox>
                        <Tooltip
                            variant={variant}
                            color="primary"
                            title={text}
                            width={width}
                            withoutBordercolor={withoutBorderColor}
                            placement="bottom-start"
                            open={tooltipOpen}
                            onClose={handleTooltipClickClose}
                            disableTouchListener
                        >
                            <StyledBox onClick={handleTooltipClickOpen}>
                                <Icon name="bulb" sx={{ color: "highlight.main", paddingRight: "5px" }} height={30} width={30} />
                                <Typography fontWeight={600} color="highlight.main">
                                    {translateText(813)}
                                </Typography>
                            </StyledBox>
                        </Tooltip>
                    </StyledBox>
                </ClickAwayListener>
            ) : (
                <Tooltip
                    variant={variant}
                    color="primary"
                    title={text}
                    width={width}
                    withoutBordercolor={withoutBorderColor}
                    placement="bottom-start"
                >
                    <StyledBox className={className}>
                        <Icon name="bulb" sx={{ color: "highlight.main", paddingRight: "5px" }} height={30} width={30} />
                        <Typography fontWeight={600} color="highlight.main">
                            {translateText(813)}
                        </Typography>
                    </StyledBox>
                </Tooltip>
            )}
        </>
    )
}
