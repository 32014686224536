import { Component } from "react"
import { elementId, bindSpecialReactMethods } from "@tm/utils";
import { Icon } from "../..";

export type XorSwitchPositions = "left" | "right"
export type XorSwitchProps = {
    status?: XorSwitchPositions
    disabled?: boolean
    className?: string
    onChange?(state: XorSwitchPositions): void
}

export type SwitchState = {
    position: XorSwitchPositions
}

export default class XorSwitch extends Component<XorSwitchProps, SwitchState> {
    private elementId = elementId(`switch`);

    constructor(props: XorSwitchProps) {
        super(props);
        this.state = {
            position: props.status || "left"
        }

        bindSpecialReactMethods(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: XorSwitchProps) {
        if (this.props.status != nextProps.status) {
            this.setState({ position: nextProps.status || "left" })
        }
    }

    handleChange(e: any) {
        this.setState((state) => {
            return {
                position: state.position == "left" ? "right" : "left"
            }
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.state.position)
            }
        })
    }

    render() {
        const { disabled, className } = this.props
        const { position } = this.state

        let cssClass = `switch switch-xor`

        if (className) {
            cssClass += " " + className
        }
        if (disabled) {
            cssClass += ` switch--disabled`
        }

        cssClass += ` switch-xor--${position}`

        return (
            <label htmlFor={this.elementId} className={cssClass}>
                <input type="checkbox" id={this.elementId} value={undefined} className="input" disabled={!!disabled} checked={position == "right"} onChange={this.handleChange} />
                <span className={`switch__content`}>
                    {
                        <div className="switch__icon">
                            <Icon skin="primary" size="xs" name={position == "right" ? "arrow-right" : "arrow-left"} />
                        </div>
                    }
                </span>
            </label>
        )
    }
}
