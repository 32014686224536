import { DefaultArticleItemState } from "@tm/models"
import { useArticleItemState } from "."

export function useDefaultArticleItemState(): DefaultArticleItemState
export function useDefaultArticleItemState<TReturnType>(selector: (value: DefaultArticleItemState) => TReturnType): TReturnType
export function useDefaultArticleItemState<TReturnType>(
    selector?: (value: DefaultArticleItemState) => TReturnType
): DefaultArticleItemState | TReturnType {
    return useArticleItemState<DefaultArticleItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
