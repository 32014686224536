import * as React from "react"
import DefaultSearchField, { DefaultSearchFieldProps } from "./index.default"
import { ExternalTooltip } from "./external-hint"

export default class SearchFieldWithExternalHintField extends React.Component<DefaultSearchFieldProps, any> {
    private _inputRef: React.RefObject<DefaultSearchField>

    constructor(props: DefaultSearchFieldProps) {
        super(props)
        this._inputRef = React.createRef()
    }

    focus() {
        this._inputRef.current && this._inputRef.current.focus && this._inputRef.current.focus()
    }

    render() {
        const { tooltip, ...rest} = this.props
        return (
            <>
                <DefaultSearchField {...rest} ref={this._inputRef}/>
                {tooltip && <ExternalTooltip  tooltip={tooltip} />}
            </>
        )
    }
}

