import { CategoryType, SearchFilters, UserInteractionLog, TmaEModule } from "@tm/models"
import { ArticleListFilteredEventHelper } from "../events/article-list-filtered/event-helper"
import { PremiumUniPartsSearchEventHelper } from "../events/premium-uni-parts-search"
import { AddToBasket } from "../events/add-to-basket/event-helper"
import { TmaHelper, getCategoryTypeFromUrl } from "./tma"
import { getCatalogOrigin } from "./origin"
import { determineKeywordSearchOriginFromDashboard } from "../events/article-list-filtered/originDetection"
import { QuerySearchType } from ".."

export const SharedHelper = {
    ByArticleAndUniParts: {
        /**
         * @param category which search category (parts, direct or uni)
         * @param query search string from user input
         * @param workTaskId current worktask
         * @param fromWidget search is located in a widget
         */
        KeywordSearch: async (category: CategoryType, query: string, workTaskId?: string, fromWidget?: boolean) => {

            if (category === "universalParts") {
                PremiumUniPartsSearchEventHelper.UniParts.Search.SetSearchContext(category, query, workTaskId, fromWidget)
                return
            }

            ArticleListFilteredEventHelper.ArticleListFiltered.Search.KeywordSearch(query, category, !!fromWidget, workTaskId)
        },
        /**
         * @description use this for widgets from dash- or startpage
         */
        SetSearchContext: async (category: CategoryType, query: string, workTaskId?: string, fromWidget?: boolean) => {
            switch (category) {
                case "dtcSearch":
                case "partsIndicator":
                case "vehicleParts":
                case "directSearch":
                case "basket": {
                    return ArticleListFilteredEventHelper.ArticleListFiltered.Search.SetSearchContext(category, query, workTaskId, fromWidget)
                }
                case "universalParts":
                case "wheels":
                case "tyresTecRmi":
                case "tyres": {
                    return PremiumUniPartsSearchEventHelper.UniParts.Search.SetSearchContext(category, query, workTaskId, fromWidget)
                }
            }
        },

        GetUserInteractionLog: (positionNumber?: number, origin?: TmaEModule): UserInteractionLog | undefined => {
            const currentOrigin = origin ?? getCatalogOrigin()

            // there are multiple cases, currently we are determine the current origin to route the request, 
            // if 'origin' parameter is filled it will be overridden and not taken from previous search!
            //TODO check if the cases are needed
            switch (currentOrigin) {
                case TmaEModule.PREMIUMUNIPARTSSEARCH:
                case TmaEModule.PREMIUMUNIPARTSSEARCH_ARTICLE_LIST:
                case TmaEModule.PREMIUMUNIPARTSSEARCH_START_PAGE:
                case TmaEModule.PREMIUMUNIPARTSSEARCH_DASHBOARD:
                case TmaEModule.UNIPARTSSEARCH:
                case TmaEModule.UNIPARTSSEARCH_PRODUCT_GROUP_KEYWORD_SEARCH:

                case TmaEModule.WHEEL_CONFIGURATOR:
                case TmaEModule.TM_TYRE_SEARCH:
                case TmaEModule.TYRE_SEARCH_START_PAGE: {
                    return AddToBasket.AddUniPartToBasket.GetUserInteractionLog(positionNumber, origin)
                }

                default: {
                    return AddToBasket.AddCatalogPartToBasket.GetUserInteractionLog(positionNumber, origin)
                }
            }
        },
        IncreaseStepNumber: async () => {
            // TODO call the correct handler directly from component
            switch (getCatalogOrigin()) {
                case TmaEModule.PREMIUMUNIPARTSSEARCH:
                case TmaEModule.PREMIUMUNIPARTSSEARCH_ARTICLE_LIST:
                case TmaEModule.PREMIUMUNIPARTSSEARCH_START_PAGE:
                case TmaEModule.PREMIUMUNIPARTSSEARCH_DASHBOARD:
                case TmaEModule.UNIPARTSSEARCH:
                case TmaEModule.UNIPARTSSEARCH_PRODUCT_GROUP_KEYWORD_SEARCH: {
                    PremiumUniPartsSearchEventHelper.UniParts.Event.IncreaseStepNumber()
                    break
                }

                default: {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Event.IncreaseStepNumber()
                    break
                }
            }
        },
        SubmitKeywordSearch: (query: string, categoryType: CategoryType, searchFilter: SearchFilters) => {
            const origin = determineKeywordSearchOriginFromDashboard(categoryType, false)

            switch (categoryType) {
                case "basket": //TODO check if this is used
                case "directSearch": {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Search.Submit(origin, query, QuerySearchType.Direct)
                    break
                }
                case "vehicleParts": {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Search.Submit(origin, query, QuerySearchType.Synonym)
                    break
                }
                case "universalParts":{
                    PremiumUniPartsSearchEventHelper.UniParts.Search.Submit(
                        origin,
                        query,
                        categoryType
                    )
                    break
                }
            }
        },
        NoSearchResultAssistent: (targetUrl: string, query: string) => {
            const categoryType = getCategoryTypeFromUrl(targetUrl)
            if (categoryType === "directSearch") {
                TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.Assistent(targetUrl, query)
            } else {
                TmaHelper.UniParts.Search.Assistent(targetUrl, query)
            }
        },
        /**
         * @deprecated try to call the specific helper function for uni or article list
         * @param data
         */
        SearchTreeNodeSelected: async (data: {
            mode?: "fast-click" | "fast-click-2.0" | "buttons" | "hide"
            nodeId: number
            treeId?: number,
            searchTreeType?: CategoryType
        }) => {
            switch (data.searchTreeType) {
                case "universalParts": {
                    TmaHelper.UniParts.SearchTree.NodeSelected(data)
                    break
                }
                case "vehicleParts":
                case "directSearch": {
                    TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.NodeSelected(data)
                    break
                }
                default: {
                    console.warn(`TMA could not resolve data.searchTreeType: ${data.searchTreeType}`)
                }
            }
        },
        CategoryChanged: async (category: CategoryType) => {
            switch (category) {
                case "directSearch":
                case "vehicleParts": {
                    ArticleListFilteredEventHelper.ArticleListFiltered.Event.SwapSearchType(category)
                    break
                }
                case "universalParts": {
                    // PremiumUniPartsSearchEventHelper.UniParts.Event.Swap()

                    break
                }
            }
        }
    },
}
