import { styled, Box, useTheme } from "@mui/material"
import { ElementType, HTMLAttributes, useState } from "react"
import { plateCountryCodeMapper } from "@tm/utils"
import { LicensePlateComponentInitiator, Vehicle } from "@tm/models"
import { TextField } from "../../generics/textfield"
import IconCh from "./icons/ch.svg"
import IconN from "./icons/n.svg"
import IconEu from "./icons/eu.svg"
import IconUk from "./icons/uk.svg"

export type LicensePlateProps = {
    value: string
    countryCode?: string
    showCountryCode?: boolean
    size?: "extrasmall" | "small" | "medium" | "large" | "extralarge"
    countryCodeFontSize?: "medium" | "large"
    isReadOnly?: boolean
    vehicle?: Vehicle
    onChangePlate?(model: Vehicle, path?: Array<string>): void
    licensePlateComponentInitiator: LicensePlateComponentInitiator
}

function LicensePlateComponent(props: LicensePlateProps & HTMLAttributes<HTMLElement>) {
    const theme = useTheme()
    const { value, countryCode, className, style, isReadOnly, size, onChangePlate, vehicle } = props
    const showCountryCode = !countryCode ? false : props.countryCode === undefined ? true : props.countryCode
    const plateStyle = getPlateStyle(countryCode || "")
    const plateCountryCode = plateCountryCodeMapper(countryCode || "")
    const [plateValue, setPlateValue] = useState(value)

    return (
        <Box className={className} style={style}>
            <Box className="country">
                {showCountryCode && (
                    <>
                        <CountryIcon countryCode={countryCode} />
                        {plateStyle !== "CH" && <Box className="country-code">{plateCountryCode}</Box>}
                    </>
                )}
            </Box>
            {(isReadOnly === undefined || isReadOnly === true) && <Box className="value">{value}</Box>}
            {isReadOnly === false && (
                <TextField
                    sx={{
                        border: "none",
                        marginTop: 0.5,
                        ".MuiInputBase-input": {
                            alignSelf: "center",
                            fontFamily: "EuroPlate",
                            letterSpacing: "normal",
                            fontSize: getFontSize(size),
                            color: theme.palette.common.black,
                        },
                    }}
                    className="value"
                    value={plateValue}
                    variant="standard"
                    onChange={(event) => {
                        const plateId = event.target.value
                        setPlateValue(plateId)
                        if (vehicle) {
                            vehicle.plateId = plateId
                            onChangePlate?.(vehicle)
                        }
                    }}
                />
            )}
        </Box>
    )
}

function isCountryInEU(countryCode: string) {
    switch (countryCode.toUpperCase()) {
        case "A":
        case "AT":
        case "B":
        case "BE":
        case "BG":
        case "CY":
        case "CZ":
        case "D":
        case "DE":
        case "DK":
        case "E":
        case "EE":
        case "ES":
        case "EST":
        case "F":
        case "FI":
        case "FIN":
        case "FR":
        case "GR":
        case "H":
        case "HR":
        case "HU":
        case "I":
        case "IE":
        case "IRL":
        case "IT":
        case "L":
        case "LT":
        case "LU":
        case "LV":
        case "M":
        case "MT":
        case "NL":
        case "P":
        case "PL":
        case "PT":
        case "RO":
        case "S":
        case "SE":
        case "SI":
        case "SK":
        case "SLO":
            return true
        default:
            return false
    }
}

function CountryIcon({ countryCode }: { countryCode?: string }) {
    const plateStyle = getPlateStyle(countryCode || "")
    switch (plateStyle) {
        case "CH":
            return <IconCh className="icon" />
        case "N":
            return <IconN className="icon" />
        case "UK":
            return <IconUk className="icon" />
        case "NL":
        case "EU":
            return <IconEu className="icon" />
        default:
            return <></>
    }
}

function getCountryCodeFontSize(countryCodeFontSize: "medium" | "large" | undefined) {
    switch (countryCodeFontSize) {
        case "medium":
            return ".4em"
        case "large":
            return ".5em"
        default:
            return ".4em"
    }
}

function getIconTop(plateStyle: "EU" | "CH" | "N" | "NL" | "UK" | "Unknown", licensePlateComponentInitiator: LicensePlateComponentInitiator) {
    if (plateStyle === "CH") {
        switch (licensePlateComponentInitiator) {
            case LicensePlateComponentInitiator.PersonalData: {
                return ".2em"
            }
            case LicensePlateComponentInitiator.VehicleWidget: {
                return ".3em"
            }
            default:
                return ".35em"
        }
    } else {
        switch (licensePlateComponentInitiator) {
            case LicensePlateComponentInitiator.PersonalData: {
                return ".1em"
            }
            case LicensePlateComponentInitiator.VehicleWidget: {
                return ".2em"
            }
            default:
                return "0.05em"
        }
    }
}

function getIconHeight(plateStyle: "EU" | "CH" | "N" | "NL" | "UK" | "Unknown", licensePlateComponentInitiator: LicensePlateComponentInitiator) {
    if (plateStyle === "CH") {
        switch (licensePlateComponentInitiator) {
            case LicensePlateComponentInitiator.PersonalData: {
                return "1.3em"
            }
            case LicensePlateComponentInitiator.VehicleEditor:
            case LicensePlateComponentInitiator.VehicleWidget: {
                return "1.5em"
            }
            default:
                return "0.8em"
        }
    } else if (plateStyle === "UK" && licensePlateComponentInitiator === LicensePlateComponentInitiator.VehicleEditor) {
        return "1em"
    }

    return "0.7em"
}

function getIconWidth(plateStyle: "EU" | "CH" | "N" | "NL" | "UK" | "Unknown", licensePlateComponentInitiator: LicensePlateComponentInitiator) {
    if (plateStyle === "CH") {
        switch (licensePlateComponentInitiator) {
            case LicensePlateComponentInitiator.PersonalData: {
                return "1.2em"
            }
            case LicensePlateComponentInitiator.VehicleEditor:
            case LicensePlateComponentInitiator.VehicleWidget: {
                return "1.4em"
            }
            default:
                return "0.7em"
        }
    } else if (plateStyle === "UK" && licensePlateComponentInitiator === LicensePlateComponentInitiator.VehicleEditor) {
        return ".9em"
    }

    return "0.7em"
}

function getCountryWidth(plateStyle: "EU" | "CH" | "N" | "NL" | "UK" | "Unknown", licensePlateComponentInitiator: LicensePlateComponentInitiator) {
    if (plateStyle === "CH") {
        switch (licensePlateComponentInitiator) {
            case LicensePlateComponentInitiator.PersonalData: {
                return "1.2em"
            }
            case LicensePlateComponentInitiator.VehicleEditor: {
                return "4em"
            }
            case LicensePlateComponentInitiator.VehicleWidget: {
                return "1.5em"
            }
            default:
                return "0.7em"
        }
    } else {
        switch (licensePlateComponentInitiator) {
            case LicensePlateComponentInitiator.VehicleEditor: {
                return "2.3em"
            }
            default:
                return "1em"
        }
    }
}

function getFontSize(size?: "extrasmall" | "small" | "medium" | "large" | "extralarge") {
    switch (size) {
        case "extrasmall":
            return "0.8em"

        case "small":
            return "0.9em"

        case "large":
            return "1.2em"

        case "extralarge":
            return "1.5em"

        case "medium":
        default:
            return "1em"
    }
}

const LicensePlate: ElementType<LicensePlateProps & HTMLAttributes<HTMLElement>> = styled(LicensePlateComponent)<LicensePlateProps>(
    ({ size, countryCode, theme, countryCodeFontSize, licensePlateComponentInitiator }) => {
        const plateStyle = getPlateStyle(countryCode || "")

        return {
            background: plateStyle === "NL" ? "#fdd504" : "#FBFBFB",
            border: "1px solid #a0a0a0",
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.common.black,
            display: "inline-flex",
            fontSize: getFontSize(size),
            minWidth: "4em",
            lineHeight: 1,
            position: "relative",
            whiteSpace: "nowrap",
            height: "1.5em",
            verticalAlign: "text-bottom",
            flexShrink: 0,
            ".country": {
                color: plateStyle === "Unknown" ? theme.palette.common.black : theme.palette.common.white,
                textTransform: "uppercase",
                borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                display: "block",
                background: getPlateCountryBackground(plateStyle),
                width: getCountryWidth(plateStyle, licensePlateComponentInitiator),
                position: "relative",
            },
            ".country-code": {
                position: "absolute",
                fontSize: getCountryCodeFontSize(countryCodeFontSize),
                bottom: "0.25em",
                left: "50%",
                transform: "translateX(-50%)",
            },
            ".icon": {
                position: "absolute",
                height: getIconHeight(plateStyle, licensePlateComponentInitiator),
                width: getIconWidth(plateStyle, licensePlateComponentInitiator),
                top: getIconTop(plateStyle, licensePlateComponentInitiator),
                left: "50%",
                transform: "translateX(-50%)",
            },
            ".value": {
                padding: "0 0.3em",
                alignSelf: "center",
                fontFamily: "EuroPlate",
                letterSpacing: "normal",
            },
        }
    }
)

export { LicensePlate }

function getPlateStyle(countryCode?: string): "EU" | "CH" | "N" | "NL" | "UK" | "Unknown" {
    if (countryCode && isCountryInEU(countryCode) && countryCode.toLowerCase() !== "nl") {
        return "EU"
    }

    switch (countryCode?.toLowerCase()) {
        case "no":
        case "n":
            return "N"
        case "ch":
            return "CH"
        case "nl":
            return "NL"
        case "uk":
        case "gb":
            return "UK"
        default:
            return "Unknown"
    }
}

function getPlateCountryBackground(plateStyle: ReturnType<typeof getPlateStyle>) {
    switch (plateStyle) {
        case "Unknown":
        case "CH": {
            return "#FBFBFB"
        }
        default:
            return "#003bb2"
    }
}
