import { forwardRef, ReactNode, UIEvent } from "react"
import { Box, BoxProps, styled } from "@mui/material"
import * as CSS from "csstype"
import { debounce } from "@tm/utils"

export type ScrollContainerProps = BoxProps & {
    children: ReactNode | ReactNode[]
    onScrollBottom?(): void
    overflowY?: CSS.Property.OverflowY | CSS.Property.OverflowY[]
    style?: any
    useDefaultStyling?: boolean
}

const ScrollWrapper = styled(Box, {
    shouldForwardProp: (prop) => !["overflowY", "onScrollBottom", "useDefaultStyling"].includes(prop as string),
})<ScrollContainerProps>(({ overflowY, useDefaultStyling }) => ({
    width: "100%",
    height: "inherit",
    maxHeight: "inherit",
    overflowY: overflowY || "scroll",

    ...(!useDefaultStyling && {
        "&::-webkit-scrollbar": {
            backgroundColor: "#fff",
            width: "10px",
            borderBottomRightRadius: "5px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
            borderBottomRightRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            borderRadius: "16px",
            border: "2px solid #fff",
            height: "30px",
        },
    }),
}))

export const ScrollContainer = forwardRef<HTMLDivElement, ScrollContainerProps>((props, ref) => {
    const { children, onScrollBottom, overflowY, style } = props

    const debouncedOnScrollBottom = debounce((scrollHeight: number, scrollTop: number, clientHeight: number) => {
        const offset = 2
        if (onScrollBottom) {
            const isBottom = scrollHeight - scrollTop - offset <= clientHeight
            isBottom && onScrollBottom()
        }
    }, 300)

    const handleScrollDebounce = (event: UIEvent<HTMLDivElement>) => {
        debouncedOnScrollBottom(event.currentTarget.scrollHeight, event.currentTarget.scrollTop, event.currentTarget.clientHeight)
    }

    return (
        <ScrollWrapper ref={ref} overflowY={overflowY} style={style} onScroll={handleScrollDebounce} {...props}>
            {children}
        </ScrollWrapper>
    )
})
