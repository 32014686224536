import { UIAHtmlAttribut, UITypes } from "../types"

const SectionListContainer: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "sectionlist",
}

const SectionListHeadline: UIAHtmlAttribut = {
    "data-type": UITypes.label,
    "data-id": "sectionlist.headline",
}

const SectionListCheckbox: UIAHtmlAttribut = {
    "data-type": UITypes.Checkbox,
    "data-id": "sectionlist.checkbox",
}

const SectionListLabel: UIAHtmlAttribut = {
    "data-type": UITypes.label,
    "data-id": "sectionlist.label",
}

const SectionListDescription: UIAHtmlAttribut = {
    "data-type": UITypes.label,
    "data-id": "sectionlist.Description",
}

export const SectionList = {
    SectionListContainer,
    SectionListHeadline,
    SectionListCheckbox,
    SectionListLabel,
    SectionListDescription,
}
