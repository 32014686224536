import { AuthorityContainer, RegisteredModels, UserContext, UserModuleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"

export const KEY_ACTIVATABLE_MODULES = "misc_getActivatableModules"
export function useGetActivatableModules(userContext?: UserContext) {
    const demoActivationIsEnabled = userContext?.modules?.some((x) => x.type == UserModuleType.DemoActivation)
    const { data, isLoading, error } = useQuery(
        [KEY_ACTIVATABLE_MODULES],
        () => {
            const container = Container.getInstance(RegisteredModels.Authority) as AuthorityContainer
            return container.action("getActivatableModules")()
        },
        { staleTime: Infinity, cacheTime: Infinity, enabled: !!demoActivationIsEnabled }
    )

    return { response: data, isLoading, error }
}

export function useFetchGetActivateableModules(userContext?: UserContext) {
    const queryClient = useQueryClient()
    const demoActivationIsEnabled = userContext?.modules?.some((x) => x.type == UserModuleType.DemoActivation)

    return useCallback(() => {
        if (!demoActivationIsEnabled) {
            return Promise.resolve(undefined)
        }
        return queryClient.fetchQuery(
            [KEY_ACTIVATABLE_MODULES],
            () => {
                const container = Container.getInstance(RegisteredModels.Authority) as AuthorityContainer
                return container.action("getActivatableModules")()
            },
            { staleTime: Infinity, cacheTime: Infinity }
        )
    }, [demoActivationIsEnabled])
}
