import { useLocalization } from "@tm/localization"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { BonusSystemImage } from "./BonusSystemImage"
import { BonusPoints } from "../../BonusPoints"

export function BonusSystem() {
    const bonus = useDefaultArticleItemState((x) => x.displayArticle.bonus)
    const isCompact = useDefaultArticleItemState((x) => !x.expanded)
    const { translateText } = useLocalization()

    if (!bonus) {
        return null
    }

    if (bonus.value) {
        return (
            <BonusPoints
                bonusSystem={bonus}
                tooltipLabel={translateText(13113)}
                variant={isCompact ? "no-label" : "two-line-label"}
                sx={{ marginBottom: "8px !important" }}
            />
        )
    }

    return <BonusSystemImage bonus={bonus} />
}
