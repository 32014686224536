import { Component } from "react"
import { LocalizationProps } from "@tm/localization";
import Icon from "../icon";

export type AddToCartProps = LocalizationProps & {
    minimun: number;
    value: number;
    article: any;
    compactMode?: boolean;
    onAddArticle?(article: any): void;
    onChangeConfirm?(quantity: number): void;
}

export type AddToCartState = {
    value: number;
}

export default class AddToCart extends Component<AddToCartProps, AddToCartState> {

    private inputfield: HTMLInputElement;

    constructor(props: AddToCartProps) {
        super(props);
        this.state = {
            value: props.value
        }
    }

    onSubmit(): void {
        if (this.props.onAddArticle) {
            this.props.onAddArticle(this.props.article);
        }
    }

    onChange(event: any): void {
        const quantity = event.target.value;

        if (quantity >= this.props.minimun || quantity == "") {
            this.setState({value: quantity});

            if (this.props.onChangeConfirm) {
                this.props.onChangeConfirm(quantity);
            }
        }
    }

    onBlur(event: any): void {
        if (event.target.value == "") {
            this.setState({value: 1});
            if (this.props.onChangeConfirm) {
                this.props.onChangeConfirm(1);
            }
        }
    }

    onDecrement(): void {
        if (this.state.value > this.props.minimun) {
            const quantity = Math.floor(this.state.value) - 1;

            this.setState({value: quantity});
            if (this.props.onChangeConfirm) {
                this.props.onChangeConfirm(quantity);
            }
        }
    }

    onIncrement(): void {
        const quantity = Math.floor(this.state.value) + 1;

        this.setState({value: quantity});

        if (this.props.onChangeConfirm) {
            this.props.onChangeConfirm(quantity);
        }
    }

    setFocus(): void {
        setTimeout(() => {
            this.inputfield.focus();
        },0)
    }

    renderInput(): JSX.Element {
        const {translate} = this.props.localization
        return (
            <div className="add-to-cart__inputwrapper" onClick={this.setFocus.bind(this)}>
                <span className="add-to-cart__input-label">{translate(89)}</span>
                <input
                    onBlur={this.onBlur.bind(this)}
                    ref={(ref) => {if (ref) { this.inputfield = ref }}}
                    type="text"
                    value={this.state.value}
                    onChange={this.onChange.bind(this)}
                    className="add-to-cart__input-field"/>
            </div>
        );
    }

    renderSubmitButton(): JSX.Element {
        const {translate} = this.props.localization
        return (
            <button className="btn btn--s has-icon add-to-cart__submit" onClick={this.onSubmit.bind(this)}>
                <Icon name="cart" size="xs" />
                {!this.props.compactMode ? <span>{translate(133)}</span> : null }
            </button>
        );
    }

    renderQuantityButtons(): JSX.Element {
        return (
            <div className="add-to-cart__quantity-buttons">
                <button className="btn btn--s add-to-cart__quantity-button-decrement" onClick={this.onDecrement.bind(this)}>
                    <Icon name="minus" size="xs"/>
                </button>
                <button className="btn btn--s add-to-cart__quantity-button-increment" onClick={this.onIncrement.bind(this)}>
                    <Icon name="add" size="xs"/>
                </button>
            </div>
        )
    }

    render(): JSX.Element {
        let className = "add-to-cart";
        className += this.props.compactMode ? " add-to-cart--compact" : "";

        return (
            <div className={className}>
                {this.renderInput()}
                {this.renderQuantityButtons()}
                {this.renderSubmitButton()}
            </div>
        );
    }
}
