import { CisCustomerResponse, CisShowCustomerRequest, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useCallback } from "react"
import { QueryFunctionContext, useQuery, useQueryClient } from "react-query"
import { useTelesalesCustomerNumber } from ".."
import { useHasCis } from "./useHasCis"

const QUERY_KEY = "cis_useCisCustomer"

type QueryKey = [typeof QUERY_KEY, string | undefined]

/**
 * Returns the current CIS customer.
 * @param enabled Set this to `false` to disable the request. Default: `true`.
 */
export function useCisCustomer(enabled = true) {
    const hasCis = useHasCis()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const { data, isLoading } = useQuery(
        [QUERY_KEY, telesalesCustomerNo],
        fetchCisCustomer,
        {
            enabled: hasCis && enabled && enableServiceCalls,
            staleTime: 60 * 60 * 1000, // 1 hour
            cacheTime: 4 * 60 * 60 * 1000, // 4 hours
        }
    )

    return {
        cisCustomerLoading: isLoading,
        cisCustomer: data,
    }
}

function fetchCisCustomer({ queryKey: [, customerNo]}: QueryFunctionContext<QueryKey>) {
    const request: CisShowCustomerRequest = { customerNo }

    return Container.getInstance<CisCustomerResponse | undefined>(RegisteredModels.CIS_Customer)
        .subscribe(request).load()
}

/**
 * Returns a function to trigger a reload of the current CIS customer.
 */
export function useReloadCisCustomer() {
    const queryClient = useQueryClient()

    const invalidateCisCustomer = useCallback(() => {
        queryClient.invalidateQueries(QUERY_KEY)
    }, [queryClient])

    return { invalidateCisCustomer }
}
