/**
 * copy from stackoverflow
 * to notify about location changes
 * https://stackoverflow.com/a/52809105
 */
if (typeof window !== "undefined") {
    const createEvent = function (type: string) {
        let event: any = null
        if (typeof Event === "function") {
            event = new Event(type)
        } else {
            event = document.createEvent("Event")
            event.initEvent(type, true, true)
        }

        return event
    }

    history.pushState = ((f) =>
        function pushState(...args) {
            const ret = f.apply(history, args)
            window.dispatchEvent(createEvent("pushState"))
            window.dispatchEvent(createEvent("locationchange"))
            return ret
        })(history.pushState)

    history.replaceState = ((f) =>
        function replaceState(...args) {
            const ret = f.apply(history as ThisType<any>, args)
            window.dispatchEvent(createEvent("replaceState"))
            window.dispatchEvent(createEvent("locationchange"))
            return ret
        })(history.replaceState)

    window.addEventListener("popstate", () => {
        window.dispatchEvent(createEvent("locationchange"))
    })
}

export {}
