import { useCallback, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Grid, Stack, styled, useTheme } from "@mui/material"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Typography } from "../../generics/typographie"
import { Tooltip } from "../../generics/tooltip"
import { Icon } from "../../generics/Icons"
import { Button } from "../../generics/button"

const ClickItem = styled(Box)(({ theme }) => ({
    display: "contents",
    cursor: "pointer",
    "> *": {
        padding: "2px 4px",
        ":first-child": {
            paddingLeft: 8,
            marginLeft: -8,
        },
        ":last-child": {
            paddingRight: 8,
            marginRight: -8,
        },
    },
    "&:hover > *": {
        background: theme.palette.grey[200],
    },
    ".unit": {
        color: theme.palette.highlight.main,
    },
}))

export function GraduatedPrices({ isExpanded }: { isExpanded?: boolean }) {
    const { translateText, translate, currency } = useLocalization()
    const theme = useTheme()

    const articleErpInfos = useDefaultArticleItemState((x) => x.articleErpInfos)
    const handleAddToBasket = useDefaultArticleItemState((x) => x.handleAddToBasket)

    const graduatedPrices = useMemo(() => {
        const erpInfo = articleErpInfos?.default?.state === "success" ? articleErpInfos.default.response : undefined
        if (!erpInfo?.graduatedPrices?.length) {
            return undefined
        }
        return erpInfo.graduatedPrices.filter((x) => !!x.priceUnit)
    }, [articleErpInfos?.default])

    const handleClick = useCallback(
        (amount?: number) => {
            if (!amount) {
                return
            }
            handleAddToBasket(amount)
        },
        [handleAddToBasket]
    )

    const title = useMemo(
        () => (
            <Stack gap={0.5}>
                <Typography variant="label">{translateText(705)}</Typography>
                <Grid display="grid" gridTemplateColumns="auto auto">
                    {graduatedPrices
                        ?.filter((x) => !!x.value)
                        .map(({ currencySymbol, priceUnit, value }) => (
                            <ClickItem key={`${value}`} onClick={() => handleClick(priceUnit)}>
                                <Typography textAlign="right">
                                    {translateText(707)} <span className="unit">{priceUnit}</span> {translateText(708)}:
                                </Typography>
                                <Typography textAlign="right" fontWeight={600}>
                                    {currency(value, currencySymbol)}
                                </Typography>
                            </ClickItem>
                        ))}
                </Grid>
            </Stack>
        ),
        [currency, graduatedPrices, handleClick, translateText]
    )

    if (!graduatedPrices) {
        return null
    }

    const showGraduatedPricesAsButton = theme.overwrites?.components?.partListV2?.articleItem?.erpContainer?.showGraduatedPricesAsButton

    return (
        <Tooltip title={title} variant="light">
            {isExpanded && showGraduatedPricesAsButton ? (
                <Box>
                    <Button variant="text" startIcon={<Icon name="staffelpreis" />} style={{ padding: 0 }}>
                        {translate(705)}
                    </Button>
                </Box>
            ) : (
                <Icon name="staffelpreis" />
            )}
        </Tooltip>
    )
}
