import * as React from "react"
import { bindMethodsToContext } from "../../helper";
import { SizedProps } from "../../models/SharedModels";

export type SubTitleProps = SizedProps & {
}

export default class SubTitle extends React.Component<SubTitleProps> {

    constructor(props: SubTitleProps) {
        super(props);
        bindMethodsToContext(this, ["get"])
    }

    render() {
        const fullClassName = this.getClassName()

        return (
            <div className={fullClassName}>
                {
                    React.Children.map(this.props.children, this.renderChildren)
                }
            </div>
        );
    }

    renderChildren(child: React.ReactNode, index: number): React.ReactNode {
        return (
            <React.Fragment key={`subtitle-${index}`}>
                {child}
            </React.Fragment>
        )
    }

    getClassName() {
        const { size, className } = this.props
        const baseName = "subtitle"
        let fullClassName = `${baseName}`

        if(size) {
            fullClassName += ` ${baseName}--${size}`
        }

        if(className) {
            fullClassName += ` ${className}`
        }

        return fullClassName
    }
}
