enum DateUnit {
    Year = 1,
    Month = 2,
    Week = 3,
    Day = 4,
}

export { DateUnit }

declare global {
    interface Date {
        year(): number
        month(): number
        day(): number
        week(): number
        isSame(comparison: Date, unit?: DateUnit): boolean
        isBefore(comparison: Date, unit?: DateUnit): boolean
        isAfter(comparison: Date, unit?: DateUnit): boolean
        add(value: number, unit?: DateUnit): Date
        subtract(value: number, unit?: DateUnit): Date
        set(value: number, unit: DateUnit): Date
        clone(): Date
        startOf(unit: DateUnit): Date
    }
}

const DAY = 86400000

function createComparableDate(date: Date): Date {
    // we need to prepare, so that the time is totally equal
    const preparedDate = new Date(date)

    preparedDate.setHours(0)
    preparedDate.setMinutes(0)
    preparedDate.setSeconds(0)
    preparedDate.setMilliseconds(0)

    return preparedDate
}

Date.prototype.year = function year(): number {
    return this.getFullYear()
}
Date.prototype.month = function month(): number {
    return this.getMonth() + 1
}
Date.prototype.day = function day(): number {
    return this.getDate()
}

Date.prototype.isSame = function isSame(comparison: Date, unit: DateUnit = DateUnit.Day): boolean {
    if (!comparison) {
        return false
    }

    let result = true

    result = result && (unit >= DateUnit.Year ? this.year() === comparison.year() : true)
    result = result && (unit >= DateUnit.Month ? this.month() === comparison.month() : true)
    result = result && (unit >= DateUnit.Week ? this.week() === comparison.week() : true)
    result = result && (unit >= DateUnit.Day ? this.day() === comparison.day() : true)

    return result
}

Date.prototype.isBefore = function isBefore(comparison: Date, unit: DateUnit = DateUnit.Day): boolean {
    if (!comparison) {
        return false
    }

    if (unit === DateUnit.Day) {
        const preparedComparison: Date = createComparableDate(comparison)
        const preparedSource: Date = createComparableDate(this)
        return preparedSource < preparedComparison // using the native date comparison when we need a check for the day, the origin-comparison below will not do the job correctly in undefined circumstances
    }

    let result = false
    result = result || (unit >= DateUnit.Year && this.year() < comparison.year())
    result = result || (unit >= DateUnit.Month && this.month() < comparison.month())
    result = result || (unit >= DateUnit.Week && this.week() < comparison.week())
    result = result || (unit >= DateUnit.Day && this.day() < comparison.day())
    return result
}

Date.prototype.isAfter = function isAfter(comparison: Date, unit: DateUnit = DateUnit.Day): boolean {
    if (!comparison) {
        return false
    }
    if (unit === DateUnit.Day) {
        const preparedComparison: Date = createComparableDate(comparison)
        const preparedSource: Date = createComparableDate(this)
        return preparedSource > preparedComparison // using the native date comparison when we need a check for the day, the origin-comparison below will not do the job correctly in undefined circumstances
    }

    let result = false
    result = result || (unit >= DateUnit.Year && this.year() > comparison.year())
    result = result || (unit >= DateUnit.Month && this.month() > comparison.month())
    result = result || (unit >= DateUnit.Week && this.week() > comparison.week())
    result = result || (unit >= DateUnit.Day && this.day() > comparison.day())
    return result
}

Date.prototype.clone = function clone() {
    return new Date(this.getFullYear(), this.getMonth(), this.getDate())
}

Date.prototype.add = function add(value: number, unit: DateUnit = DateUnit.Day): Date {
    const result = this.clone()

    switch (unit) {
        case DateUnit.Year:
            result.setFullYear(this.getFullYear() + value)
            break
        case DateUnit.Month:
            result.setMonth(this.getMonth() + value)
            break
        case DateUnit.Week:
            result.setDate(this.getDate() + value * 7)
            break
        case DateUnit.Day:
            result.setDate(this.getDate() + value)
            break
        default:
            break
    }

    return result
}

Date.prototype.subtract = function subtract(value: number, unit: DateUnit = DateUnit.Day): Date {
    return this.add(-value, unit)
}

Date.prototype.week = function week(): number {
    const thursday = new Date(this.getTime() + (3 - ((this.getDay() + 6) % 7)) * DAY)
    const cwYear = thursday.getFullYear()
    const thursdayCw = new Date(new Date(cwYear, 0, 4).getTime() + (3 - ((new Date(cwYear, 0, 4).getDay() + 6) % 7)) * DAY)

    const result = Math.floor(1.5 + (thursday.getTime() - thursdayCw.getTime()) / DAY / 7)

    return result
}

Date.prototype.startOf = function startOf(unit: DateUnit): Date {
    const result = this.clone()

    switch (unit) {
        case DateUnit.Year:
            result.setMonth(0)
            result.setDate(1)
            break
        case DateUnit.Month:
            result.setDate(1)
            break
        case DateUnit.Week:
            if (this.getDay() === 0 && this.getDate() === 1) {
                // if the first day of a month is a sunday, it's part of the last week of the last month,
                // if we dont catch this, we would get a startOfTheWeek which is later than the selected date 🤔
                result.setDate(-5) // we just go back 6 days by setting date to -5. 💡 -5 stands for [0,-1,-2,-3,-4,-5] = 6 days......
            } else {
                result.setDate(this.getDate() - this.getDay() + 1)
            }
            break
        default:
            break
    }

    return result
}

Date.prototype.set = function set(value: number, unit: DateUnit): Date {
    const result = this.clone()

    switch (unit) {
        case DateUnit.Year:
            result.setFullYear(value)
            break
        case DateUnit.Month:
            result.setMonth(value - 1)
            break
        case DateUnit.Week:
            result.setDate(value * 7)
            break
        case DateUnit.Day:
            result.setDate(value)
            break
        default:
            break
    }

    return result
}
