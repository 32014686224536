import { OeInformationItemState } from "@tm/models"
import { useArticleItemState } from "."

export function useOeInformationItemState(): OeInformationItemState
export function useOeInformationItemState<TReturnType>(selector: (value: OeInformationItemState) => TReturnType): TReturnType
export function useOeInformationItemState<TReturnType>(
    selector?: (value: OeInformationItemState) => TReturnType
): OeInformationItemState | TReturnType {
    return useArticleItemState<OeInformationItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
