import { UIAHtmlAttribut, UITypes } from "../types"

const CostestimationNavbar: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "costestimation.navbar",
}

const CostestimationNavbarDisplayoptions: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "costestimation.navbar.displayoptions",
}

const CostestimationNavbarDisplayoptionsContainer: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "costestimation.navbar.displayoptions.container",
}

const CostestimationNavbarDisplayoptionsPartnumber: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "costestimation.navbar.displayoptions.partnumber",
}

const CostestimationNavbarDisplayoptionsDealerPartNumber: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "costestimation.navbar.displayoptions.dealersPartnumber",
}

const CostestimationNavbarDisplayoptionsManufacturer: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "costestimation.navbar.displayoptions.manufacturer",
}

const CostestimationNavbarDisplayoptionsPrintImage: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "costestimation.navbar.displayoptions.printImage",
}

const CostestimationNavbarDisplayoptionsCalculateLaborVal: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "costestimation.navbar.displayoptions.calculateLaborVal",
}

export const Costestimation = {
    CostestimationNavbar,
    CostestimationNavbarDisplayoptions,
    CostestimationNavbarDisplayoptionsContainer,
    CostestimationNavbarDisplayoptionsPartnumber,
    CostestimationNavbarDisplayoptionsDealerPartNumber,
    CostestimationNavbarDisplayoptionsManufacturer,
    CostestimationNavbarDisplayoptionsPrintImage,
    CostestimationNavbarDisplayoptionsCalculateLaborVal,
}
