import { Component } from 'react';
import { bindSpecialReactMethods, getUIA } from '@tm/utils'
import Scrollspy from "react-scrollspy";

export type ScrollMenuProps = {
    ids: { [key: string]: string }
    scrollbarViewQuerySelector: string
}

class ScrollMenu extends Component<ScrollMenuProps> {
    constructor(props: ScrollMenuProps) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleMenuClick(id: string) {
        const scrollbarView = document.querySelector(this.props.scrollbarViewQuerySelector)
        const element = document.getElementById(id)

        if (element && scrollbarView)
            if (scrollbarView.scrollTo)
                scrollbarView.scrollTo(0, element.offsetTop)
            else
                scrollbarView.scrollTop = element.offsetTop
    }

    renderTabs() {
        const { ids } = this.props

        return Object.keys<Record<string, string>>(ids).map(id => {
            return (
                <div key={id} id={id + "__tab"} className="tab" onClick={() => { this.handleMenuClick(id) }} {...getUIA("Tabs", id)}>
                    <div className="tab__content">
                        {ids[id]}
                    </div>
                </div>
            )
        })
    }

    render() {
        const { ids, scrollbarViewQuerySelector } = this.props

        return (
            <Scrollspy
                items={Object.keys(ids) as string[]}
                componentTag="div"
                currentClassName="is-selected"
                className="tab-control"
                rootEl={scrollbarViewQuerySelector}
            >
                {this.renderTabs()}
            </Scrollspy>
        )
    }
}

export default ScrollMenu
