import { Icon } from "../../generics/Icons"
import { Image } from "../../generics/image"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"

export function ImsIcon({ small }: { small?: boolean }) {
    const imsIcon = useDefaultArticleItemState((x) => x.imsIcon)

    const sizeVal = small ? 16 : 20

    if (imsIcon) {
        return <Image width={sizeVal} height={sizeVal} src={imsIcon} />
    }

    return <Icon name="trader-icon" />
}
