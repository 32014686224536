import { IModelSubscription, IContainerBundleLoader, IContainerBundleUpdater, IMergable } from "../../models"
import { ModelSubscriptionFactory } from "../factories/ModelSubscriptionFactory";
import { ModelSubscription } from "./model-subscription";
import { PromiseExecuter } from "../proxy/unregistered-container";

export type ISubscriptionsHolderContracts<TModel> = { [contractId: string]: IModelSubscription<TModel> }

export type SubscriptionsHolderMergeObject<TModel> = {
    subscriptionsHolder: IMergableSubscriptionsHolder<TModel>,
    loader: IContainerBundleLoader<TModel>,
    updater: IContainerBundleUpdater<TModel>
}

type IMergeableSubscription<TModel> = IModelSubscription<TModel> & IMergable<IModelSubscription<TModel>, IModelSubscription<TModel>>

export type IMergableSubscriptionsHolder<TModel> = IMergable<SubscriptionsHolderMergeObject<TModel>, SubscriptionsHolder<TModel>> & {
    create: (onLoad: IContainerBundleLoader<TModel>, onSave: IContainerBundleUpdater<TModel>, ...identifier: any[]) => IModelSubscription<TModel>
    contracts: ISubscriptionsHolderContracts<TModel>
}

export type WithPendingRequest<TModel> = IMergableSubscriptionsHolder<TModel> & {
    // resolveRequests: (contractId: string, executors: Array<PromiseExecuter<TModel>>) =>  void
}

export class SubscriptionsHolder<TModel> implements WithPendingRequest<TModel> {
    contracts: ISubscriptionsHolderContracts<TModel>
    private _pendingRequests: Array<PromiseExecuter<TModel>>

    constructor() {
        this.contracts = {}
        this._pendingRequests = []
    }


    create(loader: IContainerBundleLoader<TModel>, updater: IContainerBundleUpdater<TModel>, ...identifier: any[]): IModelSubscription<TModel> {
        const id = ModelSubscriptionFactory.createContractId(identifier)
        let contract: IModelSubscription<TModel>

        if (!this.contracts[id]) {
            contract = ModelSubscriptionFactory.create<TModel>(identifier, loader, updater)
            this.contracts[id] = contract

        } else {
            contract = this.contracts[id]
        }

        return contract
    }

    merge(source: SubscriptionsHolderMergeObject<TModel>): SubscriptionsHolder<TModel> {
        const sourceContracts = (source.subscriptionsHolder as SubscriptionsHolder<TModel>).contracts
        for (let key in sourceContracts) {
            this.add(
                key,
                (sourceContracts[key] as IMergeableSubscription<TModel>),
                source.loader,
                source.updater
            )
        }
        return this
    }

    add(key: string, contract: IMergeableSubscription<TModel>, onLoad: IContainerBundleLoader<TModel>, onSave: IContainerBundleUpdater<TModel>) {
        const source = ModelSubscriptionFactory.create<TModel>(key, onLoad, onSave)
        this.contracts[key] = contract.merge(source)
    }

    /**
     * load pending contracts and which will notify subscribers
     */
    triggerLoad(startLoading: { [key: string]: Array<PromiseExecuter<TModel>> }) {
        for (let key in this.contracts) {
            if (this.contracts.hasOwnProperty(key)) {
                const contract = this.contracts[key] as ModelSubscription<TModel> // concrete to get access to id
                const promiseExecutors = startLoading[contract._id]
                const executorCount = promiseExecutors.length

                if (promiseExecutors && promiseExecutors.length > 0) {
                    contract.load().then((response) => {
                        for (let i = 0; i < executorCount; i++) {
                            promiseExecutors[i].resolve(response)
                        }
                    })

                    delete startLoading[contract._id]
                }
            }
        }
    }

    // // resolve all loads which occurs while the model proxy is not registered
    // resolvePendingLoadRequests = (response: TModel, requests: Array<PromiseExecuter<TModel>>) => {
    //     for(var key in requests) {
    //         if(requests.hasOwnProperty(key)) {
    //             requests[key].resolve(response)
    //         }
    //     }
    // }
}
