import * as React from "react"
import { bindMethodsToContext } from "../../helper"
import { Text, Button, Tooltip, Icon } from "./.."
import { TextModifiers } from "../text"

export type TagLayout = "holo" | "ghost" | "bordered"
export type TagSkin = "primary" | "secondary" | "success" | "warning" | "danger" | "highlight"

export type TagProps = {
    label?: string
    value?: string | TagValue | Array<string | TagValue>
    icons?: Array<TagIcon>
    buttons?: Array<TagButton>
    tooltip?: string | JSX.Element
    className?: string
    layout?: TagLayout
    skin?: TagSkin
    size?: "s" | "m"
    onClick?(value: string): void
    onCloseClick?(): void
}

export type TagValue = {
    value: string
    icons?: Array<TagIcon>
    tooltip?: string
    className?: string
    textModifiers?: TextModifiers | Array<TextModifiers>
}

export type TagIcon = {
    name: string
    tooltip?: string
}

export type TagButton = {
    buttonIcon: string
    onClick(): void
}

export default class Tag extends React.Component<TagProps, any> {
    private ignoreClick: boolean

    static defaultProps: TagProps = {
        size: "s",
    }

    constructor(props: TagProps) {
        super(props)
        bindMethodsToContext(this)
    }

    getValues(): Array<TagValue> {
        const { value } = this.props
        const values: Array<TagValue> = []
        const textModifiers: Array<TextModifiers> = ["strong"]

        if (typeof (value) == "string")
            values.push({ value, textModifiers })
        else if (Array.isArray(value))
            return value.map(x => typeof x == "string" ? { value: x, textModifiers } : x)
        else if (value != undefined)
            values.push(value)

        return values
    }

    handleClick(e: React.MouseEvent<HTMLElement>) {
        if (this.ignoreClick) {
            this.ignoreClick = false
            return
        }

        const { onClick } = this.props
        const values = this.getValues()
        onClick && values.length == 1 && onClick(values[0].value)
    }

    handleValueSelect(value: TagValue) {
        this.ignoreClick = true
        const { onClick } = this.props
        onClick && onClick(value.value)
    }

    handleCloseClick() {
        this.ignoreClick = true
        const { onCloseClick } = this.props
        onCloseClick && onCloseClick()
    }

    handleButtonClick(onClick: () => void) {
        this.ignoreClick = true
        onClick()
    }

    renderIcons(icons: Array<TagIcon> | undefined) {
        if (!icons) return

        return icons.map((x, idx) => {
            const icon = <Icon key={idx} className="tag__icon" name={x.name} />
            if (!x.tooltip) return icon

            return <Tooltip key={idx} content={x.tooltip} textSize={this.props.size}>{icon}</Tooltip>
        })
    }

    renderValue(value: TagValue, idx: number) {
        const { onClick } = this.props

        if (typeof (value) == "string")
            value = { value }

        let className = "tag__value "
        if (onClick) className += "is-clickable "
        if (value.className) className += value.className

        let content = (
            <Text
                key={idx}
                className={className}
                size={this.props.size}
                modifiers={value.textModifiers}
                onClick={() => this.handleValueSelect(value)}
            >
                {value.value}
            </Text>
        )

        if (value.tooltip)
            content = <Tooltip key={idx} content={value.tooltip} textSize={this.props.size}>{content}</Tooltip>

        if (value.icons) {
            return (
                <>
                    {this.renderIcons(value.icons)}
                    {content}
                </>
            )
        }

        return content
    }

    renderValues() {
        return this.getValues().map(this.renderValue)
    }

    renderButtons() {
        const { buttons } = this.props

        if (!buttons) return

        return buttons.map((x, idx) =>
            <Button key={idx} className="tag__button" onClick={() => this.handleButtonClick(x.onClick)} icon={x.buttonIcon} size={this.props.size} />
        )
    }

    render() {
        const { label, layout, skin, icons, onClick, onCloseClick, tooltip } = this.props
        let { className = "" } = this.props

        className += " tag"
        if (skin)
            className += ` tag--${skin}`
        if (layout)
            className += ` tag--${layout}`
        if (this.getValues().length > 1)
            className += " tag--multiple-values"
        if (onClick)
            className += " is-clickable"

        let content = (
            <div className="tag__content">
                {this.renderIcons(icons)}
                {
                    !!label &&
                    <Text className="tag__label" size={this.props.size}>
                        {label}
                    </Text>
                }
                {this.renderValues()}
                {
                    onCloseClick &&
                    <Button className="tag__close" icon="close" size={this.props.size == "s" ? "xs" : "s"} layout={["ghost"]} onClick={this.handleCloseClick} />
                }
            </div>
        )

        if (tooltip) {
            content = (
                <Tooltip content={tooltip} textSize={this.props.size}>
                    {content}
                </Tooltip>
            )
        }

        return (
            <div className={className} onClick={this.handleClick}>
                {content}
                {this.renderButtons()}
            </div>
        )
    }
}
