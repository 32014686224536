import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Note, NoteTypes } from "@tm/models"
import { FormControl, FormHelperText, Stack, styled } from "@mui/material"
import { TextField } from "../../../generics/textfield"
import { Icon } from "../../../generics/Icons"
import { Typography } from "../../../generics/typographie"
import { Tooltip } from "../../../generics/tooltip"
import { Button } from "../../../generics/button"

const MAXINPUTCHARS = 500

type NoteItemProps = {
    note: Note
    isLoading: boolean
    onDelete(noteId: string): unknown
    onSave(note: Note): unknown
}

const FormControlFullWidth = styled(FormControl)(() => ({
    width: "100%",
}))

const FormHelperTextInline = styled(FormHelperText)(() => ({
    position: "absolute",
    right: "0",
    top: "50%",
    marginTop: "-0.6em",
    fontSize: 14,
}))

const TextFieldWithInlineCounter = styled(TextField)(() => ({
    marginTop: 0,
    ".MuiInputBase-multiline": {
        paddingTop: 8,
        paddingRight: "5em",
    },
}))

export function NoteItem(props: NoteItemProps) {
    const {
        note: { noteId, text, type, version },
        onDelete,
        onSave,
        isLoading,
    } = props

    const { translateText } = useLocalization()
    const [value, setValue] = useState<string>(text ?? "")

    useEffect(
        function setLoadedText() {
            setValue(text ?? "")
        },
        [text]
    )

    const handleOnInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value)
    }

    const handleSave = useCallback(() => onSave({ noteId, text: value, type, version }), [noteId, onSave, type, value, version])
    const handleDelete = useCallback(() => onDelete(noteId), [noteId, onDelete])

    const iconName = useMemo(() => (type === NoteTypes.VEHICLE_ARTICLE ? "vehicle-note" : "notes"), [type])
    const label = useMemo(() => translateText(type === NoteTypes.VEHICLE_ARTICLE ? 12875 : 12874), [translateText, type])

    return (
        <>
            <Stack direction="row" alignItems="center" gap={1} pr={1}>
                <Icon name={iconName} size="24px" />
                <Typography variant="body2">{label}</Typography>
            </Stack>
            <FormControlFullWidth>
                <TextFieldWithInlineCounter
                    id="component-note-text-small"
                    value={value}
                    maxRows={5}
                    size="medium"
                    multiline
                    fullWidth
                    inputProps={{
                        maxLength: MAXINPUTCHARS,
                    }}
                    onChange={handleOnInputChange}
                />
                <FormHelperTextInline aria-describedby="component-note-text-small">
                    {`${(value || "").length} / ${MAXINPUTCHARS}`}
                </FormHelperTextInline>
            </FormControlFullWidth>
            <Stack direction="row" gap={0.5} alignItems="center">
                <Tooltip title={translateText(69)}>
                    <Button onClick={handleDelete} startIcon={<Icon name="delete" />} disabled={isLoading || !text} />
                </Tooltip>
                <Tooltip title={translateText(9)}>
                    <Button onClick={handleSave} startIcon={<Icon name="check" />} disabled={isLoading || !value || value === text} />
                </Tooltip>
            </Stack>
        </>
    )
}
