import { Component, KeyboardEvent } from "react"
import * as ReactDOM from "react-dom";
import { Button } from "../";
import { registerOutsideClick } from "@tm/utils"

const modalRoot = document.getElementById("portals") || document.getElementById("modals")

export type ModalProps = {
    onClose?: () => void
    interruptOutsideClick?: boolean
    className?: string
    containerClassName?: string
}

export default class Modal extends Component<ModalProps> {

    el: HTMLDivElement
    modalEl: HTMLDivElement | null
    unregisterOutsideClick?: () => void

    constructor(props: any) {
        super(props)
        this.el = document.createElement("div")
        this.el.className = "modal__container open"
        if (props.containerClassName) {
            this.el.className = `modal__container open ${props.containerClassName}`
        }
        // this.initializeModalContainer()
        this.modalEl = null
        modalRoot && modalRoot.prepend(this.el)
    }


    initializeModalContainer() {
        const modalContainers = document.getElementsByClassName("modal__container") as HTMLCollectionOf<HTMLDivElement>
        const modalContainer = modalContainers.length > 0 && modalContainers.item(0)


        if (modalContainer) {
            modalContainer.remove()
        }

        this.el = document.createElement("div")
        this.el.className = "modal__container open"

        // 2 modals
        // if(modalContainer) {
        //     this.el = modalContainer
        // } else {
        //     this.el = document.createElement("div")
        //     modalRoot && modalRoot.prepend(this.el)
        // }

        // if(this.el && this.el.className.indexOf("open") < 0) {
        //     this.el.className = "modal__container open"
        // }
    }

    componentDidMount() {
        modalRoot && modalRoot.prepend(this.el)
        this.modalEl?.focus();
    }

    componentWillUnmount() {
        this.unregisterOutsideClick && this.unregisterOutsideClick()
        this.el && this.el.remove()
    }


    componentDidUpdate(prevProps: ModalProps) {
        if (this.props.interruptOutsideClick && this.unregisterOutsideClick) {
            this.unregisterOutsideClick()
        }

        if (prevProps.interruptOutsideClick && !this.props.interruptOutsideClick && this.modalEl) {
            this.unregisterOutsideClick = registerOutsideClick(this.modalEl, this.handleOutsideClick)
        }
    }

    getModalRef = (ref: HTMLDivElement) => {
        this.unregisterOutsideClick = registerOutsideClick(ref, this.handleOutsideClick)
        this.modalEl = ref
    }

    // a new reactdom create portal will cause the div.portals container to rerender and the old portal will be unmountend, so it's enough to remove the classname
    handleOutsideClick = (e: Event) => {
        this.handleClose();
    }

    handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        event.key === "Escape" && this.handleClose();
    }

    handleClose = () => {
        this.props.onClose && this.props.onClose()
        this.el.className = "modal__container"
    }

    render(): any {
        return ReactDOM.createPortal(
            <div className={"modal " + (this.props.className ?? "")} ref={this.getModalRef}
                 onKeyDown={this.handleKeyPress} tabIndex={-1}>
                <div className="modal__body">
                    {this.props.children}
                </div>
                <Button icon="close" skin="primary" className="modal__close" onClick={this.handleOutsideClick} />
            </div>,
            this.el
        )
    }
}
