import * as React from "react"
import { bindMethodsToContext } from "../../helper"
import { BaseProps } from "../../models/SharedModels"
import Loader from "../loader"
import Icon from "../icon"
import { GrayishSkins, DefaultSkins } from "../../models/skins";

export type BadgeProps = BaseProps & {
    skin?: DefaultSkins | GrayishSkins
    loading?: boolean
    value?: string | number | null | React.ReactElement<Icon>
    buttonIcon?: string
    onButtonClick?(): void
    title?: string
}

export type BadgeState = {
    show: boolean
}

/**
* @deprecated Use new Badge from "@tm/components" instead
*/

export default class Badge extends React.Component<BadgeProps, BadgeState> {
    private _visibleTimeoutId: number
    private _isMounted: boolean = false
    baseName = "badge"

    constructor(props: BadgeProps) {
        super(props)

        this.state = {
            show: false
        }

        bindMethodsToContext(this, ["build"])
    }
    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
        window.clearTimeout(this._visibleTimeoutId)
    }

    render() {
        const className = this.buildClassName()
        const { value, loading, title } = this.props
        const show = !!(loading || value)
        this.handleBadgeVisibility()

        return (
            <div title={title} className={`${className}`}>
                {this.renderBadgeContent()}
            </div>
            // <div className={className}>
            //     {
            //         (loading || value) &&
            //             <div className={this.baseName + " " + `${this.state.show ? this.baseName + "--show": ""}`}>
            //                 {
            //                     this.renderBadgeContent()
            //                 }
            //             </div>
            //     }
            //     {
            //         this.props.children
            //     }
            // </div>
        )
    }

    renderBadgeContent() {
        const { loading, value, buttonIcon, onButtonClick } = this.props

        let badgeContent
        if (loading) {
            badgeContent = <Loader visible />
        }
        else {
            badgeContent = buttonIcon ? <div className="badge-content-wrapper">{value}<div className="badge__button" onClick={this.handleOnButtonClick.bind(this)}><Icon size="xs" name={buttonIcon}/></div></div> : value
        }

        return badgeContent
    }

    handleOnButtonClick(e: React.MouseEvent) {
        if(this.props.onButtonClick) {
            e.stopPropagation()
            this.props.onButtonClick()
        }
    }

    handleBadgeVisibility() {
        const { show } = this.state
        const { loading, value } = this.props

        if (!value && !loading && show) {
            this.handleHidden()
            return
        }

        if ((value || loading) && !show) {
            this._visibleTimeoutId = window.setTimeout(this.handleVisible, 100)
        }
    }

    handleVisible() {
        if (this._isMounted) {
            this.setState({ show: true })
        }
    }

    handleHidden() {
        this.setState({ show: false })
    }

    buildClassName() {
        let qualifiedSelector = `${this.baseName}`
        const { className, skin, loading } = this.props

        if (className) {
            qualifiedSelector = `${qualifiedSelector} ${className}`
        }

        if (skin) {
            qualifiedSelector = `${qualifiedSelector} ${this.baseName}--${skin}`
        }

        if (this.state.show) {
            qualifiedSelector = `${qualifiedSelector} ${this.state.show ? this.baseName + "--show" : ""}`
        }

        if (loading) {
            qualifiedSelector = `${qualifiedSelector} ${this.baseName + "--loading"}`
        }

        return qualifiedSelector
    }
}
