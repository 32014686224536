import { WheelEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import dragscroll from "dragscroll"

import { usePrevious } from "@tm/utils"
import { ArticleImage } from "@tm/models"

import Skeleton from "@mui/material/Skeleton"
import { ImageViewerImage } from "./imageViewerImage"
import { ImageViewerThumbnail } from "./imageViewerThumbnail"
import { ImageViewerActions } from "./imageViewerActions"

type Props = {
    isLoading: boolean
    images: ArticleImage[]
    startIndex?: number
    onClose?(): void
}

export const ImageViewer = memo<Props>(({ startIndex, images, onClose, isLoading }: Props) => {
    const [activeIndex, setActiveIndex] = useState<number>(startIndex || 0)
    const [zoomLevel, setZoomLevel] = useState<number>(startIndex || 0)
    const [zoomPoint, setZoomPoint] = useState<{ x: number; y: number }>()
    const prevZoomLevel = usePrevious(zoomLevel)

    const imageContainerRef = useRef<HTMLDivElement>(null)

    const setImageToActiveByIndex = useCallback((index: number) => {
        setActiveIndex(index)
    }, [])

    useEffect(() => {
        if (startIndex) {
            setImageToActiveByIndex(startIndex)
        }
    }, [startIndex, setImageToActiveByIndex])

    useEffect(() => {
        if ((prevZoomLevel === 1 && zoomLevel !== 1) || (zoomLevel === 1 && prevZoomLevel !== -1)) {
            dragscroll.reset()
        }

        if (imageContainerRef.current && zoomPoint) {
            const container = imageContainerRef.current
            const image = container.querySelector(".image-viewer__image") as HTMLImageElement

            if (!image) {
                return
            }

            const rect = container.getBoundingClientRect()
            const currentZoom = zoomLevel * 0.5 + 0.5
            const width = rect.width * currentZoom
            const height = rect.height * currentZoom
            let left = width * zoomPoint.x - rect.width / 2
            let top = height * zoomPoint.y - rect.height / 2
            if (left < 0) {
                left = 0
            }
            if (top < 0) {
                top = 0
            }

            image.style.transform = `scale(${currentZoom})`
            setTimeout(() => container.scrollTo(left, top), 0)
        }
    }, [zoomLevel, zoomPoint, prevZoomLevel])

    const previousEnabled = useMemo(() => {
        return images.length > 1
    }, [images])

    const nextEnabled = useMemo(() => {
        return images.length > 1
    }, [images])

    const zoomInEnabled = useMemo(() => {
        return zoomLevel <= 4
    }, [zoomLevel])

    const zoomOutEnabled = useMemo(() => {
        return zoomLevel >= 2
    }, [zoomLevel])

    const onHandleZoomIn = useCallback(
        (zoomPoint?: { x: number; y: number }) => {
            if (zoomInEnabled) {
                setZoomLevel((prev) => prev + 1)
                setZoomPoint(zoomPoint || { x: 0.5, y: 0.5 })
            }
        },
        [zoomInEnabled]
    )

    const onHandleZoomOut = useCallback(
        (zoomPoint?: { x: number; y: number }) => {
            if (zoomOutEnabled) {
                setZoomLevel((prev) => prev - 1)
                setZoomPoint(zoomPoint || { x: 0.5, y: 0.5 })
            }
        },
        [zoomOutEnabled]
    )

    const onHandleZoom = useCallback(
        (e: WheelEvent<HTMLDivElement>) => {
            e.stopPropagation()

            const currentZoom = zoomLevel * 0.5 + 0.5
            const rect = e.currentTarget.getBoundingClientRect()
            const width = rect.width * currentZoom
            const height = rect.height * currentZoom
            const x = e.clientX - rect.left + e.currentTarget.scrollLeft
            const y = e.clientY - rect.top + e.currentTarget.scrollTop
            const zoomPoint = {
                x: x / width,
                y: y / height,
            }

            if (e.deltaY < 0) {
                onHandleZoomIn(zoomPoint)
            } else {
                onHandleZoomOut(zoomPoint)
            }
        },
        [zoomLevel, onHandleZoomIn, onHandleZoomOut]
    )

    const handleImageSelect = useCallback((id: number) => {
        setActiveIndex(id)
    }, [])

    const classNames = useMemo(() => {
        return `image-viewer__image-wrapper ${zoomLevel !== 1 ? "image-viewer__image-wrapper--zoomed dragscroll" : ""}`
    }, [zoomLevel])

    const renderThumbnails = useCallback(() => {
        if (isLoading) {
            return (
                <div>
                    <Skeleton variant="rectangular" sx={{ width: "48px", height: "64px", margin: "16px" }} />
                    <Skeleton variant="rectangular" sx={{ width: "48px", height: "64px", margin: "16px" }} />
                </div>
            )
        }

        if (images.length === 1) {
            return
        }

        return (
            <div className="image-viewer__thumbnails">
                {images.map((image, index) => (
                    <ImageViewerThumbnail
                        key={`${image.name}_${index}`}
                        id={index}
                        image={image}
                        isActive={activeIndex === index}
                        onClick={handleImageSelect}
                    />
                ))}
            </div>
        )
    }, [images, activeIndex, handleImageSelect])

    const handlePreviousImage = useCallback(() => {
        if (!previousEnabled) {
            return
        }

        setActiveIndex((prev) => {
            if (prev === 0) {
                return images.length - 1
            }

            return prev - 1
        })
    }, [previousEnabled])

    const handleNextImage = useCallback(() => {
        if (!nextEnabled) {
            return
        }

        setActiveIndex((prev) => {
            if (prev === images.length - 1) {
                return 0
            }

            return prev + 1
        })
    }, [nextEnabled])

    if (!images?.length) {
        return null
    }

    return (
        <div className="image-viewer">
            <ImageViewerActions
                handlePreviousImage={handlePreviousImage}
                handleNextImage={handleNextImage}
                handleZoomIn={onHandleZoomIn}
                handleZoomOut={onHandleZoomOut}
                onClose={onClose}
                previousEnabled={previousEnabled}
                nextEnabled={nextEnabled}
                zoomInEnabled={zoomInEnabled}
                zoomOutEnabled={zoomOutEnabled}
            />
            <div className={classNames} onWheel={onHandleZoom} ref={imageContainerRef}>
                <ImageViewerImage image={images[activeIndex]} isLoading={isLoading} />
            </div>
            {renderThumbnails()}
        </div>
    )
})
