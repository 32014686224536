import { Select, SelectProps, filledInputClasses, lighten, styled } from "@mui/material"
import { ComponentType } from "react"

export const InlineSelect = styled(Select)(({ theme }) => {
    const { button } = theme.overwrites?.components ?? {}
    const { getContrastText } = theme.palette
    const primaryButton = button?.primary

    const defaultBackgroundColor = primaryButton?.backgroundColor ?? theme.palette.grey[300]

    const backgroundColorHover = primaryButton?.hover?.backgroundColor ?? lighten(theme.palette.primary.main, 0.5)

    const focusedColor = primaryButton?.focus ?? theme.palette.primary.light

    const transition = theme.transitions.create(["color", "background-color"])

    return {
        border: `1px solid ${defaultBackgroundColor} !important`,
        borderRadius: `${theme.radius?.default || "2px"} !important`,
        backgroundColor: `${defaultBackgroundColor}!important`,
        borderTopRightRadius: "0 !important",
        borderBottomRightRadius: "0 !important",
        opacity: 1,
        color: getContrastText(defaultBackgroundColor),
        svg: {
            fill: getContrastText(defaultBackgroundColor),
        },
        "&.Mui-focused": {
            boxShadow: "unset !important",
            borderRadius: `${theme.radius?.default || "2px"} !important`,
            border: `1px solid ${focusedColor} !important`,
            backgroundColor: `${focusedColor}!important`,
            borderTopRightRadius: "0 !important",
            borderBottomRightRadius: "0 !important",
            color: getContrastText(focusedColor),
        },
        ":hover": {
            [`.${filledInputClasses.input}`]: {
                transition,
                borderRadius: `${theme.radius?.default || "2px"} !important`,
                borderTopRightRadius: "0 !important",
                borderBottomRightRadius: "0 !important",
                backgroundColor: backgroundColorHover,
                color: getContrastText(backgroundColorHover),
                "&.Mui-disabled": {
                    backgroundColor: defaultBackgroundColor,
                },
            },
            ".MuiSelect-icon": {
                transition,
                color: getContrastText(backgroundColorHover),
            },
        },

        ".MuiSelect-select": {
            minHeight: "unset !important",
        },
    }
}) as ComponentType<SelectProps>
