import { styled } from "@mui/material"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"

type Props = {
    onClick(): void
    disabled?: boolean
    disableMargin?: boolean
}

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "disableMargin",
})<Props>(({ disableMargin }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "6em",
    marginLeft: disableMargin ? "initial" : "auto",
}))

export function ReplaceButton({ onClick, disabled, disableMargin }: Props) {
    return <StyledButton startIcon={<Icon name="replace" />} color="highlight" onClick={onClick} disabled={disabled} disableMargin={disableMargin} />
}
