import { DefaultArticleItemState, WholesalerArticleItemState } from "@tm/models"
import { useArticleItemState } from "."

export function useWholesalerArticleItemState(): WholesalerArticleItemState
export function useWholesalerArticleItemState<TReturnType>(selector: (value: WholesalerArticleItemState) => TReturnType): TReturnType
export function useWholesalerArticleItemState<TReturnType>(
    selector?: (value: WholesalerArticleItemState) => TReturnType
): DefaultArticleItemState | TReturnType {
    return useArticleItemState<WholesalerArticleItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
