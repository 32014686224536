import { ClientType, Memo, Paged } from "./shared"

export type FindVehiclesRequest = Paged & {
    query?: string
    customerReferenceId?: string
    orderBy?: string
    orderByDescending?: boolean
}

export type FindVehiclesResponse = Paged & {
    vehicles: Array<Vehicle>
}

export type SaveVehicleRequest = {
    vehicle: Vehicle
}

export type SaveVehicleResponse = {
    referenceId: string
}

export type ShowVehicleRequest = {
    referenceId: string
}

export type ShowVehicleResponse = {
    vehicle?: Vehicle
}

export type Vehicle = {
    referenceId: string
    customerReferenceId?: string

    vehicleType: VehicleType
    manufacturer: string
    model: string
    type: string

    registrationInformation?: Registration
    vin: string
    engineCode: string
    mileage?: number
    mileageType: number

    nextGeneralInspection?: Date
    nextServiceDate?: Date
    lastWorkshopAppointment?: Date

    memos: Array<Memo>

    creationDate: Date
    modifiedDate?: Date

    countryCode?: string
}

export type VehicleType = {
    id: number
    type: Type
    clientId: ClientType
    description: string
}

export enum Type {
    Undefined = 0,
    PassengerCar = 1,
    CommercialVehicle = 2,
    Engine = 4,
    Motorcycle = 8,
    Axle = 16,
    Universal = 32,
    DriversCab = 64,
    GearBox = 128,
}

export type Registration = {
    plateId?: string
    countryCode: string
    registrationNo: string
    registrationDate?: Date
}
