import { VehicleType } from "@tm/models"

export function getImageTypeByVehicleType(vehicleType: VehicleType): "car" | "bike" | "manufacturer" | undefined {
    switch (vehicleType) {
        case VehicleType.PassengerCar:
            return "car"
        case VehicleType.Motorcycle:
            return "bike"
        case VehicleType.CommercialVehicle:
            return "manufacturer"
        default:
            return undefined
    }
}
