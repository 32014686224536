import { convertDataURIToDecodedString } from ".."

export function getBase64FromImage(img: HTMLImageElement, overwriteWidth?: number, overwriteHeight?: number): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const c = document.createElement("canvas")
        c.width = overwriteWidth ?? img.naturalWidth
        c.height = overwriteHeight ?? img.naturalHeight

        const context = c.getContext("2d")
        if (context) {
            context.drawImage(img, 0, 0, c.width, c.height)
            const data = c.toDataURL("image/jpg")
            resolve(data)
        } else {
            reject()
        }
    })
}

function convertDataURIToBinary(dataURI: string) {
    const raw = convertDataURIToDecodedString(dataURI)
    const rawLength = raw.length
    const array = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i)
    }

    return array
}

export function convertBase64ImageToByteArray(image: string): Array<number> {
    const uint8Array = convertDataURIToBinary(image)
    return [].slice.call(uint8Array)
}

export function convertDataURIToBlob(dataURI: string) {
    return new Blob([convertDataURIToBinary(dataURI)])
}
