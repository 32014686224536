import { ComponentClass, ComponentType, memo, useRef } from "react"
import { Route, RouteComponentProps, WithRouterProps, WithRouterStatics } from "react-router"
import hoistNonReactStatics, { NonReactStatics } from "hoist-non-react-statics"

export type { RouteComponentProps }

/**
 * A customized withRouter higher order component to update the wrapped component whenever the changed url matches with the next parent route.
 * The original HOC doesn't react on query changes.
 */
function withRouter<P extends RouteComponentProps<any>, C extends ComponentType<P>>(
    component: C & ComponentType<P>
): ComponentClass<Omit<P, keyof RouteComponentProps<any>> & WithRouterProps<C>> & WithRouterStatics<C> {
    const Component = memo(component as any, (props, nextProps) => {
        const params = new URLSearchParams(nextProps.location.search)
        if (!props.firstLoadRef.current && !!params.get("modal")) {
            // don't render if a modal is going to render, despite it's the first time (after reload)
            return true
        }
        props.firstLoadRef.current = false
        return false
    })

    const WithRouter = (props: P & WithRouterProps<C> & WithRouterStatics<C> & NonReactStatics<any>) => {
        const { wrappedComponentRef, ...remainingProps } = props
        const firstLoadRef = useRef(true)

        return (
            <Route>
                {(routeComponentProps) => (
                    <Component {...remainingProps} {...routeComponentProps} firstLoadRef={firstLoadRef} ref={wrappedComponentRef} />
                )}
            </Route>
        )
    }

    WithRouter.displayName = `withRouter(${Component.displayName || Component.name})`
    WithRouter.WrappedComponent = Component

    return hoistNonReactStatics(WithRouter, Component) as any
}

export { withRouter }
