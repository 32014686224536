import { Component } from "react"
import Day from "./Day";
import { DateUnit } from "@tm/utils";

export default class Week extends Component<WeekProps, any> {

    renderDays(): JSX.Element[] {
        const startOfWeek = this.props.day.startOf(DateUnit.Week);
        const { month, onDayClick, selected, minDate, maxDate } = this.props;

        return [0, 1, 2, 3, 4, 5, 6].map((offset) => {
            const day = startOfWeek.clone().add(offset, DateUnit.Day);

            return (
                <Day
                    key={offset}
                    day={day}
                    month={month}
                    onClick={onDayClick}
                    selected={selected}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            );
        })
    }

    render() {
        const { day } = this.props;
        return (
            <div className="calendar__week">
                <div className="calendar__week-number">
                    {day.week()}
                </div>
                {this.renderDays()}
            </div>
        );
    }
}

export interface WeekProps {
    day: Date;
    month: number;
    selected?: Date;
    minDate?: Date;
    maxDate?: Date;
    onDayClick(date: Date): void;
}
