import { TypographyProps, useTheme } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ErpPrice, PriceType } from "@tm/models"
import { Typography } from "../../../generics/typographie"

export function mapPriceColor(type: PriceType): TypographyProps["color"] {
    switch (type) {
        case PriceType.Strikethrough:
            return "primary"
        case PriceType.Purchase:
            return "primary"
        default:
            return ""
    }
}

export function mapPriceFontWeight(type: PriceType): TypographyProps["fontWeight"] {
    switch (type) {
        case PriceType.Purchase:
            return "bold"
        default:
            return 600
    }
}

export function mapPriceVariant(type: PriceType): TypographyProps["variant"] {
    switch (type) {
        case PriceType.Strikethrough:
            return "label"
        default:
            return "body2"
    }
}

type PriceValueProps = {
    price: ErpPrice
    variant?: TypographyProps["variant"]
}

export function PriceValue({ price, variant }: PriceValueProps) {
    const { currency } = useLocalization()

    const theme = useTheme()
    const purchasePriceColor =
        price.type === PriceType.Purchase && theme.overwrites?.components?.partListV2?.articleItem?.erpContainer?.purchasePriceColor

    return (
        <Typography
            textAlign="right"
            color={purchasePriceColor || mapPriceColor(price.type)}
            variant={variant ?? mapPriceVariant(price.type)}
            fontWeight={mapPriceFontWeight(price.type)}
            whiteSpace="nowrap"
            lineHeight={1.2}
            sx={price.type === PriceType.Strikethrough ? { textDecoration: "line-through" } : undefined}
        >
            {currency(price.value, price.currencySymbol)}
        </Typography>
    )
}
