import { FC, ReactNode } from "react"
import { styled, Typography } from "@mui/material"
import { MessageBubble, MessageBubbleProps } from "./chatMessageBubble"

const Container = styled("div")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    padding: "8px",
    borderRadius: "2px",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[400]}`,
}))

const VehicleContainer = styled("table")(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "1fr",
    width: "100%",
    padding: "8px",
    borderRadius: "2px",
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[400]}`,
}))

const NameValueContainer = styled("div")({
    margin: "5px",
})

const Headline = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.grey[500],
}))

const Value = styled(Typography)({
    wordBreak: "break-word",
})

type NameValueProps = {
    label: string
    value: string | string[] | ReactNode
}
const NameValue: FC<NameValueProps> = ({ label, value }) => {
    return (
        <NameValueContainer>
            <Headline>{label}</Headline>
            {Array.isArray(value) ? value.map((v) => <Value key={v}>{v}</Value>) : <Value>{value}</Value>}
        </NameValueContainer>
    )
}

type Props = MessageBubbleProps & {
    dataType: "Customer" | "Vehicle"
    data: NameValueProps[]
}
export const MessageMetaData = (props: Props) => {
    return (
        <>
            {props.dataType === "Customer" ? (
                <MessageBubble {...props} headline="Kundendaten">
                    <Container>
                        {props.data.map((data) => {
                            return <NameValue key={data.label} {...data} />
                        })}
                    </Container>
                </MessageBubble>
            ) : (
                <MessageBubble {...props} headline="Fahrzeugdaten">
                    <VehicleContainer>
                        {props.data.map((data) => {
                            return <NameValue key={data.label} {...data} />
                        })}
                    </VehicleContainer>
                </MessageBubble>
            )}
        </>
    )
}
