import { useCallback, useEffect } from "react"
import { parseIfStringifiedData } from "../../helpers/json"

const BLACKLIST = ["source", "data", "type"]

export const useMessage = (calBack?: (data: any, source: Window) => void) => {
    const handleMessage = useCallback(
        (event: MessageEvent<any>) => {
            const data = parseIfStringifiedData(event.data)
            if (!data) {
                return
            }

            const dataKeys = Object.keys(data)
            if (dataKeys.some((x) => BLACKLIST.some((y) => y === x))) {
                return
            }

            const commandKeys = dataKeys.filter((x) => x != "sourceId")
            if (commandKeys.length === 0) {
                console.warn("API Call: No Command found.")
            }

            calBack?.(event.data, event.source as Window)
        },
        [calBack]
    )

    useEffect(() => {
        window.addEventListener("message", handleMessage)
        return () => window.removeEventListener("message", handleMessage)
    }, [handleMessage])
}
