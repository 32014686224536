import { FC } from "react"
import { Box, BoxProps, styled } from "@mui/material"
import { TableColumnData } from "./interfaces"

type TableGridProps = BoxProps & {
    columns: TableColumnData[]
    rowStyle: "stripped" | "uniform" | "transparent"
}

export const TableGrid: FC<TableGridProps> = styled(Box, {
    shouldForwardProp: (prop) => prop !== "columns" && prop !== "rowStyle",
})<TableGridProps>(({ theme, columns, rowStyle }) => ({
    display: "grid",
    gridTemplateColumns: columns.map((col) => col.gridSize || "auto").join(" "),
    fontSize: "14px",

    ...(rowStyle === "uniform" && {
        [`& .row > span`]: {
            backgroundColor: theme.overwrites?.components?.elementList?.backgroundColor?.secondary || theme.palette.grey[100],
        },
        [`& .row: > div`]: {
            backgroundColor: theme.overwrites?.components?.elementList?.backgroundColor?.secondary || theme.palette.grey[100],
        },
    }),
    ...(rowStyle === "stripped" && {
        [`& .row:nth-of-type(2n)>span`]: {
            backgroundColor: theme.overwrites?.components?.elementList?.backgroundColor?.primary || theme.palette.grey[50],
        },
        [`& .row:nth-of-type(2n)>div`]: {
            backgroundColor: theme.overwrites?.components?.elementList?.backgroundColor?.primary || theme.palette.grey[50],
        },
        [`& .row:nth-of-type(2n+1)>span`]: {
            backgroundColor: theme.overwrites?.components?.elementList?.backgroundColor?.secondary || theme.palette.grey[100],
        },
        [`& .row:nth-of-type(2n+1)>div`]: {
            backgroundColor: theme.overwrites?.components?.elementList?.backgroundColor?.secondary || theme.palette.grey[100],
        },
    }),
    ...(rowStyle === "transparent" && {
        backgroundColor: "transparent",
    }),
}))
