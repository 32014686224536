import { TmaEventTracking } from "../.."
import { ArticleWithAvailability } from "./models"

export const ArticleComparisonEventHelper = {
    Parts: {
        Click: (articles?: ArticleWithAvailability[], vehicleId?: string) => {
            TmaEventTracking.dispatchTrack({
                tmaEvent: "ARTICLE_COMPARISON",
                action: "article-comparison-click",
                bundle: "parts",
                component: "repository",
                payload: { articles, vehicleId },
                source: "product-group-search",
                type: "click",
            })
        },
    },
}
