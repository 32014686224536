import { forwardRef, PropsWithChildren } from "react"
import { ScrollContainer } from "../../generics/scrollContainer"
import { TableProps } from "./interfaces"
import { TableComponent } from "./TableComponent"

export const Table = forwardRef<HTMLDivElement, PropsWithChildren<TableProps>>((props, ref) => {
    const { onScrollBottom, overflowY, scrollbar, ...restProps } = props

    return scrollbar === "no" ? (
        <TableComponent ref={ref} {...props} />
    ) : (
        <ScrollContainer ref={ref} overflowY={overflowY} onScrollBottom={onScrollBottom} useDefaultStyling={scrollbar === "system"} {...restProps}>
            <TableComponent {...props} />
        </ScrollContainer>
    )
})
