import { useEffect, useRef } from "react"

export function useUnsubscribe() {
    const unsubscribe = useRef<() => void | undefined>()

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            unsubscribe.current?.()
        }
    }, [])

    return unsubscribe
}
