import { ReactNode } from "react"
import { styled } from "@mui/material"
import { AttachmentButton } from "../../generics/button"
import { MessageBubble, MessageBubbleProps } from "./chatMessageBubble"
import { StatePill } from "../../generics/statePill"

const Content = styled("div")(({ theme }) => ({
    padding: `${theme.spacing(1.5)} 0`,
}))

const Row = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    "> *": {
        margin: theme.spacing(0.5),
    },
}))

const List = styled("ul")({
    alignSelf: "flex-start",
    fontSize: "12px",
    listStyleType: "disc",
    paddingInlineStart: "40px",
    marginBlockStart: "1em",
})

const Stack = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "100%",
})

const ActionContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    marginTop: theme.spacing(1),
    "> *": {
        margin: theme.spacing(0.5),
    },
}))

type Props = MessageBubbleProps & {
    appointmentAccept?: boolean
    acceptText: string
    declineText: string
    listEntrys?: string[]
    actionButton?: ReactNode | ReactNode[]
    downloadButtonText: string
    downloadFileName: string
    downloadhref: string
}

export const MessageAppointment = (props: Props) => {
    const { appointmentAccept, listEntrys, actionButton, acceptText, declineText, downloadButtonText, downloadhref, downloadFileName, children } =
        props
    return (
        <MessageBubble {...props}>
            {children && <Content>{children}</Content>}
            <Stack>
                <Row>
                    <AttachmentButton fileName={downloadButtonText} iconName="calendar" fileType={downloadFileName} href={downloadhref} />

                    {appointmentAccept !== undefined && (
                        <StatePill state={appointmentAccept ? "accept" : "rejected"} text={appointmentAccept ? acceptText : declineText} />
                    )}
                </Row>
                {listEntrys && (
                    <List>
                        {listEntrys.map((e) => {
                            return <li key={e}>{e}</li>
                        })}
                    </List>
                )}
                {actionButton && <ActionContainer>{props.actionButton}</ActionContainer>}
            </Stack>
        </MessageBubble>
    )
}
