import { Article } from "@tm/models"
import { TmaEventTracking } from "../.."

export const DetailsFormCallEventHelper = {
    Call: (article?: Article, vehicleId?: string) => {
        TmaEventTracking.dispatchTrack({
            tmaEvent: "ARTICLE_DETAILSFORMCALL",
            action: "details-form-call",
            bundle: "parts",
            component: "repository",
            payload: { article, vehicleId },
            source: "product-group-search",
            type: "call",
        })
    },
}
