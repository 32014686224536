import { ChangeEvent, KeyboardEvent, ForwardedRef, forwardRef, useCallback, useRef, MouseEvent } from "react"
import { TextField, TextFieldProps, styled, filledInputClasses, colors, useTheme } from "@mui/material"
import { alpha, Stack } from "@mui/system"
import { IconButton, SearchButton } from "../../generics/button"
import { Icon } from "../../generics/Icons"
import { Loader } from "../../generics/loader"

const SearchTextField = styled(TextField)<{ alwaysColoredBorder?: boolean; cutEdge?: boolean }>(({ theme, alwaysColoredBorder, cutEdge }) => ({
    [`.${filledInputClasses.root}`]: {
        padding: 0,
        border: `1px solid ${
            theme.overwrites?.components?.textfield?.border?.color || (alwaysColoredBorder ? theme.palette.highlight.main : "#e2e2e1")
        }`,
        borderRadius: theme.radius?.default || "3px",
        backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
        transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
        "&:before": {
            content: "none",
        },
        "&:after": {
            content: "none",
        },

        "&:hover": {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
        },
        [`&.${filledInputClasses.focused}`]: {
            backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100],
            borderColor: theme.overwrites?.components?.textfield?.border?.color ?? theme.palette.primary.main,
            boxShadow: cutEdge ? "none !important" : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        },
        [`&.${filledInputClasses.error}`]: {
            borderColor: theme.palette.error.main,
            borderBottom: `4px solid ${theme.palette.error.main}`,
            marginBottom: "0px",
            [`&.${filledInputClasses.focused}`]: {
                boxShadow: cutEdge ? "none !important" : `${alpha(theme.palette.error.main, 0.25)} 0 0 0 2px`,
            },
        },
    },
    [`.${filledInputClasses.input}`]: {
        padding: 8,
        boxSizing: "content-box",
        fontSize: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
        lineHeight: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
        height: theme.overwrites?.components?.textfield?.fontSize?.medium || "14px",
    },
}))

const InlineSearchButton = styled(SearchButton)<{ cutEdge?: boolean }>(({ cutEdge, theme }) => ({
    borderRadius: `${theme.radius?.default || "2px"} !important`,
    borderTopLeftRadius: `${!cutEdge && "0 !important"}`,
    borderBottomLeftRadius: `${!cutEdge && "0 !important"}`,
}))

const CutEdgeStack = styled(Stack)<{ cutEdge?: boolean }>(({ cutEdge, theme }) => ({
    ...(cutEdge && {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,

        [`&::after`]: {
            content: "''",
            position: "absolute",
            width: 0,
            height: 0,
            border: "7px solid transparent",
            right: "-1px",
            bottom: "-1px",
            borderColor: "transparent #fff #fff transparent",
        },
        [`&::before`]: {
            content: "''",
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "7px",
            borderColor: `transparent  ${theme.overwrites?.components?.textfield?.border?.color}  ${theme.overwrites?.components?.textfield?.border?.color} transparent`,
            background: theme.overwrites?.components?.textfield?.backgroundColor,
        },
    }),
}))

export type SearchFieldProps = Omit<TextFieldProps, "value" | "onChange" | "color"> & {
    value: string
    isLoading?: boolean
    onChange(value: string): void
    onStartSearch(): void
    alwaysColoredBorder?: boolean
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | "highlight"
}

function SearchFieldComponent(props: SearchFieldProps, ref: ForwardedRef<HTMLDivElement>) {
    const inputRef = useRef<HTMLInputElement>()
    const theme = useTheme()
    const cutEdge = theme?.overwrites?.components?.textfield?.cutEdge

    const handleClickClear = useCallback(() => {
        props.onChange("")
        inputRef.current?.focus()
    }, [props])

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.currentTarget.value)
        },
        [props]
    )

    const handleKeyStroke = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") {
                props.onStartSearch()
            }
            props.onKeyUp?.(e)
        },
        [props]
    )

    const handleClickSearch = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            props.onStartSearch()
            e.stopPropagation()
        },
        [props]
    )

    const { onStartSearch, isLoading, color, ...rest } = props

    function renderSearchButton() {
        return <InlineSearchButton color={color} onClick={handleClickSearch} cutEdge={cutEdge} />
    }

    return (
        <>
            <SearchTextField
                autoComplete="off"
                cutEdge={cutEdge}
                {...rest}
                onChange={handleChange}
                onKeyUp={handleKeyStroke}
                ref={ref}
                variant="filled"
                inputProps={{
                    ref: inputRef,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                    ...rest.InputProps,
                    endAdornment: (
                        <>
                            {cutEdge && <CutEdgeStack paddingRight="40px" cutEdge={cutEdge} />}
                            {isLoading && <Loader size="small" />}
                            {!!props.value && (
                                <IconButton size="small" onClick={handleClickClear} sx={{ marginRight: `${cutEdge && "8px"}` }}>
                                    <Icon name="close" />
                                </IconButton>
                            )}
                            {!cutEdge && renderSearchButton()}
                        </>
                    ),
                }}
            />
            {cutEdge && renderSearchButton()}
        </>
    )
}

export const SearchField = forwardRef(SearchFieldComponent)
