import { MenuList, Popover } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { MouseEvent, useCallback, useState } from "react"
import { ArticleAction } from "@tm/models"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"
import { IconButton } from "../IconButton"
import { ActionMenuItem } from "./ActionMenuItem"
import { ButtonAction } from "./ButtonAction"

type Props = {
    iconActionList: Array<ArticleAction>
    buttonActionList: Array<ArticleAction>
}

export function LessCenterActions(props: Props) {
    const { iconActionList, buttonActionList } = props
    const detailsAction = buttonActionList.find((action) => action.id === "details")

    const { translateText } = useLocalization()
    const [moreMenuEl, setMoreMenuEl] = useState<HTMLButtonElement>()

    const handleClickMore = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setMoreMenuEl(e.currentTarget)
    }, [])

    const handleCloseMore = useCallback(() => {
        setMoreMenuEl(undefined)
    }, [])

    return (
        <>
            <Tooltip title={translateText(13775)}>
                <IconButton onClick={handleClickMore} hoverEffect disableRipple>
                    <Icon name="ellipsis" />
                </IconButton>
            </Tooltip>
            <Popover anchorEl={moreMenuEl} anchorOrigin={{ horizontal: "left", vertical: "bottom" }} open={!!moreMenuEl} onClose={handleCloseMore}>
                <MenuList variant="menu">
                    {iconActionList.map((action) => (
                        <ActionMenuItem handleCloseMore={handleCloseMore} action={action} />
                    ))}
                    {buttonActionList.map((action) => {
                        if (action.id === "details") {
                            return
                        }
                        return <ActionMenuItem handleCloseMore={handleCloseMore} action={action} />
                    })}
                </MenuList>
            </Popover>
            {detailsAction && <ButtonAction key={detailsAction.id} action={detailsAction} hideText />}
        </>
    )
}
