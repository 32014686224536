import { GetMemoImportantNoteRequest, MemoToolContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

const KEY = "memotool_useGetMemoImportantNote"

export function useGetMemoImportantNote(request: GetMemoImportantNoteRequest, vehicleId?: string, memoToolEnabled?: boolean) {
    const queryEnabled = !!request.manufacturerName && memoToolEnabled

    const { data, isLoading, error } = useQuery(
        [KEY, vehicleId],
        () => {
            const container: MemoToolContainer = Container.getInstance(RegisteredModels.MemoTool)
            return container.action("getMemoImportantNote")(request)
        },
        { staleTime: 0, cacheTime: 0, enabled: queryEnabled }
    )

    return { response: data, isLoading, error }
}
