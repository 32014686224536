import { OE, OeArticleItemState } from "@tm/models"
import { useArticleItemState } from "."

export function useOeArticleItemState(): OeArticleItemState
export function useOeArticleItemState<TReturnType>(selector: (value: OeArticleItemState) => TReturnType): TReturnType
export function useOeArticleItemState<TReturnType>(selector?: (value: OeArticleItemState) => TReturnType): OeArticleItemState | TReturnType {
    return useArticleItemState<OeArticleItemState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
