import { MileType, PriceDictionary } from "."

export type OrderVouchersShowSupplierArticleByVehicleRequest = {
    vehicleId: string
    /** Comma separated string with product group ids. If empty, all articles are shown. */
    productGroupFilterIds: string
    pageIndex?: number
    pageSize?: number
}

export type OrderVouchersShowSupplierArticleByVehicleResponse = {
    vehicleId: string
    orderedSupplierArticles: Array<OrderVoucherSupplierItem>
}

export type OrderVoucherSupplierItem = {
    supplierId: number
    supplierArticleNumber: string
}

export type OrderVoucherList = {
    orderVoucherId: string
    voucherNumber: string
    orderRecipient?: OrderRecipient
    orderNumbers?: Array<OrderNumber>
    orderDate: Date
    totals: VoucherTotals
    workTaskInfos: Array<VoucherWorkTaskInfo>
    customerComment: string
}

export type OrderRecipient = {
    warehouseId: string
    warehouseName: string
    distributorId: number
    distributorName: string
}

type OrderNumber = {
    type?: OrderIdSource // undefined is the same as 0
    number: string
}

export enum OrderIdSource {
    CatalogSystem = 0,
    User = 1,
    Wholesaler = 2,
    AdditionalWholesaler = 3,
    InvoiceNumber = 4,
    DeliveryNoteNumber = 5,
    NotYetDefined = 6,
}

export type VoucherTotals = {
    totalPrices: PriceDictionary
    numberOfPositions: number
    numberOfParts: number
}

export type VoucherWorkTaskInfo = {
    workTaskId: string
    workTaskNumber: string
    customerInfo?: VoucherCustomerInfo
    vehicleInfo?: VoucherVehicleInfo
}

export type VoucherVehicleInfo = {
    id: string
    plateId: string
    displayName: string
    vin: string
    registrationNoType?: number
    registrationNo?: string
    registrationNoLabel?: string
    initialRegistration?: Date
    mileAge?: number
    mileType?: MileType
    vehicleImage?: string  // Base64
}

export type VoucherCustomerInfo = {
    id: string
    customerNumber: string
    displayName: string
    eMailAddress: string
    phone: string
}
