import { TeccomErpInformation, Warehouse } from "@tm/models"

export function mapTeccomERPtoWarehouse(teccom: TeccomErpInformation, label: string): Warehouse {
    return {
        id: teccom.itemId,
        name: teccom.specialProcurementErpInformation?.availability.description || label,
        prices: teccom.prices,
        quantities: [
            {
                availability: teccom.specialProcurementErpInformation?.availability || {},
                requestedValue: teccom.quantity?.requestedValue || 0,
                confirmedValue: teccom.quantity?.confirmedValue,
                value: teccom.quantity?.value,
                division: teccom.quantity?.division,
                expectedDeliveryTimeString: teccom.specialProcurementErpInformation?.message,
                description: teccom.specialProcurementErpInformation?.availability.shortDescription,
            },
        ],
        shortDescription: teccom.specialProcurementErpInformation?.availability.shortDescription || "",
        description: teccom.specialProcurementErpInformation?.availability.description || "",
        showWarehouseInfo: false,
        isDefault: false,
        isManufacturerWarehouse: !!teccom.specialProcurementErpInformation,
    }
}
