import {
    Article,
    CatalogPart,
    AddCatalogPartListRequest,
    WholesalerPart,
    AddWholesalerPartListRequest,
    AddOePartListRequest,
    OePart,
    ReplaceByCatalogPartRequest,
    ErpInformation,
    OrderWarehouseInfo,
    ErpInformationLog,
    PriceDictionary,
    ErpPrice,
    TmaEModule,
    AvailabilityStatus,
    OE,
    ErpSystemConfig,
} from "@tm/models"
import { TmaHelper } from "../tracking"

export function mapArticleToCatalogPart(
    article: Article,
    erpInfo?: ErpInformation,
    erpSystem?: ErpSystemConfig,
    memo?: string,
    warehouse?: OrderWarehouseInfo,
    advertisementCategoryId?: string
): CatalogPart {
    const erpInformationLog = createErpInformationLog(article, erpInfo)

    return {
        additionalDescription: article.additionalDescription,
        advertisementCategoryId,
        articleListPosition: article.articleListPosition,
        availability: erpInfo
            ? {
                status: erpInfo.availability.type || AvailabilityStatus.Undefined,
                description: erpInfo.availability.description || erpInfo.availability.shortDescription,
            }
            : undefined,
        dataSupplierArticleNumber: article.supplierArticleNo,
        dataSupplierId: article.supplier.id,
        dataSupplierName: article.supplier.name,
        description: article.description,
        distributorId: erpSystem?.id,
        distributorName: erpSystem?.description,
        erpInformation: erpInformationLog,
        isReplacementPart: erpInfo?.isReplacementPart,
        fittingSide: article.fittingSide,
        linkedItems: erpInfo?.articles?.map((a) => mapLinkedItemToCatalogPart(a, article.quantity || 1, memo)),
        productGroupId: article.productGroup.id,
        memo,
        productGroupName: article.productGroup.name,
        quantityValue: article.quantity || 1,
        thumbnailUrl: article.thumbnail,
        warehouseId: warehouse?.id,
        warehouseName: warehouse?.name,
        wholesalerArticleNumber: erpInfo?.wholesalerArticleNumber ?? article.traderArticleNo,
    }
}

export function mapArticleToAddCatalogPartListRequest(
    articles: Array<Article>,
    workTaskId: string,
    vehicleId: string | undefined,
    customerId: string | undefined,
    foundBySearchTerm: string | undefined,
    memo: string | undefined,
    erpInfos: Array<ErpInformation> | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    advertisementCategoryId: string | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined,
    origin?: TmaEModule
): AddCatalogPartListRequest {
    const catalogParts = articles.map((article): CatalogPart => {
        const erpInfo = erpInfos?.find((erpInfo) => erpInfo.itemId == article.id)
        const erpInformationLog = createErpInformationLog(article, erpInfo)
        return {
            additionalDescription: article.additionalDescription,
            advertisementCategoryId,
            articleListPosition: article.articleListPosition,
            availability: erpInfo
                ? {
                    status: erpInfo.availability.type || AvailabilityStatus.Undefined,
                    description: erpInfo.availability.description || erpInfo.availability.shortDescription,
                }
                : undefined,
            dataSupplierArticleNumber: article.supplierArticleNo,
            dataSupplierId: article.supplier.id,
            dataSupplierName: article.supplier.name,
            description: article.description,
            distributorId,
            distributorName,
            erpInformation: erpInformationLog,
            isReplacementPart: erpInfo?.isReplacementPart,
            fittingSide: article.fittingSide,
            linkedItems: erpInfo?.articles?.map((a) => mapLinkedItemToCatalogPart(a, article.quantity || 1, memo)),
            memo,
            productGroupId: article.productGroup.id,
            productGroupName: article.productGroup.name,
            quantityValue: article.quantity || 1,
            thumbnailUrl: article.thumbnail,
            warehouseId: orderWarehouseInfo?.id,
            warehouseName: orderWarehouseInfo?.name,
            wholesalerArticleNumber: erpInfo?.wholesalerArticleNumber ?? article.traderArticleNo,
        }
    })

    return {
        workTaskId,
        vehicleId,
        customerId,
        catalogParts,
        foundBySearchTerm,
        usePercentageValues: true,
        log: origin ? TmaHelper.AddCatalogPartToBasket.GetUserInteractionLog(catalogParts.first()?.articleListPosition, origin) : undefined,
    }
}

function mapLinkedItemToCatalogPart(
    info: ErpInformation,
    quantity: number,
    memo?: string,
    warehouseId?: string,
    warehouseName?: string,
    advertisementCategoryId?: string,
    distributorId?: number,
    distributorName?: string
): CatalogPart {
    const erpInformationLog = createErpInformationLogFromLinkedItem(info)

    return {
        additionalDescription: info.additionalInformationExtended,
        advertisementCategoryId,
        availability: {
            status: info.availability.type || AvailabilityStatus.Undefined,
            description: info.availability.description || info.availability.shortDescription,
        },
        dataSupplierArticleNumber: info.dataSupplierArticleNumber,
        dataSupplierId: info.dataSupplierId,
        dataSupplierName: "",
        description: info.additionalInformation,
        distributorId,
        distributorName,
        erpInformation: erpInformationLog,
        itemRole: info.itemsRole,
        memo,
        productGroupId: 0,
        productGroupName: "",
        quantityValue: quantity,
        warehouseId,
        warehouseName,
        wholesalerArticleNumber: info.wholesalerArticleNumber,
    }
}

// TODO: Add support of erpInfo to set the correct wholesalerArticleNumber
export function mapArticleToAddOePartListRequest(
    articles: OE.OePart[],
    workTaskId: string,
    vehicleId: string | undefined,
    customerId: string | undefined,
    origin?: TmaEModule
): AddOePartListRequest {
    const oeParts = articles.map((article): OePart => {
        return {
            additionalDescription: article.additionalDescription,
            currencyCode: article.prices?.[0]?.currency || "", // TODO: to be defined
            description: article.description,
            oeArticleNumber: article.number,
            oePriceValue: article.prices?.[0]?.value,
            productGroupId: article.productGroupId,
            quantityValue: article.quantity || 1,
            vehicleManufacturerId: article.manufacturerId,
            vehicleManufacturerName: article.manufacturerName || "OE",
            articleListPosition: 0,
            oeArticleOrigin: article.oeArticleOrigin,
        }
    })

    return {
        workTaskId,
        vehicleId,
        customerId,
        oeParts,
        log: origin ? TmaHelper.AddCustomToBasket.GetUserInteractionLog("oeArticle", origin) : undefined,
    }
}

export function mapArticleToAddWholesalerPartListRequest(
    articles: Array<Article>,
    workTaskId: string,
    vehicleId: string | undefined,
    customerId: string | undefined,
    memo: string | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    advertisementCategoryId: string | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined,
    erpInfos: Array<ErpInformation> | undefined,
    origin?: TmaEModule
): AddWholesalerPartListRequest {
    const wholesalerParts = articles.map((article): WholesalerPart => {
        const erpInfo = erpInfos?.find((erpInfo) => erpInfo.itemId == article.id)
        const erpInformation = createErpInformationLog(article, erpInfo)
        return {
            additionalDescription: article.additionalDescription,
            advertisementCategoryId,
            articleListPosition: article.articleListPosition,
            description: article.description,
            distributorId,
            distributorName,
            erpInformation,
            isReplacementPart: erpInfo?.isReplacementPart,
            manufacturerName: article.supplier ? article.supplier.name : undefined,
            memo,
            productGroupName: article.productGroup ? article.productGroup.name : undefined,
            quantityValue: article.quantity || 1,
            warehouseId: orderWarehouseInfo?.id,
            warehouseName: orderWarehouseInfo?.name,
            wholesalerArticleNumber: erpInfo?.wholesalerArticleNumber ?? article.traderArticleNo,
        }
    })

    return {
        workTaskId,
        vehicleId,
        customerId,
        wholesalerParts,
        usePercentageValues: true,
        log: origin ? TmaHelper.AddCustomToBasket.GetUserInteractionLog("wholesalerPartList", origin) : undefined,
    }
}

export function mapArticleToReplaceByCatalogPartRequest(
    partToReplaceId: string,
    article: Article,
    workTaskId: string,
    vehicleId: string | undefined,
    customerId: string | undefined,
    foundBySearchTerm: string | undefined,
    memo: string | undefined,
    orderWarehouseInfo: OrderWarehouseInfo | undefined,
    advertisementCategoryId: string | undefined,
    distributorId: number | undefined,
    distributorName: string | undefined
): ReplaceByCatalogPartRequest {
    const erpInformation = createErpInformationLog(article)
    const catalogPart: CatalogPart = {
        distributorName,
        distributorId,
        additionalDescription: article.additionalDescription,
        advertisementCategoryId,
        articleListPosition: article.articleListPosition,
        dataSupplierArticleNumber: article.supplierArticleNo,
        dataSupplierId: article.supplier.id,
        dataSupplierName: article.supplier.name,
        description: article.description,
        erpInformation,
        memo,
        productGroupId: article.productGroup.id,
        productGroupName: article.productGroup.name,
        quantityValue: article.quantity || 1,
        thumbnailUrl: article.thumbnail,
        warehouseId: orderWarehouseInfo?.id,
        warehouseName: orderWarehouseInfo?.name,
        wholesalerArticleNumber: article.traderArticleNo,
    }

    return {
        partToReplaceId,
        workTaskId,
        vehicleId,
        customerId,
        catalogPart,
        foundBySearchTerm,
        fittingSide: article.fittingSide,
        usePercentageValues: true,
    }
}
/**
 * create an ErpInformationLog for tma
 * quantityUnit from ErpInformationLog will be added if the situtation is clear
 * @param article Article from catalog, e.g. which was added to the basket
 * @param erpInfo [Optinal] erp price infos if available
 */
function createErpInformationLog(article: Article, erpInfo?: ErpInformation): ErpInformationLog {
    let prices: PriceDictionary = {}

    if (article.prices) {
        prices = createPriceDictionaryFromArticle(article) || {}
    }

    if (erpInfo?.prices) {
        const erpPrices = createPriceDictionaryFromErpPrices(erpInfo.prices)
        prices = {
            ...prices,
            ...erpPrices,
        }
    }

    return { prices }
}

function createErpInformationLogFromLinkedItem(info: ErpInformation): ErpInformationLog | undefined {
    let prices: PriceDictionary | undefined

    if (info.prices) {
        prices = createPriceDictionaryFromErpPrices(info.prices)
    }

    return prices ? { prices } : undefined
}

function createPriceDictionaryFromArticle(article: Article) {
    const prices: PriceDictionary = {}

    article.prices?.forEach((price) => {
        if (price.priceType) {
            prices[price.priceType] = {
                type: price.priceType,
                currencySymbol: price.currencySymbol || "",
                unit: undefined,
                value: price.value,
                rebate: price.discount,
                currencyCode: price.currencyCode,
            }
        }
    })

    return prices
}

export function createPriceDictionaryFromErpPrices(erpPrices: ErpPrice[]) {
    const prices: PriceDictionary = {}

    erpPrices.forEach((price) => {
        if (price.type) {
            prices[price.type] = {
                currencyCode: price.currencyCode_Iso_4217,
                unit: price.priceUnit,
                type: price.type,
                vat: price.vat,
                currencySymbol: price.currencySymbol,
                value: price.value,
                rebate: price.rebate,
                memos: price.memos,
            }
        }
    })

    return prices
}

export function isPseudoArticle(article: Article): boolean {
    return article.status.some((x) => x.id === 7 || x.id === 700000000011)
}

export function getProductGroupsIdsFromArticles(articles?: Array<Article>) {
    if (!articles) {
        return []
    }

    const productGroupIds: Array<number> = []
    for (let i = 0; i < articles.length; i++) {
        if (articles[i].productGroup?.id && productGroupIds.indexOf(articles[i].productGroup.id) < 0) {
            productGroupIds.push(articles[i].productGroup.id)
        }
    }

    return productGroupIds
}
