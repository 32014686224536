import { Component } from "react"
import { Text, Icon, IconProps } from ".."
import { bindMethodsToContext, elementId } from "../../helper";
import { BaseDisableableProps } from "../../models/SharedModels";

export type StatusOptions = "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal"

export type StatusSwitchProps = BaseDisableableProps & {
    status: StatusOptions
    label?: string
    alignLabel?: "left" | "right"
    onChange?(state: boolean): void
}

export type StatusSwitchState = {
    checked: boolean
    status: StatusOptions
    disabled: boolean
}

export default class StatusSwitch extends Component<StatusSwitchProps, StatusSwitchState> {
    private elementId = elementId(`switch`);

    constructor(props: StatusSwitchProps) {
        super(props);
        this.state = {
            checked: props.status.indexOf("checked") == 0,
            status: props.status,
            disabled: !!props.disabled
        }

        bindMethodsToContext(this)
        this.getClassName = this.getClassName.bind(this)
        this.getSymbolElements = this.getSymbolElements.bind(this)
    }

    static getDerivedStateFromProps(nextProps: StatusSwitchProps, prevState: StatusSwitchState) {
        let newState: StatusSwitchState | null = null
        if (nextProps.status != prevState.status || !!nextProps.disabled != prevState.disabled) {
            newState = {
                checked: nextProps.status.indexOf("checked") == 0,
                status: nextProps.status,
                disabled: !!nextProps.disabled
            }
        }

        return newState
    }

    handleChange(e: any) {
        const checked = !this.state.checked
        this.setState({ checked })
        if (this.props.onChange) {
            this.props.onChange(checked)
        }
    }

    render() {
        const { label, alignLabel, disabled } = this.props
        const { checked } = this.state
        const baseName = "status-switch"
        const className = this.getClassName(baseName)

        const LabelElement = <Text size={"xs"}>{label}</Text>

        const SymbolElements: {
            Back: any,
            Button: any
        } = this.getSymbolElements(baseName);

        return (
            <label htmlFor={this.elementId} className={className}>
                {alignLabel && alignLabel == "left" && LabelElement}

                <input
                    type="checkbox"
                    id={this.elementId}
                    className="input"
                    value={label}
                    disabled={!!disabled}
                    checked={checked}
                    onChange={this.handleChange} />

                <span className={`${baseName}__content`}>
                    {SymbolElements.Back}
                    <div className={`${baseName}__button`}>
                        {SymbolElements.Button}
                    </div>
                </span>

                {(!alignLabel || alignLabel == "right") && LabelElement}
            </label>
        )
    }

    getClassName(baseName: string) {
        const { alignLabel, disabled } = this.props
        let className = `${baseName}`

        if (alignLabel) {
            className += ` ${baseName}--label-${alignLabel}`
        }

        if (disabled) {
            className += ` ${baseName}--disabled`
        }

        return className
    }

    getSymbolElements(baseName: string) {
        const iconProps: IconProps = {
            size: "xs",
            name: "check"
        }

        // initial unchecked values
        let ButtonSymbolElement: any = null
        let BackSymbolElement: any = <Text className={`${baseName}__back`} modifiers={["sub"]} size="s">0</Text>

        const { checked, status } = this.state
        if (checked) {
            BackSymbolElement = <Icon {...iconProps} className={`${baseName}__back`} />
        }

        const notEqual = status.indexOf("not-equal") > 0;
        if (notEqual) {
            ButtonSymbolElement = <Icon {...iconProps} name="close" />
            BackSymbolElement = <Text className={`${baseName}__back`} size="s">&#8800;</Text>

            if (checked) {
                ButtonSymbolElement = <Icon {...iconProps} name="close" />
            }
        }

        return {
            Back: BackSymbolElement,
            Button: ButtonSymbolElement
        }
    }
}
