import { AccordionSummary as MuiAccordionSummary, AccordionSummaryProps, styled, accordionSummaryClasses } from "@mui/material"
import * as React from "react"
import { Icon } from "../Icons"

function RawAccordionSummary(props: AccordionSummaryProps) {
    return <MuiAccordionSummary expandIcon={<Icon name="down" />} {...props} />
}

export const AccordionSummary: React.FC<AccordionSummaryProps> = styled(RawAccordionSummary)(({ theme }) => ({
    flexDirection: "row-reverse",
    paddingLeft: 0,
    paddingRight: 0,
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
}))
