export {}

declare global {
    interface Array<T> {
        first(): T | undefined
        last(): T | undefined
        previous(marked: T): T
        next(marked: T): T
        max(selector: (item: T) => any): any | undefined
        /**
         * @deprecated We had a polyfill for Array.find() for a while now. So please use the native function.
         */
        findFirst(comparator: (item: T) => boolean): T | undefined
        remove(comparator: (item: T) => boolean): number
        orderBy(selector: (item: T) => number | string | boolean | Date, descending?: boolean): Array<T>
        groupBy(selector: (item: T) => number | string): { [group: string]: Array<T> }

        /**
         * Removes duplicates from an array returning a new one.
         * Only works on primitive types like Boolean, Null, Undefined, Number, String and Symbol!
         */
        distinct(): Array<T>

        with(index: number, value: T): T[]
    }
}

Array.prototype.first = function first() {
    return this.length > 0 ? this[0] : undefined
}

Array.prototype.last = function last() {
    return this.length > 0 ? this[this.length - 1] : undefined
}

Array.prototype.max = function max(value: (item: any) => any) {
    if (this.length === 0) {
        return
    }

    let max: any = value(this.first())

    this.forEach((item) => {
        const val = value(item)
        max = val > max ? val : max
    })

    return max
}

Array.prototype.findFirst = function findFirst(comparator: (item: any) => boolean) {
    return this.filter(comparator).first()
}

Array.prototype.remove = function remove(comparator: (item: any) => boolean) {
    let result = 0

    this.forEach((item, i) => {
        if (comparator(item)) {
            this.splice(i, 1)
            result++
        }
    })

    return result
}

Array.prototype.orderBy = function orderBy(selector: (item: any) => number | string | boolean | Date, descending = false) {
    const list = this.map((value, index) => {
        return {
            index,
            value,
            criteria: selector(value),
        }
    })

    list.sort((left, right) => {
        const a = left.criteria
        const b = right.criteria
        let result = left.index - right.index

        if (a !== b) {
            if (a > b || a === undefined) {
                result = 1
            }
            if (a < b || b === undefined) {
                result = -1
            }
        }

        return descending ? -result : result
    })

    return list.map((x) => x.value)
}

Array.prototype.groupBy = function groupBy(selector: (item: any) => number | string) {
    const result: { [group: string]: Array<any> } = {}

    this.forEach((item) => {
        let group = selector(item)
        if (group == null) {
            group = ""
        }

        let list = result[group]
        if (!list) {
            list = []
            result[group] = list
        }

        list.push(item)
    })

    return result
}

Array.prototype.previous = function previous(marked: any) {
    if (!marked) {
        return this.last()
    }

    let idx = 0
    this.forEach((item, i) => (item == marked ? (idx = i) : null))

    if (--idx < 0) {
        idx = 0
    }

    return this[idx]
}

Array.prototype.next = function next(marked: any) {
    if (!marked) {
        return this.first()
    }

    let idx = 0
    this.forEach((item, i) => (item == marked ? (idx = i) : null))

    if (++idx >= this.length) {
        idx = this.length - 1
    }

    return this[idx]
}

Array.prototype.distinct = function distinct() {
    return this.filter((x, idx) => this.indexOf(x) === idx)
}

Array.prototype.with = function withFunc(index: number, value: unknown) {
    if (index < 0) {
        return [...this, value]
    }
    return [...this.slice(0, index), value, ...this.slice(index + 1)]
}
