import { PaperProps, styled } from "@mui/material"
import { FC } from "react"
import { Paper } from "./paper"

export const SelectorPaper: FC<PaperProps> = styled(Paper)(({ theme }) => ({
    position: "absolute",
    zIndex: 3,
    height: "2.3em",
    right: "1em",
    top: "-1em",
    padding: 0,
    paddingLeft: 8,
    borderRadius: theme.radius?.default ?? "3px",
}))
SelectorPaper.defaultProps = { elevation: 8 }
