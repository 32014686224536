import { styled } from "@mui/material"
import { ManufacturerBase } from "@tm/models"
import { Image } from "../../generics/image"
import ManufacturerCard from "./ManufacturerCard"

type Props = {
    onSelect(manufacturer: ManufacturerBase): void
    useTopManufacturers: () => {
        topManufacturers: Array<ManufacturerBase>
    }
    hideManufacturerLogos?: boolean
}

const StyledImage = styled(Image)(() => ({
    display: "block",
    maxWidth: "57px",
    maxHeight: "28px",
}))

export function TopManufacturerCards({ onSelect, useTopManufacturers, hideManufacturerLogos }: Props) {
    const { topManufacturers = [] } = useTopManufacturers()

    if (!topManufacturers.length) {
        return null
    }

    return (
        <>
            {topManufacturers.map((x) => (
                <ManufacturerCard handleClick={() => onSelect(x)} key={x.id}>
                    {hideManufacturerLogos ? (
                        <span>{x.description}</span>
                    ) : (
                        <StyledImage src={x.thumbnail} type="manufacturer" title={x.description} style={{ cursor: "pointer" }} />
                    )}
                </ManufacturerCard>
            ))}
        </>
    )
}
