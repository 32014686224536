import { useCallback, useState } from "react"
import { Box, BoxProps, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ShareOption, ShareType } from "../models"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"

type ShareOptionsProps = {
    shareOptions: ShareOption[]
    onShareOptionSelected: (option: ShareOption) => void
}

const ShareButtonWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isSelected" })<BoxProps & { isSelected?: boolean }>(
    ({ isSelected, theme }) => ({
        ...(isSelected && {
            border: `${theme.colors?.primary} solid 1px`,
            borderRadius: theme.radius?.default,
        }),
    })
)

export function ShareOptions({ shareOptions, onShareOptionSelected }: ShareOptionsProps) {
    const { translateText } = useLocalization()
    const [selectedShareOption, setSelectedShareOption] = useState<ShareType | undefined>()

    const selectShareOption = useCallback((shareOption: ShareOption) => {
        setSelectedShareOption(shareOption.type)
        onShareOptionSelected?.(shareOption)
    }, [])

    return (
        <Box display="flex" gap="1em">
            {shareOptions.map((shareOption) => {
                return (
                    <ShareButtonWrapper key={shareOption.type} isSelected={selectedShareOption === shareOption.type}>
                        <Button
                            variant="outlined"
                            size="extralarge"
                            startIcon={<Icon name={shareOption.icon} color="green" />}
                            onClick={() => selectShareOption(shareOption)}
                            disabled={shareOption.disabled}
                        >
                            {typeof shareOption.title === "number" ? translateText(shareOption.title) : shareOption.title}
                        </Button>
                    </ShareButtonWrapper>
                )
            })}
        </Box>
    )
}
