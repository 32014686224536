import { Box } from "@mui/material"
import { Divider } from "../../../../generics/divider"
import InfoItem from "./InfoItem"

type InfoListProps = {
    items: { label?: string; value?: string }[]
}

export default function InfoList({ items }: InfoListProps) {
    const { length } = items

    return (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
            {items.map((item, index) => {
                return (
                    <Box key={index} display="flex" alignItems="center">
                        <InfoItem item={item} />
                        {index < length - 1 && <Divider sx={{ margin: "0 0.25em" }} orientation="vertical" flexItem />}
                    </Box>
                )
            })}
        </div>
    )
}
