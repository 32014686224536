import { Component } from "react"
import { Tooltip } from ".."
import { bindMethodsToContext } from "../../helper";
import { MoreMenuItem, MenuItem } from "./index.item";

export type { MoreMenuItem }
export type Props = {
    items: Array<MoreMenuItem>
    disabled?: boolean
    onMenuItemSelect(item: MoreMenuItem): void
}

export default class MoreMenu extends Component<Props> {
    private tooltipRef: Tooltip | null = null

    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)
    }

    private handleTooltipRef(ref: Tooltip | null) {
        this.tooltipRef = ref
    }

    private handleItemSelect(item: MoreMenuItem) {
        this.props.onMenuItemSelect(item)
        this.tooltipRef?.hide()
    }

    private renderHeader() {
        return (
            <div className="more-menu__dots">
                <span />
                <span />
                <span />
            </div>
        )
    }

    private renderMenuItem(item: MoreMenuItem, idx: number) {
        return (
            <MenuItem
                key={idx}
                item={item}
                onSelect={this.handleItemSelect}
            />
        )
    }

    private renderMenu() {
        return (
            <ul className="more-menu__menu">
                {this.props.items.map(this.renderMenuItem)}
            </ul>
        )
    }

    render() {
        const { disabled, items } = this.props
        const isDisabled = disabled || !items || items.length === 0
        const className = `more-menu${isDisabled ? " more-menu--disabled" : ""}`

        return (
            <div className={className}>
                <Tooltip
                    ref={this.handleTooltipRef}
                    event="click"
                    style="light"
                    content={this.renderMenu()}
                    disabled={isDisabled}
                >
                    {this.renderHeader()}
                </Tooltip>
            </div>
        )
    }
}
