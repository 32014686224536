import * as React from "react"
import { Size } from "../../models/skins";
import bem from "bem-classnames"

export type InputGroupSizes = Size
export type InputGroupItemAlignment  = "Left"|"Right"|"Center"|"SpaceBetween"

export type InputGroupProps = {
    children: Array<JSX.Element> | JSX.Element
    className?: string
    size?: InputGroupSizes
    itemAlignment?: InputGroupItemAlignment
    /**
    * @param preventBorderModifications use this parameter to force the children to not have any border modifications
    */
    preventBorderModifications?: boolean
    onMouseOver?: (e: React.MouseEvent<HTMLDivElement>) => void
    onMouseOut?: (e: React.MouseEvent<HTMLDivElement>) => void
    onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLDivElement>) => void
}

export default class InputGroup extends React.Component<InputGroupProps, any> {

    private bemStyle: any = {
        group: {
            name: "inputgroup",
            modifiers: ["inputSize", "alignment","with-shadow"]
        },
        children: {
            name: "inputgroup__children"
        }
    }

    modifyChildClassNames = (child: any, index: number) => {
        if(!child) return null
        const props = {
            className: bem(this.bemStyle.children, {}, child.props.className, this.props.preventBorderModifications ? "pseudogroup": ""),
            key: `ig-${index}`
        }

        return React.cloneElement(child, props);
    }

    render() {
        const { children, size, itemAlignment, className, preventBorderModifications, ...handler } = this.props;

        const alignment = itemAlignment || false;

        const inputSize = size ? size : "m";

        return (
            <div {...handler} className={bem(this.bemStyle.group, { inputSize, alignment }, className)}>
                {React.Children.map(children, this.modifyChildClassNames.bind(this))}
            </div>
        );
    }
}
