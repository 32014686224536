import * as React from "react"

import Image from "./image"

export type Props = {
    className?: string
    id: number
    name: string
    imageUrl: string
}

export const BonusSystemImage: React.FC<Props> = props => {

    const [useDefaultTheme, setUseDefaultTheme] = React.useState(false)
    const [trySvgImage, setTrySvgImage] = React.useState(true)
    const [imageError, setImageError] = React.useState(false)

    const imageUrl = React.useMemo(() => {
        let url = props.imageUrl

        if (useDefaultTheme) {
            url = url.replace(/(\/)\d+(\/)/, "$1-1$2")
        }

        if (trySvgImage) {
            url = url.substring(0, url.lastIndexOf(".")) + ".svg"
        }

        return url
    }, [useDefaultTheme, trySvgImage])

    const handleImageLoadError = React.useCallback(() => {
        if (trySvgImage) {
            setTrySvgImage(false)
        }
        else if (!useDefaultTheme) {
            setTrySvgImage(true)
            setUseDefaultTheme(true)
        }
        else {
            setImageError(true)
        }
    }, [useDefaultTheme, trySvgImage])

    if (imageError) return null

    return (
        <div className={props.className}>
            <Image
                url={imageUrl}
                title={props.name}
                onError={handleImageLoadError}
            />
        </div>
    )
}
