import { UIAHtmlAttribut, UITypes } from "../types"

const Modulenavigation: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "modulenavigation",
}

const ModulenavigationButton: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "modulenavigation.button",
}

const ModulenavigationButtonMore: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "modulenavigation.more.button",
}

export const Worktask = {
    Modulenavigation,
    ModulenavigationButton,
    ModulenavigationButtonMore,
}
