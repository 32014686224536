import { useMemo } from "react"

export function useLocalStorage<T>(
    key: string,
    data?: T
): { localStorageData: T | undefined; setLocalStorageData(data: T): void; removeKey(): void } {
    function setLocalStorageData(data: T) {
        data && localStorage.setItem(key, JSON.stringify(data))
    }

    if (!localStorage[key] && data) {
        setLocalStorageData(data)
    }

    const localStorageData: T | undefined = useMemo(() => {
        return localStorage[key] ? JSON.parse(localStorage[key]) : undefined
    }, [localStorage[key]])

    const removeKey = () => {
        localStorage.removeItem(key)
    }

    return { localStorageData, setLocalStorageData, removeKey }
}
