import * as React from "react"
import { Size } from "../../models/skins";
import { bindMethodsToContext } from "../../helper";

export type TextModifiers =
    | "right"
    | "strong"
    | "highlight"
    | "serif"
    | "light"
    | "sub"
    | "block"
    | "strikethrough"
    | "danger"
    | "warning"
    | "success"

export type TextProps = React.HTMLAttributes<HTMLDivElement> & {
    size?: Size,
    className?: string
    modifiers?: TextModifiers | Array<TextModifiers>
    title?: string
}

export default class Text extends React.Component<TextProps, any> {

    constructor(props: TextProps) {
        super(props);
        bindMethodsToContext(this, ["build"])
    }

    render() {
        const { /*title,*/ size, modifiers, className, ...rest } = this.props
        // let childToTitle = typeof(this.props.children) === "string" ? this.props.children : undefined // MLE 12.03.2020 - Removed. Tooltips/Titles should rather be set explicit!
        const props = {
            className: this.buildClassName(),
            // title: title ? title : childToTitle,
            ...rest
        }

        return (
            <div {...props}>
                {this.props.children}
            </div>
        );
    }

    buildClassName() {
        const { modifiers, className, size } = this.props
        const baseName = "text"
        let fullClassName = `${baseName}`

        fullClassName = `${fullClassName} ${fullClassName}--${size || "m"}`

        if (className) {
            fullClassName = `${fullClassName} ${className}`
        }

        if (modifiers) {
            if (typeof (modifiers) == "string") {
                fullClassName = `${fullClassName} ${baseName}--${modifiers as string}`
            } else {
                (modifiers as Array<TextModifiers>).forEach((modifier) => {
                    fullClassName = `${fullClassName} ${baseName}--${modifier}`
                })
            }
        }

        return fullClassName
    }
}
