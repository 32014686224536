import * as React from "react"
import { LocalizationProps } from "@tm/localization" // IMPORTANT!! This is needed because of the props extending from "SelectionListProps" and their dependency to "LocalizationProps"
import { clone } from "@tm/utils"
import { bindMethodsToContext } from "../../helper"
import { SelectionListProps, TextField, SelectionList, SelectionListItemGroup, SelectionListItem } from ".."

export type Props = SelectionListProps & {
    forwardedRef?: React.Ref<SelectionList>
}

export type State = {
    filteredGroups: Array<SelectionListItemGroup>
    filterValue: string
}

export class FilterSelectionList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        bindMethodsToContext(this)

        this.state = {
            filteredGroups: [],
            filterValue: ""
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            filteredGroups: this.getFilteredGroups(this.props, this.state.filterValue),
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState(prevState => {
            return {
                filteredGroups: this.getFilteredGroups(nextProps, prevState.filterValue),
            }
        })
    }

    private getFilteredGroups(props: Props, filterValue: string = "") {
        let filteredGroups: Array<SelectionListItemGroup> = clone(props.groups)

        if (filterValue) {
            filterValue = filterValue.toLowerCase()

            const containsFilter = (value?: string) =>
                value && value.toLowerCase().indexOf(filterValue) != -1

            filteredGroups.forEach(x => {
                x.items = x.items.filter(y =>
                    containsFilter(y.name)
                    || containsFilter(y.description)
                    // || this.isSelected(props, y)
                )
            })

            filteredGroups = filteredGroups.filter(x =>
                containsFilter(x.name)
                || x.items.length
            )
        }

        return filteredGroups
    }

    private isSelected(props: Props, item: SelectionListItem): boolean {
        return props.selected.some(y => y.query == item.query)
    }

    private handleFilterValueChange(filterValue: string) {
        this.setState({
            filterValue,
            filteredGroups: this.getFilteredGroups(this.props, filterValue),
        })
    }

    private renderTextField() {
        return (
            <TextField
                className="filter-selection-list__field"
                value={this.state.filterValue}
                onChange={this.handleFilterValueChange}
                showClear
            />
        )
    }

    private renderSelectionList() {
        let { forwardedRef, groups, className = "", ...rest } = this.props

        className += " filter-selection-list__list"

        return (
            <SelectionList
                {...rest}
                ref={forwardedRef}
                className={className}
                groups={this.state.filteredGroups}
            />
        )
    }

    render() {
        return (
            <div className="filter-selection-list">
                {this.renderTextField()}
                {this.renderSelectionList()}
            </div>
        )
    }
}

export default React.forwardRef((props: Props, ref?: React.Ref<SelectionList>) => {
    return <FilterSelectionList {...props} forwardedRef={ref} />
})
