import * as React from "react"
import { clone, getValue, setValue, getFieldErrors, ButtonKeyDefinition, Overwrite } from "@tm/utils"
import { FormElementState, createErrorMessage, FormElementProps } from "../../models"
import { Icon, Tooltip } from ".."
import { bindMethodsToContext, elementId } from "../../helper"

export type Props = Overwrite<FormElementProps, {
    value?: number | null
    placeholder?: string
    minimum?: number
    maximum?: number
    showClear?: boolean
    selectValueOnFocus?: boolean
    /**
     * set to nullable if you want to allow an empty field
     */
    nullable?: boolean
    onChangeConfirm?(model: any, path?: Array<any>): void
    onChangeReset?(model: any, path?: Array<any>): void
    onKeyDown?(keyevent: React.KeyboardEvent<HTMLInputElement>): void
}>

export type State = FormElementState & {
    parsedValue: number | null
    inputValue?: string
    edit?: boolean
}

/***
 * @class PriceField used for price inputs
 * @todo needs to be refactored to a real price control
 */
export default class PriceField extends React.Component<Props, State> {
    private inputRef: HTMLInputElement | null = null
    private tooltipRef: Tooltip | null = null
    private outerContainerRef: HTMLElement | null

    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)

        let value = this.getPropsValue(this.props)
        let inputValue = value ? value.toString() : ""

        value = !value && !props.nullable ? 0 : value
        inputValue = !value ? "" : this.formatValue(value)


        this.state = {
            id: elementId(),
            parsedValue: value,
            inputValue: inputValue,
            errors: this.getErrors(this.props),
        }

        this.adjustValue = this.adjustValue.bind(this)
        this.getPrecision = this.getPrecision.bind(this)
    }

    formatValue(value: number) {
        const formattedInputValue = this.replaceSeperator(value)
        let inputValue = this.enforceDecimalDigits(formattedInputValue)

        return inputValue
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if ((props.value || props.value == 0) && props.value !== state.parsedValue) {
            const { value } = props

            let inputValue: string = value.toFixed(2).replace(".", ",")
            inputValue = /[\.,]/.test(inputValue) ? inputValue : (inputValue || "0") + ",00"
            inputValue = /[\.,](\d)$/.test(inputValue) ? inputValue + "0" : inputValue

            return {
                parsedValue: value,
                inputValue: inputValue
            }
        }

        // Return null to indicate no change to state.
        return null;
    }

    componentDidMount() {
        this.props.autoFocus && this.focus()
    }

    private getErrors(props: Props): Array<string> | undefined {
        const { modelState, path } = props

        if (modelState && path) {
            return getFieldErrors(modelState, path)
        }
    }

    private getPropsValue(props: Props): number | null {
        const { value, model, path, minimum, maximum, nullable } = props
        const propsValue: number | undefined = model && path ? getValue(model, path) : value

        if (nullable && propsValue == null) return null
        if (propsValue != null) return this.adjustValue(propsValue)
        if (minimum != null) return minimum
        if (maximum != null && maximum < 0) return maximum

        return 0
    }

    adjustValue(value: number) {
        const stepSize = 0.01
        const precision = this.getPrecision(stepSize)
        const fixedValue = parseFloat(value.toFixed(precision))
        const rest = parseFloat((fixedValue % stepSize).toFixed(precision))

        let adjustedValue = fixedValue

        if (rest != 0) {
            if (rest < stepSize / 2) {
                adjustedValue = fixedValue - rest
            } else {
                adjustedValue = fixedValue - rest + stepSize
            }
        }

        adjustedValue = this.adjustMinMaxValue(adjustedValue)

        return adjustedValue
    }

    getPrecision(value: number) {
        const tmpValue = value.toString()
        if (tmpValue.indexOf('.') >= 0) {
            const splittedValue = tmpValue.split('.')
            const valueDecimals = splittedValue.last()
            return (valueDecimals ? valueDecimals.length : 0)
        }

        return 0
    }

    adjustMinMaxValue(value: string | number) {
        const { maximum, minimum } = this.props

        let fittedValue = typeof (value) == "string" ? parseFloat(value) : value

        if (maximum && fittedValue > maximum) {
            fittedValue = maximum
        }

        if (minimum && fittedValue < minimum) {
            fittedValue = minimum
        }

        return fittedValue
    }

    private replaceSeperator(value: number | string | null): string {
        if (value == null) return ""

        return value.toString().replace(".", ",")
    }

    private parseValue(value: string): number | null {
        const number = parseFloat(value)
        if (isNaN(number)) return null

        return number
    }

    private setValueToModel(value: number | null): any {
        const { model, path } = this.props
        const clonedModel = clone(model)

        if (path) {
            setValue(clonedModel, path, value)
        }

        return clonedModel
    }

    private handleInputRef(ref: HTMLInputElement) {
        this.inputRef = ref

        const { onRef } = this.props
        onRef && onRef(ref)
    }

    private handleTooltipRef(ref: Tooltip | null) {
        this.tooltipRef = ref
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.readonly) return

        const match = e.target.value.match(/[-+]?[\d]*[.,]?[\d]{0,2}/g)
        const inputValue: string = match ? match.first() || "" : ""

        const parsedValue = this.handleChangedValue(inputValue)

        const { onChange } = this.props
        onChange && onChange(parsedValue)

        this.tooltipRef && this.tooltipRef.hide()
    }

    private handleChangeConfirm(value: number | null) {
        if (this.props.readonly) return

        const { model, path, onChangeConfirm } = this.props

        if (onChangeConfirm) {
            if (model && path) {
                onChangeConfirm(this.setValueToModel(value), path)
            }
            else {
                onChangeConfirm(value)
            }
        }
        this.handleChangedValue(value)
    }

    private handleChangeReset(value: number | null) {
        if (this.props.readonly) return

        const { model, path, onChangeReset } = this.props

        if (onChangeReset) {
            if (model && path) {
                onChangeReset(model, path)
            }
            else {
                onChangeReset(value)
            }
        }
    }

    private handleFocus(e: React.FocusEvent<HTMLInputElement>) {
        if (this.props.readonly) return

        this.setState({ edit: true })

        const { onFocus, selectValueOnFocus } = this.props
        onFocus && onFocus()

        if (selectValueOnFocus) {
            e.currentTarget.select()
        }
    }

    private handleBlur() {
        const { inputValue, parsedValue } = this.state

        const returnValue = this.handleChangedValue(inputValue)

        this.handleChangeConfirm(returnValue)
    }

    private handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (this.props.readonly) return

        switch (e.key) {
            case ButtonKeyDefinition.Tab: {
                this.handleBlur()
                this.tooltipRef && this.tooltipRef.hide()
                break
            }
            case ButtonKeyDefinition.Escape: {
                const parsedValue = this.getPropsValue(this.props)

                this.setState({
                    parsedValue,
                    inputValue: this.replaceSeperator(parsedValue),
                })

                this.handleChangeReset(parsedValue)
                this.tooltipRef && this.tooltipRef.hide()

                break
            }
            case ButtonKeyDefinition.Enter: {
                this.handleBlur()
                this.tooltipRef && this.tooltipRef.hide()
                break
            }
        }

        const { onKeyDown } = this.props
        onKeyDown && onKeyDown(e)
    }

    private handleChangedValue = (value?: string | number | null) => {
        if ((!value && value != 0) && this.props.nullable) {
            this.setState({
                parsedValue: null,
                inputValue: ""
            })

            return null
        }

        if (typeof (value) == "string") {
            let parsedValue = null
            let inputValue = ""
            if (value == "" && this.props.nullable) {
                parsedValue == null
                inputValue == ""
            } else {
                parsedValue = this.parseValue(value.replace(",", "."))
                parsedValue = parsedValue != null ? this.adjustValue(parsedValue) : this.state.parsedValue
                inputValue = this.replaceSeperator(value)
            }

            this.setState({
                parsedValue,
                inputValue
            })

            return parsedValue
        } else {
            let inputValue = this.replaceSeperator(value || 0)
            inputValue = this.enforceDecimalDigits(inputValue)

            this.setState({
                parsedValue: value || 0,
                inputValue: inputValue
            })

            return value || null
        }
    }

    private handleClear(ev?: React.SyntheticEvent<HTMLButtonElement>) {
        ev && ev.preventDefault()
        const e = {
            target: {
                value: ""
            }
        }

        this.handleChange(e as React.ChangeEvent<HTMLInputElement>)
    }

    focus() {
        this.tooltipRef && this.tooltipRef.show()

        if (this.inputRef) {
            // reset the value so the value will be changed by the setState after focus
            // and the cursor will be at the end of input
            const value = this.inputRef.value
            this.inputRef.value = ""
            this.inputRef.value = value
            setTimeout(() => {
                this.inputRef && this.inputRef.focus()
            }, 0)
        }
    }

    private renderContent() {
        const { readonly, showClear, disabled, placeholder, label } = this.props
        const { inputValue, id, errors } = this.state

        const labelElement = label ? <label className="input__label" htmlFor={id}>{label}</label> : false
        const tabIndex = readonly ? 0 : this.props.tabIndex

        return (
            <div className="input__inner" >
                {labelElement}
                <input
                    className="input__field"
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    ref={this.handleInputRef}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    readOnly={!!readonly}
                    tabIndex={tabIndex}
                    disabled={!!disabled}
                    id={id}
                />

                <div className="input__icons">
                    {
                        showClear && !readonly &&
                        <button className="btn btn--ghost" onClick={this.handleClear}><Icon name="close" /></button>
                    }
                </div>
                {!!errors && !!errors.length && this.outerContainerRef ? createErrorMessage(errors, this.outerContainerRef, "bottom") : null}
            </div>
        )
    }

    enforceDecimalDigits = (inputValue: number | string) => {
        const stringValue = typeof (inputValue) != "string" ? inputValue.toString() : inputValue
        let value = this.attachDoubleZero(stringValue)
        return /[\.,](\d)$/.test(value) ? value + "0" : value
    }

    attachDoubleZero(value: string) {
        return /[\.,]/.test(value) ? value : value + ",00"
    }

    render() {
        const { readonly, floatingLabel } = this.props
        const { errors, edit, value } = this.state

        let className = "input input--textfield input--numberfield "
        className += floatingLabel ? "input--floating-label " : ""
        className += readonly ? "readonly " : ""
        className += edit ? "is-active " : ""
        className += value != "" ? "has-value " : ""
        className += !!errors && !!errors.length ? "has-error " : ""
        className += this.props.className || ""

        const { layout } = this.props

        layout && layout.forEach(element => {
            if (element == "dropshadow") {
                className += ` has-${element}`
            } else {
                className += ` input--${element}`
            }
        })

        return (
            <div className={className} ref={this.handleOuterRef}>
                {this.renderContent()}
            </div>
        )
    }

    handleOuterRef = (ref: HTMLElement | null) => {
        this.outerContainerRef = ref
    }
}
