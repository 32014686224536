import { MileType } from "@tm/models"

export function formatMileage(mileage: string, mileType?: MileType) {
    switch (mileType) {
        case MileType.Mile:
            return `${mileage} mi.`
        case MileType.Kilometer:
        default:
            return `${mileage} km.`
    }
}

export function formatVin(vin: string) {
    return vin.toUpperCase().replace(/I/g, "1").replace(/[OQ]/g, "0")
}
