import { MessageBus, Channel } from "@tm/hermes"
import { NextChannels } from "./channels"
export * from "./helper"
export type { VehicleImportData, SetVehicleTecDocNumberRequest } from "./messages/vehicle"
export * from "./messages/settings"
export * from "./channels"

let mb: MessageBus<NextChannels>

/**
 * @memberof Models
 * @deprecated don't use hermes
 * @param type
 * @param id
 * @returns Channel<NextChannels, C>
 */
export function channel<C extends keyof NextChannels>(type: C, id?: string): Channel<NextChannels, C> {
    if (!mb) {
        mb = new MessageBus<NextChannels>()
        if (typeof window != "undefined") {
            (window as any)["__NEXT_MESSAGEBUS__"] = mb
        }
    }
    return mb.channel(type, id)
}

