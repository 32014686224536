import { createContext } from "react"
import { ILocalizationContext } from ".";

const na = "Not available"

export const LocalizationContext = createContext<ILocalizationContext>({
    language: na,
    languageId: na,
    currency: () => na,
    date: () => na,
    number: () => na,
    translate: () => na,
    translateText: () => na,
    changeConfig: () => na,
    changeLocale: () => na,
    isTranslationId: () => false,
    availableLanguages: {}
})