import { useLocalization } from "@tm/localization"
import { styled, TypographyProps } from "@mui/material"
import { Typography } from "../../../generics/typographie"
import { Icon } from "../../../generics/Icons"

type SharingLinkProps = TypographyProps & {
    link: string | undefined
    errorMessage?: string
}

const SharingLink = styled(({ link, errorMessage, ref /** extract 'ref' here to not pass it to the typography */, ...rest }: SharingLinkProps) => {
    const { translate } = useLocalization()

    return (
        <>
            {!link && !!errorMessage && <Icon name="alert_W_warning" color="error" />}
            <Typography {...rest}>{link ?? errorMessage ?? translate(13300)}</Typography>
        </>
    )
})(({ theme, link, errorMessage }) => ({
    userSelect: link ? "all" : undefined,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    opacity: !link && !errorMessage ? theme.opacity?.disabled : undefined,
})) as React.ElementType<SharingLinkProps>

export { SharingLink }
