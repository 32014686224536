import * as React from "react"
import { Omit } from "@tm/utils"
import { ILocalizationContext, IWithChangeConfig, IWithLocalization } from "./models"
import { LocalizationContext } from "./LocalizationContext"

export type ChangeLocalizationProps = {
    localization: IWithLocalization & IWithChangeConfig
}

export function withChangeLocalization<P extends ChangeLocalizationProps>(component: React.ComponentType<P>): React.ComponentClass<Omit<P, keyof ChangeLocalizationProps>> {
    return class extends React.Component<Omit<P, keyof ChangeLocalizationProps>> {
        static displayName = `withChangeLocalization(${component.displayName || component.name})`

        renderInnerComponent(localizationContext?: ILocalizationContext) {
            if (!localizationContext) {
                throw ("No localization initialized. Make sure your app is wrapped by the <Localized ... /> component.")
            }

            const currentProps: any = this.props || {}

            const props: ChangeLocalizationProps & P = {
                ...currentProps,
                localization: {
                    language: localizationContext.language,
                    languageId: localizationContext.languageId,
                    date: localizationContext.date,
                    number: localizationContext.number,
                    currency: localizationContext.currency,
                    translate: localizationContext.translate,
                    translateText: localizationContext.translateText,
                    isTranslationId: localizationContext.isTranslationId,
                    changeConfig: localizationContext.changeConfig,
                    changeLocale: localizationContext.changeLocale,
                    availableLanguages: localizationContext.availableLanguages,
                }
            }

            return React.createElement(component, props)
        }

        render() {
            return (
                <LocalizationContext.Consumer>
                    {(localizationContext?: ILocalizationContext) => this.renderInnerComponent(localizationContext)}
                </LocalizationContext.Consumer>
            )
        }
    }
}
