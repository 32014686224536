export type DemoProps = {
    className?: string
    displayMode?: "flat" | "edge"
}

export default function Demo({ displayMode, className }: DemoProps) {
    if (displayMode == "edge") {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" className={className}>
                <path
                    fill="#F3AE21"
                    d="M19.5 19.5L0 0h16.5c1.657 0 3 1.343 3 3v16.5z"
                    transform="translate(-1127 -333) translate(441 243) translate(560 90) translate(126) translate(4.5)"
                />
                <path
                    fill="#FAFAFA"
                    d="M16.679 8.518c.398-.075.76.038 1.074.341.312.3.436.656.371 1.058-.064.389-.305.804-.718 1.232-.413.428-.82.684-1.21.76-.081.016-.162.024-.24.024-.306 0-.584-.12-.831-.358-.312-.302-.438-.659-.374-1.061.062-.39.305-.807.721-1.239.414-.429.82-.683 1.207-.757zm.554.88c-.117-.113-.264-.135-.448-.066-.204.075-.443.253-.71.53-.267.276-.437.522-.506.729-.062.186-.036.33.08.442.073.07.155.105.25.105.235-.003.535-.197.9-.575.367-.379.55-.687.546-.915-.001-.097-.039-.179-.112-.25zm-3.355-4.205l.833.805-1.329 2.15 2.1-1.407.844.816-2.554 2.644-.57-.551 1.53-1.584-1.884 1.243-.612-.591 1.169-1.914-1.521 1.574-.56-.541 2.554-2.644zm-1.679-1.621l1.261 1.217-.513.532-.667-.644-.456.474.618.597-.515.532-.618-.597-.552.572.667.643-.518.537-1.26-1.22 2.553-2.643zM10.054 1.5l.736.711c.313.302.446.658.396 1.06-.048.391-.27.793-.658 1.195-.413.43-.826.677-1.225.735-.063.01-.125.014-.187.014-.337 0-.65-.139-.936-.414l-.68-.657L10.054 1.5zm.081 1.106L8.612 4.183l.089.086c.148.143.312.186.5.13.203-.06.441-.23.708-.504.26-.27.419-.508.473-.707.05-.184.01-.336-.122-.462l-.125-.12z"
                    transform="translate(-1127 -333) translate(441 243) translate(560 90) translate(126) translate(4.5)"
                />
            </svg>
        )
    } else {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" className={className}>
                <g transform="translate(-590 -633) translate(22 633) translate(568) translate(2 9.438)">
                    <path
                        fill="#F3AE21"
                        fillOpacity=".87"
                        d="M26.76 0H.847C.379 0 0 .374 0 .835V12.29c0 .46.379.835.847.835H26.76c.467 0 .847-.375.847-.835V.835c0-.461-.38-.835-.847-.835" />
                    <path
                        fill="#FAFAFA"
                        fillOpacity=".87"
                        d="M22.65 3.563c.562.012 1.011.209 1.335.584.317.37.49.876.512 1.507.006.234-.007.46-.037.668l-.134.902c-.124.834-.412 1.496-.855 1.966-.438.465-.995.701-1.657.701h-.058c-.555-.012-1.002-.199-1.331-.555-.324-.35-.504-.842-.532-1.457-.011-.21-.003-.425.026-.64l.15-.982c.08-.538.24-1.022.473-1.439.237-.424.538-.745.896-.953.358-.209.768-.306 1.213-.302zm-18.583.08l1.568.004c.46.013.852.135 1.168.362.316.23.546.55.684.955.135.396.172.843.11 1.327l-.13.864c-.123.82-.426 1.473-.9 1.94-.478.472-1.086.711-1.809.715H3l1.067-6.166zm8.762.001l-.223 1.267h-2.115l-.195 1.114h1.8l-.218 1.237h-1.796l-.223 1.285h2.128l-.222 1.263H8.2l1.067-6.166h3.562zm2.784 0l.31 3.805 1.624-3.805h1.784L18.26 9.81h-1.445l.31-1.792.234-1.066-1.302 2.858h-1.003l-.334-3.075-.185 1.401-.288 1.674h-1.44l1.066-6.166h1.74zm6.941 1.185h-.04c-.26 0-.465.102-.628.314-.182.237-.312.623-.388 1.147l-.14.99c-.032.255-.044.458-.036.6.015.65.312.738.535.748.263.004.475-.086.645-.29.18-.217.308-.585.382-1.097l.141-.998c.032-.27.047-.479.047-.625-.015-.69-.303-.78-.518-.789zM5.29 4.912l-.632 3.639.128.003c.362 0 .634-.099.83-.306.2-.212.338-.565.409-1.052l.137-.953c.037-.3.05-.518.043-.653-.012-.236-.068-.407-.167-.511-.099-.103-.249-.157-.456-.163l-.292-.004z" />
                </g>
            </svg>
        )
    }
}