import { lighten, MenuItem, MenuItemProps, styled } from "@mui/material"
import { FC, ReactNode } from "react"

export interface SelectItemProps extends MenuItemProps {
    disabled?: boolean
    value: any
    key: string
    children: ReactNode | ReactNode[]
}

export const SelectItem: FC<SelectItemProps> = (props) => {
    const { children } = props
    return <MenuItem {...props}>{children}</MenuItem>
}

const WidgetMenuItem = styled(SelectItem)(({ theme }) => ({
    color: theme.palette.common.black,
    opacity: 1,
    justifyContent: theme.overwrites?.components?.dropdown?.menu?.justifyContent || "start",
    "&.Mui-selected": {
        color:
            theme.overwrites?.components?.dropdown?.item?.text?.selected || theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.5)),
        backgroundColor: theme.overwrites?.components?.dropdown?.item?.background?.selected || lighten(theme.palette.primary.main, 0.5),
        "&:hover": {
            backgroundColor: theme.overwrites?.components?.dropdown?.item?.background?.hover || lighten(theme.palette.primary.main, 0.5),
        },
    },
    "&:hover": {
        backgroundColor: theme.overwrites?.components?.dropdown?.item?.background?.hover || lighten(theme.palette.primary.main, 0.5),
        color: theme.overwrites?.components?.dropdown?.item?.text?.hover || theme.palette.getContrastText(lighten(theme.palette.primary.main, 0.5)),
    },
}))

export const MenuItemWidget: FC<SelectItemProps> = (props) => {
    const { children } = props
    return <WidgetMenuItem {...props}>{children}</WidgetMenuItem>
}
