let _weekdays: Array<string> | undefined
let _months: Array<string> | undefined

/**
 * This `RegExp` can match a `string` of the ISO 8601 format.
 * @see parseISODate @link https://en.wikipedia.org/wiki/ISO_8601
 */
export const ISO_DATE_REGEX =
    /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)?$/

/**
 * Parse a `string` of the ISO 8601 format to a JS `Date` object.
 * @see ISO_DATE_REGEX @link https://en.wikipedia.org/wiki/ISO_8601
 */
export function parseISODate(isoString: string): Date | null {
    return ISO_DATE_REGEX.test(isoString) ? new Date(isoString) : null
}

export function initDateHelper(weekdays: Array<string>, months: Array<string>) {
    if (weekdays && weekdays.length == 7) {
        _weekdays = weekdays
    } else {
        console.error("Passed in weekdays Array has the wrong format")
    }

    if (months && months.length == 12) {
        _months = months
    } else {
        console.error("Passed in months Array has the wrong format")
    }
}

export function formatConstructionYear(constructionYear: number): string {
    if (!constructionYear) {
        return ""
    }
    let result = constructionYear.toString()
    result = `${result.substr(4, 2)}/${result.substr(0, 4)}`
    return result
}

export function dateFromString(date: string, format: string): Date | null {
    if (!date) {
        return null
    }

    if (format.length == 1) {
        const temp = getShortCodeFormat(format)
        if (temp) {
            format = temp
        } else {
            return null
        }
    }

    const result = new Date()

    result.setSeconds(0)
    result.setMinutes(0)
    result.setHours(0)
    result.setDate(1)
    result.setMonth(0)
    result.setFullYear(0)

    let startIndex = 0
    let length = 0
    let ctrlChar = ""

    for (let i = 0; i <= format.length; i++) {
        const char = format.charAt(i)

        if (ctrlChar == char) {
            length++
            continue
        }

        switch (ctrlChar) {
            case "d":
                result.setDate(parseInt(date.substr(startIndex, length)))
                break
            case "H":
                result.setHours(parseInt(date.substr(startIndex, length)))
                break
            case "m":
                result.setMinutes(parseInt(date.substr(startIndex, length)))
                break
            case "M":
                result.setMonth(parseInt(date.substr(startIndex, length)) - 1)
                break
            case "s":
                result.setSeconds(parseInt(date.substr(startIndex, length)))
                break
            case "y":
                result.setFullYear(parseInt(date.substr(startIndex, length)))
                break
        }

        ctrlChar = ""

        if ("dHmMsy".indexOf(char) >= 0) {
            ctrlChar = char
            startIndex += length
            length = 0
        }

        length++
    }

    return result
}

/**
 * returns a formated string of the given date/time using a predefined format.
 * @param {Date} date
 * @param {string} [format]
 *   d: Kurzes Datumsmuster
 *   D: Langes Datumsmuster
 *   t: Kurzes Zeitmuster
 *   T: Langes Zeitmuster
 *   g: Allgemeines Datums-/Zeitmuster (kurze Zeit)
 *   f: Vollständiges Datums-/Zeitmuster (kurze Zeit)
 *   Y: Monat-Jahr-Muster (Monat als Wort)
 *   y: Monat-Jahr-Muster (Monat als Zahl)
 * @returns {string}
 */
export function formatDate(date: Date, format: string, utc?: boolean): string {
    if (!date) {
        return ""
    }

    if (!_weekdays || !_months) {
        console.error("DateHelper was not initialized")
        return ""
    }

    if (format.length == 1) {
        const temp = getShortCodeFormat(format)
        if (temp) {
            format = temp
        } else {
            return ""
        }
    }

    let sec
    let min
    let hrs
    let day
    let wdy
    let mth
    let yea

    if (utc == true) {
        sec = date.getUTCSeconds()
        min = date.getUTCMinutes()
        hrs = date.getUTCHours()
        day = date.getUTCDate()
        wdy = date.getUTCDay()
        mth = date.getUTCMonth() + 1
        yea = date.getUTCFullYear()
    } else {
        sec = date.getSeconds()
        min = date.getMinutes()
        hrs = date.getHours()
        day = date.getDate()
        wdy = date.getDay()
        mth = date.getMonth() + 1
        yea = date.getFullYear()
    }

    let result = ""
    let ctrlChar = ""
    let ctrlCount = 0

    for (let i = 0; i <= format.length; i++) {
        const char = format.charAt(i) || ""

        if (ctrlChar == char) {
            ctrlCount++
            continue
        }

        switch (ctrlChar) {
            case "d":
                switch (ctrlCount) {
                    case 1:
                        result += day
                        break
                    case 2:
                        result += day.pad(2)
                        break
                    case 3:
                        result += _weekdays[wdy].substr(0, 2)
                        break
                    default:
                        result += _weekdays[wdy]
                }
                break
            case "h":
                switch (ctrlCount) {
                    case 1:
                        result += hrs > 12 ? hrs - 12 : hrs
                        break
                    default:
                        result += (hrs > 12 ? hrs - 12 : hrs).pad(2)
                }
                break
            case "H":
                result += hrs.pad(ctrlCount)
                break
            case "m":
                result += min.pad(ctrlCount)
                break
            case "M":
                switch (ctrlCount) {
                    case 1:
                        result += mth
                        break
                    case 2:
                        result += mth.pad(2)
                        break
                    case 3:
                        result += _months[mth - 1].substr(0, 3)
                        break
                    default:
                        result += _months[mth - 1]
                }
                break
            case "s":
                result += sec.pad(ctrlCount)
                break
            case "y":
                result += yea.pad(4).substr(4 - ctrlCount)
                break
            case "t":
                result += (hrs < 12 ? "AM" : "PM").substr(0, ctrlCount)
                break
            case "w":
                result += calendarWeek(date).pad(ctrlCount)
                break
        }

        ctrlChar = ""
        ctrlCount = 0

        if ("dhHmMsytw".indexOf(char) >= 0) {
            ctrlChar = char
            ctrlCount++
        } else {
            result += char
        }
    }

    return result
}

function calendarWeek(date: Date): number {
    const thursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000)
    const cwYear = thursday.getFullYear()
    const thursdayCw = new Date(new Date(cwYear, 0, 4).getTime() + (3 - ((new Date(cwYear, 0, 4).getDay() + 6) % 7)) * 86400000)
    const result = Math.floor(1.5 + (thursday.getTime() - thursdayCw.getTime()) / 86400000 / 7)
    return result
}

function getShortCodeFormat(shortCode: string) {
    switch (shortCode) {
        case "d":
            return "dd.MM.yyyy"
        case "D":
            return "dddd, dd. MMMM yyyy"
        case "t":
            return "HH:mm"
        case "T":
            return "HH:mm:ss"
        case "g":
            return "dd.MM.yyyy HH:mm"
        case "f":
            return "dddd, dd. MMMM yyyy HH:mm"
        case "Y":
            return "MMMM yyyy"
        case "y":
            return "MM.yyyy"
    }
}

export function isSameDay(date1: Date, date2: Date) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
}

/**
 * @description
 * I parse a simple timespan string (ex, "12h,30m,17s" or "12h30m17s") into a number of MILLISECONDS.
 * Supports the following units (must be lowercase):
   --
     * d = days
     * h = hours
     * m = minutes
     * s = seconds
   --
 * Input may contain other delimiters, as desired, in order to make the input
 * more readable by the developers.
 * @copyright https://www.bennadel.com/blog/3583-code-kata-parsing-simple-timespan-strings-like-3h-12m-57s-using-javascript.htm  (modified, returning ms instead of s)
 * @returns MILLISECONDS or 0 on failure (changed from seconds to milliseconds)
 * */
export function parseTimespan(input: string) {
    // CAUTION: On its own, this pattern does not require the input to only
    // contain tokens that can be matched by this pattern. Depending on how hard
    // you squint, this can be a good or bad thing.
    const pattern = /(\d+)(d|h|m|s)/g
    const multiplier: { [key: string]: number } = {
        d: 86400,
        h: 3600,
        m: 60,
        s: 1,
    }

    let timespan = 0
    let match

    // Apply each matched magnitude-unit combination to the running total.
    try {
        match = pattern.exec(input)

        while (match) {
            const magnitude: any = match[1]
            const unit = match[2]
            timespan += magnitude * multiplier[unit]
            match = pattern.exec(input)
        }
    } catch (e) {
        console.error("whitelist ttl option could not be parsed", input)
        timespan = 0
    }

    return timespan * 1000
}
