import { AvailabilityStatus } from "@tm/models"
import { TmaEvent, TrackingModel } from "../../models/models"
import { TmaSender } from "../tma-sender"
import { ArticlePDFClick } from "./models"

export class ArticlePDFClickEventHandler extends TmaSender<ArticlePDFClick> implements TmaEvent {
    key = "ARTICLE_PDFCALL"

    static serviceEndpoint = "/articles/pdfClick"

    constructor() {
        super(ArticlePDFClickEventHandler.serviceEndpoint)

        this.requestBody = {}
    }

    handle = (trackingData: TrackingModel<unknown>): boolean => {

        if (!trackingData.tmaEvent || trackingData.tmaEvent !== this.key || !trackingData.payload) {
            return false
        }

        if (!this.requestBody) {
            this.requestBody = {}
        }

        if (trackingData.bundle == "parts") {
            if (Array.isArray(trackingData.action)) {
                trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
            } else {
                this.fillRequest(trackingData, trackingData.action)
            }
            return true
        }

        return false
    }

    fillRequest = (trackingData: TrackingModel<any>, action: string) => {
        const { article, quantityModifiers, vehicleId } = trackingData.payload

        if (action === "article-PDF-click") {
            if (article) {
                this.requestBody.catalogPart = {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupIds: [article.productGroup.id],
                    wholesalerArticleNumber: article.traderArticleNo,
                    availabilityState: {
                        id:
                            (quantityModifiers?.availability &&
                                quantityModifiers.availability !== AvailabilityStatus.Unknown &&
                                AvailabilityStatus[quantityModifiers.availability]) ||
                            "",
                        description: quantityModifiers?.availability?.toString() || "",
                    },
                }
            }

            if (vehicleId) {
                this.requestBody.vehicleId = vehicleId
            }

            this.sendEvent()
        }
    }
}
