import { Component } from "react"
import Icon from "../icon";
import Image from "../image"

export const WidgetTitleText = (props: any) => (
    <div className={`text ${props.className || ""}`}>
        {props.children}
    </div>
)

export const WidgetOptions = (props: any) => (
    <div className={`widget__options ${props.className || ""}`}>
        {props.children}
    </div>
)

export function WidgetIcon(props: any) {
    const { iconName } = props
    return iconName && <Icon name={iconName} size={props.size} className={props.className} />
}

export function WidgetImage(props: any) {
    const { imageUrl } = props
    return imageUrl && <Image className={props.className} url={imageUrl} />
}

export class WidgetTitle extends Component<any> {
    public static Text = WidgetTitleText
    public static Options = WidgetOptions

    render() {
        const { className } = this.props

        return (
            <div className={`widget__title ${className || ""}`}>
                {this.props.children}
            </div>
        )
    }
}

export class WidgetHeader extends Component<any> {
    public static Title = WidgetTitle
    public static Icon = WidgetIcon

    render() {
        const { className } = this.props

        return (
            <div className={`widget__header ${className || ""}`}>
                {this.props.children}
            </div>
        )
    }
}

export const WidgetContent = (props: any) => (
    <div className={`widget__content ${props.className || ""}`}>
        {props.children}
    </div>
)

export const WidgetFooter = (props: any) => (
    <div className={`widget__footer ${props.className || ""}`}>
        {props.children}
    </div>
)

export const WidgetCover = (props: any) => (
    <div className="widget__cover">
        {props.children}
    </div>
)
