import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { styled } from "@mui/material"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"

const PartsListButtonComponent = styled(Button)({
    width: 90,
    "&.MuiButton-bordered": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
})

PartsListButtonComponent.defaultProps = {
    color: "highlight",
    variant: "bordered",
    startIcon: <Icon name="partslist" />,
}

export function PartsListButton() {
    const { translateText } = useLocalization()
    const handleClickDetails = useDefaultArticleItemState((x) => x.handleClickDetails)

    const handleClick = useCallback(() => handleClickDetails("partslist"), [handleClickDetails])

    return (
        <Tooltip title={translateText(1525)}>
            <PartsListButtonComponent onClick={handleClick} />
        </Tooltip>
    )
}
