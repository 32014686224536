import { useMemo } from "react"
import { useUser } from "../user/consumer/useUser"
import { ErpSettings } from "../user/model/UserSettings"

type ErpCredentials =
    | {
          showErpSettings: boolean
          erpModules: { [key: string]: ErpSettings }
      }
    | undefined

/**
 * @memberof Context-Distribution
 */
export function useErpCredentials(): ErpCredentials {
    const { userContext } = useUser()
    const externalErpModules = userContext.externalModules?.filter((x) => x.accessDataFromCatalogue === true)
    return useMemo(() => {
        if (externalErpModules?.length) {
            const erpModules: { [key: string]: ErpSettings } = {}
            externalErpModules.forEach((element) => {
                erpModules[element.id] = {
                    userName: element?.username,
                    customerNumber: element?.customerNo,
                    isPasswordSet: element?.isPasswordSet,
                    isCustomerNoRequired: element?.isCustomerNoRequired,
                }
            })
    
            return { erpModules, showErpSettings: true }
        }
    }, [externalErpModules])
}
