import { memo } from "react"
import { fixPrecisionSymbol } from "@tm/utils";
import { DecimalAmountItem } from "./decimal-amount-field";

export const DecimalAmountFieldItem = memo<DecimalAmountItem>((props) => {
    const { value, unit } = props
    const fixedSeperator = fixPrecisionSymbol(value.toString())

    return (
        <div className="amount-field__item">
            {fixedSeperator} {!!unit && unit}
        </div>
    )
})
