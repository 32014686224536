import { ArticleListFilteredEvent } from "./event-handle"
import { EventSubmitDelay } from "./event-submit-delay"

export * from "./event-helper"

export type ArticleListFilteredTrackEvent = {
    action: string // "parts-search"
    bundle: string // "parts"
    component: string // "tree"
    initiateProcess: boolean // true
    payload: any // (4) [{…}, {…}, {…}, {…}]
    source: string // "searchtree"
    step: number // 1
    tmaEvent: string // "ARTICLE_LIST_FILTERED"
    type: string // "search"
}

export default new ArticleListFilteredEvent(new EventSubmitDelay())
