import { UIAHtmlAttribut, UITypes } from "../types"

const Tabs: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "tab",
}

export const TabsControls = {
    Tabs,
}
