import { FormControlLabel as MuiFormControlLabel, FormControlLabelProps, styled } from "@mui/material"

const StyledFormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
    ":hover": {
        ".MuiFormControlLabel-label": {
            color: theme.palette.primary.main,
        },
    },
}))

export const FormControlLabel = (props: FormControlLabelProps) => {
    return <StyledFormControlLabel {...props} />
}
