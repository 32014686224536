import { ChangeEvent, useCallback, useState } from "react"
import { Autocomplete, InputAdornment, AutocompleteRenderInputParams, styled, outlinedInputClasses, formControlClasses } from "@mui/material"
import { TextField } from "../../generics/textfield"

const StyledAutoComplete = styled(Autocomplete)({
    [`.${formControlClasses.root}`]: {
        margin: 0,
    },
    [`.${outlinedInputClasses.root}`]: {
        height: 48,
        paddingRight: "16px !important",
    },
    [`.${outlinedInputClasses.input}`]: {
        padding: "0 !important",
    },
})

type VinFieldProps = {
    vin: string
    onChange(vin: string): void
    loadSuggests(): Promise<string[]>
    className?: string
    label?: string
    error?: boolean
}

function VinFieldComponent(props: VinFieldProps): JSX.Element {
    const { vin, onChange, loadSuggests, className, label, error } = props
    const [suggests, setSuggests] = useState<string[] | null>(null)
    const [suggestsLoading, setSuggestsLoading] = useState(false)

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const val = event.target.value.toUpperCase()
            const test = /^[A-Z0-9]{1,17}$/.test(val) || !val.length
            if (!test) {
                return
            }
            onChange(val)
        },
        [onChange]
    )

    const handleFocus = useCallback(async () => {
        if (suggestsLoading || suggests) {
            return
        }
        setSuggestsLoading(true)
        const response = await loadSuggests()
        setSuggestsLoading(false)
        setSuggests(response)
    }, [loadSuggests, suggests, suggestsLoading])

    const renderInput = useCallback(
        (params: AutocompleteRenderInputParams) => {
            return (
                <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    error={error}
                    label={label}
                    inputProps={{ maxLength: 17 }}
                    inputCounter
                    value={vin}
                    onChange={handleChange}
                />
            )
        },
        [error, label, vin]
    )

    return (
        <StyledAutoComplete
            className={className}
            freeSolo
            clearIcon={false}
            forcePopupIcon={false}
            options={suggests || []}
            value={vin}
            loading={suggestsLoading}
            onFocus={handleFocus}
            renderInput={renderInput}
        />
    )
}

export const VinField = VinFieldComponent
