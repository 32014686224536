import { Box, Stack, styled, Tooltip } from "@mui/material"
import { AvailabilityStatus } from "@tm/models"
import { Typography } from "../../generics/typographie"
import { AvailabilitySymbol } from "./AvailabilitySymbol"
import * as ColorMapping from "./color-mapping"

export type DefaultAvailabilityProps = {
    variant?: "IconOnly" | "full"
    status: AvailabilityStatus
    imageUrl?: string
    iconName?: string
    description?: string
    mapping?: string
    tourDate?: string
    tourName?: string
    onClick?(): void
}

export type IndicatorPosition = 1 | 2 | 3 | 4

const Description = styled(Typography)({})
Description.defaultProps = {
    variant: "label",
    maxLines: 1,
    lineHeight: "16px", // TODO: Currently required because of lineHeight being set to 0 in parent: https://tfsados/ados/DVSE/Next%20React%20Ui/_git/Frontend.Monorepo?path=%2Fbundles%2Ferp%2Fsrc%2F_shared%2Favailability-wrapper.tsx&version=GBmaster&line=38&lineStyle=plain&lineEnd=38&lineStartColumn=5&lineEndColumn=19
}

const Wrapper = styled(Box)(({ theme, onClick }) => {
    return {
        width: "100px",
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
        ...(onClick
            ? {
                  cursor: "pointer",
                  [`${AvailabilitySymbol}, ${Description}`]: {
                      transition: theme.animation?.transition,
                  },
                  "&:hover": {
                      [`${AvailabilitySymbol}, ${Description}`]: {
                          opacity: 1,
                          color: theme.palette.primary.main,
                          fill: "currentColor",
                      },
                  },
              }
            : {}),
    }
})

const Indicators = styled(Stack)({
    height: "12px",
})

Indicators.defaultProps = {
    direction: "row",
    spacing: 0.25,
}

const Indicator = styled("span")<{ mapping: string; position: IndicatorPosition; status: AvailabilityStatus }>(
    ({ theme, mapping, position, status }) => ({
        flex: 1,
        boxSizing: "border-box",
        backgroundColor: ColorMapping.getBackgroundColor(theme, mapping, position, status),
        border: `1px solid ${ColorMapping.getBorderColor(theme, mapping, status)}`,
        borderBottom: "none",
    })
)

const Content = styled(Stack)<{ mapping: string; status: AvailabilityStatus }>(({ theme, mapping, status }) => ({
    border: `1px solid ${ColorMapping.getBorderColor(theme, mapping, status)}`,
    padding: theme.spacing(0.25, 0.5),
}))
Content.defaultProps = {
    direction: "row",
    spacing: 0.5,
    alignItems: "center",
    justifyContent: "center",
}

export function DefaultAvailability(props: DefaultAvailabilityProps) {
    const { status, iconName, imageUrl, description, onClick, variant = "full", mapping = "default", tourDate, tourName } = props

    if (variant === "IconOnly") {
        return (
            <Box onClick={onClick}>
                <Tooltip title={description || ""}>
                    <AvailabilitySymbol iconName={iconName} imageUrl={imageUrl} width="60px" height="32px" />
                </Tooltip>
            </Box>
        )
    }

    return (
        <Wrapper onClick={onClick}>
            <Indicators>
                <Indicator mapping={mapping} position={1} status={status} />
                <Indicator mapping={mapping} position={2} status={status} />
                <Indicator mapping={mapping} position={3} status={status} />
                <Indicator mapping={mapping} position={4} status={status} />
            </Indicators>

            <Content mapping={mapping} status={status}>
                <AvailabilitySymbol iconName={iconName} imageUrl={imageUrl} />
                {!!description && <Description>{description}</Description>}
            </Content>

            {(tourDate || tourName) && (
                <div className="availability__tour">
                    <div className="availability__text">
                        {tourDate}
                        {tourDate && tourName && <br />}
                        {tourName}
                    </div>
                </div>
            )}
        </Wrapper>
    )
}
