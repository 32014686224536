import { useCallback, useEffect, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Stack } from "@mui/material"
import { useResizeObserver } from "@tm/utils"
import { ArticleReferenceParms, Reference } from "./Reference"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { Divider } from "../../../generics/divider"
import { Typography } from "../../../generics/typographie"
import { Button } from "../../../generics/button"

type ReferenceGroupType = { references: ArticleReferenceParms[]; label: string }

export function ReferenceGroup(props: ReferenceGroupType) {
    const handleClickDetailsIntern = useDefaultArticleItemState((x) => x.handleClickDetails)
    const { references, label } = props
    const { translateText } = useLocalization()

    const moreButtonRef = useRef<HTMLButtonElement>(null)
    const [showMoreLink, setShowMoreLink] = useState(false)
    const handleClickReference = useDefaultArticleItemState((x) => x.handleClickReference)

    const handleClickDetails = useCallback(() => handleClickDetailsIntern("references"), [handleClickDetailsIntern])

    function handleResizeContainer(entry?: ResizeObserverEntry) {
        const difference = moreButtonRef.current ? moreButtonRef.current.offsetWidth + (entry?.target.lastElementChild?.clientWidth ?? 0) : 0
        setShowMoreLink(!!entry && entry.target.clientWidth < entry.target.scrollWidth - difference)
    }
    const [stackRef, unsubResizeObserver] = useResizeObserver(handleResizeContainer)

    useEffect(() => unsubResizeObserver, [unsubResizeObserver])

    if (!references?.length) {
        return null
    }

    return (
        <Stack direction="row" overflow="hidden" p="8px 10px" sx={{ backgroundColor: "#f0f0f0" }}>
            <Stack
                direction="row"
                spacing={1}
                divider={<Divider orientation="vertical" flexItem />}
                flexWrap="nowrap"
                overflow="hidden"
                ref={stackRef}
                pr={showMoreLink ? "100px" : undefined}
            >
                <Typography variant="label" sx={{ textTransform: "uppercase" }}>
                    {label}
                </Typography>

                {references.map((reference, idx) => (
                    <Reference key={`reference-${idx}`} reference={reference} onClick={handleClickReference} />
                ))}
            </Stack>
            {showMoreLink && (
                <Button
                    ref={moreButtonRef}
                    variant="text"
                    color="primary"
                    style={{ padding: 0, fontSize: 12, lineHeight: 1, marginLeft: 8 }}
                    onClick={handleClickDetails}
                >
                    {translateText(44)}
                </Button>
            )}
        </Stack>
    )
}
