import { Interruptions } from "./interruption-handler"
import { EventInterruptionHandler, EventListenerEvents, ListenerItem, EventRule } from "./models"
import { EventRules } from "./event-rules"

/** *
 * Can hold multiple event listenerds and  call em. For each event type there is only one listener added to the dom.
 * How to use: Create an export function which will use the static method for a specific event like this:
 *
 * const registerOutsideClick = (element: Element, handler: (e: Event) => void): (() => void) | undefined => {
 *   // Example of registerOutsideClick which is already in use and will work with the EventListenerManager
 *   const removeListenerAfterCallingHandler = true
 *   return EventListenerHandler.registerOutsideClick("outsideClick", element, handler, removeListenerAfterCallingHandler)
 * }
 *
 * Differences between the simple function and this class are
 * * Events will be saved as
 * * one listener per event
 * * event listener won't get removed before calling
 *
 * Improvements
 * * To avoid duplicated events, add another key to Listeners for specific events like
 * * * this.Listeners["mouseup"]["outsideClick"] = Array<ListenerItem>
 * * * this.Listeners["scroll"]["scroller"] = Array<ListenerItem>
 * * * this.Listeners["scroll"]["outsideClick"] = Array<ListenerItem> // stuff like this would be possible now
 */

export class EventListenerManager {
    private static instance: EventListenerManager

    private Listeners: { [key: string]: Array<ListenerItem> }

    private initialClickTarget: EventTarget | null

    private interruptionHandler?: EventInterruptionHandler

    public static TriggerRules: { [eventName: string]: EventRule } = {}

    static Events: { [key: string]: EventListenerEvents } = {
        OutsideClick: "outsideClick",
    }

    /** *
     * HowToAddListener: Use existing event listener or introduce a new one
     * (new: add a listener function where registered listeners will be called)
     * @todo Register mouseUpListener as mouseDown, save the eventTarge bubble phase and what ever and register another mousedown to check if the callback should be executed
     */
    private constructor() {
        this.initialClickTarget = null
        window.document.body.addEventListener("mousedown", this.mouseDownListener)
        window.document.body.addEventListener("mouseup", this.mouseUpListener)
        this.Listeners = {}
    }

    private static getInstance = () => {
        if (!EventListenerManager.instance) {
            EventListenerManager.instance = new EventListenerManager()
        }

        return EventListenerManager.instance
    }

    private initializeListenerRepo = (eventName: string) => {
        this.Listeners[eventName] = this.Listeners[eventName] || []
    }

    /** *
     * HowToAddListener: create your static listener Method, don't forget to add your listener
     */
    static registerEventListener = (eventName: string, element: Element, handler: (e: Event) => void, removeAfterHandle: boolean): (() => void) => {
        const instance = EventListenerManager.getInstance()

        const unregisterHandler = () => {
            instance.removeListener(eventName, { element, handler })
        }

        instance.addListener(eventName, element, handler, removeAfterHandle ? unregisterHandler : undefined)

        return unregisterHandler
    }

    private removeListener = (event: string, listener: ListenerItem) => {
        this.Listeners[event] = this.Listeners[event].filter((listenerItem) => listenerItem.element != listener.element)
    }

    private addListener = (event: string, element: Element, handler: (e: Event) => void, afterHandle?: () => void) => {
        if (!this.Listeners || !this.Listeners[event]) {
            this.initializeListenerRepo(event)
        }

        if (!this.Listeners[event].find((item) => item.element == element)) {
            this.Listeners[event].push({ element, handler, afterHandle })
        }
    }

    /** *
     * Will trigger events
     * HowToAddListener: call your listener here
     */
    private mouseUpListener = (e: Event) => {
        this.triggerRegisteredEventListener(e, EventListenerManager.Events.OutsideClick)

        this.initialClickTarget = null
    }

    private mouseDownListener = (e: Event) => {
        this.initialClickTarget = e.srcElement
    }

    /** *
     * get all the registered callbacksfor this event
     */
    private triggerRegisteredEventListener = (e: Event, eventName: EventListenerEvents) => {
        const outsideClickListeners = this.getListenerCallbacks(eventName)
        if (outsideClickListeners.length == 0) {
            return
        }

        this.callEventListeners(e, outsideClickListeners, eventName)
    }

    private getListenerCallbacks = (eventName: string) => {
        return this.Listeners[eventName] ? this.Listeners[eventName].map((item) => item) : []
    }

    /** *
     * call each callback and remove ?
     */
    private callEventListeners = (e: Event, listeners: Array<ListenerItem>, eventName: EventListenerEvents) => {
        if (!e.srcElement) {
            return
        }

        const eventTriggerRule = EventListenerManager.TriggerRules[eventName]

        const shouldExecuteHandler = (listenerItem: ListenerItem) => {
            if (this.shouldInterruptEvent(eventName, listenerItem.element, e)) {
                return false
            }

            return eventTriggerRule.isFulfilled(this.initialClickTarget as Element, e.srcElement as Element, listenerItem.element)
        }

        if (eventTriggerRule) {
            const lastListener = listeners.last()
            if (lastListener && shouldExecuteHandler(lastListener)) {
                lastListener.handler(e)
                lastListener.afterHandle && lastListener.afterHandle()
            }

            // for (let i = 0; i < listeners.length; i++) {
            //     const listener = listeners[i]
            //     if (listener && shouldExecuteHandler(listener)) {
            //         listener.handler(e)
            //         listener.afterHandle && listener.afterHandle()
            //     }
            // }
        }
    }

    private shouldInterruptEvent(eventName: EventListenerEvents, srcElement: Element, e: Event) {
        if (this.interruptionHandler) {
            if (this.interruptionHandler.processor(eventName, this.initialClickTarget as Element, srcElement, this.Listeners[eventName], e)) {
                return true
            }
        }

        return false
    }

    static addInterruptionHandler(handler: EventInterruptionHandler) {
        const instance = EventListenerManager.getInstance()
        if (!instance.interruptionHandler) {
            instance.interruptionHandler = handler
        } else {
            instance.interruptionHandler.addHandler(handler)
        }
    }

    static addEventRule(evRules: EventRule) {
        EventListenerManager.TriggerRules[evRules.eventName] = evRules
    }
}

EventRules.forEach(EventListenerManager.addEventRule)

Interruptions.forEach(EventListenerManager.addInterruptionHandler)
