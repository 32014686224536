import { ComponentType, memo } from "react"
import { Box, BoxProps, Stack, styled } from "@mui/material"
import { Notes } from "../../Notes"
import { TraderSelection } from "../../TraderSelection"
import { useDefaultArticleItemState } from "../../ContextProviderStates/DefaultArticleItemState"
import { Icon } from "../../../../generics/Icons"
import { Typography } from "../../../../generics/typographie"
import { Divider } from "../../../../generics/divider"
import { DefaultCompactArticleContentComponent } from "./CompactArticleContent"
import { DefaultExpandedArticleContentComponent } from "./ExpandedArticleContent"
import { RecommendedArticle } from "../../RecommendedArticle"

export type DefaultArticleItemProps = BoxProps & {
    disabled?: boolean
}

export const ArticleWrapper = styled(Box)({
    marginBottom: 8,
}) as typeof Box

export const ArticleContainer = styled(Box, { shouldForwardProp: (prop: string) => !["disabled"].includes(prop) })<DefaultArticleItemProps>(
    ({ theme, disabled }) => ({
        margin: "12px 0 0 0",
        backgroundColor: "#fafafa",
        boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
        width: "100%",
        borderBottom: "3px #c3c3c3 solid",

        ...(disabled && {
            marginTop: 0,
            pointerEvents: "none",
            opacity: theme.opacity?.disabled ?? 0.3,
        }),
    })
) as ComponentType<DefaultArticleItemProps>

const DefaultArticleItemComponent = memo(({ disabled }: DefaultArticleItemProps) => {
    const expandedArticleList = useDefaultArticleItemState((x) => x.expanded)

    return (
        <ArticleWrapper>
            <ArticleContainer disabled={disabled}>
                {expandedArticleList ? <DefaultExpandedArticleContentComponent /> : <DefaultCompactArticleContentComponent />}
            </ArticleContainer>
            <Notes />
            <RecommendedArticle />
            <TraderSelection />
        </ArticleWrapper>
    )
})

export const DefaultArticleItem = memo(() => {
    const disableReason = useDefaultArticleItemState((x) => x.options.disableReason)

    if (!disableReason) {
        return <DefaultArticleItemComponent />
    }

    return (
        <Box>
            <Stack direction="row" gap={1} alignItems="center" mt={1}>
                <Icon name="not" color="error" />
                <Typography>{disableReason}</Typography>
                <Box flex={1}>
                    <Divider light />
                </Box>
            </Stack>
            <DefaultArticleItemComponent disabled />
        </Box>
    )
})
