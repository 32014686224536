import { VatRate, VatRateType } from "@tm/models"

export function useVatRateText(translateText: (id: number) => string) {
    const vatRateTypeDisplayShortText = (vatRate: VatRate) => {
        if (!vatRate.vatRate) {
            return "-"
        }
        switch (vatRate.vatType) {
            case VatRateType.Normal:
            case VatRateType.Reduced: {
                return `${vatRate.vatRate.round(0.01)}% `
            }
            case VatRateType.Replacement: {
                return translateText(12829)
            }
            case VatRateType.WithoutRate:
            default: {
                return "-"
            }
        }
    }

    function getVatRateInPercent(vatRate: VatRate) {
        if (!vatRate.vatRate) {
            return undefined
        }
        const vatRateInPercent = `${vatRate.vatRate.round(0.01)}% ${translateText(706)}.`
        return vatRateInPercent
    }

    const vatRateTypeDisplayLongText = (vatRate: VatRate) => {
        switch (vatRate.vatType) {
            case VatRateType.Normal: {
                return `${getVatRateInPercent(vatRate)} (${translateText(12825)})`
            }
            case VatRateType.Reduced: {
                return `${getVatRateInPercent(vatRate)} (${translateText(12826)})`
            }
            case VatRateType.Replacement: {
                return `${getVatRateInPercent(vatRate)} + ${translateText(12827)}`
            }
            case VatRateType.WithoutRate: {
                return translateText(12828)
            }
            default: {
                return ""
            }
        }
    }

    return { vatRateTypeDisplayShortText, vatRateTypeDisplayLongText }
}
