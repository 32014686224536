import { Vehicle } from "@tm/models"

export function isVin(value?: string): boolean {
    return !!value && /^[^\W_]{17}$/.test(value) // only (a-z, A-Z, 0-9) with length of 17
}

export function isValidVin(vehicle?: Vehicle) {
    const VIN_LENGTH = 17
    const VIN_LENGTH_SMALL = 7
    const BMW_TecDoc_Id = 16

    return (
        !vehicle?.vin?.length ||
        vehicle.vin?.length === VIN_LENGTH ||
        (vehicle?.tecDocManufacturerId === BMW_TecDoc_Id && vehicle.vin?.length === VIN_LENGTH_SMALL)
    )
}
