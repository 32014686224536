import { useLocalization } from "@tm/localization"
import { ReactNode, useCallback, useMemo, useState } from "react"
import { Box } from "@mui/material"
import { channel } from "@tm/models"
import { EmailShareOption, ShareOption, ShareType } from "../models"
import { Typography } from "../../../generics/typographie"
import { ShareOptions } from "./ShareOptions"
import { SharingLink } from "./SharingLink"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { EmailList } from "./EmailList"
import { useEmailState } from "../hooks/useEmailState"
import { LoadingContainer } from "../../../generics/LoadingContainer"

type ShareProps = {
    shareOptions: ShareOption[]
    url: string | undefined
    generateLink: () => void
    onShareCompleted?: (success: boolean) => void
    isLoading?: boolean
    errorMessage?: string
    preventAutomaticGeneration?: boolean
    additionalContent?: ReactNode
    disableSendFromOutside?: boolean
}

export function Share({
    url,
    isLoading,
    errorMessage,
    onShareCompleted,
    shareOptions,
    generateLink,
    additionalContent,
    disableSendFromOutside,
    preventAutomaticGeneration,
}: ShareProps) {
    const { language, translateText } = useLocalization()
    const [selectedShareOption, setSelectedShareOption] = useState<ShareOption>()
    const [isSharing, setIsSharing] = useState(false)

    const emailShareOption = useMemo(
        () => shareOptions.find((shareOption) => shareOption.type === ShareType.Email) as EmailShareOption | undefined,
        [shareOptions]
    )

    const { emailActions, emailState } = useEmailState(emailShareOption)

    const sendDisabled = useMemo(() => {
        if (!url || !selectedShareOption || disableSendFromOutside) {
            return true
        }
        if (selectedShareOption?.type === ShareType.Email && emailState.hasError) {
            return true
        }

        return false
    }, [url, selectedShareOption, emailState.hasError, disableSendFromOutside])

    const handleToastMessages = useCallback((shareOption: ShareOption, shareSuccess: boolean) => {
        if (shareSuccess && shareOption.toastMessage?.success) {
            const toast = shareOption.toastMessage.success
            const message = typeof toast.message === "number" ? translateText(toast.message) : toast.message
            channel("APP").publish("TOAST_MESSAGE/SHOW", { ...toast, message })
        }

        if (!shareSuccess && shareOption.toastMessage?.failure) {
            const toast = shareOption.toastMessage.failure
            const message = typeof toast.message === "number" ? translateText(toast.message) : toast.message
            channel("APP").publish("TOAST_MESSAGE/SHOW", { ...toast, message })
        }
    }, [])

    const send = async () => {
        if (!selectedShareOption || !url) {
            return
        }

        setIsSharing(true)
        const content = selectedShareOption.contentOverride !== undefined ? await Promise.resolve(selectedShareOption.contentOverride(url)) : url

        let shareSuccess = false
        switch (selectedShareOption.type) {
            case ShareType.Email:
                shareSuccess = await selectedShareOption.handler(
                    selectedShareOption.subject,
                    content,
                    emailState.emails.map((email) => email.email),
                    emailState.bccEmails
                )
                break

            case ShareType.WhatsApp:
                shareSuccess = await selectedShareOption.handler(language, content)
                break

            case ShareType.Carteligence:
            case ShareType.Download:
                shareSuccess = await selectedShareOption.handler(content)
                break

            default:
                break
        }

        setIsSharing(false)
        handleToastMessages(selectedShareOption, shareSuccess)
        onShareCompleted?.(shareSuccess)
    }

    const actionLabel = useMemo(() => {
        if (!selectedShareOption) {
            return translateText(642)
        }

        if (typeof selectedShareOption.shareActionButtonLabel === "number") {
            return translateText(selectedShareOption.shareActionButtonLabel)
        }

        return selectedShareOption.shareActionButtonLabel
    }, [selectedShareOption, translateText])

    return (
        <LoadingContainer isLoading={isSharing}>
            <Box display="flex" flexDirection="column" gap="1em">
                <Typography variant="h4">{translateText(13776)}</Typography>

                <ShareOptions onShareOptionSelected={setSelectedShareOption} shareOptions={shareOptions} />

                <SharingLink
                    url={url}
                    errorMessage={errorMessage}
                    isLoading={!!isLoading && !errorMessage}
                    generateAction={generateLink}
                    preventAutomaticGeneration={preventAutomaticGeneration}
                />

                {additionalContent && additionalContent}

                {selectedShareOption?.type === ShareType.Email && <EmailList emailState={emailState} emailActions={emailActions} />}

                <Box display="flex" justifyContent="flex-end">
                    <Button
                        className="submit"
                        size="large"
                        color="success"
                        startIcon={<Icon name="paperplane" />}
                        disabled={sendDisabled}
                        onClick={() => send()}
                    >
                        {actionLabel}
                    </Button>
                </Box>
            </Box>
        </LoadingContainer>
    )
}
