import { useCallback } from "react"
import { atomFamily, useSetRecoilState, useRecoilValue } from "recoil"
import { Article, RequestArticleListPayload } from "@tm/models"
import { useWorkTaskId } from "../worktask"

type PureArticleListState = {
    request?: RequestArticleListPayload
    data?: Article[]
    isLoading?: boolean
}

const pureArticleListsState = atomFamily<Record<string, PureArticleListState>, string | undefined>({
    default: { },
    key: "pureArticleLists",
})

export function usePureArticleList(id: string) {
    const workTaskId = useWorkTaskId()
    const states = useRecoilValue(pureArticleListsState(workTaskId))
    return { state: states[id] }
}

export function usePureArticleLists() {
    const workTaskId = useWorkTaskId()
    const setStates = useSetRecoilState(pureArticleListsState(workTaskId))

    const setRequest = useCallback((id: string, request: RequestArticleListPayload) => {
        setStates((prev) => {
            return {
                ...prev,
                [id]: { request },
            }
        })
    }, [])

    const reset = useCallback((id: string) => {
        setStates((prev) => {
            return {...prev,
                [id]: {},
            }
        })
    }, [])

    return { setRequest, reset }
}


