import * as React from "react"
import { Action, applyMiddleware, compose, createStore } from "redux"
import thunk from "redux-thunk"
import { Subject } from "rxjs"
import { ComponentConfig, IContext, PayloadAction, Transmission } from ".."
import { BundleMicroContext } from "./createComponent"

const showMicrosLogs = false //!!localStorage.getItem("showMicrosLogs")

const store_debug_bundles: string[] = JSON.parse(window.localStorage?.getItem("store_debug_bundles") ?? "[]")
const store_debug_names: string[] = JSON.parse(window.localStorage?.getItem("store_debug_names") ?? "[]")


//create the component for the new renderMicro function 
export function createMicro(context: IContext, componentConfig: ComponentConfig, moduleActions$: Subject<Transmission>, parentKey: string, bundleName: string, microName: string, props: any = {}): React.ReactNode {
    const bundle = context.bundles[bundleName]
    if (!bundle || !bundle.micros) {
        showMicrosLogs && console.warn(`The bundle ${bundleName} cannot be found }`)
        return
    }

    const micro = bundle.micros.find(x => x.name == microName)

    if (!micro || !micro.component) {
        showMicrosLogs && console.warn(`The micro ${microName} from ${bundleName} cannot be found`)
        return
    }

    const key = `${bundleName}_${microName}`
    const microKey = `${parentKey}/${key}`

    if (micro.reduce) {
        const store = context.stores[microKey]
        if (!store) {

            const extReduce = (state: any, action: Action) => {
                if (micro.resetOnWorktaskChange && typeof action != "function" && action.type == "@@redux/INIT") {
                    return micro.reduce!(undefined, action)
                }
                return micro.reduce!(state, action)
            }

            let composeEnhancers = compose
            if (process.env.NODE_ENV != "production") {
                const devTool = (window as any)?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                if (store_debug_bundles.includes(bundle.name) || store_debug_names.includes(micro.name) && devTool) {
                    // console.warn(`${componentConfig.bundle}-${component.name} it's in debug mode (Please remove debug:true from bundle definition)`)
                    composeEnhancers =
                        devTool({
                            name: `MICRO_${componentConfig.bundle}_${componentConfig.component}`
                        })
                }
            }

            context.stores[microKey] = createStore(extReduce,
                composeEnhancers(
                    applyMiddleware(thunk, _ => next => (action: {} | Function) => {
                        next(action as Action)
                        const transmit = micro.transmit
                        if (typeof action != "function" && transmit) {
                            const transmitAction = transmit(action as PayloadAction<any>)
                            if (transmitAction) {
                                moduleActions$.next({
                                    module: microKey,
                                    bundle: bundleName,
                                    component: microName,
                                    action: transmitAction,
                                    received: [],
                                })
                            }
                        }
                    })))
        }
        props.store = context.stores[microKey]
    }

    const component = React.createElement(micro.component, { ...props, key: `micro--${key}` })
    return React.createElement(BundleMicroContext.Provider, { value: createMicro.bind(undefined, context, componentConfig, moduleActions$, microKey) }, component)
}
