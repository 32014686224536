import * as React from "react"
import { bindMethodsToContext } from "../../../helper/bindObjectContext";
import { ButtonKeyDefinition, fixPrecisionSymbol, getLocalePrecisionSymbol } from "@tm/utils";
import { AmountItem } from "..";

type AmountFieldInputProps = AmountItem & {
    inputRef: React.RefObject<HTMLInputElement>
    onChange?(item: AmountItem): void
}

type AmountFieldInputState = {
    showValue: string
    dropdownNavigationActive: boolean
    fromInput: boolean
}

export default class AmountFieldInput extends React.PureComponent<AmountFieldInputProps, AmountFieldInputState> {
    constructor(props: AmountFieldInputProps) {
        super(props)
        bindMethodsToContext(this, ["get", "set", "step", "adjust", "validate", "focus"])

        this.state = {
            showValue: props.value?.toString() ?? "",
            dropdownNavigationActive: false,
            fromInput: false    // need this to handle input changes in getDerivedStateFromProps
        }
    }

    static getDerivedStateFromProps(props: AmountFieldInputProps, state: AmountFieldInputState) {
        const { value } = props
        const { showValue, fromInput } = state

        if (value?.toString() == showValue) return null

        return { showValue: fromInput ? showValue : value }
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target
        const valid = this.validateValue(value)

        if (!valid)
            return

        if (!value) {
            this.setState({ showValue: value, fromInput: true })
            return
        }

        const adjustedValue = this.adjustMinMaxValue(value).toString()
        const showValue = this.appendPrecision(value, adjustedValue)

        this.setState({ showValue, fromInput: true })
    }

    /**
     * due to parsing the userinput, the precision symbol could be lost,
     * so we will replace it with the locale language of the users browser
     * @param userInput the data the user has given
     * @param value parsed value
     */
    appendPrecision = (userInput: string, value: string) => {
        let fixedPrecisionValue = value
        if (/.*[^\.,]$/.test(value)) {
            const locale = getLocalePrecisionSymbol()

            if (/.*[\.,]$/.test(userInput)) {
                fixedPrecisionValue = `${value}${locale}`
            }

            if (fixedPrecisionValue.indexOf(locale) < 0) {
                fixedPrecisionValue = fixPrecisionSymbol(fixedPrecisionValue)
            }
        }
        return fixedPrecisionValue
    }



    validateValue(value: string): boolean {
        let valid = this.isNumber(value)
        valid = valid && this.validateBoundary(value)

        return valid
    }

    isNumber(value: string): boolean {
        const match = value.match(/^[+-]?\d*[,.]?\d*$/g)

        if (!match)
            return false

        return true
    }

    validateBoundary(value: string): boolean {
        const currentValue = parseFloat(value)

        if (currentValue > this.props.maxAmount || currentValue < this.props.minAmount)
            return false

        return true
    }

    adjustMinMaxValue(value: string | number) {
        const { maxAmount, minAmount } = this.props

        let fittedValue = typeof (value) == "string" ? parseFloat(value.replace(',', '.')) : value

        if (fittedValue > maxAmount)
            fittedValue = maxAmount

        if (fittedValue < minAmount)
            fittedValue = minAmount

        return fittedValue
    }

    handleSubmitValue() {
        const { unit, maxAmount, minAmount, onChange, division } = this.props
        let adjustedValue = parseFloat(this.state.showValue.replace(',', '.')) || this.props.value

        if (!this.stepSizeFits(adjustedValue)) {
            adjustedValue = this.adjustValue(adjustedValue)
        }

        this.setState({ fromInput: false })
        onChange?.({ value: adjustedValue, unit, division, maxAmount, minAmount })
    }

    handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case ButtonKeyDefinition.Enter: {
                if (this.state.dropdownNavigationActive) {
                    this.setState({ dropdownNavigationActive: false })
                }
                else {
                    this.handleBubbling(e)
                    this.handleSubmitValue()
                }
                break
            }
            case ButtonKeyDefinition.ArrowDown: {
                this.setState({ dropdownNavigationActive: true })
                break;
            }

            case ButtonKeyDefinition.Tab: {
                this.handleSubmitValue()
                break;
            }
        }
    }

    stepSizeFits(value: number) {
        if ((value % this.props.division as number) == 0)
            return true

        return false
    }

    adjustValue(value: number) {
        const { division } = this.props

        const precision = this.getPrecision(division)
        const fixedValue = parseFloat(value.toFixed(precision))
        const rest = parseFloat((fixedValue % division).toFixed(precision))

        if (rest == 0)
            return fixedValue

        return fixedValue - rest + division
    }

    getPrecision(value: number) {
        const tmpValue = value.toString()
        if (tmpValue.indexOf('.') >= 0) {
            const splittedValue = tmpValue.split('.')
            const valueDecimals = splittedValue.last()
            return (valueDecimals ? valueDecimals.length : 0)
        }

        return 0
    }

    handleBubbling(e: React.SyntheticEvent<HTMLInputElement>) {
        e.stopPropagation()
        e.preventDefault()
        e.bubbles = false
    }

    render() {
        const { showValue } = this.state
        const { division } = this.props

        let numberDecimalPlaces = 0
        if (division.toString().indexOf('.') >= 0) {
            const decimalPlaces = division.toString().split('.').last()
            if (decimalPlaces && decimalPlaces.length > 0)
                numberDecimalPlaces = decimalPlaces.length
        }

        const className = "amount-field__input input"

        let value = numberDecimalPlaces == 1 || this.state.fromInput ? showValue : Number(showValue).toFixed(numberDecimalPlaces)
        value = fixPrecisionSymbol(value)

        return (
            <div className={className} >
                <div className="input__inner">
                    <input
                        type={"text"}
                        ref={this.props.inputRef}
                        value={value}
                        onChange={this.handleInputChange}
                        // onBlur={this.handleSubmitValue}
                        onKeyDown={this.handleKeyDown}
                        onClick={this.handleBubbling}
                    />
                </div>
            </div>
        )
    }
}
