import { EventListenerManager } from ".."

/**
 * Registers a [handler] function which will be called once you click outside of the given [element]
 */
export function registerOutsideClick(element: Element, handler: (e: Event) => void, listenOnce = true): () => void {
    if (!element) {
        return () => undefined
    }

    const removeEventListener = EventListenerManager.registerEventListener("outsideClick", element, handler, listenOnce)

    return removeEventListener
}
