import * as React from "react"
import { VehicleType } from "@tm/models"
import { Image } from "../../generics/image"
import { getImageTypeByVehicleType } from "./helper"

type Props = Omit<React.ComponentProps<typeof Image>, "src" | "type"> & {
    modelImage: string
    modelSeriesImage: string
    vehicleType: VehicleType
}

export const VehicleImage = React.memo<Props>(
    React.forwardRef(({ modelImage, modelSeriesImage, vehicleType, onError, ...rest }, ref) => {
        const [shouldUseModelSeriesImage, setShouldUseModelSeriesImage] = React.useState(false)

        React.useEffect(() => {
            setShouldUseModelSeriesImage(false)
        }, [modelImage])

        const handleError = React.useCallback(
            (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                if (!shouldUseModelSeriesImage && modelImage !== modelSeriesImage && vehicleType === VehicleType.PassengerCar) {
                    setShouldUseModelSeriesImage(true)
                }
                onError?.(e)
            },
            [shouldUseModelSeriesImage, modelImage, modelSeriesImage, vehicleType, onError]
        )

        return (
            <Image
                {...rest}
                ref={ref}
                src={shouldUseModelSeriesImage ? modelSeriesImage : modelImage}
                type={getImageTypeByVehicleType(vehicleType)}
                onError={handleError}
            />
        )
    })
)
