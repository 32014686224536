import { ConstructionYear, EFuelTypes, ManufacturerBase, ModelSeriesBase, VehicleModel } from "."
import { RegistrationNoType } from "../../enumerations"

export type CarManufacturer = ManufacturerBase & {
    bodyTypeIds: Array<number>
}

export type CarMainModelSeries = {
    id: number
    description: string
    bodyTypeIds: Array<number>
    carModelSeries: Array<CarModelSeries>
    constructionYearFrom: ConstructionYear
    constructionYearTo: ConstructionYear
    thumbnail: string
    sortId: number // not used
}

export type CarModelSeries = ModelSeriesBase & {
    bodyTypeIds: Array<number>
    mainModelSeriesId: number
    mainModelSeriesDescription: string
    mainModelSeriesSortId: number // not used
}

export type CarModel = VehicleModel & {
    bodyTypeIds?: Array<number> // not used
    engineCapacityLiter?: number
    enginePowerKw?: number
    enginePowerHp?: number
    cylinderCount?: number
    fuelType?: string
    fuelTypeId?: EFuelTypes
    hasAutodataRepairTimes: boolean // not used
    hasAutodataInspectionData: boolean // not used
    hasAutodataTechnicalData: boolean // not used
    hasHaynesProTechnicalData: boolean // not used
    hasHaynesProInspectionData: boolean // not used
    priority: number // not used
    registrationNoDetails?: RegistrationNoDetailsSimple
}

export type CarModelDetails = CarModel & {
    engineCapacityTaxation?: number
    voltage?: number
    hasABS: boolean
    hasTCS: boolean
    engineType?: string
    vehicleType?: string
    driveType?: string
    brakeType?: string
    brakeSystem?: string
    catalystType?: string
    transmissionType?: string
    fuelTankCapacity?: number
    fuelMixtureFormation?: string
    valvesPerCylinder?: number
    doorCount?: number
    bodyType?: string
    registrationNos?: Array<string>
}

export type ShowCarModelDetailsRequest = {
    modelId: number
    selectedLookupType?: RegistrationNoType
    registrationNoTypeId?: RegistrationNoType
    registrationNo?: string
    modelIdentifier?: AdditionalModelIdentifier
    forceUpdateRegistrationNoDetails?: boolean
}

export type AdditionalModelIdentifier = {
    plateId?: string
    vin?: string
    initialRegistration?: Date
}

export type ShowCarModelDetailsResponse = {
    manufacturer?: CarManufacturer
    modelSeries?: CarModelSeries
    modelDetails?: CarModelDetails
    alternativeModels?: Array<CarModel>
    conflictReason?: LookupConflictReason
    error?: CarLookupError
}

export enum LookupConflictReason {
    NoConflict = 0,
    TecdocTypeNotMatch = 1,
    AdditionalModelIdentifierNotMatch = 2,
    AdditionalModelIdentifierNotUnique = 3,
}

export type CarLookupError = {
    errorType: CarLookupErrorType
    errorMessage: string
}

export enum CarLookupErrorType {
    None = 0,
    NoPrivilege = 1,
    Technical = 2,
}

export type RegistrationNoDetailsSimple = {
    additionalDetails: Array<RegistrationNoAttribute>
    initialRegistration?: Date
    nextGeneralInspection?: Date
    vin: string
    plateId: string
    engineCodes: Array<string>
    registrationNo: string
}

export type RegistrationNoAttribute = {
    id?: number
    description: string
    value: string
    type?: number
}

export type RegistrationNoDetails = RegistrationNoDetailsSimple & {
    chassisNumber: string
    manufacturer: string
    modelSeries: string
    model: string
    manufacturingDate: Date
    /** This is not the same as `engineCode` */
    engineNumber: string
    engineModel: string
    hasTurbo: string
    fuelType: string
    engineCapacity: string
    enginePowerKw: string
    enginePowerHp: string
    transmissionType: string
    gearCount: string
    bodyStyle: string
    cylinderCount: string
    valveCount: string
    driveAxle: string
    color: string
    /** Also known as `tecdocTypeId` */
    modelId: string
    typScheinNr: string
    constructionYearFrom: ConstructionYear
    constructionYearTo: ConstructionYear
    brakes: string
    importCondition: string
    esbGroup: string
    countryOfOrigin: string
    foundBySearchTerm: string
    vehicleType: number
    colors: Array<Color>
    equipments: Array<Equipment>
    vehicleTypeName: string
    container: string
    containerName: string
    datECode: string
    longlife?: boolean
}

export type Color = {
    code: string
    id: string
    description: string
}

export type Equipment = {
    label: string
    appraisalGroup: string
    classification: number
    number: number
    standardOrOptional: string
    manufacturerCode: string
    price: number
}
