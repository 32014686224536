import { ErpSystemConfigMode, ErpSystemConfig } from "@tm/models"
import { useErpConfig } from "./useErpConfig"

export function useDefaultErpSystem(erpSystemConfig?: ErpSystemConfig) {
    const erpConfig = useErpConfig()

    if (!erpSystemConfig && erpConfig.mode === ErpSystemConfigMode.Partnersystems) {
        return {
            erpSystemConfig: erpConfig.erpSystemConfigs?.find((x) => x.isDefaultGetErpInfoSystem) ?? erpConfig.erpSystemConfigs?.[0],
            erpConfig,
        }
    }

    return { erpSystemConfig, erpConfig }
}
