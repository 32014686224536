/* eslint-disable max-classes-per-file */

import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios"

import { AuthTokenPayload } from "@tm/models"

import { getCoopMemberId, getLanguageFromLocalStorage } from "../.."
import { ModelState } from "../../types"
import { decodeJwtToken, getStoredAuthorization } from "../auth"
import { parseISODate } from "../date"
import { createQueryString } from "../url"
import { handleCaching } from "./caching"

export type RequestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE"
export type ResponseType = "text" | "json" | "blob"
export type ContentType = "JSON" | "BLOB" | "XML"
export type AuthorizationType = "Bearer" | "Basic"
export type Authorization = {
    type: AuthorizationType
    credentials: string
}

/**
 * The request object containing information like the `url`, `method`, `body`, ...
 */
export type Request<TRequest> = {
    /**
     * The url to call - can be relative to document root or absolute.
     */
    url: string

    /**
     * The used HTTP method.
     * @default "GET"
     */
    method?: RequestMethod

    /**
     * The data send in the request body.
     * Supplied to `axios` as `data`.
     *
     * *Remark: Setting the `method` to `"GET"` will send the data in the URL query string instead of the body.*
     * *Supplied to `axios` as `params`.*
     *
     * @see AxiosRequestConfig.data @see AxiosRequestConfig.params
     */
    body?: TRequest

    /**
     * The type of the request data - used to set the HTTP header `Content-Type`.
     *
     * *Remark: Setting the `method` to `"GET"` will always result in `Content-Type: "text/plain"` being sent.*
     * @default "JSON"
     */
    contentType?: ContentType

    /**
     * The value of the HTTP header `Accept-Language` in ISO-639-1 format.
     *
     * @default localStorage.getItem("language") ?? "de"
     */
    language?: string

    /**
     * If set to `true` supply the language in a custom HTTP header `App-Language` instead.
     * @see Request.language parameter
     */
    languageAsCustomerHeader?: boolean

    /**
     * If supplied it will be sent in the HTTP header `Authorization` in the form of `"<type> <credentials>"`.
     */
    authorization?: Authorization

    /**
     * Supplied to `axios` as `responseType`.
     * @see AxiosRequestConfig.responseType
     */
    responseType?: ResponseType

    /**
     * HTTP headers that will be sent in the request.
     *
     * *Remark: HTTP headers set by other options can be overwritten here.*
     */
    headers?: Record<string, string>

    /**
     * Supplied to `axios` as `withCredentials`.
     * @see AxiosRequestConfig.withCredentials
     */
    withCredentials?: boolean

    /**
     * `AbortController.signal` will be supplied to `axios` as `signal`.
     * @see AxiosRequestConfig.signal
     */
    abortController?: AbortController

    /**
     * The timeout in milliseconds after which the request will be aborted when no response isn't received yet.
     *
     * Supplied to `axios` as `timeout`.
     * @default 100000
     * @see AxiosRequestConfig.timeout
     */
    timeout?: number

    /**
     * Please only use when really necessary, as it might cause more requests. This skips request and response caching.
     */
    skipCaching?: boolean
}

const ajaxErrorHandlers: Array<(error: any) => void> = []

// Serialize url params correctly (nested objects etc.)
axios.defaults.paramsSerializer = (params) => createQueryString(params).slice(1) // createQueryString also returns a leading ? which is also added by axios

// Serialize body as JSON (nested objects etc.)
axios.defaults.transformRequest = (data) => (data != undefined ? JSON.stringify(data) : undefined)

let tokenExpMs: number | undefined
axios.interceptors.request.use((config) => {
    const authorization = config.headers?.Authorization

    if (authorization?.startsWith("Bearer ")) {
        // if a request with Authorization header is made

        if (tokenExpMs === undefined) {
            // get the expiry time of the token (only once)
            try {
                const payload = decodeJwtToken<AuthTokenPayload>(authorization.slice(authorization.indexOf("Bearer ")))
                tokenExpMs = payload.exp * 1000 // payload.exp is in seconds
            } catch {}
        }

        if (tokenExpMs !== undefined && tokenExpMs < Date.now()) {
            // the token is expired - manually throw error with an 401 response code
            throw {
                config,
                response: {
                    status: 401,
                },
            }
        }
    }

    return config // important otherwise all requests would be skipped
})

function convertISODates(data: any): any {
    return data != undefined ? JSON.parse(data, parseISODates) : undefined
}

function parseISODates(_key: string, value: any): any {
    if (typeof value == "string") {
        return parseISODate(value) ?? value
    }

    return value
}

// first step to use Only this function for authorization calls
export function ajaxAuth<Res = any, Req = any>(
    request: Request<Req>,
    skipErrorHandlers?: boolean,
    _?: boolean,
    parseDates?: boolean
): Promise<Res | undefined> {
    const authorization = getStoredAuthorization()
    const authRequest = { ...request, authorization }
    return ajax(authRequest, skipErrorHandlers, _, parseDates)
}

/**
 * Make an AJAX call using `Axios`.
 *
 * @param request see `Request` type
 * @param skipErrorHandlers  set to `true` to skip calling the error handlers registered using `ajax.onError`
 * @param _ DEPRECATED - just here to not modify the method signature
 * @param parseDates set to `true` to automatically parse ISO date strings in the response to JS `Date` objects - works nested -
 * ***IMPORTANT**: please use this parameter (set to `true`) so we can make this the default in future*
 * @returns A `Promise` which will be resolved with the parsed response or `undefined` in case of no failure but a HTTP response status other than `200` or `201` -
 * in case of failure the `Promise` is rejected with an `Error` object
 */
export function ajax<Res = any, Req = any>(
    request: Request<Req>,
    skipErrorHandlers?: boolean,
    _?: boolean,
    parseDates?: boolean
): Promise<Res | undefined> {
    const {
        method = "GET",
        contentType = "JSON",
        language = getLanguageFromLocalStorage() ?? "de",
        abortController = new AbortController(),
        timeout = 100000, // Cloudflare default
    } = request

    const headers: AxiosRequestHeaders = {
        [request.languageAsCustomerHeader ? "App-Language" : "Accept-Language"]: language,
    }

    let params: Req | undefined
    let body: Req | undefined

    if (method == "GET") {
        params = request.body
        headers["Content-Type"] = "text/plain"
    } else {
        body = request.body

        switch (contentType) {
            case "JSON": {
                headers["Content-Type"] = "application/json"
                break
            }
            case "BLOB": {
                headers["Content-Type"] = "application/octet-stream"
                break
            }
            case "XML": {
                headers["Content-Type"] = "application/xml"
                break
            }
        }
    }

    if (request.authorization) {
        headers.Authorization = `${request.authorization.type} ${request.authorization.credentials}`
    }

    // Only send custom headers for requests to our services (implemented because of CORS restrictions related to custom headers (see NEXT-27260))
    if (request.url.startsWith("/data")) {
        const coopMemberId = getCoopMemberId()
        if (coopMemberId) {
            headers.CoopMemberId = coopMemberId
        }

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
        if (timeZone) {
            headers.LocalTimeZone = timeZone
        }
    }

    const requestConfig: AxiosRequestConfig<Req> = {
        url: request.url,
        headers: { ...headers, ...request.headers },
        method,
        params,
        data: body,
        withCredentials: request.withCredentials,
        signal: abortController.signal,
        timeout,
    }

    if (request.responseType) {
        requestConfig.responseType = request.responseType
    }

    const doAjaxRequest = createAjaxRequestPromise<Res, Req>(requestConfig, skipErrorHandlers, parseDates)

    if (request.skipCaching) {
        return doAjaxRequest()
    }

    try {
        return handleCaching(doAjaxRequest, requestConfig)
    } catch (e) {
        console.debug("caching failed", e)
        return doAjaxRequest()
    }
}

function createAjaxRequestPromise<Res, Req>(config: AxiosRequestConfig<Req>, skipErrorHandlers?: boolean, parseDates?: boolean) {
    return () =>
        new Promise<Res | undefined>((resolve, reject) => {
            if (parseDates) {
                config.transformResponse = convertISODates
            }

            axios
                .request<Res, AxiosResponse<Res>, Req>(config)
                .then(
                    (response) => {
                        switch (response.status) {
                            case 200:
                            case 201: {
                                resolve(response.data)
                                break
                            }
                            default: {
                                resolve(undefined)
                                break
                            }
                        }
                    },
                    (error: AxiosError<Res, Req>) => {
                        if (axios.isCancel(error) || error.message == "canceled") {
                            // If requested was cancelled by consumer, Promise shouldn't be rejected
                            console.log(`Request timed-out/aborted/cancelled: ${config.method ?? "GET"} ${config.url}`)
                            return
                        }

                        if (skipErrorHandlers !== true) {
                            ajaxErrorHandlers.forEach((handler) => handler(error))
                        }

                        switch (error.response?.status) {
                            case 404: {
                                reject(new NotFoundError(error.message, error.response.data as any))
                                break
                            }
                            case 401: {
                                reject(new AuthenticationError(error.message, error.response.data as any))
                                break
                            }
                            case 400: {
                                reject(new ValidationError(error.message, error.response.data as any))
                                break
                            }
                            default: {
                                reject(new Error(error.message))
                            }
                        }
                    }
                )
                .catch((error: unknown) => {
                    const errorMessage =
                        typeof error == "string"
                            ? error
                            : typeof error == "object" && error && "toString" in error && typeof error.toString == "function" && error.toString()
                            ? error.toString()
                            : JSON.stringify(error)

                    reject(new Error(errorMessage))
                })
        })
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ajax {
    /**
     * Register an handler that will be called in case of an error occuring
     * during any `Axios.request` call made inside the `ajax` function.
     *
     * @param handler The handler which will be called with an `AxiosError` object supplied as parameter.
     * @returns A function to unsubscribe this handler.
     */
    export function onError(handler: (error: AxiosError) => void) {
        ajaxErrorHandlers.push(handler)
        return () => ajaxErrorHandlers.remove((x) => x == handler)
    }
}

export class AuthenticationError extends Error {
    data: any | undefined

    constructor(message?: string, data?: any) {
        super()
        this.name = "AuthenticationError"
        if (message) {
            this.message = message
        }
        if (data) {
            this.data = data
        }
    }
}

export class NotFoundError extends Error {
    type: string | undefined

    level: string | undefined

    detail: any | undefined

    traceId: string | undefined

    constructor(message?: string, data?: any) {
        super()
        this.name = "NotFoundError"
        if (message) {
            this.message = message
        }
        if (data?.error) {
            const { error } = data
            this.type = error.type
            this.level = error.level
            this.detail = error.detail
            this.traceId = error.traceId
        }
    }
}

export class ValidationError extends Error {
    modelState: ModelState

    constructor(message: string, modelState?: ModelState) {
        super()
        this.name = "ValidationError"
        this.message = message
        this.modelState = modelState || {}
    }
}

export class ServerError extends Error {
    constructor(error?: Error) {
        super()
        this.name = "ServerError"
        if (error) {
            this.message = error.message
            this.stack = error.stack
        }
    }
}
