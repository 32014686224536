import { useState, useMemo } from "react"
import { useDebounce } from "./useDebounce"

export function useDebounceFormSubmit(value: string, isValid: boolean, isSubmitting: boolean, callback: () => void, debounceValue = 100) {
    const [startValue, setStartValue] = useState<string>(value)

    const hasChanges = useMemo(() => JSON.stringify(value) !== JSON.stringify(startValue), [value, startValue])

    useDebounce(
        () => {
            if (hasChanges && isValid && !isSubmitting) {
                setStartValue(value)
                callback()
            }
        },
        debounceValue,
        [isValid, isSubmitting, value]
    )
}
