import { TMShellMetaData } from "@tm/models"

export interface LoggedInRequest {
    clientInfo?: ClientInfo
    loginType?: LoginType
    shellMeta?: TMShellMetaData
}

export interface ClientInfo {
    ApplicationVersion: string

    Browser: string

    BrowserVersion: string

    CookiesEnabled: boolean

    DotNetFrameworkVersion?: string

    DriveSpaceFree?: number

    DriveSpaceTotal?: number

    GraphicCard?: string

    JavascriptEnabled: boolean

    NetworkType?: NetworkType

    /// <summary>
    /// Unique id of the os.
    /// </summary>
    OperatingSystem: string

    /// <summary>
    /// VersionId of the os.
    /// </summary>
    OperatingSystemVersion: string

    ProcessorType?: string

    /// <summary>
    /// Free space of RAM
    /// </summary>
    RamFree?: number

    /// <summary>
    /// Total space of RAM
    /// </summary>
    RamTotal?: number

    /// <summary>
    /// Screen height in px
    /// </summary>
    ScreenHeight: number

    /// <summary>
    /// Screen width in px
    /// </summary>
    ScreenWidth: number

    /// <summary>
    /// Viewport height in px. Mandatory for web application.
    /// </summary>
    ViewportHeight: number

    /// <summary>
    /// Viewport width in px. Mandatory for web application.
    /// </summary>
    ViewportWidth: number
}

export enum LoginType {
    form = 0,
    hidden = 1,
}

export enum NetworkType {
    lan = 0,
    wifi = 1,
    mobile = 2,
}
