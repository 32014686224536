import { memo } from "react"
import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material"

type Props = LinearProgressProps & {
    value: number
}

export const ProgressBar = memo((props: Props) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%", mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        </Box>
    )
})
