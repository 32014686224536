import { TmaEventTracker } from "./event-tracker"

import { EventCollection } from "./events"

const TmaEventTracking = TmaEventTracker.getInstance()
TmaEventTracking.addCollection(EventCollection)

export { TmaEventTracking }
export * from "./helper/tma"
export * from "./models/models"
