import { getStoredAuthorization, ajax, Dictionary } from "@tm/utils";
import { UserSettingsKeys } from "../model";
import { mapUserContext } from "./mapper";
import { AuthToken, RegisteredModels, UserContext, ViewState, ViewStateContainer } from "@tm/models";
import { hasAuthorization } from "../../helper";
import { Container } from "@tm/nexus";
export * from "./repairshop"

export function getUserContext(authorityServiceUrl: string): Promise<UserContext> {
    const url = `${authorityServiceUrl}/user/v3/GetUserContext`
    const authorization = getStoredAuthorization()

    if (!hasAuthorization(authorization)) {
        return Promise.reject("Authorization is missing")
    }

    return new Promise<UserContext>((resolve, reject) =>
        ajax({ url, authorization })
            .then(data => data ? resolve(mapUserContext(data)) : reject(), reject)
    )
}

export async function getExternalAuthenticationTokens(externalAuthentication: Record<string, string>): Promise<Record<string, AuthToken>> {
    const authorization = getStoredAuthorization()

    if (!hasAuthorization(authorization)) {
        return Promise.reject("Authorization is missing")
    }

    const promises = Object.keys(externalAuthentication).map(key => {
        return ajax<{ token?: string } | undefined>({ url: externalAuthentication[key], authorization })
            .then(
                response => response ? { [key]: response as AuthToken } : {},
                () => ({})
            )
    })

    const responses = await Promise.all(promises)
    return responses.reduce((prev, curr) => ({ ...prev, ...curr }), {})
}

export function getUserSetting(id: UserSettingsKeys): Promise<any> {
    return Container.getInstance<ViewState>(RegisteredModels.ViewState)
        .subscribe(id)
        .load()
        .then(viewState => {
            let response = viewState.value
            let result

            // "!= undefined" because response can be a falsey boolean or empty string which are valid
            if (response != undefined) {
                // Legacy fallback: if response still has nested state "unpack" it
                if (response.hasOwnProperty("state")) {
                    response = response.state
                }

                result = convertDates(response)
            }

            return result
        })
}

export function setUserSetting(key: UserSettingsKeys, value: any) {
    const container: ViewStateContainer = Container.getInstance(RegisteredModels.ViewState)
    return container.action("saveViewState")({ key, value })
}

const regexDate = new RegExp(/^\d{4}\-\d{2}\-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z$/)
function convertDates(state: any) {
    if (!state) return state
    if ((Array.isArray(state))) {
        for (let i = 0; i < state.length; i++) {
            state[i] = convertDates(state[i])
        }
    }
    else if (typeof state == "object") {
        Object.keys(state).forEach(key => {
            state[key] = convertDates(state[key])
        })
    }
    else if (typeof state == "string" && regexDate.exec(state)) {

        state = new Date(state)
    }
    return state
}
