import { UserContext, UserModule, UserModuleType } from "@tm/models"
import { Authorization } from "@tm/utils"

export const hasAuthorization = (authorization: Authorization | undefined) => {
    return !!(authorization && authorization.type && authorization.credentials)
}

export function getCountryFilter(userContext: UserContext | undefined): number | undefined {
    if (userContext && userContext.modules) {
        const catalogModule = userContext.modules.find(x => x.type == UserModuleType.Catalog)

        if (catalogModule) {
            return catalogModule.countryFilter
        }
    }
}

export function getVehicleLookupType(userContext: UserContext | undefined): number | undefined {
    if (userContext && userContext.modules) {
        const vehicleLookupModule = userContext.modules.find(x => x.type == UserModuleType.VehicleLookup)

        if (vehicleLookupModule) {
            return vehicleLookupModule.lookupType
        }
    }
}

const foundModules: Partial<Record<UserModuleType, UserModule>> = {}

export function getModuleFromUserContext(userContext: UserContext, moduleKey: UserModuleType) {
    if (foundModules.hasOwnProperty(moduleKey)) {
        return foundModules[moduleKey]
    }

    const foundModule = userContext.modules?.find(x => x.type == moduleKey)
    foundModules[moduleKey] = foundModule

    return foundModule
}

type ParameterValueType = "string" | "number" | "boolean"
type ParameterValue = string | number | boolean | undefined

export function getParameterFromUserContextModule(userContext: UserContext, moduleKey: UserModuleType, parameterKey: string): string | undefined
export function getParameterFromUserContextModule(userContext: UserContext, moduleKey: UserModuleType, parameterKey: string, valueType: "string"): string | undefined
export function getParameterFromUserContextModule(userContext: UserContext, moduleKey: UserModuleType, parameterKey: string, valueType: "number"): number | undefined
export function getParameterFromUserContextModule(userContext: UserContext, moduleKey: UserModuleType, parameterKey: string, valueType: "boolean"): boolean
export function getParameterFromUserContextModule(userContext: UserContext, moduleKey: UserModuleType, parameterKey: string, valueType?: ParameterValueType): ParameterValue
export function getParameterFromUserContextModule(userContext: UserContext, moduleKey: UserModuleType, parameterKey: string, valueType?: ParameterValueType): ParameterValue {
    const foundModule = getModuleFromUserContext(userContext, moduleKey)

    if (!foundModule) {
        return valueType === "boolean" ? false : undefined
    }

    return getParameterFromModule(foundModule, parameterKey, valueType)
}

export function getParameterFromModule(foundModule: UserModule, parameterKey: string): string | undefined
export function getParameterFromModule(foundModule: UserModule, parameterKey: string, valueType: "string"): string | undefined
export function getParameterFromModule(foundModule: UserModule, parameterKey: string, valueType: "number"): number | undefined
export function getParameterFromModule(foundModule: UserModule, parameterKey: string, valueType: "boolean"): boolean
export function getParameterFromModule(foundModule: UserModule, parameterKey: string, valueType?: ParameterValueType): ParameterValue
export function getParameterFromModule(foundModule: UserModule, parameterKey: string, valueType?: ParameterValueType): ParameterValue {
    const parameterValue = foundModule.parameters?.find((item) => item.key === parameterKey)?.value

    switch (valueType) {
        case "number":
            if (parameterValue && !Number.isNaN(parseInt(parameterValue))) {
                return parseInt(parameterValue)
            }
            return undefined
        case "boolean":
            return parameterValue === "1"
        case "string":
        default:
            return parameterValue
    }
}

type Res<T> =
    T extends "string" ? string | undefined :
    T extends "number" ? number | undefined :
    T extends "boolean" ? boolean :
    string | undefined

export function getParametersFromUserContextModule<T extends Record<string, [string] | [string, ParameterValueType]>>(userContext: UserContext, moduleKey: UserModuleType, parameterKeys: T): { [K in keyof T]: Res<T[K][1]> } {
    const foundModule = getModuleFromUserContext(userContext, moduleKey)

    if (!foundModule) {
        return Object.fromEntries(
            Object.keys(parameterKeys).map((key) => [key, undefined])
        ) as { [K in keyof T]: Res<T[K][1]> }
    }

    return getParametersFromModule(foundModule, parameterKeys)
}

export function getParametersFromModule<T extends Record<string, [string] | [string, ParameterValueType]>>(foundModule: UserModule, parameterKeys: T): { [K in keyof T]: Res<T[K][1]> } {
    return Object.fromEntries(
        Object.keys(parameterKeys).map((key) => [key, getParameterFromModule(foundModule, parameterKeys[key][0], parameterKeys[key][1])])
    ) as { [K in keyof T]: Res<T[K][1]> }
}
