import { styled } from "@mui/material"
import { memo } from "react"
import { Link } from "react-router-dom"
import { Button, ButtonProps } from "./button"

export type LinkButtonProps = ButtonProps & {
    to: string
}

const StyledLink = styled(Link)<LinkButtonProps>(({ theme }) => {
    return {
        textDecoration: "none",
        "&.disabled": {
            color: theme.palette.common.black,
            cursor: "default",
            opacity: 0.38,
        },
    }
})

export const LinkButton = memo<LinkButtonProps>((props) => {
    const { children, to, ...rest } = props
    return (
        <StyledLink to={props.disabled ? "" : to} className={props.disabled ? "disabled" : ""}>
            <Button {...rest}>{children}</Button>
        </StyledLink>
    )
})
