import { Component } from "react"
import { LocalizationProps } from "@tm/localization"
import { bindMethodsToContext } from "../../../helper"
import { Icon, Text } from "../../"
import { ListItemGroup, ListItem } from "../"
import SelectionListItem from "./selection-list-item"

export type Props = LocalizationProps & {
    group: ListItemGroup
    containsSelectedItems: boolean
    initiallyOpened?: boolean
    initiallyShowOthers?: boolean
    onItemToggle(item: ListItem, exclusive: boolean): void
    isItemSelected(item: ListItem): boolean
    canCollapse?: boolean
    itemSize?: "s" | "m"
    onToggleCollapseGroup?(): void
    onToggleShowOthers?(): void
}

export type State = {
    prioritizedItems: PrioritizedItems
    isCollapsed: boolean
    showOthers: boolean
}

export type PrioritizedItems = {
    high: Array<ListItem>
    normal: Array<ListItem>
    low: Array<ListItem>
}

const DEFAULT_SORT_NUMBER = 999999

export default class SelectionListGroup extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)

        const { group, containsSelectedItems, canCollapse, initiallyOpened, initiallyShowOthers } = this.props

        this.state = {
            prioritizedItems: this.getPrioritizedItems(group.items),
            isCollapsed: this.checkCollapsedState(props),
            showOthers: initiallyShowOthers ?? false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({
            prioritizedItems: this.getPrioritizedItems(nextProps.group.items)
        })
    }

    checkCollapsedState = (props: Props) => {
        return !!props.canCollapse && !props.containsSelectedItems && (props.initiallyOpened === false || props.group.priority != "high")
    }

    public toggleOthers() {
        this.handleToggleShowOthers()
    }

    public showOthers() {
        this.setState({ showOthers: true })
    }

    public hideOthers() {
        this.setState({ showOthers: false })
    }

    private getPrioritizedItems(items: Array<ListItem>): PrioritizedItems {
        return {
            high: items.filter(x => x.priority == "high" || x.priority == "high-normal").orderBy(x => x.sortNumber || DEFAULT_SORT_NUMBER),
            normal: items.filter(x => x.priority == "normal" || x.priority == "normal-high").orderBy(x => x.sortNumber || DEFAULT_SORT_NUMBER),
            low: items.filter(x => x.priority == "low" || !x.priority).orderBy(x => x.sortNumber || DEFAULT_SORT_NUMBER),
        }
    }

    private handleToggleCollapse() {
        this.setState(prevState => {
            return {
                isCollapsed: !prevState.isCollapsed,
            }
        })
        this.props.onToggleCollapseGroup?.()
    }

    private handleToggleShowOthers() {
        this.setState(prevState => {
            return {
                showOthers: !prevState.showOthers,
            }
        })
        this.props.onToggleShowOthers?.()
    }

    private renderItem(item: ListItem, idx: number) {
        const { isItemSelected, onItemToggle, itemSize, localization } = this.props

        return (
            <SelectionListItem
                key={idx}
                item={item}
                isSelected={isItemSelected(item)}
                onToggle={onItemToggle}
                size={itemSize}
                localization={localization}
            />
        )
    }

    private renderShowOthersButton() {
        return (
            <li
                className="selection-list__show-others is-clickable"
                onClick={this.handleToggleShowOthers}
            >
                <Text size="xs">{this.props.localization.translate(this.state.showOthers ? 169 : 168)}</Text>
            </li>
        )
    }

    private renderItems() {
        const { isCollapsed, showOthers, prioritizedItems } = this.state

        if (isCollapsed) return

        const { high, normal, low } = prioritizedItems

        const hasPriorityItems = !!high.length
        const hasOtherItems = !!normal.length || !!low.length

        return (
            <>
                {high.map(this.renderItem)}
                <div className={hasPriorityItems ? "selection-list__indent" : ""}>
                    {
                        hasPriorityItems && hasOtherItems &&
                        this.renderShowOthersButton()
                    }
                    {
                        (showOthers || !hasPriorityItems) &&
                        <>
                            {normal.map(this.renderItem)}
                            {
                                !!normal.length && !!low.length &&
                                <li className="selection-list__item selection-list__item--divider"></li>
                            }
                            {low.map(this.renderItem)}
                        </>
                    }
                </div>
            </>
        )
    }

    render() {
        const { itemSize, group, canCollapse } = this.props

        let groupClassName = "selection-list__group-name"
        groupClassName += canCollapse ? " is-clickable" : ""

        return (
            <ul className="selection-list__group">
                {
                    group.name &&
                    <li className={groupClassName} onClick={canCollapse ? this.handleToggleCollapse : undefined}>
                        {
                            canCollapse &&
                            <Icon size={itemSize} name={this.state.isCollapsed ? "down" : "up"} />
                        }
                        <Text modifiers="strong" size={itemSize}>{group.name}</Text>
                    </li>
                }
                {this.renderItems()}
            </ul>
        )
    }
}
