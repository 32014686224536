import { useState, useCallback } from "react"

/**
 * @param getAllIds should be a memoized function with useCallback or useMemo
 */
export function useSelection(getAllIds: () => Array<string>) {
    const [selectedIds, setSelectedIds] = useState<Array<string>>([])

    const toggleSelected = useCallback((id: string) => {
        setSelectedIds((prev) => (prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]))
    }, [])

    const selectAll = useCallback(() => {
        setSelectedIds(getAllIds())
    }, [getAllIds])

    const unselectAll = useCallback(() => {
        setSelectedIds([])
    }, [])

    return { selectedIds, toggleSelected, selectAll, unselectAll }
}
