import { TitleType } from "@tm/models"

export function getTitleTypeTextId(titleType: TitleType): number | undefined {
    switch (titleType) {
        case TitleType.Mister:
            return 145
        case TitleType.Miss:
            return 146
        case TitleType.Company:
            return 108
        case TitleType.Undefined:
        default:
            return undefined
    }
}
