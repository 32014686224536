import { Box, styled, tooltipClasses } from "@mui/material"
import { Tooltip } from "../../../generics/tooltip"
import { HTMLBox } from "../../HTMLBox"
import { Icon } from "../../../generics/Icons"

type Props = {
    additionalInformationExtended?: string
}

const StyledTooltip = styled(Tooltip)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "400px",
    },
})

export function HTMLInformation({ additionalInformationExtended }: Props) {
    return (
        <StyledTooltip title={<HTMLBox innerHTML={additionalInformationExtended} />}>
            <Box width="20px" height="20px" alignSelf="end">
                <Icon name="info" />
            </Box>
        </StyledTooltip>
    )
}
