import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { ButtonProps } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { ShareButton } from "./ShareButton"

type Props = Omit<ButtonProps, "href"> & {
    /**
     * The email address that should be used as recipient of the email.
     */
    recipient?: string

    /**
     * The subject that should be used for the email.
     */
    subject?: string

    /**
     * The body that should be used for the email (should contain the link).
     *
     * When set to `undefined` the button is disabled.
     */
    body: string | undefined
}

export function EmailButton({ recipient, subject, body, ...buttonProps }: Props) {
    const { translate } = useLocalization()

    const href = useMemo(() => {
        if (body) {
            let message = `mailto:${recipient ?? ""}?body=${encodeURIComponent(body)}`

            if (subject) {
                message += `&subject=${subject}`
            }

            return message
        }
    }, [recipient, body, subject])

    return (
        <ShareButton target="_blank" startIcon={<Icon name="envelope" />} disabled={!href} {...buttonProps} href={href}>
            {translate(109)}
        </ShareButton>
    )
}
