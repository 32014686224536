import { LinearProgress, PaperProps, Stack, styled } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { useCallback, useEffect, useMemo } from "react"
import { Icon } from "../../../generics/Icons"
import { Button } from "../../../generics/button"
import { Typography } from "../../../generics/typographie"
import { useCopy } from "../hooks/useCopy"
import { Paper } from "../../../generics/paper"

const LinearLoader = styled(LinearProgress)({
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
})

const LinkPaper = styled(Paper, {
    shouldForwardProp: (prop) => prop !== "hasError",
})<PaperProps & { hasError: boolean }>(({ theme, hasError }) => ({
    width: "600px",
    minWidth: "100%",
    position: "relative",
    border: `solid 1px ${hasError ? theme.palette.error.main : theme.palette.background.paper}`,
}))

const RowStack = styled(Stack)({})
RowStack.defaultProps = {
    direction: "row",
    alignItems: "center",
    spacing: 2,
}

const StyledTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "hasError" && prop !== "errorMessage" && prop !== "link",
})<{ link?: string; errorMessage?: string }>(({ theme, link, errorMessage }) => ({
    userSelect: link ? "all" : undefined,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    opacity: !link && !errorMessage ? theme.opacity?.disabled : undefined,
}))

type Props = {
    url: string | undefined
    isLoading: boolean
    generateAction: () => void
    preventAutomaticGeneration?: boolean
    errorMessage?: string
}

export const SharingLink = ({ url, errorMessage, isLoading, generateAction, preventAutomaticGeneration }: Props) => {
    const { translateText } = useLocalization()
    const { buttonText: copyButtonText, copyToClipboard } = useCopy()

    const buttonText = useMemo(() => {
        if (url) {
            return copyButtonText
        }

        if (errorMessage) {
            return translateText(13297)
        }

        return translateText(13298)
    }, [url, copyButtonText, errorMessage, translateText])

    const handleCopyLink = useCallback(() => {
        if (url) {
            copyToClipboard(url)
        }
    }, [url, copyToClipboard])

    useEffect(() => {
        if (!url && !preventAutomaticGeneration) {
            generateAction()
        }
    }, [url, generateAction, preventAutomaticGeneration])

    return (
        <LinkPaper hasError={!url && !!errorMessage}>
            <RowStack>
                {!url && !!errorMessage && <Icon name="alert_W_warning" color="error" />}
                <StyledTypography link={url} errorMessage={errorMessage} flex={1}>
                    {url ?? errorMessage ?? translateText(13300)}
                </StyledTypography>
                <Button onClick={!url ? generateAction : handleCopyLink} startIcon={url ? <Icon name="copy" /> : undefined} disabled={isLoading}>
                    {buttonText}
                </Button>
            </RowStack>
            {isLoading && <LinearLoader />}
        </LinkPaper>
    )
}
