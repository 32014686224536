import { useMemo } from "react"
import { Stack, styled } from "@mui/material"
import { ArticleAttributeParams } from "@tm/models"
import { Typography } from "../../../generics/typographie"
import { Divider } from "../../../generics/divider"
import { AttributeValue } from "./AttributeValue"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

const AttributeLabel = styled(Typography, { shouldForwardProp: (prop: string) => !["isHighlighted"].includes(prop) })<{ isHighlighted?: boolean }>(
    ({ theme, isHighlighted }) => ({
        fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
        whiteSpace: "nowrap",
        padding: "3px 0",
        height: "100%",
        border: "1px solid transparent",
        borderRadius: theme.radius?.default ?? 3,
        flexShrink: 1,
        flexGrow: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginRight: 3,
        ...(isHighlighted && {
            paddingLeft: 4,
            paddingRight: 4,
            borderColor: theme.palette.highlight.main,
            backgroundColor: theme.palette.highlight.light,
            fontWeight: 600,
        }),
    })
)
AttributeLabel.defaultProps = { variant: "label" }

type AttributeItemElementProps = {
    hasBackground?: boolean
    isHighlighted?: boolean
    isCompact?: boolean
}

const AttributeItemElement = styled(Stack, {
    shouldForwardProp: (prop: string) => !["hasBackground", "isHighlighted"].includes(prop),
})<AttributeItemElementProps>(({ theme, hasBackground, isHighlighted, isCompact }) => ({
    padding: "0 4px",
    flexWrap: isCompact ? "nowrap" : "wrap",
    maxWidth: "100%",
    alignItems: "baseline",
    alignContent: "center",
    whiteSpace: "nowrap",
    flex: "none",
    minWidth: 0,

    ...(isHighlighted && {
        "> div": {
            backgroundColor: theme.palette.highlight.light,
        },
    }),
    ...(hasBackground && {
        backgroundColor: "#f0f0f0",
    }),
}))

AttributeItemElement.defaultProps = {
    direction: "row",
    spacing: "6px",
}

export type AttributeItemProps = {
    hasBackground?: boolean
    hideDivider?: boolean
    hide?: boolean
    attribute: ArticleAttributeParams
    onClickAttributeValue?(id: number, value?: string): void
    dividerInFront?: boolean
}

export function AttributeItem(props: AttributeItemProps) {
    const {
        hasBackground,
        hideDivider,
        attribute: { id, values, label },
        onClickAttributeValue,
        hide,
        dividerInFront,
    } = props

    const handleClickValue = useMemo(
        () => (onClickAttributeValue ? (value: string) => onClickAttributeValue(id, value) : undefined),
        [onClickAttributeValue, id]
    )

    const isHighlighted = values?.length === 1 && values.some((x) => !x.value && x.isHighlighted)

    if (hide) {
        return null
    }

    const isCompactView = useDefaultArticleItemState((x) => x.options.compactView)
    return (
        <>
            {!hideDivider && dividerInFront && <Divider orientation="vertical" flexItem />}
            <AttributeItemElement hasBackground={hasBackground} isHighlighted={isHighlighted} isCompact={isCompactView}>
                {label && <AttributeLabel isHighlighted={isHighlighted}>{label}</AttributeLabel>}
                {values
                    ?.filter((x) => !!x.value)
                    .map((value) => (
                        <AttributeValue key={`${value.id}_${value.value}`} valueData={value} onClick={handleClickValue} />
                    ))}
                {!hideDivider && !dividerInFront && <Divider orientation="vertical" flexItem />}
            </AttributeItemElement>
        </>
    )
}
