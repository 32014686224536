import { styled } from "@mui/material"
import { TableVariants } from "../interfaces"
import { BORDER_RADIUS, LEFT_ROW_INDICATOR } from "../configs"

type ShadowProps = {
    colCount: number
    rowCap?: number
    active?: boolean
    variant?: TableVariants
    rowIndicatorColor?: string
    transparent?: boolean
}
type ShadowType = React.FC<ShadowProps & React.HTMLAttributes<HTMLDivElement>>

function shouldForwardProp(prop: PropertyKey) {
    return !["colCount", "active", "rowCap", "variant", "rowIndicatorColor"].includes(prop as string)
}

export const Shadow: ShadowType = styled("div", { shouldForwardProp })<ShadowProps>(
    ({ theme, colCount, rowCap, active, variant, rowIndicatorColor, transparent }) => {
        const elementListOverwrites = theme.overwrites?.components?.elementList ?? {}
        const overwritesBorderLeftWidth = elementListOverwrites?.backgroundColor?.borderLeftWidth
        const borderLeftWidth = overwritesBorderLeftWidth || LEFT_ROW_INDICATOR
        return {
            zIndex: 0,
            gridColumnStart: 1,
            gridColumnEnd: `span ${colCount}`,
            height: variant === "small" ? "1px" : 1,
            marginBottom: `${rowCap}px`,
            filter: "drop-shadow(0 0 2px rgba(0, 0, 0, 0.3))",
            // borderLeft: `${active ? 1 : borderLeftWidth}px solid ${transparent ? "transparent" : rowIndicatorColor || theme.palette.grey[200]}`,
            borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
            backgroundColor: active ? `white !important` : "inherit",
            borderBottom: `1px solid ${transparent ? "transparent" : "rgba(0,0,0,0.1)"}`,
        }
    }
)
