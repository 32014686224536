import * as React from "react"
import Icon from "../icon"

export type SidebarWidgetProps = {
    children: React.ReactNode
    title: string
    icon: string
    className?: string
    id?: string
}

export default function SidebarWidget(props: SidebarWidgetProps) {
    return (
        <div className={`sidebar ${props.className}`} id={props.id}>
            <div className="sidebar-title-content">
                <div className="sidebar-icon">
                    <Icon name={props.icon} size="l" />
                </div>
                <div className="sidebar-title">{props.title}</div>
            </div>
            <div className="sidebar-content">
                {props.children}
            </div>
        </div>
    )
}
