import { KeyValuePair } from "../models/configuration";

const ValidTypesMap = {
    "switch": true,
    "component": true,
    "module": true,
    "reference": true,
    "undefined": true,
}

export function validType(kvp: KeyValuePair) {
    if (!kvp.value || ValidTypesMap[kvp.value.type]) {
        return true
    } else {
        console.error(`Configuration error: the key "${kvp.key}" has a invalid type: "${kvp.value.type}".`)
        return false
    }
}
