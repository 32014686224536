import { PagingDto, ArticleListFilteredRequest } from "../events/article-list-filtered/models"
import { SearchExecutedRequest } from "../events/premium-uni-parts-search/models"
import { VehicleSelectionLog } from "../events/vehicle-selection/models"

export type { AnalyticsData } from "../events/article-list-filtered/models"

export enum QuerySearchType {
    Undefined = 0,
    Direct = 1,
    Synonym = 2,
    ErrorCode = 3,
    ProductGroup = 4
}

export interface BaseTrackingModel<P> {
    /**
     *  @property type of event e.g. ArticleListFiltered
     */
    tmaEvent: string
    /**
     * @property name of the bundle
     */
    bundle: string
    /**
     * @property name of the component
     */
    component: string
    /**
     * @property which action/method/function is called e.g. getArticles
     */
    action: string | Array<string>
    /**
     * @property type of the action, like request, input, click
     */
    type: string
    /**
     * @property some informations like vehicle id oder query
     */
    payload?: P
    /**
     * @property start of the tracking
     */
    source: string
    /**
     * @property handle this as a standalone/fireOnce event
     */
    singleEvent?: boolean
    /**
     * @property use this property to store any comprehensive context related data
     */
    contextData?: {
        paging?: PagingDto
    }
}
export interface TrackingModel<P> extends BaseTrackingModel<P> {
    /**
     * @property initiate an event queue to generate a process id
     */
    initiateProcess?: boolean
}

export type TmaEvent = {
    key: string
    /**
     * @todo implement typescript generic variable definition of the trackingmodel depending on the parameter tmaEvent
     * @param data Event data structure
     * @returns indicator for event completed
     */
    handle: ((data: TrackingModel<any>) => Promise<void> | boolean | void) | ((data: BaseTrackingModel<any>) => Promise<void> | boolean | void)
}

export interface EventRequestType {
    (key: "ARTICLE_LIST_FILTERED"): ArticleListFilteredRequest | undefined
    (key: "PREMIUM_UNI_PARTS_SEARCH"): SearchExecutedRequest | undefined
    (key: "VEHICLE_SELECTION"): VehicleSelectionLog | undefined
}

export type Filter = {
    id: number
    name?: string
    group?: string
    query?: string
    priority?: number
    isTopPriority?: boolean
    articleCount?: number
    topPriorityArticleCount?: number
    isSelected?: boolean
}
