import { useMemo } from "react"
import { getModuleFromUserContext } from "../../helper"
import { useUser } from "../../user/consumer/useUser"
import { PriceType, UserModuleType } from "@tm/models"

/**
 * @description this hook returns a function to check if a specific price type should be displayed or not. it validates the mdm property "hidePurchasePriceTypes" if the pricetype is included and if the property "showPurchasePrice" set by mdm or  if the user disabled the "showPurchasePrice" manually
 * @returns {isPriceTypeHidden: (priceType: PriceType) => boolean } returns a function to check if a pricetype should be shown or hidden
 */
export function useHidePriceTypes(): { isPriceTypeHidden: (priceType: PriceType) => boolean } {
    const { userContext, userSettings } = useUser()
    const catalogModule = getModuleFromUserContext(userContext, UserModuleType.Catalog)
    const hiddenByUserSettings = userSettings?.showPurchasePrice === false

    return useMemo(() => ({
        isPriceTypeHidden: (priceType: PriceType) => {
            return catalogModule?.hidePurchasePriceTypes?.includes(priceType) && hiddenByUserSettings
        }
    }), [catalogModule?.showPurchasePrice, catalogModule?.hidePurchasePriceTypes, hiddenByUserSettings])
}

