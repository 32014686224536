import { Stack, styled } from "@mui/material"
import { SpecialIcon } from "./ErpContainer/SpecialIcon"
import { ErpIconPosition, useErpSpecialIcons } from "../../hooks/useErpSpecialIcons"

export const ErpIconsWrapper = styled(Stack)({
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "4px",

    "&:empty": {
        display: "none",
    },
}) as typeof Stack

type Props = {
    position: ErpIconPosition
}

export function ErpIcons({ position }: Props) {
    const erpSpecialIcons = useErpSpecialIcons(position)

    return (
        <>
            {erpSpecialIcons.map((x) => (
                <SpecialIcon key={x.useIconFromUrl ? x.url : x.topmotiveId} icon={x} />
            ))}
        </>
    )
}
