import { GetCatMemoVKNRequest, MemoToolContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"
import { useKeyValue } from "../statemanagement"

const KEY = "memotool_useVehicleModelMemoInfo"

export function useVehicleModelMemoInfo(request: GetCatMemoVKNRequest, vehicleId?: string, memoToolEnabled?: boolean) {
    const [savedVehicleMemoInfo, saveVehicleMemoInfo, stateSavedVehicleMemoInfo] = useKeyValue({
        key: `MEMOTOOL_VEHICLEMEMOINFO_${vehicleId}`,
        ownedByRepairShop: false,
    })

    const [savedCompletedSteps, updateSavedCompletedSteps, stateSavedCompletedSteps] = useKeyValue({
        key: `MEMOTOOL_VEHICLEMEMOINFO_COMPLETEDSTEPS_${vehicleId}`,
        ownedByRepairShop: false,
    })

    const queryEnabled = !!request.manufacturerId && !!vehicleId && memoToolEnabled

    const { data, isLoading, error } = useQuery(
        [KEY, vehicleId, request],
        () => {
            const container: MemoToolContainer = Container.getInstance(RegisteredModels.MemoTool)
            return container.action("getKTypeMemoInfo")(request)
        },
        {
            staleTime: 60 * 60 * 1000,
            cacheTime: 60 * 60 * 1000,
            enabled: queryEnabled,
        }
    )

    if (error || stateSavedVehicleMemoInfo == "hasError" || stateSavedCompletedSteps === "hasError") {
        return {
            vehicleMemoInfoError: error,
        }
    }

    if (stateSavedVehicleMemoInfo == "loading" || isLoading || stateSavedCompletedSteps === "loading") {
        return {
            vehicleMemoInfoIsLoading: true,
        }
    }

    if (stateSavedVehicleMemoInfo == "hasValue" && !!savedVehicleMemoInfo?.length && !!data && !!Object.keys(data)?.length) {
        const dataHash = JSON.stringify(data)?.hashCode()?.toString()

        if (dataHash != savedVehicleMemoInfo && !!saveVehicleMemoInfo) {
            return {
                vehicleMemoInfo: data,
                saveVehicleMemoInfo,
                memoWasConfirmed: false,
                stepsChanged: true,
                vehicleHasMemoInfo: true,
                showVehicleMemoDialog: true,
                savedCompletedSteps: savedCompletedSteps ? (JSON.parse(savedCompletedSteps) as Record<string, string[]>) : {},
                updateSavedCompletedSteps,
            }
        }

        return {
            vehicleMemoInfo: data,
            memoWasConfirmed: true,
            showVehicleMemoDialog: false,
            vehicleHasMemoInfo: true,
            savedCompletedSteps: savedCompletedSteps ? (JSON.parse(savedCompletedSteps) as Record<string, string[]>) : {},
            updateSavedCompletedSteps,
        }
    }

    return {
        vehicleMemoInfo: data,
        saveVehicleMemoInfo,
        memoWasConfirmed: false,
        showVehicleMemoDialog: !!data && !!Object.keys(data)?.length,
        vehicleHasMemoInfo: !!data && !!Object.keys(data)?.length,
        savedCompletedSteps: savedCompletedSteps ? (JSON.parse(savedCompletedSteps) as Record<string, string[]>) : {},
        updateSavedCompletedSteps,
    }
}
