export interface EventRule {
    isFulfilled(...elements: Element[]): boolean
    eventName: EventListenerEvents
    // getRule() : EventRules
}

export type EventListenerEvents = "outsideClick"
//  | "scroll"

export type ListenerItem = {
    element: Element
    handler: (e: Event) => void
    afterHandle?: () => void
}

export abstract class EventInterruptionHandler {
    nextHandler?: EventInterruptionHandler

    abstract ev: string

    public addHandler(nextHandler: EventInterruptionHandler) {
        if (this.nextHandler) {
            this.nextHandler.addHandler(nextHandler)
        } else {
            this.nextHandler = nextHandler
        }
    }

    abstract process: (ev: EventListenerEvents, currentElement: Element, sourceElement: Element, eventListeners: ListenerItem[], e: Event) => boolean

    processor(ev: EventListenerEvents, currentElement: Element, sourceElement: Element, eventListeners: ListenerItem[], e: Event): boolean {
        if (this.ev != ev) {
            return false
        }

        let result = this.process(ev, currentElement, sourceElement, eventListeners, e)
        if (!result) {
            result = this.nextHandler?.processor(ev, currentElement, sourceElement, eventListeners, e) || false
        }

        return result
    }
}
