import { Action, BundleComponent } from "@tm/morpheus"
import { CommunicationChannel } from "../channel/communicationChannel"
import { getComponentFromBundle } from "./getComponentFromBundle"

export async function getComponent<C extends { default: BundleComponent }>(
    bundleComponentPromise: Promise<C>,
    communicationChannel?: CommunicationChannel,
    communicationId?: string
): Promise<NonNullable<C["default"]["component"]>> {
    const bundleComponent = await bundleComponentPromise

    return getComponentFromBundle(bundleComponent.default, communicationChannel, communicationId)
}

export async function getNamedComponent<C extends { [K in keyof C]: BundleComponent }, K extends keyof C>(
    bundleComponentPromise: Promise<C>,
    componentName: K,
    communicationChannel?: CommunicationChannel,
    communicationId?: string
): Promise<NonNullable<C[K]["component"]>> {
    const bundleComponent = await bundleComponentPromise

    return getComponentFromBundle(bundleComponent[componentName], communicationChannel, communicationId)
}
