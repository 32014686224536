import { EventRule, EventListenerEvents } from "../models"

export class OutsideClickRule implements EventRule {
    eventName: EventListenerEvents = "outsideClick"

    isFulfilled = (initialClickElement: Element, eventElement: Element, listenerElement: Element) => {
        let isOutside = this.isOutsideListenerElement(initialClickElement, listenerElement)
        isOutside = isOutside && this.isOutsideListenerElement(eventElement, listenerElement)
        return isOutside
    }

    private isOutsideListenerElement = (outsideElement: Element, sourceElement: Element) => {
        let currentElement: Element | null = outsideElement

        while (currentElement) {
            // don't send an outsideclick from a tooltip shown by a modal
            if (sourceElement.className.split(" ").indexOf("modal") != -1 && currentElement.attributes.getNamedItem("role")?.value === "tooltip") {
                return false
            }
            if (currentElement.attributes.getNamedItem("role")?.value === "presentation") {
                return false
            }
            if (currentElement === sourceElement) {
                return false
            }

            currentElement = currentElement.parentElement
        }
        return true
    }

    private initialElementIsOutside(initialClickElement: Element, listenerElement: Element) {
        let currentElement: Element | null = initialClickElement

        while (currentElement) {
            if (currentElement === listenerElement) {
                return false
            }

            currentElement = currentElement.parentElement
        }
        return true
    }
}
