import React from "react"
import PropTypes from 'prop-types'
import { errorListener } from ".";
import { DefaultError } from "./DefaultError"

type ComponentProps = {
    errorComponent?: React.ComponentType
}
export default class ErrorBoundary extends React.Component<ComponentProps, any> {

    static contextTypes = {
        routes: PropTypes.array
    }

    getContext() {
        return {
            routes: [ ...(this.context.routes || []), this.context.router.route.match.path ]
        }
    }

    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    // Error handling
    componentDidCatch(error: any, info: any) {
        errorListener.forEach(listener => {
            try {
                listener(error)
            } finally {}
        })
        this.setState({ hasError: true });
        console.error(error, info);
    }

    render() {
        if (this.state.hasError) {
            if (this.props.errorComponent) {
                return React.createElement(this.props.errorComponent)
            }
            return <DefaultError />
        }
        return this.props.children
    }
}