import { ShowMessageProps } from "@tm/models"

export enum ShareType {
    WhatsApp = "WhatsApp",
    Email = "Email",
    Carteligence = "Carteligence",
    Download = "Download",
}

type ToastMessage = Omit<ShowMessageProps, "message"> & {
    message: string | number
}

/**
 * @property {ShareType} type - Defined the share type.
 * @property {string} icon - Set the icon of the share, for example envelope in case of email.
 * @property {string | number} title - If its a number it will be translated, if string it will render it.
 * @property {string | number} shareActionButtonLabel - The text on the share action button. If its a number it will be translated, if string it will render it.
 * @property {success: ShowMessageProps | undefined, failure: ShowMessageProps | undefined} toastMessage - Show a toast message when complete or fail
 * @property {(url: string) => string} [contentOverride] - This function receives the url from the share and needs to return a content. By default only URL will be shared
 */
export type BaseShareOption = {
    type: ShareType
    icon: string
    title: string | number
    shareActionButtonLabel: string | number
    disabled?: boolean
    toastMessage?: {
        success?: ToastMessage
        failure?: ToastMessage
    }
    contentOverride?: (url: string) => string | Promise<string>
}

/**
 * @extends BaseShareOption
 * @property {(content: string) => void} [handler] - This handler will take care of the share itself
 */
export type WhatsAppShareOption = BaseShareOption & {
    type: ShareType.WhatsApp
    handler: (language: string, content: string, recipient?: string) => boolean | Promise<boolean>
}

/**
 * @extends BaseShareOption
 * @property {(content: string) => void} [handler] - This handler will take care of the share itself
 */
export type CarteligenceShareOption = BaseShareOption & {
    type: ShareType.Carteligence
    handler: (content: string) => boolean | Promise<boolean>
}

/**
 * @extends BaseShareOption
 * @property {string[]} initialEmails - Sometimes a default set of emails needs to be set
 * @property {string[]} bccEmails - If shadow copy option should be available, this needs to be set
 * @property {(subject: string, content: string, emails: string[], bcc?: string[]) => void} [handler] - This handler will take care of the share itself
 */
export type EmailShareOption = BaseShareOption & {
    type: ShareType.Email
    subject: string
    initialEmails: string[]
    bccEmails?: string[]
    handler: (subject: string, content: string, emails: string[], bcc?: string[]) => boolean | Promise<boolean>
}

/**
 * @extends BaseShareOption
 * @property {(content: string) => void} [handler] - This handler will take care of the download
 */
export type DownloadShareOption = BaseShareOption & {
    type: ShareType.Download
    handler: (content: string) => boolean | Promise<boolean>
}

export type ShareOption = EmailShareOption | WhatsAppShareOption | CarteligenceShareOption | DownloadShareOption
