import { MouseEvent } from "react"
import { useLocalization } from "@tm/localization"
import { Box, styled } from "@mui/material"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { BasketButton } from "../../BasketButton"
import { Badge } from "../../../../generics/Badge"
import { Icon } from "../../../../generics/Icons"
import { Tooltip } from "../../../../generics/tooltip"

type AddToBasketButtonProps = {
    onAddToBasket(quantity?: number): void
    onRemoveFromBasket(): void
    basketQuantity?: number
    hideBasketQuantity?: boolean
    disabled: boolean
}

const QuantityBadge = styled(Badge)({
    cursor: "pointer",
    ".MuiBadge-badge": {
        height: "auto",
        lineHeight: 1,
        padding: "2px 2px 2px 6px",
        transform: "translate(-4px, -40%)",
        margin: 0,
    },
})
const RemoveIcon = styled(Box)(({ theme }) => ({
    borderRadius: 14,
    border: "1px solid white",
    marginLeft: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 14,
    height: 14,
    ":hover": {
        background: theme.palette.grey[400],
        color: "black",
    },
}))
RemoveIcon.defaultProps = {
    children: <Icon name="close" width="10px" height="10px" />,
}

export function AddToBasketButton(props: AddToBasketButtonProps) {
    const { onAddToBasket, onRemoveFromBasket, basketQuantity, hideBasketQuantity, disabled } = props
    const { translateText } = useLocalization()

    const handleRemoveFromBasket = (e: MouseEvent) => {
        e.stopPropagation()
        onRemoveFromBasket()
    }

    const handleAddToBasket = (e: MouseEvent) => {
        e.stopPropagation()
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListAddToBasket)
        onAddToBasket()
    }

    return (
        <div>
            <QuantityBadge
                size="small"
                title={translateText(1299)}
                invisible={!basketQuantity || hideBasketQuantity}
                badgeContent={
                    basketQuantity && (
                        <>
                            {basketQuantity}
                            <Tooltip title={translateText(13580)}>
                                <RemoveIcon onClick={handleRemoveFromBasket} />
                            </Tooltip>
                        </>
                    )
                }
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <Tooltip title={translateText(937)}>
                    <BasketButton sx={{ width: "100px" }} onClick={handleAddToBasket} className="addToBasketButton" disabled={disabled} />
                </Tooltip>
            </QuantityBadge>
        </div>
    )
}
