import { Component } from "react"
import { SizedProps, Colored } from "../../models/SharedModels"

export type IconProps = SizedProps & Colored & {
    name: string
    path?: string
}

export default class Icon extends Component<IconProps> {
    render() {
        const { className, skin } = this.props
        let fullClassName = "icon"

        if (className) {
            fullClassName = `${fullClassName} ${className}`
        }

        if (skin) {
            fullClassName = `${fullClassName} icon--${skin}`
        }

        fullClassName += " icon--" + (this.props.size || "m")

        const href = `#${this.props.name}`

        return (
            <svg className={fullClassName}>
                <use xlinkHref={href}></use>
            </svg>
        )
    }
}
