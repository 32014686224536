import { Tabs as MuiTabs, styled, TabsProps as MuiTabsProps } from "@mui/material"

type TabsProps = MuiTabsProps & {
    size?: "small" | "medium"
}

const StyledTabs = styled(MuiTabs)<TabsProps>(({ theme, size, variant }) => {
    const tabOverwrites = theme.overwrites?.components?.tabs ?? {}

    const generalProperties = {
        fontFamily: tabOverwrites?.font?.fontFamily ? tabOverwrites?.font?.fontFamily : theme.typography.fontFamily,
        textTransform: theme.overwrites?.components?.tabs?.font?.textTransform ?? "none",
        fontSize: size === "small" ? theme.typography.fontSize - 2 : theme.typography.fontSize,
    }

    return {
        "& .MuiTab-root": {
            ...generalProperties,
            minWidth: 0,
            minHeight: 0,
            padding: "11px 10px 4px 10px",
            borderBottom: "3px solid lightgray",
            background: "none",
            position: "relative",
            margin: "0 4px 0 8px",

            "&:hover": {
                color: "#2e2e2e",
            },
        },
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.primary.main,
            height: "3px",
            bottom: "10px",
            ...(size === "small" && {
                transform: "translate(0, -8px)",
                bottom: "5px",
            }),
        },
        "& .MuiTab-root.Mui-selected": {
            color: "#2e2e2e",
        },
        "& .MuiTabs-scrollButtons.Mui-disabled": {
            opacity: theme.opacity?.disabled,
        },

        ...(variant === "standard" && {
            "& .MuiTabs-root": {
                backgroundColor: "#e2e2e2",
            },
            "& .MuiTabs-flexContainer": {
                backgroundColor: "#e2e2e2",
                border: "1px solid lightGray",
                borderTopLeftRadius: theme.radius?.default || "4px",
                borderTopRightRadius: theme.radius?.default || "4px",
            },
            "& .MuiTab-root": {
                ...generalProperties,
                backgroundColor: "#e2e2e2",

                "&:hover": {
                    backgroundColor: "#4d4d4d",
                    color: "white",
                },
            },
            "& .Mui-selected": {
                backgroundColor: "#ffffff",
                borderRight: "1px solid lightGray",

                "&:hover": {
                    backgroundColor: "#4d4d4d",
                    color: "white",
                },
            },
            "& .MuiTabs-flexContainer :not(:first-child).Mui-selected": {
                borderLeft: "1px solid lightGray",
            },
            "& .MuiTabs-indicator": {
                display: "none",
            },
        }),
    }
})

export function Tabs(props: TabsProps) {
    const { children, value } = props

    return (
        <StyledTabs value={value} {...props}>
            {children}
        </StyledTabs>
    )
}
