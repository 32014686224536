import { RegisteredModels, RegistrationNoType, ShowCarModelDetailsResponse, VehicleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { selectorFamily, useRecoilValue } from "recoil"

export type RequestWithVehicleType = {
    vehicleType: VehicleType
}

type ModelDetailsRequest = Partial<RequestWithVehicleType> & {
    modelId?: number
    selectedLookupType?: RegistrationNoType
    registrationNoTypeId?: RegistrationNoType
}

type ModelDetailsResponse = ShowCarModelDetailsResponse | undefined

export const modelDetailsSelector = selectorFamily<ModelDetailsResponse, ModelDetailsRequest>({
    key: "vehicle_modelDetails",
    get:
        ({ vehicleType, modelId }) =>
        async () => {
            if (!vehicleType || !modelId) {
                return
            }

            return Container.getInstance<ShowCarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
                .subscribe({ modelId }, vehicleType)
                .load()
        },
})

export function useModelDetails(request: ModelDetailsRequest) {
    return useRecoilValue(modelDetailsSelector(request))
}
