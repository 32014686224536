import { Warehouse } from "@tm/models"

export function mapPrices(prices: Warehouse["prices"]) {
    if (!prices || Array.isArray(prices)) {
        return prices
    }
    return Object.keys(prices)
        .map((key) => prices[key])
        .filter((x) => x)
}
