import * as React from "react"
import Icon from "../icon"
import { WidgetHeader, WidgetContent, WidgetFooter, WidgetTitle, WidgetCover, WidgetTitleText, WidgetImage, WidgetIcon } from "./components"
import { bindMethodsToContext } from "../../helper"
export * from "./components"
import { style } from "typestyle"
import { Demo, Text } from ".."

export type WidgetSizes =
    | "1x1" | "1x2" | "1x3" | "1x4" | "1x5"
    | "2x1" | "2x2" | "2x3" | "2x4" | "2x5"
    | "3x1" | "3x2" | "3x3" | "3x4" | "3x5"
    | "4x1" | "4x2" | "4x3" | "4x4" | "4x5"
    | "5x1" | "5x2" | "5x3" | "5x4" | "5x5"
    | "6x1" | "6x2" | "6x3" | "6x4" | "6x5"
    | "12x1" | "12x2" | "12x3" | "12x4" | "12x5"

export type WidgetProps = {
    size?: WidgetSizes
    height?: number
    variableHeight?: boolean
    className?: string
    collapsible?: boolean
    tinyWidget?: boolean
    title?: string | React.ReactNode
    iconName?: string
    imageUrl?: string
    active?: boolean
    highlight?: boolean
    indicator?: boolean
    cover?: JSX.Element
    header?: JSX.Element
    footer?: JSX.Element
    hideCover?: boolean
    blockedByCondition?: boolean
    onClick?: React.MouseEventHandler<any>
    onDoubleClick?: React.MouseEventHandler<any>
    tooltip?: string
    notAvailableMsg?: string
    notAvailableIcon?: string
    id?: string
    forwardedRef?: React.MutableRefObject<HTMLDivElement | null> | ((instance: HTMLDivElement | null) => void)
    displayDemoBadge?: boolean
    fullHeight?: boolean
    appendToHeader?: JSX.Element
    hidePlaceholders?: boolean
}

export type WidgetStates = {
    collapsed: boolean
}

export type WidgetElements = {
    Header?: JSX.Element
    Footer?: JSX.Element
    Cover?: JSX.Element
}


/**
 * Class Widget will set the frame for a widget, which are used e.g. by the dashboard
 */
export default class Widget extends React.Component<WidgetProps, WidgetStates> {
    public static Cover = WidgetCover
    public static Content = WidgetContent
    public static Header = WidgetHeader
    public static Footer = WidgetFooter
    private internalComponents: WidgetElements
    private defaultWidgetSize: string

    constructor(props: WidgetProps) {
        super(props)
        bindMethodsToContext(this)

        this.internalComponents = {
            Cover: props.cover,
            Footer: props.footer,
            Header: props.header
        }

        this.state = {
            collapsed: false
        }

        this.getSizeClassnames = this.getSizeClassnames.bind(this)
        this.defaultWidgetSize = this.getSizeClassnames("4x2")
    }

    handleChange(e: any) {
        this.setState(state => ({ collapsed: !state.collapsed }))
    }

    UNSAFE_componentWillReceiveProps(props: WidgetProps) {
        this.internalComponents = {
            Cover: props.cover,
            Footer: props.footer,
            Header: props.header
        }
    }

    render() {
        const { className, collapsible, size, active, highlight, indicator, tooltip, notAvailableMsg, blockedByCondition, notAvailableIcon, height, id, fullHeight } = this.props
        const { collapsed } = this.state

        const widgetClassname = `${collapsed ? " widget--collapsed" : ""}${highlight ? " widget--highlight" : ""}${indicator ? " widget--indicator" : ""}${" " + (size ? this.getSizeClassnames(size) : this.defaultWidgetSize)}${active ? " is-open" : ""}`

        let widgetStyle = height ? { height: `${height}em` } : undefined
        widgetStyle = fullHeight ? {height: "100%"} : widgetStyle

        return (
            <div className={`widget${widgetClassname} ${className}`} id={id} onClick={this.handleClick} onDoubleClick={this.handleDoubleClick} style={widgetStyle} ref={this.props.forwardedRef}>
                <div className="widget__card" title={tooltip}>
                    {(!active || blockedByCondition) && notAvailableMsg &&
                        <div className={vehicleCover}>
                            <span>
                                <Text modifiers="strong" size="m">{notAvailableMsg}</Text>
                                {notAvailableIcon &&
                                    <>
                                        <br />
                                        <Icon className="" name={notAvailableIcon} />
                                    </>
                                }
                            </span>
                        </div>
                    }
                    {collapsible ? <div className="widget__collapse" onClick={this.handleChange}><Icon className="" name={collapsed ? "down" : "up"} /></div> : ''}
                    {
                        active ?
                            <div className="widget__inner">{this.renderOpenWidget()}</div> :
                            this.renderCover()
                    }
                </div>
            </div>
        )
    }

    renderOpenWidget() {
        return (
            <>
                {this.renderHeader()}
                {this.renderContent()}
                {this.renderFooter()}
            </>
        )
    }

    renderHeader() {
        return this.internalComponents.Header || defaultHeader({ ...this.props })
    }

    renderContent() {
        return <WidgetContent key="widget__content">{this.props.children}</WidgetContent>
    }

    renderFooter() {
        return <div className="widget__footer">{this.internalComponents.Footer}</div>
    }

    renderCover() {
        if (this.props.hideCover)
            return null

        return this.internalComponents.Cover || defaultCover({ ...this.props })
    }

    handleClick(e: any) {
        this.props.onClick?.(e)
    }

    handleDoubleClick(e: any) {
        this.props.onDoubleClick?.(e)
    }

    getSizeClassnames(widgetSize: WidgetSizes): string {
        const size = /(\d+)x(\d+)/.test(widgetSize) ? widgetSize: this.defaultWidgetSize
        let dimension = /(?<width>\d+)x(?<height>\d+)/.exec(size)?.groups

        if(dimension) {
            return `widget--w${dimension.width} ${!this.props.variableHeight ? `widget--h${dimension.height}` : ""}`
        }

        return `widget--w4${!this.props.variableHeight ? ` widget--h2 ` : " "}`
    }
}

const defaultImageOrIcon = (props: any) => {
    const { iconName, imageUrl } = props
    let IconOrImage = iconName ? <WidgetIcon iconName={props.iconName} size="s" className="widget__icon" /> : null
    IconOrImage = imageUrl ? <WidgetImage imageUrl={props.imageUrl} className="widget__icon icon--s" /> : IconOrImage
    return IconOrImage
}

const defaultCoverImageOrIcon = (props: any) => {
    const { iconName, imageUrl } = props
    let IconOrImage = iconName ? <WidgetIcon iconName={props.iconName} className="widget__cover-icon" /> : null
    IconOrImage = imageUrl ? <WidgetImage imageUrl={props.imageUrl} className="widget__cover-icon" /> : IconOrImage
    return IconOrImage
}

const defaultHeader = (props: any) => {
    const { title } = props
    const HeaderAppendix = props.appendToHeader ? props.appendToHeader : null
    return (
        <WidgetHeader key="widget__header">
            <WidgetTitle>
                {defaultImageOrIcon(props)}
                {title && <WidgetTitleText>{title}</WidgetTitleText>}
            </WidgetTitle>
            {
                HeaderAppendix
            }
        </WidgetHeader>
    )
}

const defaultCover = (props: any) => (
    <WidgetCover>
        {props.displayDemoBadge && <Demo displayMode="edge" className={demoBadge} />}
        {defaultCoverImageOrIcon(props)}
        <div className="widget__cover-text">
            {props.title}
        </div>
    </WidgetCover>
)

const vehicleCover = style({
    position: "absolute",
    zIndex: 9,
    backgroundColor: "rgba(0,0,0,0.6)",
    color: '#fff',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    opacity: 0,
    $nest: {
        "&:hover": {
            opacity: 1
        },
        "span": {
            maxWidth: '100px'
        },
        ".text": {
            color: '#fff'
        },
        ".icon": {
            fill: '#fff',
            opacity: '1',
            marginTop: '5px'
        }
    }
})

const demoBadge = style({
    position: "absolute",
    top: 0,
    right: 0,
    height: "32px",
    width: "32px"
})
