import * as React from "react"
import { AvailabilityDescription } from "./components/availability-description";
import { AvailabilityStatus } from "@tm/models";
import { mapAvailabilityStatusToLevel } from "@tm/utils";

export type AvailabilityProps = React.PropsWithChildren<{
    status: AvailabilityStatus
    description?: string
    iconname?: string
    imageUrl?: string
    indicatorPosition?: "bottom" | "top"
    tourDate?: string
    tourName?: string
    onClick?(): void
}>

export default function Availability({ status, iconname, imageUrl, description, indicatorPosition, tourDate, tourName, onClick, children }: AvailabilityProps) {
    const baseName = "availability"

    let className = `${baseName} ${baseName}--level-${mapAvailabilityStatusToLevel(status)} ${baseName}--status-${status}`

    if (indicatorPosition)
        className += ` ${baseName}--${indicatorPosition}`

    if (onClick)
        className += ` ${baseName}--clickable`

    if (imageUrl)
        className += ` ${baseName}--has-image`


    return (
        <div className={className} onClick={onClick}>
            <div className={baseName + "__wrapper"}>
                <div className={baseName + "__container"} title={description} >
                    <span className={baseName + "__indicator " + baseName + "__indicator-0"} />
                    <span className={baseName + "__indicator " + baseName + "__indicator-1"} />
                    <span className={baseName + "__indicator " + baseName + "__indicator-2"} />
                    <span className={baseName + "__indicator " + baseName + "__indicator-3"} />
                </div>
                <div className={baseName + "__description"}>
                    {children || <AvailabilityDescription baseName={baseName} iconname={iconname} imageUrl={imageUrl} description={description} />}
                </div>
            </div>
            {
                (tourDate || tourName) &&
                <div className={baseName + "__tour"}>
                    <div className={baseName + "__text"}>
                        {tourDate}
                        {tourDate && tourName && <br />}
                        {tourName}
                    </div>
                </div>
            }
        </div>
    )
}