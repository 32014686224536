import * as React from "react"
import { bindActionCreators, Dispatch, Action } from "redux"
import { connect } from "react-redux"

type ConnectProps<T> = { actions: T, state: unknown }

export function connectComponent<T, P>(actions: T, component: React.ComponentType<P>): React.ComponentType<Omit<P, keyof ConnectProps<T>>> {

    function mapStateToProps(state: {}, props: {}) {
        return {
            ...props,
            state
        }
    }

    function mapDisptachToProps(dispatch: Dispatch<Action<{}>>, props: {}) {
        return {
            ...props,
            actions: bindActionCreators(actions as any || {}, dispatch)
        }
    }
    
    return connect(mapStateToProps, mapDisptachToProps)(component as any)

}

