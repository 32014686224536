/* eslint-disable no-cond-assign */

/**
 * @repository https://github.com/thorst/supportedBrowser.js/blob/master/src/browserInfo.js
 * small modifications on browserfeatures, added some props and replaced cookietest
 * stored in a module instead of window
 * added windows 10 recognition
 * */

export const BrowserInfo = {
    getBrowser: () => {
        // initial values for checks
        const nAgt = navigator.userAgent // store user agent [Mozilla/5.0 (Windows NT 6.1; WOW64; rv:27.0) Gecko/20100101 Firefox/27.0]
        let browser = navigator.appName // browser string [Netscape]
        let version = `${parseFloat(navigator.appVersion)}` // version string (5) [5.0 (Windows)]
        let majorVersion = parseInt(navigator.appVersion) // version number (5) [5.0 (Windows)]
        let nameOffset // used to detect other browsers name
        let verOffset // used to trim out version
        let ix // used to trim string

        // Opera
        if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
            browser = "Opera"
            version = nAgt.substring(verOffset + 6)
            if ((verOffset = nAgt.indexOf("Version")) !== -1) {
                version = nAgt.substring(verOffset + 8)
            }

            // MSIE
        } else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
            browser = "Microsoft Internet Explorer"
            version = nAgt.substring(verOffset + 5)

            // IE 11 no longer identifies itself as MS IE, so trap it
            // http://stackoverflow.com/questions/17907445/how-to-detect-ie11
        } else if (browser === "Netscape" && nAgt.indexOf("Trident/") !== -1) {
            browser = "Microsoft Internet Explorer"
            version = nAgt.substring(verOffset + 5)
            if ((verOffset = nAgt.indexOf("rv:")) !== -1) {
                version = nAgt.substring(verOffset + 3)
            }

            // Chrome
        } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
            browser = "Chrome"
            version = nAgt.substring(verOffset + 7)

            // Chrome on iPad identifies itself as Safari. However it does mention CriOS.
        } else if ((verOffset = nAgt.indexOf("CriOS")) !== -1) {
            browser = "Chrome"
            version = nAgt.substring(verOffset + 6)

            // Safari
        } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
            browser = "Safari"
            version = nAgt.substring(verOffset + 7)
            if ((verOffset = nAgt.indexOf("Version")) !== -1) {
                version = nAgt.substring(verOffset + 8)
            }

            // Firefox
        } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
            browser = "Firefox"
            version = nAgt.substring(verOffset + 8)

            // Other browsers
        } else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
            browser = nAgt.substring(nameOffset, verOffset)
            version = nAgt.substring(verOffset + 1)
            if (browser.toLowerCase() === browser.toUpperCase()) {
                browser = navigator.appName
            }
        }

        // trim the version string
        if ((ix = version.indexOf(";")) !== -1) {
            version = version.substring(0, ix)
        }
        if ((ix = version.indexOf(" ")) !== -1) {
            version = version.substring(0, ix)
        }
        if ((ix = version.indexOf(")")) !== -1) {
            version = version.substring(0, ix)
        }

        // why is this here?
        majorVersion = parseInt(`${version}`)
        if (isNaN(majorVersion)) {
            version = `${parseFloat(navigator.appVersion)}`
            majorVersion = parseInt(navigator.appVersion)
        }

        return {
            name: browser,
            version: majorVersion,
            versionString: version,
        }
    },
    getDevice: () => {
        let i
        const nVer = navigator.appVersion
        const nAgt = navigator.userAgent
        const tabletStrings = [
            { s: "iPad", r: /iPad/ },
            { s: "Samsung Galaxy", r: /SCH-I800/ },
            { s: "Motorola", r: /xoom/ },
            { s: "Kindle", r: /kindle/ },
        ]
        const phoneStrings = [
            { s: "iPhone", r: /iPhone/ },
            { s: "iPod", r: /iPod/ },
            { s: "blackberry", r: /blackberry/ },
            { s: "android 0.5", r: /android 0.5/ },
            { s: "htc", r: /htc/ },
            { s: "lg", r: /lg/ },
            { s: "midp", r: /midp/ },
            { s: "mmp", r: /mmp/ },
            { s: "mobile", r: /mobile/ },
            { s: "nokia", r: /nokia/ },
            { s: "opera mini", r: /opera mini/ },
            { s: "palm", r: /palm|PalmSource/ },
            { s: "pocket", r: /pocket/ },
            { s: "psp", r: /psp|Playstation Portable/ },
            { s: "sgh", r: /sgh/ },
            { s: "smartphone", r: /smartphone/ },
            { s: "symbian", r: /symbian/ },
            { s: "treo mini", r: /treo mini/ },
            { s: "SonyEricsson", r: /SonyEricsson/ },
            { s: "Samsung", r: /Samsung/ },
            { s: "MobileExplorer", r: /MobileExplorer/ },
            { s: "Benq", r: /Benq/ },
            { s: "Windows Phone", r: /Windows Phone/ },
            { s: "Windows Mobile", r: /Windows Mobile/ },
            { s: "IEMobile", r: /IEMobile/ },
            { s: "Windows CE", r: /Windows CE/ },
            { s: "Nintendo Wii", r: /Nintendo Wii/ },
        ]

        let is_tablet = false
        let is_phone = false
        let device = ""

        tabletStrings.forEach((x) => {
            if (x.r.test(nAgt)) {
                device = x.s
                is_tablet = true
            }
        })

        if (device === "") {
            phoneStrings.forEach((x) => {
                if (x.r.test(nAgt)) {
                    device = x.s
                    is_phone = true
                }
            })
        }

        // if they are on tablet or phone
        let is_mobile = is_tablet || is_phone
        if (!is_mobile) {
            is_mobile = /Mobile|mini|Fennec|Android/.test(nVer)
        }

        return {
            screen: {
                width: screen.width,
                height: screen.height,
            },
            device,
            isTable: is_tablet,
            isMobile: is_mobile,
            isPhone: is_phone,
        }
    },
    getOS: () => {
        const nVer = navigator.appVersion
        const nAgt = navigator.userAgent
        let osVersion = "unknown"
        let os = "unknown"
        // system

        const clientStrings = [
            { s: "Windows 3.11", r: /Win16/ },
            { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
            { s: "Windows ME", r: /(Win 9x 4\.90|Windows ME)/ },
            { s: "Windows 98", r: /(Windows 98|Win98)/ },
            { s: "Windows CE", r: /Windows CE/ },
            { s: "Windows 2000", r: /(Windows NT 5\.0|Windows 2000)/ },
            { s: "Windows XP", r: /(Windows NT 5\.1|Windows XP)/ },
            { s: "Windows Server 2003", r: /Windows NT 5\.2/ },
            { s: "Windows Vista", r: /Windows NT 6\.0/ },
            { s: "Windows 7", r: /(Windows 7|Windows NT 6\.1)/ },
            { s: "Windows 8.1", r: /(Windows 8\.1|Windows NT 6\.3)/ },
            { s: "Windows 8", r: /(Windows 8|Windows NT 6\.2)/ },
            { s: "Windows 10", r: /(Windows 10|Windows NT 10\.0)/ },
            { s: "Windows NT 4.0", r: /(Windows NT 4\.0|WinNT4\.0|WinNT|Windows NT)/ },
            { s: "Windows ME", r: /Windows ME/ },
            { s: "Android", r: /Android/ },
            { s: "Open BSD", r: /OpenBSD/ },
            { s: "Sun OS", r: /SunOS/ },
            { s: "Linux", r: /(Linux|X11)/ },
            { s: "iOS", r: /(iPhone|iPad|iPod)/ },
            { s: "Mac OS X", r: /Mac OS X/ },
            { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
            { s: "QNX", r: /QNX/ },
            { s: "UNIX", r: /UNIX/ },
            { s: "BeOS", r: /BeOS/ },
            { s: "OS/2", r: /OS\/2/ },
            { s: "Search Bot", r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
        ]

        clientStrings.every((x) => {
            if (x.r.test(nAgt)) {
                os = x.s
                return false
            }

            return true
        })

        if (/Windows/.test(os)) {
            // @ts-ignore
            osVersion = /Windows (.*)/.exec(os)[1]
            os = "Windows"
        }

        switch (os) {
            case "Mac OS X":
                // @ts-ignore
                osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1]
                break

            case "Android":
                // @ts-ignore
                osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1]
                break

            case "iOS":
                // @ts-ignore
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer)
                // @ts-ignore
                osVersion = `${osVersion[1]}.${osVersion[2]}.${osVersion[3] | 0}`
                break
        }

        return {
            name: os,
            versionString: osVersion,
        }
    },
    getBrowserFeatures: () => {
        const { hardwareConcurrency, cookieEnabled } = navigator
        return {
            window: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            desktop: {
                width: window.screen.width * window.devicePixelRatio,
                height: window.screen.height * window.devicePixelRatio,
            },
            numberOfLogicalProcessorCores: hardwareConcurrency,
            allowsCookies: !!cookieEnabled,
        }
    },
    getCookies: () => {
        return {
            allowsCookies: !!navigator.cookieEnabled,
        }
    },
    getCurrentGeolocation: navigator.geolocation && navigator.geolocation.getCurrentPosition,

    getDesktopScreenResolution: () => {
        return {
            width: window.screen.width * window.devicePixelRatio,
            height: window.screen.height * window.devicePixelRatio,
        }
    },
}
