import { useState, useMemo, useEffect } from "react"

export type IFrameProps = {
    url: string
    id: string
    className?: string
    children?: JSX.Element
    contextDependent?: boolean
    allowFullscreen?: boolean
    allow?: string
    observeViewportIntersection?: boolean
    /** Can be either a valid sandbox value or true (equals sandbox="") */
    sandbox?: string | boolean
    refreshOnUrlChanged?: boolean
    refreshOnLoad?: boolean
    onLoad?(ev: Event): void
}

export default function IFrame(props: IFrameProps) {
    const { url, id, className, children } = props
    const [iframe, setIframe] = useState<HTMLIFrameElement>()
    const [container, setContainer] = useState<HTMLDivElement>()
    const [waitForBoundingRect, setWaitForBoundingRect] = useState(false)

    const observer = useMemo(() =>
        new IntersectionObserver(handleResize, {
            root: null,
            rootMargin: "0px",
            threshold: 1.0
        }), []
    )

    function handleRef(el: HTMLDivElement) {
        if (el) {
            setContainer(el)
            observer && observer.observe(el)
        }
    }

    function handleResize() {
        setTimeout(() => iframe && container && setBoundingRect(iframe, container, waitForBoundingRect, setWaitForBoundingRect), 200)
    }

    useEffect(() => {

        iframe && container && setBoundingRect(iframe, container, waitForBoundingRect, setWaitForBoundingRect)
        window.addEventListener("resize", handleResize)

        return () => {
            iframe && hideIframe(iframe)
            window.removeEventListener("resize", handleResize)
            observer && observer.disconnect()
        }
    })

    useEffect(() => {
        if (iframe?.id == id) {
            iframe.onload = props.onLoad || null
            iframe.src = url
            return
        }

        const newIframe = getOrCreateIFrame(props)
        setIframe(newIframe)

    }, [url])

    return (
        <div className={className || ""} ref={handleRef}>
            {children}
        </div>
    )

}

function hideIframe(iframe: HTMLIFrameElement) {
    iframe.style.display = "none"
    iframe.style.pointerEvents = "none"
    iframe.style.zIndex = "-1"
}

function getOrCreateIFrame(props: IFrameProps) {
    let iframe = document.querySelector<HTMLIFrameElement>("#" + props.id)
    if (!iframe) {
        iframe = document.createElement("iframe")

        if (props.contextDependent) {
            iframe.classList.add("context-dependent")
        }

        // if(props.className) {
        //     const classNameList = props.className.split(' ')
        //     classNameList.forEach(item => iframe && iframe.classList.add(item))
        // }

        iframe.id = props.id
        iframe.src = props.url
        iframe.style.position = "absolute"
        iframe.style.display = "none"
        iframe.allowFullscreen = props.allowFullscreen || false
        iframe.allow = props.allow || ""

        if (typeof props.sandbox == "string" || props.sandbox === true) {
            iframe.sandbox.value = props.sandbox === true ? "" : props.sandbox
        }

        iframe.onload = props.onLoad || null

        document.body.appendChild(iframe)

    } 
    else if ((props.refreshOnUrlChanged && iframe.src != props.url) || props.refreshOnLoad) {
        iframe.onload = props.onLoad || null
        iframe.src = props.url
    } else {
        props.onLoad?.(new Event('loaded'))
    }

    return iframe
}

function setBoundingRect(iframe: HTMLIFrameElement, container: HTMLDivElement, waitForBoundingRect: boolean, setWaitForBoundingRect: (value: boolean) => void) {

    const containerBoundingRect = container.getBoundingClientRect()

    if (!container.clientWidth || containerBoundingRect.left > 0.5 * window.innerWidth) {
        setTimeout(() => setBoundingRect(iframe, container, waitForBoundingRect, setWaitForBoundingRect), 500)
        setWaitForBoundingRect(true)
        return
    }

    setWaitForBoundingRect(false)

    iframe.style.left = containerBoundingRect.left + "px"
    iframe.style.top = containerBoundingRect.top + "px"
    iframe.style.width = containerBoundingRect.width + "px"
    iframe.style.height = containerBoundingRect.height + "px"
    iframe.style.display = ""
    iframe.style.pointerEvents = ""
    iframe.style.zIndex = ""
}





// export default class IFrameOld extends React.Component<IFrameProps, any> {

//     iframe: HTMLIFrameElement | null
//     container: HTMLElement
//     waitForBoundingRect: boolean
//     observer?: IntersectionObserver
//     showIframeTimeout: any

//     constructor(props: IFrameProps) {
//         super(props)
//         bindMethodsToContext(this)

//         if (props.observeViewportIntersection && typeof IntersectionObserver === 'function') {
//             this.observer = new IntersectionObserver(this.handleResize, {
//                 root: null,
//                 rootMargin: "0px",
//                 threshold: 1.0
//             })
//         }
//     }

//     UNSAFE_componentWillReceiveProps(nextProps: IFrameProps) {
//         if (nextProps.url != this.props.url && this.iframe) {
//             this.iframe.src = nextProps.url
//         }
//     }

//     componentDidUpdate() {
//         clearTimeout(this.showIframeTimeout)
//         this.showIframeTimeout = setTimeout(this.showOrCreateIFrame.bind(this), 50)
//     }

//     componentDidMount() {
//         this.showIframeTimeout = setTimeout(() => this.showOrCreateIFrame(), 50)
//     }

//     componentWillUnmount() {
//         const iframe = this.getIFrame()
//         if (iframe) {
//             iframe.style.display = "none"
//             iframe.style.pointerEvents = "none"
//             iframe.style.zIndex = "-1"
//         }
//         clearTimeout(this.showIframeTimeout)
//         window.removeEventListener("resize", this.handleResize)
//         this.observer && this.observer.disconnect()
//     }

//     showOrCreateIFrame() {
//         const { contextDependent } = this.props
//         this.iframe = this.getIFrame()

//         if (!this.iframe) {
//             this.iframe = document.createElement("iframe")

//             if (contextDependent) {
//                 this.iframe.classList.add("context-dependent")
//             }

//             this.iframe.id = this.props.id
//             this.iframe.src = this.props.url
//             this.iframe.style.position = "absolute"
//             this.iframe.style.display = "none"
//             this.iframe.allowFullscreen = this.props.allowFullscreen || false
//             this.iframe.allow = this.props.allow || ""
//             this.iframe.onload = this.props.onLoad || null

//             document.body.appendChild(this.iframe)
//         }
//         else {
//             if (!this.waitForBoundingRect) {
//                 this.iframe.style.display = ""
//                 this.iframe.style.pointerEvents = ""
//                 this.iframe.style.zIndex = ""
//             }

//             if (this.iframe.src.replace(/\/\?/,'?') != this.props.url) {
//                 this.iframe.src = this.props.url
//             }
//         }

//         this.setBoundingRect()
//         window.addEventListener("resize", this.handleResize)
//     }

//     setBoundingRect() {
//         if (this.iframe && this.container) {
//             if (!this.container.clientWidth) {
//                 setTimeout(this.setBoundingRect.bind(this), 100)
//                 this.waitForBoundingRect = true
//                 return
//             }
//             this.waitForBoundingRect = false

//             const containerBoundingRect = this.container.getBoundingClientRect()
//             this.iframe.style.left = containerBoundingRect.left + "px"
//             this.iframe.style.top = containerBoundingRect.top + "px"
//             this.iframe.style.width = containerBoundingRect.width + "px"
//             this.iframe.style.height = containerBoundingRect.height + "px"
//             this.iframe.style.display = ""
//             this.iframe.style.pointerEvents = ""
//             this.iframe.style.zIndex = ""
//         }
//     }

//     getIFrame() {
//         return document.querySelector<HTMLIFrameElement>("#" + this.props.id)
//     }

//     handleResize() {
//         setTimeout(() => this.setBoundingRect(), 200)
//     }

//     handleRef(el: HTMLDivElement | null) {
//         if (el) {
//             this.container = el
//             this.observer && this.observer.observe(el)
//         }
//     }

//     render() {
//         return (
//             <div className={this.props.className || ""} ref={this.handleRef}>
//                 {this.props.children}
//             </div>
//         )
//     }
// }
