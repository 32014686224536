import ArticleListFilteredEvent from "./events/article-list-filtered"
import LoggedIn from "./events/logged-in"
import AddToBasket from "./events/add-to-basket"
import VehicleSelection from "./events/vehicle-selection/event-handle"
import ModuleCalledSwitch from "./events/module-called"
import PremiumUniPartsSearchEvent from "./events/premium-uni-parts-search"
import ArticleListErpInfoShownEvent from "./events/article-list-erp-info-shown/event-handle"
import ArticleEvent from "./events/article"
import ArticleDetailsPDFEvent from "./events/articlePDFClick"
import DetailsFormCallEvent from "./events/detailsFormCall"
import ArticleComparisonEvent from "./events/articleComparison"
import GeneralCountEvent from "./events/generalCount"

export const EventCollection = [
    AddToBasket,
    ArticleListFilteredEvent,
    LoggedIn,
    VehicleSelection,
    ModuleCalledSwitch,
    PremiumUniPartsSearchEvent,
    ArticleListErpInfoShownEvent,
    ArticleEvent,
    ArticleDetailsPDFEvent,
    DetailsFormCallEvent,
    ArticleComparisonEvent,
    GeneralCountEvent,
]
