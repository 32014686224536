import { compile } from "path-to-regexp"
import { parseISODate } from ".."

type QueryParamType_ = string | number | boolean | Date | undefined
type QueryParamType = QueryParamType_ | Record<string, QueryParamType_> | Array<string | number | boolean | undefined>
type QueryParams = Record<string, QueryParamType> | undefined

export function createQueryString(params: QueryParams): string {
    if (!params) {
        return ""
    }

    const queries = Object.entries(params)
        .map(([key, value]) => createQueryComponent(key, value))
        .filter((x) => typeof x == "string")

    if (!queries.length) {
        return ""
    }

    const result = queries.join("&")
    return result ? `?${result}` : ""
}

function createQueryComponent(key: string, value: QueryParamType): string | undefined {
    if (value == undefined) {
        return
    }

    if (value instanceof Date) {
        value = value.toISOString()
    } else if (Array.isArray(value)) {
        value = value.join(",")
    } else if (typeof value == "object") {
        const queries = Object.entries(value)
            .map(([key2, value]) => createQueryComponent(`${key}.${key2}`, value))
            .filter((x) => typeof x == "string")

        if (!queries.length) {
            return
        }

        return queries.join("&")
    }

    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
}

export function parseQueryString(query: string): { [key: string]: string | number | Date | boolean | undefined } {
    if (!query) {
        return {}
    }
    const params = query.replace(/^\?/, "").split("&").map(parseQueryComponent)
    if (params.length == 0) {
        return {}
    }
    const result: { [key: string]: string | number | Date | boolean | undefined } = {}
    params.forEach(({ key, value }) => (result[key] = value))
    return result
}

function parseQueryComponent(component: string): { key: string; value: string | number | Date | boolean | undefined } {
    let [key, value] = component.split("=").map(decodeURIComponent) as [string, string | number | Date | boolean]

    if (typeof value == "string") {
        value = parseISODate(value) ?? value
    }

    return { key, value }
}

export function renderRoute(path: string, params: { [key: string]: any }) {
    if (!path) {
        return ""
    }

    const stringParams: { [key: string]: string | undefined } = {}
    Object.keys(params).forEach((key) => (stringParams[key] = params[key] != null ? encodeURIComponent(String(params[key])) : undefined))
    path = path.replace("//", "/")
    return compile(path)(stringParams)
}

export function joinPaths(...parts: Array<string>): string {
    let path = ""
    let keepLastSlash = false

    parts.forEach((x, idx) => {
        if (!x) {
            return
        }

        if (path && idx > 0 && x.startsWith("/")) {
            x = x.substr(1)
        }

        if (x.endsWith("/")) {
            keepLastSlash = true
            x = x.substr(0, x.length - 1)
        } else {
            keepLastSlash = false
        }

        path += `${x}/`
    })

    if (!keepLastSlash) {
        path = path.substring(0, path.length - 1)
    }

    return path
}

/**
 * This checks if the given string is a valid url used
 * for the DMS (GSI Remote) gateway iframe.
 *
 * It was introduced because an invalid url used for the iframe
 * could cause major problems in the application.
 * See NEXT-23708 for more information.
 *
 * @param url The url that should be validated.
 * @returns `true` if the supplied url is valid otherwise `false`.
 */
export function validateDmsApiUrl(url: string): boolean {
    // Regex: "url" must
    // start with "http://" or "https://"
    // and contains at least one following character
    // except a whitespace following anywhere afterwards
    return /^https?:\/\/[^ ]+$/i.test(url)
}
