/**
 * @description loads a module using XMLHttpRequest
 */
export function loadModule(url: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onloadend = () => {
            try {
                const obj = eval(xhr.responseText)
                resolve(obj)
            } catch (err) {
                reject(err)
            }
        }
        xhr.onerror = () => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(`Module couldn't be loaded (${url})`)
        }
        xhr.open("GET", url)
        xhr.send()
    })
}
