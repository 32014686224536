/**
 * Return true if given dongleId or vin exists in @carMunMockValidData
 */
export const carMunValidate = (_dongleId?: string, _vin?: string) => {
    return carMunMockValidData.some(([dongleId, vin]) => _dongleId == dongleId || _vin == vin)
}

export const carMunMockValidData = [
    ["G9FA212043B8", "WAUZZZF23KN029537"],
    ["G9A3212043E1", "WBA8H71020K358088"],
    ["E48321235EAD", "W1K1770861J296501"],
    ["E4412123D2E3", "WDD2052041F319497"],
    ["E4DC2123D27E", "W1K2132161A856659"],
    ["E4F82123CE46", "WDC2539091F623964"],
    ["G97D21204238", "WVWZZZ3CZBE336296"],
    ["G9FB212043B9", "WV2ZZZ7HZBH026548"],
    ["G9F4212043B6", "WBA2C71000V782954"],
    ["G9392120437B", "WBAWZ510400U73201"],
    ["G92221204360", "WVWZZZAUZEW900654"],
    ["G9D0211031D4", "SALCA2DN3HH662883"],
    ["G90A21204348", "VF1AG000164872798"],
]
