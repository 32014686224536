import { AjaxCachingMode, CachingOptions } from "@tm/models"
import { AxiosRequestConfig } from "axios"

import { CachingCounter } from "./caching-counter"
import { handleRequestCaching } from "./request-cache"
import { handleResponseCaching } from "./response-cache"

// Importing this file will start the garbage collection process
import "./garbage-collection"

declare global {
    interface Window {
        ajaxCachingMode?: AjaxCachingMode
        logCaching?: boolean
        cc?: CachingCounter
        gcIntervalMS?: number
    }
}

export type CacheKey = string

export type AjaxRequest<Res = unknown> = () => Promise<Res>

export function handleCaching<Res>(doAjaxRequest: AjaxRequest<Res>, ajaxRequest: AxiosRequestConfig<unknown>): Promise<Res> {
    const cachingMode = window.ajaxCachingMode ?? window.userContext?.parameter?.ajaxCachingMode ?? AjaxCachingMode.Request

    if (cachingMode == AjaxCachingMode.NoCache) {
        return doAjaxRequest()
    }

    const requestUrl = ajaxRequest.url ?? ""
    const key = createRequestKey(ajaxRequest)
    const cachingOptions = getWhiteListOptions(requestUrl)

    if (key && cachingMode == AjaxCachingMode.Response && cachingOptions) {
        return handleResponseCaching(doAjaxRequest, key, cachingOptions, requestUrl)
    }

    return handleRequestCaching(doAjaxRequest, key)
}

function createRequestKey(ajaxRequest: AxiosRequestConfig<unknown>): CacheKey {
    // all parts which make a request unique should be present here
    // use JSON.stringify to capture the maybe nested objects "data" and "params"
    return `${ajaxRequest.url ?? ""},${ajaxRequest.data ? JSON.stringify(ajaxRequest.data) : ""},${
        ajaxRequest.params ? JSON.stringify(ajaxRequest.params) : ""
    }`
        .hashCode()
        .toString()
}

function getWhiteListOptions(requestUrl: string): CachingOptions | undefined {
    if (!window.cachingWhitelist) {
        return
    }

    const cachingOptionsOrKey = window.cachingWhitelist[requestUrl.split("?")[0]] // in case url contains query parameters ignore them
    if (!cachingOptionsOrKey) {
        return
    }

    let cachingOptions: CachingOptions | undefined

    if (typeof cachingOptionsOrKey === "string") {
        const value = window.cachingWhitelist[cachingOptionsOrKey]
        cachingOptions = typeof value === "object" ? value : undefined
    } else {
        cachingOptions = cachingOptionsOrKey
    }

    return cachingOptions
}
