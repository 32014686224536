import { SearchExecutedRequest, ArticleListInfo } from "./models"
import { GetArticlesRequest, getCurrentWorkTaskId, GetUniArticlesResponse, Article, TmaEModule } from "@tm/models"
import { TmaSender } from "../tma-sender"
import { TmaEvent, TrackingModel } from "../../models/models"

import { uniqueId } from "../../../helpers/id"
import { ProductGroupDataSupplier } from "../article-list-filtered/models"
import { getPageIndexMax } from "../article-list-filtered/helper"
import { determineOrigin } from "../article-list-filtered/originDetection"
import { cleanContextUrl } from "../../helper/context"

export class PremiumUniPartsSearchEvent extends TmaSender<SearchExecutedRequest> implements TmaEvent {
    static serviceEndpoint = "/uniArticles/searchExecuted"

    key = "PREMIUM_UNI_PARTS_SEARCH"

    workTaskId: string
    autocompleteResults: Array<string>
    temporaryRequestBody: SearchExecutedRequest
    constructor() {
        // extending tma sender needs to set
        super(PremiumUniPartsSearchEvent.serviceEndpoint)

        this.workTaskId = ""
        this.autocompleteResults = []
        this.reset()
    }

    reset = () => {
        const resetRequest = {
            criterionFilters: [],
            articleList: {
                articleCount: 0,
                paging: {
                    pageIndex: undefined,
                    pageIndexMax: undefined,
                    pageSize: undefined,
                },
                productGroupDataSuppliers: [],
                wholesalerArticleCount: 0,
                maxArticleCount: undefined,
            },
            origin: TmaEModule.UNDEFINED,
            searchStep: {
                number: 1,
                processId: uniqueId(),
            },
            searchTree: undefined,
            query: undefined,
        }
        this.temporaryRequestBody = resetRequest
    }


    public handle = (trackingData: TrackingModel<any>): boolean => {
        if (!trackingData.tmaEvent || trackingData.tmaEvent !== this.key || !trackingData.payload) {
            return false
        }

        // when the event doesnt have a searchstep it was called from the dashboard with no worktask id.
        if (!this.temporaryRequestBody?.searchStep) {
            this.temporaryRequestBody = {
                ...this.temporaryRequestBody,
                searchStep: {
                    number: 1,
                    processId: uniqueId()
                },
                origin: trackingData.payload.origin || TmaEModule.UNDEFINED
            }
        }
        let caseFound = false
        switch (trackingData.bundle) {
            case "parts":
            case "tyres":
            case "api": {
                this.workTaskId = trackingData.payload.workTaskId ?? getCurrentWorkTaskId()

                if (Array.isArray(trackingData.action)) {
                    trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
                } else {
                    this.fillRequest(trackingData, trackingData.action)
                }


                caseFound = true
                break;
            }
            case "worktask": {
                if (trackingData.action == "reset-process-id") {
                    if (Array.isArray(trackingData.payload.worktaskIds) && trackingData.payload.worktaskIds.indexOf(this.workTaskId) >= 0) {
                        this.reset()
                    }
                }
                caseFound = true
                break;
            }

            default:
                caseFound = false
                break;
        }

        return caseFound
    }
    private swapOnPreinitialized = () => {
        this.storage.initWithPreInitializedContent(this.storage.getContext())
    }

    private fillRequest(trackingData: TrackingModel<any>, action: string) {
        const payload = trackingData.payload

        switch (action) {
            case "articlesLoaded": {
                this.swapOnPreinitialized()
                this.handleBrowserRefresh()
                const { request, result } = payload
                this.temporaryRequestBody.workTaskId = payload.worktaskId
                if (request.query) {
                    this.attachSearchQuery(request.query)
                }
                this.attachArticleListInfo(request, result)

                this.saveContext()
                console.log(`%c${this.serviceEndpoint}\r\n${document.location.pathname}\r\nprocessId: ${this.temporaryRequestBody.searchStep.processId}\r\norigin: ${this.temporaryRequestBody.origin}\r\nquery: ${this.temporaryRequestBody.query}\r\ntree: ${this.temporaryRequestBody.searchTree}\r\nevent will be send now\r\n`, "color:green", this.temporaryRequestBody)
                this.sendEvent(this.temporaryRequestBody).then(() => {
                    if (this.temporaryRequestBody.context) {
                        this.storage.delete(this.temporaryRequestBody.context)
                        this.temporaryRequestBody.searchStep.processId && this.storage.removeModifiedState(this.temporaryRequestBody.searchStep.processId)
                    }
                })

                break
            }

            case "autocomplete": {
                const { hits } = payload

                if (hits && Array.isArray(hits)) {
                    this.autocompleteResults = hits
                }
                break
            }
            case "nodeSelected":
            case "handleChangeBreadcrumbs": {
                const { nodeId, treeId, origin } = payload
                this.reset()
                this.addSearchTree(treeId, nodeId)
                this.temporaryRequestBody.origin = origin
                break
            }

            case "increase-step-number": {
                this.temporaryRequestBody.searchStep = {
                    number: !this.temporaryRequestBody.searchStep ? 1 : ++this.temporaryRequestBody.searchStep.number,
                    processId: !this.temporaryRequestBody.searchStep ? uniqueId() : this.temporaryRequestBody.searchStep.processId
                }
                break
            }

            case "filterChanged": {
                if (payload.criterionFilters) {
                    this.temporaryRequestBody.criterionFilters = payload.criterionFilters
                }
                break
            }

            case "keyword-search-redirect": {
                const { query, origin, targetUrl } = payload

                this.reset()
                this.temporaryRequestBody.context = {
                    identifier: this.serviceEndpoint,
                    contextId: cleanContextUrl(targetUrl)
                }
                this.attachSearchQuery(query)
                this.temporaryRequestBody.origin = origin
                break;
            }

            case "handleExternalSearch":
            case "keyword-search-context":
            case "handleSearch":
            case "tyresSelection": {
                const { origin, query } = payload

                this.reset()
                this.attachSearchQuery(query)
                this.temporaryRequestBody.origin = origin
                break
            }

            case "reset": {
                // this.reset()
                break
            }
        }
    }

    private handleBrowserRefresh = () => {
        if (this.temporaryRequestBody.origin === TmaEModule.UNDEFINED) {
            this.temporaryRequestBody.origin = determineOrigin()
        }
    }
    private addSearchTree = (treeId: number, nodeId: number) => {
        this.temporaryRequestBody.searchTree = { nodeId, treeId }
        this.temporaryRequestBody.query = undefined // if searchtree is used, query will not be considered
    }
    private attachSearchQuery = (query: string) => {
        let searchQuery: string | undefined = query

        if (!searchQuery) {
            const params = window.location.search.split("&")
            params.forEach((param) => {
                const regexMatch = /query=(.*)/.exec(param)
                if (regexMatch && regexMatch[1]) {
                    searchQuery = decodeURIComponent(regexMatch[1]).replace(/\+/g, " ")
                }
            })
        }

        if (!searchQuery || searchQuery == "") {
            this.temporaryRequestBody.query = undefined
            return
        }

        this.temporaryRequestBody.query = searchQuery
        this.temporaryRequestBody.searchTree = undefined // if it's a query search, the searchtree will not be considered
    }

    attachArticleListInfo(request: GetArticlesRequest, result: GetUniArticlesResponse) {
        let wholesalerArticleCount = 0
        const productGroupDataSuppliers: Array<ProductGroupDataSupplier> = []

        result?.uniArticles?.forEach((article: Article) => {
            if (article.traderArticleNo) {
                ++wholesalerArticleCount
            }

            if (article.supplier) {
                const { supplier, productGroup } = article
                const productGroupDataSupplierPair = {
                    dataSupplierId: supplier.id,
                    productGroupId: productGroup.id,
                }

                if (
                    !productGroupDataSuppliers.find(
                        (item) =>
                            item.dataSupplierId == productGroupDataSupplierPair.dataSupplierId &&
                            item.productGroupId == productGroupDataSupplierPair.productGroupId
                    )
                ) {
                    productGroupDataSuppliers.push(productGroupDataSupplierPair)
                }
            }
        })

        const maxArticleCount = result?.articleListCount ?? 0
        const pageSize = result?.pageSize ?? request.pageSize
        const pageIndexMax = getPageIndexMax(maxArticleCount, pageSize)

        const articleListInfo: ArticleListInfo = {
            articleCount: result?.uniArticles?.length ?? 0,
            maxArticleCount,
            paging: {
                pageIndex: result?.pageIndex ?? request.pageIndex,
                pageSize,
                pageIndexMax,
            },
            wholesalerArticleCount,
            productGroupDataSuppliers,
        }
        this.temporaryRequestBody.articleList = articleListInfo
    }

    private saveContext() {
        this.requestBody = this.temporaryRequestBody
    }
}
