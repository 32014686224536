/**  can be used in creating memoized selectors using reselect
 *
 * usage
 * const selector = createSelector(
    vehicleSelector, using another selector
    s=> s.customer, using new selector
    combiner.array)

    component:
    const [vehicle,customer] = useSelector(selector)
*/

export const combiner = {
    array: <T extends S[], S>(...x: T) => x,
    object: <T extends S[], S extends {}>(...x: T) => x.reduce((obj, item: S) => ({ ...obj, ...item }), {}),
    self: <T extends any>(x: T) => x,
}

// 1
export function initSelector<T extends unknown, A1>(args_0: (x: T) => A1): (state: T) => [A1]

// 2
export function initSelector<T extends unknown, A1, A2>(args_0: (x: T) => A1, args_1: (x: T) => A2): (state: T) => [A1, A2]

// 3
export function initSelector<T extends unknown, A1, A2, A3>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3
): (state: T) => [A1, A2, A3]

// 4
export function initSelector<T extends unknown, A1, A2, A3, A4>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4
): (state: T) => [A1, A2, A3, A4]

// 5
export function initSelector<T extends unknown, A1, A2, A3, A4, A5>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5
): (state: T) => [A1, A2, A3, A4, A5]

// 6
export function initSelector<T extends unknown, A1, A2, A3, A4, A5, A6>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5,
    args_5: (x: T) => A6
): (state: T) => [A1, A2, A3, A4, A5, A6]

// 7
export function initSelector<T extends unknown, A1, A2, A3, A4, A5, A6, A7>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5,
    args_5: (x: T) => A6,
    args_6: (x: T) => A7
): (state: T) => [A1, A2, A3, A4, A5, A6, A7]

// 8
export function initSelector<T extends unknown, A1, A2, A3, A4, A5, A6, A7, A8>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5,
    args_5: (x: T) => A6,
    args_6: (x: T) => A7,
    args_7: (x: T) => A8
): (state: T) => [A1, A2, A3, A4, A5, A6, A7, A8]

// 9
export function initSelector<T extends unknown, A1, A2, A3, A4, A5, A6, A7, A8, A9>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5,
    args_5: (x: T) => A6,
    args_6: (x: T) => A7,
    args_7: (x: T) => A8,
    args_8: (x: T) => A9
): (state: T) => [A1, A2, A3, A4, A5, A6, A7, A8, A9]

// 10
export function initSelector<T extends unknown, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5,
    args_5: (x: T) => A6,
    args_6: (x: T) => A7,
    args_7: (x: T) => A8,
    args_8: (x: T) => A9,
    args_9: (x: T) => A10
): (state: T) => [A1, A2, A3, A4, A5, A6, A7, A8, A9, A10]

// 11
export function initSelector<T extends unknown, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5,
    args_5: (x: T) => A6,
    args_6: (x: T) => A7,
    args_7: (x: T) => A8,
    args_8: (x: T) => A9,
    args_9: (x: T) => A10,
    args_10: (x: T) => A11
): (state: T) => [A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11]

// 12
export function initSelector<T extends unknown, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12>(
    args_0: (x: T) => A1,
    args_1: (x: T) => A2,
    args_2: (x: T) => A3,
    args_3: (x: T) => A4,
    args_4: (x: T) => A5,
    args_5: (x: T) => A6,
    args_6: (x: T) => A7,
    args_7: (x: T) => A8,
    args_8: (x: T) => A9,
    args_9: (x: T) => A10,
    args_10: (x: T) => A11,
    args_11: (x: T) => A12
): (state: T) => [A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12]

export function initSelector(...args: Function[]) {
    return (state: unknown) => args.map((f: Function) => f?.(state))
}
