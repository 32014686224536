function convertDateNumbers(value: number): string  {
    const charCount = value.toString().length
    let returnValue = value.toString()

    if(charCount < 2) {
        returnValue = `0${value}`
    }

    return returnValue
}

export function getTime(date: Date) {
    const hours = convertDateNumbers(date.getHours())
    const minutes = convertDateNumbers(date.getMinutes())
    const seconds = convertDateNumbers(date.getSeconds())

    return `${hours}:${minutes}:${ seconds}`
}