import * as React from "react"
import { bindMethodsToContext } from "../../helper"
import { BaseProps, PositionsX } from "../../models/SharedModels"
import Loader from "../loader"
import Icon from "../icon"
import { GrayishSkins, DefaultSkins } from "../../models/skins";

export type BetaProps = BaseProps & {
    className?: string
    tooltip?: string
    align?: PositionsX
    skin?: string
    customLogo?: string
    style?: React.CSSProperties
}

export type BetaState = {
}

export default class BetaVersion extends React.Component<BetaProps, BetaState> {
    private _visibleTimeoutId: number
    private _isMounted: boolean = false
    baseName = "beta"
    iconRef: SVGSVGElement

    constructor(props: BetaProps) {
        super(props)
        bindMethodsToContext(this, ["build"])
    }
    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
        window.clearTimeout(this._visibleTimeoutId)
    }
    // setIconRefAndFixed = (element:SVGSVGElement) => {
    //     this.iconRef = element;
    //     let sibling: Element

    //     if(element && element.previousElementSibling) {
    //         sibling = element.previousElementSibling
    //     }else if ( element && element.nextElementSibling) {
    //         sibling =element.nextElementSibling
    //     }else{
    //         console.warn("no sibling found to attach beta print")
    //         return
    //     }
    //     const siblingBoundings = sibling.getBoundingClientRect()
    //     const elementBoundings = element.getBoundingClientRect()
    //     const margin = 5;

    //     this.iconRef.style.position = "static";

    //     const elementHeight = element.getBoundingClientRect().height
    //     const elementWidth = element.getBoundingClientRect().height

    //     let position =  {
    //         top: (siblingBoundings.top - elementHeight/3)- margin,
    //         left: (siblingBoundings.left) + margin,
    //         right: (siblingBoundings.left + siblingBoundings.width) - margin - elementWidth
    //     }

    //     this.iconRef.style.top = `${position.top}px`

    //     if(this.props.align == "right") {
    //         this.iconRef.style.left = `${position.right}px`
    //     } else {
    //         this.iconRef.style.left = `${position.left}px`
    //     }
    // };

    // setIconRefAndPositioning = (element:SVGSVGElement) => {
    //     this.iconRef = element;
    //     let sibling: Element

    //     if(element && element.previousElementSibling) {
    //         sibling = element.previousElementSibling
    //     }else if ( element && element.nextElementSibling) {
    //         sibling =element.nextElementSibling
    //     }else{
    //         console.warn("no sibling found to attach beta print")
    //         return
    //     }
    //     const offset = sibling.getBoundingClientRect()
    //     const elementBoundings = element.getBoundingClientRect()
    //     const margin = 5;

    //     this.iconRef.style.position = "absolute";
    //     // console.log(offset,"\n" ,element.getBoundingClientRect())
    //     // const top = elOffsetto.top > offset.top ? elOffsetto.top - offset.top : offset.top - elOffsetto.top + marginLeft
    //     // const left = elOffsetto.left <= offset.left ? marginLeft : (elOffsetto.left-offset.width+elOffsetto.width/2)*-1
    //     const top = sibling.getBoundingClientRect().top - element.getBoundingClientRect().top
    //     let position =  {
    //         left: element.getBoundingClientRect().left - sibling.getBoundingClientRect().left,
    //         top: element.getBoundingClientRect().top - sibling.getBoundingClientRect().top
    //     }

    //     if(this.props.align == "right") {
    //         // position.left = (sibling.getBoundingClientRect().left + sibling.getBoundingClientRect().width) - (element.getBoundingClientRect().left + element.getBoundingClientRect().width + margin)
    //         position.left =  element.getBoundingClientRect().left - sibling.getBoundingClientRect().left == 0 ? sibling.getBoundingClientRect().width: (element.getBoundingClientRect().width)
    //         position.left *= -1
    //     }

    //     this.iconRef.style.transform = "translate("+(position.left> 0 ?(position.left-margin)*-1:(position.left+margin))+"px, -"+(position.top + elementBoundings.height/3 + margin)+"px)"
    // };

    setIconRef = (element:SVGSVGElement) => {
        return element
    }
    render() {
        return (
            <>
                {/* {this.props.children} */}
                {this.renderIcon()}
            </>
        )
    }

    renderIcon() {
        const className = this.buildClassName()
        const { customLogo, style } = this.props
        let BetaIcon = customLogo ? <Icon name={customLogo} /> :
            <svg
                className={className} style={style}
                ref={this.setIconRef}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32">
                <g
                    fill="none"
                    fillRule="evenodd">
                    <g
                        transform="translate(0 7.748)">
                        <path
                            fill="#fff"
                            id="a"
                            d="M0 .785h31.55V16H0z"/>

                        <path
                            fill="#727272"
                            d="M30.583.785H.968A.967.967 0 0 0 0 1.753v13.28c0 .533.433.967.968.967h29.615a.969.969 0 0 0 .968-.967V1.753a.968.968 0 0 0-.968-.968"
                            mask="url(#b)"/>
                    </g>
                    <path fill="#FFF" d="M6.625 15.336h.332c.272 0 .477-.058.617-.176.139-.117.208-.305.208-.564 0-.315-.196-.472-.588-.472h-.292l-.277 1.212zm-.274 1.338l-.305 1.42h.38a.991.991 0 0 0 .664-.224.702.702 0 0 0 .266-.562c0-.213-.05-.372-.153-.477-.102-.105-.28-.157-.537-.157h-.315zm-.985-3.934H7.62c.67 0 1.177.128 1.522.382.347.255.518.62.518 1.094 0 .889-.457 1.47-1.374 1.745V16c.271.088.488.245.648.47.16.223.24.496.24.82 0 .708-.235 1.253-.705 1.639-.47.386-1.138.58-2.001.58H3.935l1.43-6.768zM14.34 19.507h-4.016l1.429-6.767h4.017l-.3 1.468h-2.208l-.217 1.064h2.054l-.333 1.467h-2.054l-.265 1.278h2.208zM18.747 19.507h-1.81l1.098-5.272h-1.467l.32-1.495h4.756l-.328 1.495h-1.472zM23.593 16.794h1.356l-.079-1.961-.01-.4c0-.23.007-.432.02-.605a5.006 5.006 0 0 1-.283.768l-1.004 2.198zm1.411 1.445h-2.073l-.578 1.268h-1.88l3.445-6.767h2.327l.57 6.767H25.05l-.047-1.268z"/>
                </g>
            </svg>
        return BetaIcon
    }

    buildClassName() {
        let qualifiedSelector = `${this.baseName}`
        const { className,align,skin } = this.props

        if (className) {
            qualifiedSelector = `${qualifiedSelector} ${className}`
        }

        if (skin) {
            qualifiedSelector = `${qualifiedSelector} ${this.baseName}--${skin}`
        }

        if (align && align != "right") {
            qualifiedSelector = `${qualifiedSelector} ${this.baseName}--${skin}`
        }

        return qualifiedSelector
    }
}
