export type CancelablePromise<T> = {
    promise: Promise<T>
    cancel(): void
}

export type CancelError = {
    isCanceled: true
}

/**
 * Takes a `Promise` and wraps it into a new `Promise` that will be returned alongside with a `cancel` function.
 *
 * When this function was called the result of the supplied `Promise` will not be returned,
 * but instead the `Promise` will be rejected with the following error object: `{ isCanceled: true }`
 *
 * Use the function @see `isCanceled` to check if an rejection was due to cancellation.
 *
 * @param promise The `Promise` that will be made cancelable.
 * @returns An object containing the wrapped supplied `Promise` and a `cancel` function.
 */
export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
    let cancelError: CancelError | undefined

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        // eslint-disable-next-line prefer-promise-reject-errors
        promise.then((val) => (cancelError ? reject(cancelError) : resolve(val)))

        // eslint-disable-next-line prefer-promise-reject-errors
        promise.catch((error) => (cancelError ? reject(cancelError) : reject(error)))
    })

    return {
        promise: wrappedPromise,
        cancel() {
            cancelError = {
                isCanceled: true,
            }
        },
    }
}

function isCancelError(error: unknown): error is CancelError {
    return typeof error === "object" && !!error && "isCanceled" in error
}

/**
 * Check if an error indicates that the `Promise` was being canceled.
 *
 * @param error An error object from the `Promise` rejection.
 * @returns `true` if the error indicates the `Promise` was cancelled, otherwise `false`.
 */
export function isCanceled(error: unknown): boolean {
    return isCancelError(error) && error.isCanceled
}
