import { encodeToBase64 } from "../../tools/base64";
import { IModelSubscription, IContainerBundleLoader, IContainerBundleUpdater, IMergable } from "../../models";
import { ModelSubscription } from "../subscriptions/model-subscription";
import { SubscriptionsHolder } from "../subscriptions/subscription-holder";

export class ModelSubscriptionFactory {
    private constructor() {
    }
    static create<SCModel>(uniqueModelId: any, load: IContainerBundleLoader<SCModel>, save: IContainerBundleUpdater<SCModel>): IModelSubscription<SCModel> & IMergable<IModelSubscription<SCModel>, IModelSubscription<SCModel>> {
        return new ModelSubscription<SCModel>(encodeToBase64(uniqueModelId), load, save);
    }
    static createContractId(...identifier: any[]) {
        return encodeToBase64(identifier);
    }

    static createSubscriptionHolder<Model>() {
        return new SubscriptionsHolder<Model>()
    }
}
