import { AvailabilityStatus } from "@tm/models"
import { TmaEvent, TrackingModel } from "../../models/models"
import { TmaSender } from "../tma-sender"
import { ArticleComparison, ArticleWithAvailability } from "./models"

export class ArticleComparisonEventHandler extends TmaSender<ArticleComparison> implements TmaEvent {
    key = "ARTICLE_COMPARISON"

    static serviceEndpoint = "/articles/articleComparison"

    constructor() {
        super(ArticleComparisonEventHandler.serviceEndpoint)
    }

    handle = (trackingData: TrackingModel<unknown>): boolean => {
        if (trackingData.tmaEvent && trackingData.tmaEvent == this.key && trackingData.payload && trackingData.bundle == "parts") {
            if (Array.isArray(trackingData.action)) {
                trackingData.action.forEach((action) => this.fillRequest(trackingData, action))
            } else {
                this.fillRequest(trackingData, trackingData.action)
            }

            return false
        }

        return false
    }

    fillRequest = (trackingData: TrackingModel<any>, action: string) => {
        const { articles } = trackingData.payload

        if (action === "article-comparison-click") {
            if (articles) {
                this.requestBody.catalogParts = articles.map((article: ArticleWithAvailability) => {
                    return {
                        dataSupplierArticleNumber: article.supplierArticleNo,
                        dataSupplierId: article.supplier.id,
                        productGroupIds: [article.productGroup.id],
                        wholesalerArticleNumber: article.traderArticleNo,
                        availabilityState: {
                            id:
                                (article.availability &&
                                    article.availability !== AvailabilityStatus.Unknown &&
                                    AvailabilityStatus[article.availability]) ||
                                "",
                            description: article.availability?.toString() || "",
                        },
                    }
                })

                this.sendEvent()
            }
        }
    }
}
