import { Customer, Vehicle } from "@tm/models"
import * as Data from "../data"

export async function saveCustomerOrGetId(customer?: string | Customer) {
    if (!customer || typeof customer === "string") {
        return customer
    }
    else {
        const savedCustomer = await Data.saveCustomer(customer)
        return savedCustomer.id
    }
}

export async function saveVehicleOrGetId(newVehicle?: string | Vehicle, newCustomerId?: string): Promise<{ vehicleId?: string, computedCustomerId?: string }> {
    const { vehicle, customerId: computedCustomerId } = await getVehicleWithUpdatedCustomerId(newVehicle, newCustomerId)
    if (!vehicle || typeof vehicle === "string") {
        return { vehicleId: vehicle, computedCustomerId }
    }
    else {
        const savedVehicle = await Data.saveVehicle(vehicle)
        return { vehicleId: savedVehicle.id, computedCustomerId }
    }
}

async function getVehicleWithUpdatedCustomerId(newVehicle?: string | Vehicle, customerId?: string): Promise<{
    vehicle?: string | Vehicle,
    customerId?: string
}> {
    if (newVehicle) {
        if (typeof newVehicle === "string") {
            const savedVehicle = await Data.loadVehicle(newVehicle)
            if (savedVehicle) {
                if (savedVehicle.customerId)
                    return { vehicle: newVehicle, customerId: savedVehicle.customerId }
                else if (customerId)
                    return { vehicle: { ...savedVehicle, customerId }, customerId }
                else
                    return { vehicle: newVehicle }
            }
            else
                console.error(`Tried to attach vehicle by id ${newVehicle}, which doesn't exist. Continuing without attaching it...`)
        }
        else {
            if (newVehicle.customerId)
                return { vehicle: newVehicle, customerId: newVehicle.customerId }
            else if (customerId)
                return { vehicle: { ...newVehicle, customerId }, customerId }
            else
                return { vehicle: newVehicle }
        }
    }
    return { customerId }
}
