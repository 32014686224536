import { Box } from "@mui/material"
import { ArticleInfoType } from "@tm/models"
import { Icon } from "../../generics/Icons"
import { Image } from "../../generics/image"

type Props = {
    thumbnailUrl: string | undefined
    isLinkedItem?: boolean
    articleInfoType?: ArticleInfoType
}

export const ArticleThumbnailImage: React.FC<Props> = ({ isLinkedItem, thumbnailUrl, articleInfoType }) => {
    let imageUrl = thumbnailUrl
    if (!imageUrl && articleInfoType === ArticleInfoType.OeArticle) {
        imageUrl = "/styles/base/images/oe-part-logo.svg"
    }

    function renderIcon(iconName: string) {
        return <Icon name={iconName} width="4.5em" height="4.5em" />
    }

    return (
        <>
            {(articleInfoType !== ArticleInfoType.WholesalerArticle || !articleInfoType) && (
                <Box height="4.5em" width="4.5em" lineHeight="4.5em">
                    <Image
                        width="100%"
                        height="100%"
                        src={imageUrl || ""}
                        type="article"
                        style={{ objectFit: "contain", objectPosition: "center" }}
                    />
                </Box>
            )}
            {articleInfoType === ArticleInfoType.WholesalerArticle && !isLinkedItem && renderIcon("supplier")}
            {isLinkedItem && renderIcon("deposit-article")}
        </>
    )
}
