/**
 * @memberof Models
 * Also known as BinLkz, Landeskennzeichen-Binaer
 */
export enum CountryFilter {
    B = 1 << 0,
    BIH = 1 << 1,
    BG = 1 << 2,
    DK = 1 << 3,
    D = 1 << 4,
    EW = 1 << 5,
    SF = 1 << 6,
    F = 1 << 7,
    GR = 1 << 8,
    GB = 1 << 9,
    IRL = 1 << 10,
    I = 1 << 11,
    IS = 1 << 12,
    HR = 1 << 13,
    TJ = 1 << 14,
    CY = 1 << 15,
    NL = 1 << 16,
    N = 1 << 17,
    A = 1 << 18,
    PL = 1 << 19,
    P = 1 << 20,
    RO = 1 << 21,
    RUS = 1 << 22,
    S = 1 << 23,
    CH = 1 << 24,
    SLO = 1 << 25,
    SK = 1 << 26,
    E = 1 << 27,
    CZ = 1 << 28,
    TR = 1 << 29,
    UA = 1 << 30,
    H = 1 << 31,
    AUS = 1 << 32,
    BR = 1 << 33,
    BY = 1 << 34,
    CDN = 1 << 35,
    CO = 1 << 36,
    LT = 1 << 37,
    J = 1 << 38,
    M = 1 << 39,
    MEX = 1 << 40,
    MNE = 1 << 41,
    NZ = 1 << 42,
    RA = 1 << 43,
    RCH = 1 << 44,
    ROK = 1 << 45,
    ROU = 1 << 46,
    SRB = 1 << 47,
    LV = 1 << 48,
    USA = 1 << 49,
    ZA = 1 << 50,
}

/**
 * @memberof Models
 * AvailabilityStatus
 */
export enum AvailabilityStatus {
    Undefined = 0,                                // Undefiniert
    Unknown = 1,                                  // Unbekannt
    Available = 2,                                // Verfügbar
    PartiallyAvailable = 3,                       // Teilverfügbar
    NotAvailable = 4,                             // Nicht verfügbar
    AvailableInAlternativeWarehouse = 5,          // Verfügbar im Alternativlager
    PartiallyAvailableInAlternativeWarehouse = 6, // Teilverfügbar im Alternativlager
    AvailableInCentralWarehouse = 7,              // Verfügbar im Stammlager
    PartiallyAvailableInCentralWarehouse = 8,     // Teilverfügbar im Stammlager
    NotYetDefined = 9,                            // Noch nicht definiert
    NoLongerAvailable = 10,                       // Nicht mehr lieferbar
    NightExpress = 11,                            // Lieferung über Nachtexpress möglich
    NotYetRequested = 12,                         // Gibt an, dass der Artikel nicht beim ERP System angefragt wurde. In diesem Fall soll kein ERP Icon angezeigt werden.
    ManufacturerAvailable = 13,                   // Teccom verfügbar
    ManufacturerUnavailable = 14,                 // Teccom nicht verfügbar
    ManufacturerNotYetRequested = 15,             // Teccom nocht nicht angefragt
    NotShown = 16,                                // Verfügbarkeit ausblenden
}

export enum DataPool {
    Topmotive = 0,
    TecDoc = 1
}

export enum OrderStatus {
    NotOrderable = 0,
    ExcludedFromOrder = 1,
    IncludedInOrder = 2,
    Ordered = 3
}

export enum PriceType {
    None = 0,                   // Undefiniert
    RecommendedRetail = 1,      // Unverbindliche Preisempfehlung (UVP) - RecommendedSales
    UsedPart = 2,               // Altteilpreis
    Deposit = 3,                // Pfand                                - Pledge
    Purchase = 4,               // Einkaufspreis
    Retail = 5,                 // Verkaufspreis                        - Sales
    Net = 6,                    // Netto
    Gross = 7,                  // Brutto
    ProcurementCosts = 8,       // Beschaffungskosten
    Garage = 9,                 // Werkstattpreis
    CalculatedWithoutVat = 10,  // Kalkuliert (ohne Mwst.)
    CalculatedWithVat = 11,     // Kalkuliert (mit Mwst.)
    Strikethrough = 12,         // Streichpreis                         - CrossedOut
    GraduatedPurchase = 13,     // Staffelpreis                         - ScaledPurchase
    OriginalEquipmnetPrice = 14,// Stellt den Ersatzteilpreis des Originalausrüstungshersteller dar
    BasicPrice = 15,            // BasisPreis
    Environmental = 16,         // Umweltpreis
    Packaging = 17,             // Verpackungskosten
    Replacement = 18,           // Austauschpreis
    TeccomEK = 19,              // Hersteller-EK (Preis für den Großhändler)
}

export enum CostsType{
    Unknown = 0,
    DefaultDispatch = 1,   //Versandkosten
    Express = 2,           //Expressaufschlag
    Enviromental = 3,      //Umwelt-Pauschale/Abgabe
    Packaging = 4,         //Vepackungskosten
    Pledge = 5,            //Pfand
    Procurement = 6,       //Beschaffungskosten
}

export enum RepairTimeProvider {
    None = 0,
    AwDocCar = 1,
    AwDocTruck = 2,
    Autodata = 3,
    TecRmiCar = 4,
    HaynesProCar = 5,
    Eurotax = 6,
    Dat = 7,
    Audatex = 8,
    TecRmiTruck = 9,
    HaynesProTruck = 10,
    Topmotive = 11,
    ATU = 12,
    MobilityMarketToolRental = 13,
}

export enum WorkCategory {
    NotCategorized = 0,
    WorkshopWork = 1,   //WS - Werkstattarbeiten
    BodyWork = 2,       //KA - Karosseriearbeiten,
    AccessoryWork = 3,  //ZB - Zubehörarbeiten
    PaintWork = 4,      //LA - Lackierarbeien
    ElectricalWork = 5, //EL - Elektrikarbeiten
    ElectronicWork = 6, //EK - Elektronikarbeiten
    SaddlerWork = 7,    //SA - Sattlerarbeiten (Ere)
    SmartRepair = 8,    //SR - SmartRepair (Ere)
    Diagnose = 9        //DW -  Diagnose (Fast DTC)
}

export enum VehicleType {
    Undefined = 0,                  // An undefined type results in a validation error!
    PassengerCar = 1,               // PKW
    CommercialVehicle = 2,          // NKW
    Engine = 4,                     // Motor
    Motorcycle = 8,                 // Motorrad
    Axle = 16,                      // Achse
    Universal = 32,                 // Universal
    DriversCab = 64,                // Fahrerhaus
    GearBox = 128,                  // Getriebe
    All = 256,                      // All Vehicles Types, except Light Commercial Vehicles.
    LightCommercialVehicle = 512    // LCV
}

// In the service is called EFittingSide
export enum FittingPosition {
    None = 0,
    FrontAxle = 1,
    RearAxle = 2,
    Front = 4,
    Rear = 8,
    Left = 16,
    Right = 32,
    Top = 64,
    Bottom = 128,
    Inside = 256,
    Outside = 512,
    WheelSided = 1024,
    GearboxSided = 2048
}

export enum MileType {
    Undefined = 0,
    Kilometer = 1,
    Mile = 2
}

export enum ArticleReferenceType {
    None = 0,
    UtilityNo = 1,
    ComparisonNo = 2,
    ReferenceNo = 3,
    AlternativeArticleNo = 4,
    EuropeanArticleNo = 5,
    AlternativeReferenceNo = 6,
    ReferenceSearch = 7,
    AdditionalArticleNo = 8,
    ReplacementArticleNo = 9,
    OeReferenceNo = 10,
    Asin = 11,
    AdditionalTradeReferenceNo = 12,
    Sku = 13,
    Eurocode = 14,
    ReplacedByArticleNo = 999
}

export enum ArticleInfoType {
    Undefined = 0,
    TecdocArticle = 1,
    OeArticle = 2,
    CustomArticle = 3,
    WholesalerArticle = 4
}

export enum WorkType {
    Undefined = 0,
    MainWork = 1,
    CustomWork = 2,
    CustomMainWork = 3,
    MaintenanceWork = 4
}

export enum MemoType {
    Undefined,                   //0 - Undefiniert
    Note,                        //1 – Hinweis
    Mark,                        //2 – Kennzeichnung
    Hyperlink,                   //3 – Hyperlink
    Tooltip,                     //4 – Tooltip
    AdditionalDescription,       //5 – Zusätzliche Beschreibung
    RecommendedArticle,          //6 – Artikelempfehlung
    Assortment,                  //7 - Sortiment
    ManufactureWarehouse,        //8 -
    ArticleListInformation,      //9 - To be diplayed on the article list
    HeaderInformation            //10 - Kopfinformationen für die Artikeldetailseite / Sonderbeschaffung
}

export enum IconSource {
    Default,            //0 - Default
    Url,                //1 – Url
    TopmotiveId,        //2 – Kennzeichnung
    AldocId             //3 – Hyperlink
}

export enum LinkedItemsRole {
    Undefined,               //0 - Undefiniert
    LinkedItems,             //1 – Verbundartikel
    Alternative,             //2 – Alternativartikel
    Replacement,             //3 – Ersatzartikel
    Accessory,               //4 – Zubehörartikel
    Suggestion,              //5 – Vorschlagsartikel
    Obligated,               //6 – Pflichtartikel
    ItemsCollection,         //7 – ItemsCollection: Diese Produkte werden automatisch in den Warenkorb eingefügt, wenn der angefragte Artikel eingefügt wird.
    PawnItems,               //8 - Pfandartikel
}

export enum TitleType {
    Undefined = 0,
    Mister = 1,
    Miss = 2,
    Company = 3
}

export enum SystemType {
    Smartclient = 3,
    Redesign = 12,
    Next = 23,
}

export enum VoucherType {
    Undefined = 0,
    CostEstimation = 1,
    Order = 2,
    Return = 3
}

export enum WorkTaskStatus {
    Undefined = 0,
    Released = 1,
    Completed = 2,
    Canceled = 3,
    OnHold = 4
}

/**
 * @memberof Models
 * RegistrationNoType
 */
export enum RegistrationNoType {
    /* MDM 0 */
    None = -1,

    /* MDM 0 */
    Kba = 0,

    KennzeichenNorwegenNbk = 1,
    KennzeichenNiederlande = 2,

    /* Typ_mine_suche F */
    NationalcodeFrankreich = 3,

    NationalcodeAustria = 4,
    NkwId = 5,
    KennzeichenFinnlandHlGroup = 6,
    KennzeichenFinnlandAd = 7,
    CarwebVrmWsvc = 8,
    CarwebVrmVinWsvc = 9,
    TypenscheinSchweizEsa = 10,
    VinSlowenien = 11,
    KennzeichenSchwedenKgk = 12,
    TypenscheinSchweizHoststettler = 13,
    KennzeichenFinnlandParkanon = 14,
    HpiWsvc = 15,
    AdsWsvc = 16,
    VrmVinGbEdpWsvc = 17,
    VrmEireWsvc = 18,
    VrmEireEdpWsvc = 19,
    KennzeichenIslandOderVrmIsland = 20,
    NationalcodeFrankreichHerthBuss = 21,
    CartellIrlandKennzeichenWsvc = 22,

    LicensePlateSwitzerlandAutoIDat_Eurotax = 23,

    /* früher Vin_Benelux_Aldoc */
    TopmotiveVin = 24,

    GerausivWsvc = 25,
    TypenscheinSchweizEurotax = 26,
    KennzeichenPortugal = 27,
    KennzeichenFinnlandSpareParts = 28,
    VinNorwegen = 29,
    AisWsvc = 30,
    TecAllianceAutoGeekWsvc = 31,
    Bosch = 32,
    KbaMotorrad = 33,
    KoivunenCatDataWsvc = 34,
    KennzeichenSchwedenBanner = 35,

    // /* Nur xdevdb, keine Daten */
    // VIN_Benelux = 36,

    SchweizTypenscheinAtu = 37,
    ItalienEdidomusWsvc = 38,
    VinSlowakei = 39,
    TypenscheinDehrendinger = 40,
    KennzeichenSchwedenSchwenker = 41,
    KennzeichenNiederlandeSchwenker = 42,

    /* ?? */
    VehicleHostory = 43,

    /* Kennzeichen Frankreich */
    VrmAAA = 44,

    /* Kennzeichen GB -HCV */
    VrmKennzeichenGBHCV = 45,

    /* Kennzeichen Spanien - DGI */
    VrmKennzeichenSpanienDGT = 46,

    VrmVINSpanienInforauto = 47,
    VrmKennzeichenSchwedenAIS = 48,

    /* Kennzeichen Estland */
    KennzeichenEstland = 49,

    /* Kennzeichen GB - DDS */
    VrmKennzeichenGBDDS = 52,

    KennzeichenNiederlandeCarat = 53,
    TypenscheinSchweizCarat = 54,
    NationalCodeFrankreichCarat = 55,
    VrmKennzeichenNorwegenAIS = 56,
    LicencePlateDK = 57,
    VrmKennzeichenIrlandDDS = 58,
    LicensePlateGBHaynesPro = 59,
    VrmKennzeichenFinnlandAISVeCloud = 60,
    VrmVINAutoDAP = 61,
    VINSouth_Africa_Autoboys = 62,

    LicensePlateSwitzerlandAutoIDat_Hostettler = 63,
    LicensePlateSwitzerlandAutoIDat_ESA = 64,

    LicensePlateEstoniaAlm = 65,

    LicensePlateDriveRight = 66,
    LicensePlateSwedenDriveRight = 67,
    LicensePlateNorwayDriveRight = 68,
    LicensePlateFinlandDriveRight = 69,
    LicensePlateDenmarkDriveRight = 70,
    LicensePlateEireDriveRight = 71,
    LicensePlateNewZealandDriveRight = 72,
    LicensePlateNetherlandsDriveRight = 73,
    LicensePlateItalyDriveRight = 74,
    LicensePlatePortugalDriveRight = 75,
    LicensePlateSpainDriveRight = 76,
    LicensePlateBrazilDriveRight = 77,
    LicensePlateSwitzerlandDriveRight = 78,
    LicensePlateUSDriveRight = 79,
    LicensePlateFranceDriveRight = 80,
    LicensePlateUKDriveRight = 81,

    VrmBilling = 82,
    VinPortugal = 83,

    LicensePlateItalyRhiag = 84,

    // Stammdaten (CRM)
    VehicleBase = 85,

    VinOeNrAutoDAP = 86,
    VINFranceInforauto = 87,
    VLPortugalCreateBusiness = 88,

    LicensePlateFranceTruckInfoAuto = 96,

    VrmLicensePlateNetherlandRDC = 97, //In: LicensePlate, out: Vin
    VrmLicensePlateHaynesProIt = 99,

    TypeApprovalNumberSwitzerlandRhiag = 100,
    LicensePlateCyprusKapodistrias = 101,

    TopmotiveTypeId = 102, //TMF-Id Suche

    LicensePlateVRMAutoDAP = 103,
    VrmLicensePlateNetherlandsRDW = 104,
    LicensePlateDkVrm_WMKAT = 107,
    LicensePlateIcelandAutomatic = 108, // automatic eh license plate
    LicensePlateSwitzerlandECOC_AutoIDat_Esa = 109,
    LicensePlateSwitzerlandECOC_AutoIDat_Hostettler = 110,
    LicensePlateSwitzerlandECOC_AutoIDat_Rhiag = 111,
    VinFrance_AAA = 113,
    VLSpain_CreateBusiness = 114,
    LicensePlateSwitzerland_RhiagCH = 116,
    LicensePlateSwitzerlandECOC_AutoIDat_Eurotax = 117,

    // /* Modulnummer MDM */
    // TecrmiVin = 208,

    /* nur Kurzkennzeichen MDM */
    KeywordSearch = 9992,

    /* nur Kurzkennzeichen MDM */
    RückwFahrzeugVkn = 9993,

    /* nur Kurzkennzeichen MDM */
    DatVin = 9994,

    /* nur Kurzkennzeichen MDM */
    KTypNr = 9995,

    /* nur Kurzkennzeichen MDM */
    Motorcode = 9996,

    /* nur Kurzkennzeichen MDM */
    Fahrzeugbaum = 9997
}

export enum SearchFilters {
    SupplierArticleNo = 1,                          /// Also known as EArtNr, Einspeiserartikelnummer
    OeReference = 2,                                /// Also known as OE Referenz
    UtilityNo = 4,                                  /// Also known as Gebrauchsnummer
    ReplacementNo = 8,                              /// Also known as Ersatznummer
    Manufacturer = 15,                              /// Also known as Hersteller // combination of all above
    EuropeanArticleNumber = 16,                     /// Also known as EAN, EANNr, Europäische Artikelnummer
    TradeReference_KART = 32,
    TradeReference_KARTREF = 64,
    TradeReference = 96,                            /// Also known as Handelsreferenz // combination of the two above
    SupplierArticleNoAndTradeReference = 97,
    OeAndTradeReference = 98,                       // combination of OeReference + TradeReference
    All = 2047,
}

export enum CisVoucherType {
    Undefined = 0,
    Offers = 1,
    Order = 2,
    DeliveryNote = 3,
    Estimation = 4,
    Returns = 5,
    CreditItems = 6,
    Backorder = 7,
    OpenOrders = 8,
    ClosedOrders = 9,
    ArticleSearchInOrders = 10,
    Complaints = 11,
    OldParts = 12,
    PickupInvoices = 13,
    Invoices = 14,
    AllOrders = 15
}

export enum ReturnStatus {
    Undefined = 0,
    ExcludedFromReturn = 1,
    IncludedInReturn = 2,
    Returned = 3
}

export enum VatRateType {
    None = 0,           //Undefiend
    Normal = 1,         //Normal rate -> e.g. 19%
    Reduced = 2,        //Reduced rate -> e.g. 7%
    Replacement = 3,    //AT-Steuer
    WithoutRate = 4     //Durchlaufposten (bereits versteuert) -> 0%
}

export enum DispatchCostsType {
    Unknown = 0,
    Default = 1,
    Express = 2
}

// Could not be named DispatchType, since there is already a Type named like that already
export enum WarehouseDispatchType {
    Undefiend = 0,
    Delivery = 1,
    Collection = 2,
    NightExpressDelivery = 3,
    DayExpressDelivery = 4
}

export enum MessageType {
    Unknown = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    TechnicalError = 4
}

export enum CatalogContext {
    ArticleList = 0,
    ArticleDetails = 1,
    ArticleComparison = 2,
    Basket = 3,
    Order = 4,
    OrderOptionsPage = 5,
    CostEstimation = 6, //Also FastCalculation
    Dashboard = 7,
    StartPage = 8,
    BasketExport = 23,
    BasketSummary = 9999 //Temporary till NEXT-16156 is finished (Basket Refactoring)
}

export enum MVCPlatformType {
    Redesign,
    Smartclient,
    ExternalWeb,
    ExternalWpf,
    Next
}

export enum VrmLookupTextIds {
    UnknownError = 1261,
    UserNotRegistered = 1354,
    NoResultForVin = 1262,
    NoResult = 1405,
    ManufacturerNotSupported = 1263,
    ContingentLimitReached = 1524,
    ResultSuccess = 12448
}

export enum AjaxCachingMode {
    NoCache = 0,
    Request = 1,
    Response = 2
}

export enum DATAvailableCountries {
    Spain,
    CzechRepublic,
    Austria,
    Switzerland,
    Germany,
    Greece,
    GreatBritain,
    USA,
    France,
    Hungary,
    Italy,
    Poland,
    Romania,
    Russia,
    Slovakia,
    Turkey
}

export enum DisplayBehaviour {
    None = 1,
    ClassicWidget = 1,
    ExtendedWidget = 2,
    SelectionWidget = 3,
    StartPage = 514,
    Dashboard = 515,
    StartPageAndDashboard = 516,
    Basket = 519,
    Header = 521,
    Module = 523,
    DashboardAndHeader = 525
}

export enum CalculatedCostType {
    Unknown = 0,
    Vin = 1,
    DatPackage = 2,
    DatGlas = 3,
    DatEre = 4,
    DatFiOnline = 5
}

export enum CausedCostsType {
    All = 0,
    NoCosts = 1,
    OnlyCosts = 2,
}

//add every new dialog key here
export enum ConfirmationDialogKeys {
    DeleteBasketItem = "deleteBasketItem",
    DeleteAllBasketItems = "deleteAllBasketItems",
    LeaveCentralOrder = "leaveCentralOrder"
}

export enum SelectedPriceTypeMode {
    Retail = 0,
    Purchase = 1,
}

export enum SelectedPriceVatMode {
    Net = 0,
    Gross = 1,
}

export enum SelectedCustomerGroupMode {
    B2C = 0,
    B2B = 1,
}
