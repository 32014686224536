import { Component } from "react"

export default class Day extends Component<DayProps, any> {

    isDisabled() {
        const { day, minDate, maxDate } = this.props;
        return minDate && day.isBefore(minDate) || maxDate && day.isAfter(maxDate)
    }

    getClassName() {
        const { selected, day, month, minDate, maxDate } = this.props;
        let className = "calendar__day";
        className += selected && day.isSame(selected) ? " is-selected" : "";
        className += day.isSame(new Date()) ? " is-today" : "";
        className += this.props.month !== this.props.day.month() ? " outside-month" : "";
        className += this.isDisabled() ? " is-disabled" : "";
        return className;
    }

    handleClick(): void {
        if (!this.isDisabled() && this.props.onClick) {
            this.props.onClick(this.props.day);
        }
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={this.handleClick.bind(this)}>
                {this.props.day.day()}
            </div>
        );
    }
}

export interface DayProps {
    day: Date;
    month: number;
    selected?: Date;
    minDate?: Date;
    maxDate?: Date;
    onClick(day: Date): void;
}
