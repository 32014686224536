import { Box, Stack } from "@mui/material"
import { ArticleNotification } from "@tm/models"
import { Divider } from "../../../generics/divider"
import { Icon } from "../../../generics/Icons"
import { TooltipContentText } from "../Attributes/TooltipWrapper"

type CompactNotificationProps = {
    note: ArticleNotification
    blockIndex: number
    hideDivider: boolean
}

function getIcon(skin?: string): string {
    switch (skin) {
        case "warning": {
            return "alert_W_attention"
        }
        case "danger": {
            return "alert_W_warning"
        }
        case "success": {
            return "alert_W_success"
        }
        default: {
            return "alert_W_infomation"
        }
    }
}

export function CompactNotification({ note, blockIndex, hideDivider }: CompactNotificationProps) {
    return (
        <Box key={`informationBox_${blockIndex}`} my={2}>
            {hideDivider && (
                <Stack direction="row" alignItems="center" justifyContent="stretch" mb={1} minHeight="12px">
                    <Divider light />
                </Stack>
            )}
            <Stack direction="row" alignItems="center" mt={1} gap={1} ml={hideDivider ? 3 : 0}>
                <Icon size="24px" color={note.variant} name={getIcon(note.variant)} />
                <TooltipContentText variant="body2">{note.message}</TooltipContentText>
            </Stack>
        </Box>
    )
}
