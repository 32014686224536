import { memo, PropsWithChildren, ReactNode, useCallback } from "react"
import { Box, styled, tooltipClasses } from "@mui/material"
import { Tooltip } from "../../generics/tooltip"
import { Typography } from "../../generics/typographie"
import { NoteMessage } from "."

type Props = {
    notes: NoteMessage[]
    onClick?: () => void
}

const WiderTooltip = styled(Tooltip)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: theme.spacing(80),
    },
}))

export const NoteTooltip = memo<PropsWithChildren<Props>>(({ notes, children, onClick }) => {
    const renderSingleNoteItem = useCallback((note: NoteMessage, index: number): ReactNode => {
        if (!note.message) {
            return null
        }
        return (
            <Box key={`note_${index}`}>
                <Box pt={1}>
                    <Typography color="inherit" variant="h4">
                        {note.title}
                    </Typography>
                </Box>
                <Box py={0.5}>
                    <Typography color="inherit">{note.message}</Typography>
                </Box>
            </Box>
        )
    }, [])

    const hasNote = notes.find((e) => e.message !== undefined)

    if (!hasNote) {
        return <div onClick={onClick}>{children}</div>
    }

    return (
        <WiderTooltip variant="light" title={notes.map(renderSingleNoteItem)} onClick={onClick}>
            <div>{children}</div>
        </WiderTooltip>
    )
})
