import { ChildConfig, ComponentProps, Dictionary, IContext } from "..";
import { IPluginComponent } from "../models/bundle";
import { ModuleProps } from "../module";


interface IContextWithComponents extends Omit<IContext, "plugins"> { plugins: Array<[IPluginComponent<ChildConfig>, Dictionary<any>]> }

export function executePlugin(context: IContext, props: ModuleProps | ComponentProps, type: string) {
    if ((props as ComponentProps).componentProps) {
        executePluginComponent((context as unknown as IContextWithComponents), props, type)
    }
    else {
        executePluginModule(context, props, type)
    }
}

export function executeConditionalPlugin(context: IContext, props: ModuleProps | ComponentProps, type: string): boolean {
    if ((props as ComponentProps).componentProps) {
        return executePluginComponent((context as unknown as IContextWithComponents), props, type)
    }
    else {
        return executePluginModule(context, props, type)
    }
}

export function executePluginModule(context: IContext, props: ModuleProps | ComponentProps, type: string): boolean {
    let returnValue: boolean = true
    const plugins = context.plugins.filter(([x]) => x.type == type)
    plugins.forEach(([plugin, pluginProps]) => {
        try {
            returnValue = !!(plugin.plugin && plugin.plugin({
                path: props.moduleKey,
                module: props.config,
                pluginProps,
            })) && returnValue
        } catch (ex) {
            console.error(`Plugin ${plugin.name}`, ex)
        }
    })

    return returnValue
}

export function executePluginComponent(context: IContextWithComponents, props: ComponentProps, type: string): boolean {
    let returnValue: boolean = true
    const plugins = context.plugins.filter(([x]) => x.type == type)
    plugins.forEach(([plugin, pluginProps]) => {
        try {
            returnValue = !!(plugin.plugin && plugin.plugin({
                path: props.componentProps.key,
                component: props.componentProps.__config,
                pluginProps,
            })) && returnValue

        } catch (ex) {
            console.error(`Plugin ${plugin.name}`, ex)
        }
    })

    return returnValue
}
