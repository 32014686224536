export enum UITypes {
    Container = "container",
    Button = "button",
    Icon = "icon",
    Link = "link",
    Checkbox = "checkbox",
    label = "label",
    switch = "switch",
    Select = "select",
    Radio = "radiobutton",
}

export type UIAHtmlAttribut = {
    /* What kind of type is this element */
    "data-type": UITypes
    /* descriptive id with . notation, like <Container>.<Element>.<SubElement> 
       example 'Searchtree.bestseller.checkbox'
    */
    "data-id": string
}
