import { Base64 } from "js-base64"

export function encodeBase64(text: string) {
    return Base64.encode(text)
}

export function decodeBase64(base64: string) {
    return Base64.decode(base64)
}

export function objectToBase64(object: Object) {
    return Base64.encode(JSON.stringify(object))
}

const BASE64_MARKER = ";base64,"
export function getAdjustedValueFromDataUri(dataUri: string): string {
    const base64Index = dataUri.indexOf(BASE64_MARKER) + BASE64_MARKER.length
    return dataUri.substring(base64Index)
}

export function convertDataURIToDecodedString(dataUri: string) {
    return window.atob(getAdjustedValueFromDataUri(dataUri))
}
