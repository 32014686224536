import { Component } from "react"
import scrollMonitor from "scrollmonitor";
import { bindMethodsToContext, elementId } from "../../helper"

export type ScrollSpyProps = {
    area: string; //className for the watched content area
    watch: string; //className for the watched elements
}

export default class ScrollSpy extends Component<ScrollSpyProps, any> {

    private activeClassName = "is-active";
    private watcher = new Array();
    private containerElement: HTMLElement | null = null;
    private containerMonitor: any = null;

    constructor(props: ScrollSpyProps) {
        super(props);
        this.state = {
            menuItems: false
        };

        bindMethodsToContext(this)
    }

    componentDidMount() {
        window.setTimeout(this.getNavigationElements.bind(this), 10); //@TODO: Find a better solution
    }

    /**
     * Find all content sections on the page and save it into the state
     * @return void
     */

    getNavigationElements() {
        //create a scroll container for the scrollMonitor
        this.containerElement = document.querySelector<HTMLElement>(this.props.area);
        this.containerMonitor = scrollMonitor.createContainer(this.containerElement!);

        //get the menu items
        const menu = new Array();
        const el = document.querySelectorAll(this.props.area + " " + this.props.watch);
        for (let index = 0; index < el.length; index++) {
            const element = el[index];
            this.watcher.push(this.containerMonitor.create(el[index], 1));
            element.setAttribute("id", elementId());
            menu.push(element);
        }

        this.setState({ menuItems: menu });
    }

    /**
     * Scroll to the element inside the area
     *
     * Take the ID from the element and scroll to the matching DOM Node
     * @param el - the clicked element in the menu
     * @return void
     */

    scrollTo(el: any, e: any) {
        const id = el.id;

        //get the position of the element
        const areaEl = document.getElementById(id);

        if (areaEl) {
            areaEl.scrollIntoView({ block: "start", behavior: "smooth" })
        }
    }

    /**
     * Set the active class on the active menu item
     * @param el - this is the element how entered/left the viewport
     * @return void
     */

    setActiveClass(el: any) {
        const id = el.id;
        const menuEl = document.querySelectorAll("[data-id='" + id + "']");

        //remove from other elements
        const menuItems = document.querySelectorAll(".scrollspy .tabbar__button");
        for (let index = 0; index < menuItems.length; index++) {
            const element = menuItems[index];
            element.classList.remove(this.activeClassName);

        }

        if (menuEl.length > 0) {
            menuEl[0].classList.add(this.activeClassName);
        }
    }

    /**
     * Remove the active class on a DOM element
     * @param el - this is the element how entered/left the viewport
     * @return void
     */

    removeActiveClass(el: any) {
        const id = el.id;
        const menuEl = document.querySelectorAll("[data-id='" + id + "']");

        if (menuEl.length > 0) {
            menuEl[0].classList.remove(this.activeClassName);
        }
    }

    /**
     * Watch the content and set the update the menu by adding classes
     * @return void
     */

    watch() {
        const self = this;
        this.watcher.forEach((element: any) => {
            element.fullyEnterViewport(() => self.onEnterViewport(element));
            element.exitViewport(() => self.onExitViewport(element));
        });
    }

    /**
     * A Element enterd the viewport
     * Set a active class in the menu
     * @param el - element who entered the viewport
     * @return void
     */

    onEnterViewport(el: any) {
        this.setActiveClass(el.watchItem);
    }

    /**
     * A Element left the viewport
     * Remove the active class in the menu
     * @param el - element who left the viewport
     * @return void
     */

    onExitViewport(el: any) {
        // currently the active class will be removed by the addActiveClass method
        // this.removeActiveClass(el.watchItem);
    }

    render() {
        const self = this;

        if (this.state.menuItems) {
            const menuItems = this.state.menuItems.map(function (obj: any, i: number) {
                let className = "tabbar__button";
                if (i == 0) {
                    className += " is-active";
                }
                return <span className={className} data-id={obj.id} onClick={(e) => self.scrollTo(obj, e)} key={i}>{obj.dataset.title}</span>;
            });

            // start the watcher and return the HTML
            self.watch();
            return (<div className="scrollspy tabbar">{menuItems}</div>)
        }

        return null;
    }
}
