import { Article, Note, NoteTypes, Vehicle } from "@tm/models"
import { useMemo, memo } from "react"
import { useLocalization } from "@tm/localization"
import { Collapse, Stack } from "@mui/material"
import { NoteItem } from "./NoteItem"
import { SubArticleContainer } from "../SubArticleContainer"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"

export const mapArticleToNoteIdentifier = (article: Article | string): string => {
    return typeof article === "string" ? article : `${article.supplierArticleNo}_${article.supplier.id}`
}

export const mapArticleAndVehicleToNoteIdentifier = (article: Article | string, vehicle: Vehicle): string => {
    return typeof article === "string" ? article : `${article.supplierArticleNo}_${article.supplier.id}_${vehicle.tecDocTypeId}`
}

export const Notes = memo(() => {
    const { translateText } = useLocalization()
    const article = useDefaultArticleItemState((x) => x.article)
    const vehicle = useDefaultArticleItemState((x) => x.vehicle)
    const isVehicleDependent = useDefaultArticleItemState((x) => x.isVehicleDependent)
    const { notes, handleDelete, handleSave, isLoading, visible, hide } = useDefaultArticleItemState((x) => x.notes)

    const displayNotes = useMemo<Note[]>(() => {
        const list: Note[] = []
        list.push(
            notes.find((x) => x.type === NoteTypes.ARTICLE) ?? {
                noteId: mapArticleToNoteIdentifier(article),
                type: NoteTypes.ARTICLE,
                text: "",
            }
        )
        if (isVehicleDependent && vehicle) {
            list.push(
                notes.find((x) => x.type === NoteTypes.VEHICLE_ARTICLE) ?? {
                    noteId: mapArticleAndVehicleToNoteIdentifier(article, vehicle),
                    type: NoteTypes.VEHICLE_ARTICLE,
                    text: "",
                }
            )
        }
        return list
    }, [article, isVehicleDependent, notes, vehicle])

    return (
        <Collapse in={visible}>
            {visible && (
                <SubArticleContainer title={translateText(14)} onClose={hide} isLoading={isLoading}>
                    <Stack rowGap={1} columnGap={2} display="grid" gridTemplateColumns="auto 1fr auto">
                        {displayNotes.map((note) => (
                            <NoteItem key={note.noteId} note={note} onSave={handleSave} onDelete={handleDelete} isLoading={isLoading} />
                        ))}
                    </Stack>
                </SubArticleContainer>
            )}
        </Collapse>
    )
})
