import { BasketMemoSection, channel, Customer, Vehicle, WorkTaskDetailsReadModel } from "@tm/models"

import { notUndefinedOrNull } from ".."
import { WorkTaskTruckData } from "../hooks"

/**
 * @deprecated Use `createBasketMemo` instead - see example below.
 *
 * @example
 * ```typescript
 * createBasketMemo({
 *   vehicle,
 *   customer,
 *   truckData,
 * })
 * ```
 */
export function createBasketPartMemo(
    vehicle: Vehicle | undefined,
    customer: Customer | undefined,
    truckData: WorkTaskTruckData | undefined
): string | undefined {
    const memoParts = []

    if (vehicle) {
        if (vehicle.plateId) {
            memoParts.push(vehicle.plateId)
        } else {
            memoParts.push(vehicle.manufacturer, vehicle.modelSeries, vehicle.model)
        }
    }

    if (customer?.firstName) {
        memoParts.push(customer.firstName)
    }

    if (customer?.lastName) {
        memoParts.push(customer.lastName)
    }

    if (!memoParts.length && truckData) {
        if (truckData.plateNr) {
            memoParts.push(truckData.plateNr)
        }

        if (truckData.customerName) {
            memoParts.push(truckData.customerName)
        }
    }

    if (memoParts.length) {
        return memoParts.join(" ")
    }
}

export type BasketMemoConfig = {
    sections: Array<BasketMemoSection>
}

export type CreateBasketMemoOptions = {
    config?: BasketMemoConfig
    vehicle?: Vehicle
    customer?: Customer
    truckData?: WorkTaskTruckData
    workTask?: WorkTaskDetailsReadModel
}

export function createBasketMemo({ config, vehicle, customer, truckData, workTask }: CreateBasketMemoOptions): string | undefined {
    // If no config was passed or the config did not contain any sections create the memo like before this change.
    if (!config?.sections.length) {
        return createBasketPartMemo(vehicle, customer, truckData)
    }

    function concat(separator: string, ...strings: Array<string | number | false | undefined | null>): string | undefined {
        const string = strings.filter(notUndefinedOrNull)

        if (string.length) {
            return string.join(separator)
        }
    }

    function getMemoSectionValue(type: BasketMemoSection): string | undefined {
        switch (type) {
            case "vehicleLicensePlate":
                return vehicle?.plateId ?? truckData?.plateNr
            case "vehicleLicensePlateWithVehicleDescriptionFallback":
                return getMemoSectionValue("vehicleLicensePlate") ?? concat(" ", vehicle?.manufacturer, vehicle?.modelSeries, vehicle?.model)
            case "customerFirstName":
                return customer?.firstName
            case "customerLastName":
                return customer?.lastName
            case "customerFullName":
                return concat(" ", customer?.firstName, customer?.lastName) ?? truckData?.customerName
            case "customerCompanyName":
                return customer?.companyName
            case "voucherNumber":
                return workTask?.voucherNo
        }
    }

    return concat(" ", ...config.sections.map(getMemoSectionValue))
}

export function isCentralOrder(): boolean {
    return window.location.pathname.includes("/order") && !window.location.pathname.includes("/orderedArticles")
}

export function showWarehouseDataMissingError(translator: (id: number) => string) {
    channel("APP").publish("TOAST_MESSAGE/SHOW", {
        skin: "danger",
        message: translator(13187),
        closeDelay: 10000,
        icon: "alert",
    })
}
