import React, { FC, useContext } from "react"
import { EasyPopoverContext } from "./context"

export const PopoverActionButton: FC = ({ children }) => {
    const context = useContext(EasyPopoverContext)

    if (!context) {
        throw new Error("ActionButton should be used inside EasyPopover")
    }

    const { setAnchor } = context

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget)

        if (React.isValidElement(children) && children.props.onClick) {
            children.props.onClick(event)
        }
    }

    return React.cloneElement(children as React.ReactElement, {
        onClick: handleClick,
    })
}
