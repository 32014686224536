import { UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { useUser } from "../../user"
import { getModuleFromUserContext } from "../../helper"

/**
 * Returns a boolean indicating whether the current user has the CIS module enabled.
 */
export function useHasCis(): boolean {
    const { userContext } = useUser()

    return useMemo(() => {
        return !!getModuleFromUserContext(userContext, UserModuleType.CustomerInformation)
    }, [userContext.modules])
}
