import { PartsContainer, RegisteredModels, SupplierArticle } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

const KEY = "parts_useArticlesBySupplierArticleNumber"
export function useArticlesBySupplierArticleNumber(request: SupplierArticle | undefined) {
    const { data } = useQuery(
        [KEY, request],
        () => {
            if (request) {
                const container: PartsContainer = Container.getInstance(RegisteredModels.Articles)
                return container.action("getArticlesBySupplierArticleNumber")(request)
            }
        },
        {
            staleTime: 4 * 60 * 60 * 1000, // 4 hours
            enabled: !!request,
        }
    )

    return { articles: data }
}
