export function getLocalePrecisionSymbol() {
    const result = new RegExp(/\d(,|\.)\d/).exec((0.2).toLocaleString())
    return result && result[1] ? result[1] : "."
}

export function fixPrecisionSymbol(value: string) {
    const locale = getLocalePrecisionSymbol()
    if (value) {
        return value.toString().replace(locale == "." ? "," : ".", locale)
    }
    return value
}
