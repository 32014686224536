import { useLocalization } from "@tm/localization"
import { AttributeValueParms, ModificationState } from "@tm/models"
import { useMemo } from "react"
import { alpha, Box, StackProps, styled } from "@mui/material"
import { getContrastText } from "../../../theme"
import { Typography } from "../../../generics/typographie"
import { Tooltip } from "../../../generics/tooltip"
import { Icon } from "../../../generics/Icons"

type AttributeValueElementProps = StackProps & {
    isClickable?: boolean
    isSelected?: boolean
    isRemoved?: boolean
    isHighlighted?: boolean
}

const AttributeValueElement = styled(Box, {
    shouldForwardProp: (prop: string) => !["isClickable", "isSelected", "isRemoved", "isHighlighted"].includes(prop),
})<AttributeValueElementProps>(({ theme, isClickable, isSelected, isRemoved, isHighlighted }) => ({
    fontWeight: 600,
    opacity: 1,
    padding: 0,
    border: "1px solid transparent",
    borderRadius: theme.radius?.default,
    fontFamily: theme.font.fontFamily.condensed ?? theme.font.fontFamily.secondary,
    fontSize: theme.font.label?.fontSize,
    whiteSpace: "nowrap",
    flexWrap: "nowrap",
    flex: "inherit", // make sure to use the flex-settings from parent
    minWidth: 0,
    overflow: "hidden",
    margin: "0 !important", // make sure spacing from parent is not applied here, we have to use the SpacingWrapper instead

    svg: {
        marginRight: 4,
    },

    ...(isRemoved && {
        textDecoration: "line-through",
    }),
    ...(isClickable && {
        cursor: "pointer",
        ...(!isSelected && {
            "&:hover *": {
                color: theme.palette.primary.main,
            },
        }),
    }),
    ...(isHighlighted && {
        backgroundColor: theme.palette.highlight.light,
        borderColor: theme.palette.highlight.main,
    }),
    ...(isSelected && {
        backgroundColor: theme.palette.primary.main,
        borderColor: "transparent",
        "*": {
            color: getContrastText(theme.palette.primary.main),
        },
    }),
}))
AttributeValueElement.defaultProps = { direction: "row" }

// use separate SpacingWrapper to make sure parent can shrink to 0px
const SpacingWrapper = styled(Box)({
    padding: "1px 3px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    minWidth: 0,
    display: "flex",
})

type AttributeValueProps = {
    valueData: AttributeValueParms
    onClick?(val: string): void
}

export function AttributeValue({ valueData, onClick }: AttributeValueProps) {
    const { translateText } = useLocalization()

    const handleClick = useMemo(
        () => (valueData.isSelectable !== false && onClick && valueData.value ? () => valueData.value && onClick(valueData.value) : undefined),
        [valueData.value, onClick]
    )

    const isRemoved = valueData.modificationState === ModificationState.Removed

    let content = (
        <Typography variant="label" whiteSpace="nowrap">
            {valueData.displayValue}
        </Typography>
    )

    if (valueData.tooltip) {
        content = (
            <Tooltip sx={{ whiteSpace: "nowrap" }} title={valueData.tooltip}>
                {content}
            </Tooltip>
        )
    }

    return (
        <AttributeValueElement
            onClick={handleClick}
            isHighlighted={valueData.isHighlighted}
            isSelected={valueData.isSelected}
            isRemoved={isRemoved}
            isClickable={!!handleClick && !isRemoved}
        >
            <SpacingWrapper>
                {(() => {
                    switch (valueData.modificationState) {
                        case ModificationState.Added:
                            return (
                                <Tooltip title={translateText(886)}>
                                    <Icon name="plus" size="12px" />
                                </Tooltip>
                            )
                        case ModificationState.Removed:
                            return (
                                <Tooltip title={translateText(895)}>
                                    <Icon name="minus" size="12px" />
                                </Tooltip>
                            )
                        default:
                    }
                })()}
                {content}
            </SpacingWrapper>
        </AttributeValueElement>
    )
}
