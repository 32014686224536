import { Component } from "react"
import { getFieldErrors, getValue, setValue, clone, registerOutsideClick, bindSpecialReactMethods } from "@tm/utils";
import { createErrorMessage, FormElementProps, FormElementState } from "../../models";
import Icon from "../icon"
import Popover from "../popover";


export default class DropdownField extends Component<DropdownFieldProps, DropdownFieldState> {
    element?: HTMLElement
    unregisterOutsideClick?: () => void

    constructor(props: DropdownFieldProps) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            value: this.getValue(this.props),
            errors: []
        }
    }

    UNSAFE_componentWillMount() {
        this.checkErrors(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: DropdownFieldProps) {
        this.setState({
            value: this.getValue(nextProps),
        });
        this.checkErrors(nextProps);
    }

    componentWillUnmount() {
        this.unregisterOutsideClick && this.unregisterOutsideClick()
    }

    checkErrors(props: DropdownFieldProps) {
        if (props.modelState && props.path) {
            const errors = getFieldErrors(props.modelState, props.path);
            if (errors) {
                this.setState({ errors });
            }
        } else {
            this.setState({ errors: [] });
        }
    }

    getValue(props: DropdownFieldProps): string {
        const value = (props.model && props.path ? getValue(props.model, props.path) : props.value);
        return value != null ? value.toString() : "";
    }

    getItemValue(key: any) {
        return this.props.items[key] || key
    }

    focus() {
        if (this.props.readonly) { return }

        if (!this.state.active) {
            this.setState({ active: true });
        }

        if (this.element) {
            setTimeout(() => {
                this.element && this.element.focus();
            }, 0)
        }
    }

    handleClickOutside() {
        this.setState({ open: false })
    }

    handleRef(el: HTMLElement | null) {
        if (el) {
            this.element = el
        }
    }

    handleFocus() {
        if (this.props.readonly) { return }
        this.setState({ active: true });

        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    handleBlur() {
        if (this.props.readonly) { return }
        this.setState({ active: false });
    }

    handleSetOpen(open: boolean) {
        if (this.props.readonly) { return }
        this.setState({ open });
        if (this.element) {
            if (open) {
                this.unregisterOutsideClick = registerOutsideClick(this.element, this.handleClickOutside)
            }
            else {
                this.unregisterOutsideClick && this.unregisterOutsideClick()
            }
        }
    }

    handleChange(item: string) {
        let value = item
        let model;

        if (this.props.model && this.props.path) {
            model = setValue(clone(this.props.model), this.props.path, item)
            value = getValue(model, this.props.path)
        }

        this.setState({
            value,
            open: false
        })

        if (this.props.onChange) {
            if (model && this.props.path) {
                this.props.onChange(model, this.props.path)
            } else {
                this.props.onChange(value)
            }
        }
    }

    renderItems() {
        let className = "input__list-item"
        if (this.props.noWrap) {
            className += "-nowrap"
        }

        return Object.keys<Record<string, string>>(this.props.items).map((key) =>
            <li className={className} key={key} onClick={this.handleChange.bind(this, key)}>{this.props.items[key]}</li>
        );
    }

    render() {
        const { className, label } = this.props
        const { open, value } = this.state;

        let elClassName = "input input--dropdown";
        const hasErrors = this.state.errors != null && this.state.errors.length > 0;
        elClassName += !label ? " input--s" : ""
        elClassName += className ? ` ${className}` : ""
        elClassName += hasErrors ? " has-error" : ""
        elClassName += this.props.readonly ? " readonly" : ""
        elClassName += open ? " is-active" : ""
        elClassName += this.props.value && this.props.value != " " ? " has-value" : "";
        elClassName += this.props.floatingLabel ? " input--floating-label" : "";
        elClassName += this.props.noCover && this.state.value == "0" ? "" : " has-value";

        const inputClassName = "input__field";

        const labelElement = label ? <label className="input__label" htmlFor={this.state.id}>{label}</label> : false

        return (
            <div className={elClassName} ref={this.handleRef} onClick={this.handleSetOpen.bind(this, !open)}>
                <div className="input__wrapper">
                    <div className="input__inner">
                        {labelElement}
                        <div className={inputClassName}>
                            {this.getItemValue(value)}
                        </div>
                        <div className="input__icons">
                            <Icon name={open ? "up" : "down"} className="icon--tiny" />
                        </div>
                        <Popover active={open} alignArrow="top" className="input__selection" onOutsideInteraction={(e) => {
                            if (!(this.element as any).contains(e.target)) {
                                this.handleSetOpen(!open)
                            }
                        }}>
                            <ul className={"input__list"}>
                                {this.renderItems()}
                            </ul>
                        </Popover>
                        {hasErrors && this.element ? createErrorMessage(this.state.errors || [], this.element, "bottom") : null}
                    </div>
                </div>
            </div>
        );
    }
}

export interface DropdownFieldProps extends FormElementProps {
    items: { [key: string]: string }
    noCover?: boolean
    noWrap?: boolean
}

export interface DropdownFieldState extends FormElementState {
    open?: boolean
}
