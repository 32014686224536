import { Dictionary } from "../types"

export function mapDictionary<T>(data: any, mapT?: (data: any) => T): Dictionary<T> {
    const result: { [key: string]: T } = {}

    if (data) {
        Object.keys(data).forEach((key) => (result[key] = mapT ? mapT(data[key]) : data[key]))
    }

    return result
}
