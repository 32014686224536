export function getFileContent(acceptedFile: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e: any) => {
            if (e.target && e.target.result) {
                resolve(e.target.result)
            } else {
                reject()
            }
        }

        reader.onerror = () => {
            reject()
        }
        reader.readAsDataURL(acceptedFile)
    })
}
