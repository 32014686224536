import { ESubModules, EUserContextParameters, UserContext, UserModuleType } from "@tm/models"

export function getVatFromUserContext(userContext?: UserContext): number {
    return userContext?.modules?.find((x) => x.type == UserModuleType.Catalog)?.vat ?? 0
}

export function getCurrencyFromUserContext(userContext?: UserContext): string {
    return userContext?.modules?.find((x) => x.type == UserModuleType.Catalog)?.currencyCode ?? "EUR"
}

export const getCostPerCalculation = (moduleType: UserModuleType, submodule: ESubModules, userContext?: UserContext) => {
    const module = userContext?.modules?.find((x) => x.type == moduleType)

    if (module?.moduleSubId == submodule) {
        let costParameter: string | undefined

        if (moduleType === UserModuleType.TMDamageCalculationDat) {
            costParameter = module.parameters?.find((x: any) => x.key == EUserContextParameters.ModuleCostsDAT)?.value
        } else if (moduleType === UserModuleType.TMDamageCalculationEurotax || moduleType === UserModuleType.TMDamageCalculationGlass) {
            costParameter = module.parameters?.find((x: any) => x.key == EUserContextParameters.ModuleCostsGlassAndEurotax)?.value
        }

        if (costParameter) {
            return parseFloat(costParameter.replace(",", "."))
        }
    }
}
export const getCountryCodeFromUserContext = (userContext?: UserContext) => {
    return userContext?.modules?.find((x) => x.type == UserModuleType.Catalog)?.countryCode
}

export const getSystemTypeFromUserContext = (userContext?: UserContext) => {
    return userContext?.system.systemType
}
