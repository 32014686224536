import { FocusEvent, KeyboardEvent, MouseEvent, forwardRef, useEffect, useState } from "react"
import { ButtonProps, Popover, styled, Box } from "@mui/material"
import { ButtonKeyDefinition, fixPrecisionSymbol } from "@tm/utils"
import { Button } from "../button"
import { Icon } from "../Icons"
import { NumberSelectList } from "./NumberSelectList"

const PopoverContent = styled(`div`)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: "5px",
}))

const DropDownButton = styled(Button)(() => ({
    minWidth: "80px",
}))

type Props = ButtonProps & {
    maxAmount?: number
    minAmount?: number
    division?: number
    steps?: number
    onValueChange?: (value: number) => void
    value?: number
    maxItemsToShow?: number
    roundedType?: RoundedType
    classNameWrapper?: string
}

type RoundedType = "ceil" | "floor"

function getRoundedValue(value: number, roundedType?: RoundedType, division?: number) {
    let roundedValue = value
    if (roundedType === "ceil" && division) {
        roundedValue = Math.ceil(value / division) * division
    } else if (roundedType === "floor" && division) {
        roundedValue = Math.floor(value / division) * division
    }
    // Limit decimal to 2 decimal places (0.95 / 0.01 * 0.01 = 0.950...01)
    return Math.round((roundedValue + Number.EPSILON) * 100) / 100
}

export const NumberSelect = forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
    const { onValueChange, value, roundedType, steps, maxAmount, minAmount, maxItemsToShow, disabled, classNameWrapper } = props
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [selectedValue, setSelectedValue] = useState<number>(value || 0)
    const [displayValue, setDisplayValue] = useState(fixPrecisionSymbol((value || 0).toString()))
    const [alreadyFocused, setAlreadyFocused] = useState(false)
    const [recentlyFocused, setRecentlyFocused] = useState(false)

    const division = props.division || props.steps || 1

    useEffect(() => {
        if (value) {
            const roundedValue = getRoundedValue(value, roundedType, division)
            if (onValueChange && roundedValue !== value) {
                onValueChange(roundedValue)
            }

            if (roundedValue !== selectedValue) {
                setSelectedValue(roundedValue)
                setDisplayValue(fixPrecisionSymbol(roundedValue.toString()))
            }
        }
    }, [value, roundedType, steps, division])

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    const handleFocus = (event: FocusEvent<HTMLButtonElement>) => {
        if (!alreadyFocused) {
            if (!recentlyFocused) {
                setAnchorEl(event.currentTarget)
                setAlreadyFocused(true)
                setRecentlyFocused(true)
            } else {
                event.target.blur()
                setRecentlyFocused(false)
            }
        }
    }

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (newValue: number) => {
        const roundedValue = getRoundedValue(newValue, roundedType, division)

        setSelectedValue(roundedValue)
        setDisplayValue(fixPrecisionSymbol(roundedValue.toString()))
        if (onValueChange) {
            onValueChange(roundedValue)
        }
        setAnchorEl(null)
        setAlreadyFocused(false)
    }

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        switch (event.key) {
            case ButtonKeyDefinition.Tab:
            case ButtonKeyDefinition.Enter: {
                handleClose(selectedValue)
                break
            }
            default:
        }
    }

    return (
        <div ref={ref} className={classNameWrapper} onKeyDown={handleKeyDown}>
            <DropDownButton
                endIcon={open ? <Icon name="up" /> : <Icon name="down" />}
                onClick={handleClick}
                disableFocusRipple
                disabled={disabled}
                onFocus={handleFocus}
            >
                <Box sx={{ minWidth: "40px" }}>{fixPrecisionSymbol(displayValue.toString())}</Box>
            </DropDownButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => handleClose(selectedValue)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <PopoverContent>
                    <NumberSelectList
                        selectedValue={selectedValue}
                        setSelectedValue={setSelectedValue}
                        handleClose={handleClose}
                        maxAmount={maxAmount}
                        minAmount={minAmount}
                        steps={steps}
                        maxItemsToShow={maxItemsToShow}
                    />
                </PopoverContent>
            </Popover>
        </div>
    )
})
