/* eslint-disable prefer-destructuring */
import { FC } from "react"
import { TooltipProps as MuiTooltipProps, styled, tooltipClasses } from "@mui/material"
import { Omit } from "@tm/utils"
import { maxWidth } from "@mui/system"
import { TooltipComponent } from "./component"

export interface TooltipProps extends Omit<MuiTooltipProps, "title"> {
    title?: React.ReactNode
    variant?: "dark" | "light" | "highlight"
    color?: "primary" | "highlight" | "warning"
    width?: number | string
    onClickAway?: (event: MouseEvent | TouchEvent | WheelEvent) => void
    noDelay?: boolean
    preventCloseOnScroll?: boolean
    withoutBordercolor?: boolean
    showOnlyOnOverflow?: boolean
    noWidthLimit?: boolean
    contentPadding?: string
}

export const Tooltip: FC<TooltipProps> = styled(TooltipComponent, {
    shouldForwardProp: (prop: string) => !["variant", "color", "width", "withoutBordercolor", "noMaxWidth", "contentPadding"].includes(prop),
})<TooltipProps>(({ theme, variant = "dark", color, width, withoutBordercolor, noWidthLimit, contentPadding }) => {
    const colorDark = theme.palette.grey[800]
    let backgroundColor = colorDark
    let borderColor = colorDark
    switch (variant) {
        case "light":
            backgroundColor = theme.palette.grey[50]
            break
        case "highlight":
            backgroundColor = theme.palette.highlight.main
            break
        default:
    }
    switch (color) {
        case "primary":
        case "highlight":
        case "warning": {
            borderColor = theme.palette[color].main
            break
        }
        default:
    }
    return {
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: width,
            backgroundColor,
            color: theme.palette.getContrastText(backgroundColor),
            padding: contentPadding ?? theme.spacing(),
            fontFamily: theme.font?.fontFamily?.secondary,
            fontSize: theme.font?.textSize?.s || "14px",
            ...(!withoutBordercolor && {
                border: `1px solid ${borderColor}`,
            }),
            ...(noWidthLimit && {
                maxWidth: "none",
            }),
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: withoutBordercolor ? backgroundColor : borderColor,
            ...(!withoutBordercolor && {
                "&::before": {
                    border: borderColor ? `1px solid ${borderColor}` : undefined,
                    backgroundColor,
                },
            }),
        },
        [`.${tooltipClasses.popper}[data-popper-placement*="bottom"] &`]: {
            marginTop: 8,
        },
    }
})
