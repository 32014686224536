import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"

export function sendTmaEvent(id: string): void {
    switch (id) {
        case "erpAlternatives":
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAlternativeArticle)
            break
        case "details":
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaDetailsButton)
            break
        case "accessories":
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAccessoryList)
            break
        /// more will be added later
        default:
            break
    }
}
