import { memo, useCallback, useMemo } from "react"

import { ArticleImage } from "@tm/models"

import { Image } from "../../generics/image"

type Props = {
    id: number
    image: ArticleImage
    isActive: boolean
    onClick(id: number): void
}

export const ImageViewerThumbnail = memo<Props>(({ id, image, isActive, onClick }) => {
    const classNames = useMemo((): string => {
        return `image-viewer__thumbnail ${isActive ? "image-viewer__thumbnail--selected" : ""}`
    }, [isActive])

    const handleClick = useCallback(() => {
        onClick(id)
    }, [onClick, id])

    return (
        <div onClick={handleClick}>
            <Image src={image.thumbnailUrl} title={image.description} className={classNames} />
        </div>
    )
})
