export enum DiscountType {
    Total = 0,
    SpareParts = 1,
    LabourCosts = 2,
    PaintWork = 3,
    PaintMaterial = 4
}

export enum eOEArtType
{
  MaterialPosition = 0,
  AdditionalCostsPosition = 1,
  SmallSparePart = 2,
  MaterialFlatAmountLacquerWorks = 3,
  MaterialsForLacquerWorks = 4,
  ConstantCostsForLacquerWorks = 5,
  DiscountForLacquerWorks = 6,
  SmallPartsAndConsumables = 7,
  PartsSurcharge = 8
}

export enum EDatRepairworkType {
    PaintWork = 0,
    LabourWork = 1
}