import { styled } from "@mui/material"
import { forwardRef, ReactNode, useImperativeHandle, useRef } from "react"

export type ImperativeFunctions = {
    scrollDown(smooth?: boolean): void
}

type Props = {
    children: ReactNode | ReactNode[]
}

const MessageStack = styled("div")({
    display: "flex",
    flexDirection: "column",
    width: "auto",
    height: "inherit",
    maxHeight: "inherit",
    overflowY: "scroll",
    overflowX: "hidden",

    "&::-webkit-scrollbar": {
        backgroundColor: "inherit",
        width: "10px",
        borderBottomRightRadius: "5px",
        marginBottom: "10px",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "inherit",
        borderBottomRightRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        borderRadius: "16px",
        border: "2px solid #fff",
        height: "30px",
    },
})

export const MessageContainer = forwardRef<ImperativeFunctions, Props>(({ children }, ref) => {
    const divRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = (smooth?: boolean) => {
        divRef.current?.scrollIntoView({
            behavior: smooth ? "smooth" : "auto",
        })
    }

    useImperativeHandle(
        ref,
        () => ({
            scrollDown: (smooth?: boolean) => {
                scrollToBottom(smooth)
            },
        }),
        []
    )

    return (
        <MessageStack>
            {children}
            <div ref={divRef} />
        </MessageStack>
    )
})
