import { Article } from "@tm/models"
import { TmaEventTracking } from "../.."

export const ArticleDetailsPDFEventHelper = {
    Parts: {
        PDFClick: {
            Click: (article?: Article, vehicleId?: string) => {
                TmaEventTracking.dispatchTrack({
                    tmaEvent: "ARTICLE_PDFCALL",
                    action: "article-PDF-click",
                    bundle: "parts",
                    component: "repository",
                    payload: { article, vehicleId },
                    source: "product-group-search",
                    type: "click",
                })
            },
        },
    },
}
