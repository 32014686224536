import { Base64 } from "js-base64"

function encodeBase64(text: string) {
    return Base64.encode(text)
}

function decodeBase64(base64: string) {
    return Base64.decode(base64)
}

function objectToBase64(object: Object) {
    return Base64.encode(JSON.stringify(object))
}

export function encodeToBase64(value: any) {
    if (typeof value === "object") {
        return objectToBase64(value)
    } else if (typeof value === "string") {
        return encodeBase64(value)
    } else {
        return encodeBase64(value.toString())
    }
}

export function base64ToObject(base64: string) {
    return JSON.parse(decodeBase64(base64))
}
