import { TmaSender } from "../../tma-sender"
import { RepairTimesModuleCalledRequest } from "../models"
import { TmaEvent, BaseTrackingModel } from "../../../models/models"

export class RepairTimesAutoDataModuleCalled extends TmaSender<RepairTimesModuleCalledRequest> implements TmaEvent {
    key = "repair-times/autodata"

    constructor() {
        // when extending tma sender we need to set the endpoint
        super("/autodata/repairTimesModuleCalled")
        this.requestBody = {}
    }

    handle = (trackingData: BaseTrackingModel<RepairTimesModuleCalledRequest>): Promise<void> | void => {
        if (trackingData.payload) {
            this.requestBody.productGroupIds = trackingData.payload.productGroupIds
            this.requestBody.vehicleId = trackingData.payload.vehicleId
        }

        return this.sendEvent() // if it's called from the module itself cdm send the event
    }
}
