import * as React from "react"

import { LocalizationProps } from "@tm/localization"
import { Text } from "../"
import { bindMethodsToContext } from "../../helper";
import SelectionListGroup from "./components/selection-list-group"
import { getUIA } from "@tm/utils";

export type ListItemGroup = {
    name?: string
    priority?: "high" | "low"
    sortNumber?: number
    items: Array<ListItem>
}

export type ListItem = {
    name: string
    query: string
    priority?: "high" | "high-normal" | "normal" | "normal-high" | "low"
    sortNumber?: number
    groupName?: string
    description?: string
    resultCount?: number
}

export type Props = LocalizationProps & {
    groups: Array<ListItemGroup>
    selected: Array<ListItem>
    onToggleCollapseGroup?(): void
    onToggleOthers?(): void
    onToggleShowOthers?(): void
    onChange(items: Array<ListItem>): void
    className?: string
    canCollapseGroups?: boolean
    size?: "s" | "m"
    noExclusiveToggle?: boolean
    initiallyShowOthers?: boolean
    initiallyOpened?: boolean

    showOthersText?: string
    hideOthersText?: string
}

export type State = {
    showOthers: boolean
}

export default class SelectionList extends React.Component<Props, State> {
    private groupRefs: Array<React.RefObject<SelectionListGroup>> = []

    static defaultProps: Partial<Props> = {
        size: "s",
    }

    constructor(props: Props) {
        super(props)
        bindMethodsToContext(this)
        this.isSelected = this.isSelected.bind(this)

        this.state = {
            showOthers: props.initiallyShowOthers ?? false,
        }
    }

    public toggleOthers(groupsToo?: boolean) {
        this.handleToggleShowOthers()
        groupsToo && this.groupRefs.forEach(ref => ref.current && ref.current.toggleOthers())
    }

    public showOthers(groupsToo?: boolean) {
        this.setState({ showOthers: true })
        groupsToo && this.groupRefs.forEach(ref => ref.current && ref.current.showOthers())
        this.props.onToggleOthers?.()
    }

    public hideOthers(groupsToo?: boolean) {
        this.setState({ showOthers: false })
        groupsToo && this.groupRefs.forEach(ref => ref.current && ref.current.hideOthers())
        this.props.onToggleOthers?.()
    }

    private isSelected(item: ListItem): boolean {
        return this.props.selected.some(y => y.query == item.query)
    }

    private handleToggleShowOthers() {
        this.setState(prevState => {
            return {
                showOthers: !prevState.showOthers
            }
        })
        this.props.onToggleOthers?.()
    }

    private handleItemToggle(item: ListItem, exclusive: boolean) {
        const { selected, onChange, noExclusiveToggle } = this.props

        if (!noExclusiveToggle && exclusive) {
            if (this.isSelected(item)) {
                onChange(selected.filter(x => x.query != item.query))
            } else {
                onChange(selected.filter(x => x.groupName != item.groupName).concat([item]))
            }
        }
        else if (this.isSelected(item)) {
            onChange(selected.filter(x => x.query != item.query))
        }
        else {
            onChange([...selected, item])
        }
    }

    private renderGroup(group: ListItemGroup, idx: number) {
        const { size, canCollapseGroups, localization, initiallyOpened, initiallyShowOthers } = this.props

        if (this.groupRefs.length <= idx) {
            this.groupRefs.push(React.createRef())
        }

        const containsSelectedItems = group.items.some(this.isSelected)

        return (
            <SelectionListGroup
                ref={this.groupRefs[idx]}
                key={idx}
                group={group}
                containsSelectedItems={containsSelectedItems}
                onItemToggle={this.handleItemToggle}
                isItemSelected={this.isSelected}
                itemSize={size}
                canCollapse={canCollapseGroups}
                initiallyOpened={initiallyOpened}
                initiallyShowOthers={initiallyShowOthers}
                localization={localization}
                onToggleCollapseGroup={this.props.onToggleCollapseGroup}
                onToggleShowOthers={this.props.onToggleShowOthers}
            />
        )
    }

    private renderShowOthersButton() {
        const { translateText } = this.props.localization

        let text = ""

        if (this.state.showOthers) {
            text = this.props.hideOthersText || translateText(169)
        }
        else {
            text = this.props.showOthersText || translateText(168)
        }

        return (
            <div
                className="selection-list__show-others is-clickable"
                onClick={this.handleToggleShowOthers}
            >
                <Text size="xs">{text}</Text>
            </div>
        )
    }

    render() {
        const { groups, size } = this.props

        let className = "selection-list "
        className += " selection-list--" + size + " "
        if (this.props.className) className += this.props.className

        const priorityGroups = groups.filter(x => x.priority == "high")
        const otherGroups = groups.filter(x => x.priority == "low" || !x.priority)

        const hasPriorityGroups = !!priorityGroups.length
        const hasOtherGroups = !!otherGroups.length

        return (
            <div className={className} {...getUIA("SectionListContainer")}>
                {priorityGroups.map(this.renderGroup)}
                {
                    hasPriorityGroups && hasOtherGroups &&
                    this.renderShowOthersButton()
                }
                <div className={hasPriorityGroups ? "selection-list__indent" : ""}>
                    {
                        (this.state.showOthers || !hasPriorityGroups) &&
                        otherGroups.map(this.renderGroup)
                    }
                </div>
            </div>
        )
    }
}
