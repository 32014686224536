import { UIAHtmlAttribut, UITypes } from "../types"

const SettingsTabNavigation: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "settingsTabNavigation",
}

const SettingsTab: UIAHtmlAttribut = {
    "data-type": UITypes.Button,
    "data-id": "settingsTab",
}

const SettingsContainer: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "settings.container",
}

const SettingsPricesPurchasePrice: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "settings.price.purchaseprice",
}

const SettingsBasketArticleSorting: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "settings.basket.articleSorting",
}

const SettingsBasketPurchasePriceSummary: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "settings.basket.priceSorting",
}

const SettingsBasketAutomaticPlateNumber: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "settings.basket.Platenumber",
}

const SettingsBasketAutomaticPlateNumberOnlyForRelated: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "settings.basket.articleSorting",
}

const SettingsBasketOrderConfirmation: UIAHtmlAttribut = {
    "data-type": UITypes.switch,
    "data-id": "settings.basket.orderConfirmation",
}

const SettingsBasketOrderShipmentmode: UIAHtmlAttribut = {
    "data-type": UITypes.Select,
    "data-id": "settings.basket.shipmentmode",
}

const SettingsBasketOrderPaymentmode: UIAHtmlAttribut = {
    "data-type": UITypes.Radio,
    "data-id": "settings.basket.paymentmode",
}

export const Settings = {
    SettingsTabNavigation,
    SettingsTab,
    SettingsContainer,
    SettingsPricesPurchasePrice,
    SettingsBasketArticleSorting,
    SettingsBasketPurchasePriceSummary,
    SettingsBasketAutomaticPlateNumber,
    SettingsBasketAutomaticPlateNumberOnlyForRelated,
    SettingsBasketOrderConfirmation,
    SettingsBasketOrderShipmentmode,
    SettingsBasketOrderPaymentmode,
}
