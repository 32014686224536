import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useRef, useState } from "react"
import { Box } from "@mui/material"
import { useDebounceFormSubmit } from "../hooks/useDebounceFormSubmit"
import { TextField } from "../../../generics/textfield"
import { isValidEmail } from "../helpers"

type AddEmailEntryProps = {
    id: string
    onEmailModified: (id: string, email: string) => void
    startingEmail?: string
    disabled?: boolean
}

export default function EmailEntry({ id, onEmailModified, startingEmail, disabled }: AddEmailEntryProps) {
    const { translateText } = useLocalization()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const [email, setEmail] = useState(startingEmail ?? "")

    const validEmail = useMemo(() => isValidEmail(email), [email])

    useEffect(() => {
        if (!disabled && !startingEmail && inputRef.current) {
            inputRef.current.focus()
        }
    }, [disabled, startingEmail, inputRef])

    useDebounceFormSubmit(email, true, false, () => {
        onEmailModified(id, email)
    })

    const handleSubmit = () => {
        onEmailModified(id, email)
    }

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex" }}>
                <TextField
                    type="email"
                    value={email}
                    placeholder={translateText(109)}
                    inputRef={inputRef}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!validEmail}
                    sx={{ width: "100%", marginTop: "0px" }}
                />
            </Box>
        </form>
    )
}
