import { useCallback, useEffect, useMemo, useState } from "react"
import { uniqueId } from "@tm/utils"
import { EmailShareOption } from "../models"
import { isValidEmail } from "../helpers"

export type EmailState = {
    emails: SingleEmail[]
    showBCCCheckbox: boolean
    bccEmails: string[] | undefined
    hasError: boolean
}

export type SingleEmail = {
    id: string
    email: string
}

export type EmailActions = {
    handleAddEmail: () => void
    emailChanged: (id: string, email: string) => void
    toggleBCCSend: (send: boolean) => void
}

export function useEmailState(emailShareOption?: EmailShareOption): { emailState: EmailState; emailActions: EmailActions } {
    const hasBccEmails = useMemo(() => !!emailShareOption?.bccEmails?.filter((email) => email.trim() !== "").length, [emailShareOption])

    const [sendBCCCopy, setSendBCCCopy] = useState(false)
    const [hasValidEmail, setHasValidEmail] = useState(false)
    const [emails, setEmails] = useState<SingleEmail[]>(
        emailShareOption?.initialEmails?.map((email) => {
            return { id: uniqueId(), email }
        }) ?? [{ id: "0", email: "" }]
    )

    useEffect(() => {
        setHasValidEmail?.(emails.filter((email) => isValidEmail(email.email))?.length !== 0)
    }, [emails])

    const handleAddEmail = useCallback(() => {
        setEmails([...emails, { id: uniqueId(), email: "" }])
    }, [emails])

    const emailChanged = useCallback(
        (id: string, email: string) => {
            const editedEmails = emails.map((e) => {
                if (e.id === id) {
                    e.email = email
                }

                return e
            })

            setEmails(editedEmails)
        },
        [emails]
    )

    return {
        emailState: {
            emails,
            showBCCCheckbox: hasBccEmails,
            bccEmails: hasBccEmails && sendBCCCopy ? emailShareOption?.bccEmails : undefined,
            hasError: !hasValidEmail,
        },
        emailActions: {
            handleAddEmail,
            toggleBCCSend: setSendBCCCopy,
            emailChanged,
        },
    }
}
