import { DispatchType } from "@tm/models"
import { mapTour } from ".."

export function mapDispatchType(data: any): DispatchType | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        tours: data.tours && data.tours.map(mapTour),
    }
}
