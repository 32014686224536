import { Fragment } from 'react';
import { mapAvailabilityStatusToIconName } from '@tm/utils'
import { AvailabilityStatus } from '@tm/models';
import { Icon, Text } from '../..';

export type AvailabilityQuantityProps = {
    quantities: Array<{ status: AvailabilityStatus, quantity: number }>
    tourDate?: string
    tourName?: string
    onClick?(): void
}

export default function AvailabilityQuantity({ quantities, tourDate, tourName, onClick }: AvailabilityQuantityProps) {
    const baseName = "availability-quantity"
    let className = baseName

    if (onClick)
        className = `${className} ${baseName}--clickable`

    return (
        <div className={className} onClick={onClick}>
            <div className={baseName + "__wrapper"}>
                <div className={baseName + "__row1"}>
                    {quantities.map((q, i) =>
                        <Fragment key={`availability-quantity__status_${q.status}`}>
                            <Icon name={mapAvailabilityStatusToIconName(q.status)} className={`${baseName}__icon--status-${q.status}`} />
                            {i < quantities.length - 1 && <div className="availability-quantity__row1__separator" />}
                        </Fragment>
                    )}
                </div>
                <div className={baseName + "__row2"}>
                    {quantities.map((q, i) =>
                        <Fragment key={`availability-quantity__quantity_${q.status}`}>
                            <Text>{q.quantity}</Text>
                            {i < quantities.length - 1 && <div className="availability-quantity__row2__separator" />}
                        </Fragment>
                    )}
                </div>
            </div>
            {
                (tourDate || tourName) &&
                <div className={baseName + "__tour"}>
                    <div className={baseName + "__text"}>
                        {tourDate}
                        {tourDate && tourName && <br />}
                        {tourName}
                    </div>
                </div>
            }
        </div>
    );
}