import * as React from "react"
import { ThemeProviderContext } from "./ThemeProviderContext"
import { ThemeProvider as MuiThemeProvider, createTheme, createNextMuiTheme } from "@tm/components"
import { getStyleTheme } from "."

type ThemeProviderProps = React.PropsWithChildren<{}>

export function ThemeProvider(props: ThemeProviderProps) {
    const nextTheme = getStyleTheme() // theme was already loaded by the portal, before the Providers are rendered
    const muiTheme = React.useMemo(() => {
        try {
            return createNextMuiTheme(nextTheme)
        }
        catch (err: any) {
            return createTheme()
        }
    }, [nextTheme])

    return (
        <ThemeProviderContext.Provider value={nextTheme}>
            <MuiThemeProvider theme={muiTheme}>
                {props.children ? React.Children.only(props.children) : null}
            </MuiThemeProvider>
        </ThemeProviderContext.Provider>
    )
}
