import { UserContext, UserModuleType } from "@tm/models"

/**
 * Determines the country code by looking into the user context.
 * If a module with type "catalog" is found, the country code from that module is returned.
 * The fallback is always an empty string, to avoid displaying a wrong country code.
 * @param userContext The current user context.
 * @returns The country code from the catalog module, or an empty string as fallback.
 */
export function getShortCountryCodeByUserContext(userContext?: UserContext): string {
    return userContext?.modules?.find((x) => x.type === UserModuleType.Catalog)?.countryCode || ""
}

export function getCountryDescriptionByCountryCode(userContext: UserContext, translateText: (key: string | number) => string): string {
    const countryCode = userContext?.modules?.find((x) => x.type === UserModuleType.Catalog)?.countryCode

    switch (countryCode) {
        case "D":
        default:
            return translateText(3147)
        case "E":
            return translateText(12710)
        case "CZ":
            return translateText(12704)
        case "A":
            return translateText(12705)
        case "CH":
            return translateText(12706)
        case "GR":
            return translateText(12707)
        case "GB":
            return translateText(12708)
        case "USA":
            return translateText(12717)
        case "F":
            return translateText(3115)
        case "H":
            return translateText(12711)
        case "I":
            return translateText(3114)
        case "PL":
            return translateText(12712)
        case "RO":
            return translateText(12713)
        case "RUS":
            return translateText(12714)
        case "SK":
            return translateText(12715)
        case "TR":
            return translateText(12716)
    }
}
