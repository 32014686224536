import * as React from "react"
import { FormElementProps } from "../../models"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { DateFieldAny } from "./index.any"
// import { DateFieldDe } from "./index.de"

export type DateFieldProps = LocalizationProps & FormElementProps & {
    maxDate?: Date
    minDate?: Date
    openToDate?: Date
    /**
     * @description make sure your placeholder fit's to the format and the pattern
     */
    placeholder?: string
    /**
     * @description if a datefield is required the last valid date will be set if user clicks the clear button
     */
    required?: boolean
    showClear?: boolean
    useUtc?: boolean
    value?: Date

    showTimeSelect?: boolean
    timeIntervals?: number

    ignoreOnSelect?: boolean  //Sometimes is not desired to have the onchange event triggered twice. OnSelect and OnChange trigger the same event, which cuases double persistence requests
    onChange?: (value: Date) => void
    onKeyPress?: (e: React.KeyboardEvent) => void
}

function DateField(props: DateFieldProps) {
    return <DateFieldAny {...props} />
}


const Component: React.ComponentClass<Omit<DateFieldProps, keyof LocalizationProps>> = withLocalization(DateField)
export default Component

export { getFormattedLocalDate } from "./helper"
