/** The Type of the general counter. */
export enum ECounterType {
    Undefined = 0,
    MiniFastClickHome = 1,
    ArticleListShowCompact = 2,
    ArticleListShowDetailed = 3,
    ArticleListHideImages = 4,
    ArticleListShowImages = 5,
    ArticleListShowMoreSuppliers = 6,
    ArticleListShowLessSuppliers = 7,
    SidePaneCollapse = 8,
    SidePaneExpand = 9,
    ShoppingCartShowCompact = 10,
    ArticleListShowOnStockOnlyItems = 11,
    ArticleListShowAllItems = 12,
    OoTransBasketDownload = 13,
    ArticleListTop10 = 14,
    ArticleListAddToBasket = 0xF,
    ArticleListCompareArticles = 0x10,
    ArticleListArticleNotes = 17,
    ArticleListCriteriaSearch = 18,
    ArticleListFixMenu = 19,
    ArticleListReleaseMenu = 20,
    ArticleListFastTd = 21,
    ArticleListCollapseGenArt = 22,
    ArticleListExpandGenArt = 23,
    ArticleListShowMoreGenArt = 24,
    ArticleListShowLessGenArt = 25,
    ArticleListLocationFilter = 26,
    ArticleListStatusBarFilter = 27,
    ArticleListMiniFastClick = 28,
    ArticleListInlineFilter = 29,
    ArticleListFilterSelectCriteria = 30,
    ArticleListArticleDocuments = 31,
    ArticleListArticleLabourtimes = 32,
    ArticleListArticlePrint = 33,
    ArticleListFastCalc = 34,
    ArticleListCriteriaFilter = 35,
    ArticleListDeleteCriteriaFilter = 36,
    ArticleListFilterSelectProductGroup = 39,
    ArticleListFilterSelectSupplier = 40,
    ArticleListFilterSelectOENumber = 41,
    ArticleListExpandArticle = 42,
    ArticleListArticleDetailsViaTraderArticleNumber = 43,
    ArticleListArticleDetailsViaAccessoryList = 44,
    ArticleListArticleDetailsViaAlternativeArticle = 45,
    ArticleListArticleDetailsViaAvailability = 46,
    ArticleListArticleDetailsViaDetailsButton = 47,
    ArticleListArticleFurtherOptions = 48,
    ArticleListArticleCheckbox = 49,
    VehicleSelectionSearchSymbol = 50,
    VehicleSelectionReset = 51,
    VehicleSelectionThumbsUp = 52,
    VehicleSelectionThumbsDown = 53,
    VehicleSelectionSort = 54,
    VehicleSelectionDataGridView = 55,
    VehicleSelectionShowAllManufacturers = 56,
    VehicleSelectionHome = 57,
    VehicleSelectionBodyType = 58,
    VehicleSelectionConstructionYear = 59,
    VehicleSelectionFilterFuelType = 60,
    VehicleSelectionFilterEngineCode = 61,
    VehicleSelectionFilterccm = 62,
    VehicleSelectionSelectVehicle = 0x3F,
    SidePanelSearch = 70,
    SidePanelArticleHistory = 71,
    SidePanelVehicleHistory = 72,
    SidePanelBasket = 74,
    TopBarBasket = 75,
    EditFastClick = 76,
    FastClickMinimize = 77,
    FastClickMaximize = 79,
    Top10 = 80,
    SearchTreeMinimize = 81,
    SearchTreeMaximize = 83,
    FastClick = 84,
    SettingsOpen = 85,
    SettingsClose = 86,
    ArticleTree = 87,
    CostEstimate = 88,
    DeleteBasket = 89,
    PrintBasket = 90,
    ImportToBasket = 91,
    BasketShowInvoicePrice = 92,
    BasketOrder = 93,
    BasketDirectBuy = 94,
    BasketHistory = 95,
    StartPage = 100,
    NewWorktask = 101,
    LastActivities = 102,
    CloseTask = 103,
    Chat = 104,
    Feedback = 105,
    WorkshopProfiler = 106,
    MoreWorktasks = 107,
    MyAccount = 108,
    MyAssortment = 109,
    StartPageWorktask = 110,
    ArticleListViewOptions = 130,
    ArticleListNavigation = 131,
    ArticleListReleaseAllFilters = 132,
    ArticleListShowInvoicePrice = 133,
    ArticleListAreaSwitch = 134,
    CustomerSearch = 150,
    CustomerList = 151,
    AddCustomer = 152,
    DeleteCustomer = 153,
    CustomerDetails = 154,
    CustomerSave = 155,
    CustomerDetailsexpandcollapse = 156,
    CustomerAddVehicle = 157,
    CostEstimatePrint = 160,
    CostEstimateSend = 161,
    CostEstimateViewOptions = 162,
    CostEstimateReset = 163,
    CostEstimateSave = 164,
    CostEstimateBackToBasket = 165,
    CostEstimateAddArticle = 166,
    CostEstimateAddLabourTime = 167,
    PassengerCar = 200,
    PassengerCarClassicSearch = 201,
    PassengerCarGraphicSearch = 202,
    PassengerCarTextSearch = 203,
    PassengerCarDetails = 204,
    PassengerCarChange = 205,
    PassengerCarEdit = 206,
    PassengerCarPartsCatalog = 210,
    PassengerCarGpi = 220,
    PassengerCarGpiDat = 225,
    PassengerCarGpiEurotax = 226,
    PassengerCarGpiTecRmi = 223,
    PassengerCarGpiTemot = 227,
    PassengerCarRepairTimes = 230,
    PassengerCarRepairTimesAwDoc = 234,
    PassengerCarRepairTimesAutodata = 232,
    PassengerCarRepairTimesHaynesPro = 231,
    PassengerCarRepairTimesTecRmi = 233,
    PassengerCarServiceData = 240,
    PassengerCarServiceDataHaynesPro = 241,
    PassengerCarServiceDataAutoData = 242,
    PassengerCarServiceDataTecRmi = 243,
    PassengerCarTechnicalData = 250,
    PassengerCarTechnicalDataHaynesPro = 251,
    PassengerCarTechnicalDataAutodata = 252,
    PassengerCarTechnicalDataTecRMI = 253,
    PassengerCarTyres = 260,
    PassengerCarTyresDriveRight = 268,
    PassengerCarTyresTecRmi = 263,
    PassengerCarFastCalculator = 270,
    PassengerCarServiceCalc = 271,
    PassengerCarAllData = 280,
    PassengerCarRepairCalculation = 290,
    TMWheelsAndTyres = 291,
    PassengerCarFastCheck = 292,
    PartsIndicator = 293,
    PartsIndicatorApply = 294,
    PartsIndicatorCalculate = 295,
    FastDTC = 296,
    FastService = 298,
    EDS = 299,
    Vans = 300,
    VansPartsCatalog = 310,
    VansRepairTimes = 330,
    VansRepairTimesAwDoc = 334,
    VansRepairTimesAutodata = 332,
    VansRepairTimesHaynesPro = 331,
    VansRepairTimesTecRmi = 333,
    VansServiceData = 340,
    VansTechnicalData = 350,
    TecDocTruck = 400,
    TecDocTruckTextSearch = 401,
    TecDocTruckGraphicSearch = 402,
    TecDocTruckPartsCatalog = 410,
    TecDocTruckRepairTimes = 430,
    TecDocTruckRepairTimesAwDoc = 434,
    TecDocTruckRepairTimesAutoData = 432,
    TecDocTruckRepairTimesHaynesPro = 431,
    TecDocTruckRepairTimesTecRmi = 433,
    TecDocTruckServiceData = 440,
    TecDocTruckServiceDataHaynesPro = 441,
    TecDocTruckServiceDataAutoData = 442,
    TecDocTruckServiceDataTecRmi = 443,
    TecDocTruckTechnicalData = 450,
    TecDocTruckTechnicalDataHaynesPro = 451,
    TecDocTruckTechnicalDataAutoData = 452,
    TecDocTruckTechnicalDataTecRmi = 453,
    TruckVIN = 500,
    MyTruck = 600,
    MyTruckPlus = 601,
    TruckGarage = 700,
    Axle = 800,
    Bike = 900,
    BikeTextSearch = 901,
    BikeGraphicSearch = 902,
    UniParts = 1000,
    UniPartsPremium = 1010,
    UniPartsTecDoc = 1020,
    ArticleDirectSearch = 1100,
    ArticleDirectSearchHistory = 1101,
    Ets = 1200,
    Gva = 1300,
    Offers = 1400,
    VoucherList = 1500,
    VoucherCostEstimate = 1501,
    VoucherOrders = 1502,
    TopVehicleInfo = 2000,
    SidePanelVehicleInfo = 2001,
    VehicleInfoPrint = 2002,
    VehicleInfoTop10 = 2003,
    VehicleInfoFastClick = 2004,
    VehicleInfoClose = 2005,
    VehicleInfoGpiDat = 2021,
    VehicleInfoGpiEurotax = 2022,
    VehicleInfoGpiTecRmi = 2023,
    VehicleInfoGpiTemot = 2024,
    VehicleInfoRepairTimesAwDoc = 2031,
    VehicleInfoRepairTimesAutodata = 2032,
    VehicleInfoRepairTimesHaynesPro = 2033,
    VehicleInfoRepairTimesTecRmi = 2034,
    VehicleInfoInspectionDataHaynesPro = 2041,
    VehicleInfoInspectionDataAutoData = 2042,
    VehicleInfoInspectionDataTecRmi = 2043,
    VehicleInfoTechnicalDataHaynesPro = 2051,
    VehicleInfoTechnicalDataAutodata = 2052,
    VehicleInfoTyresDriveRight = 2061,
    VehicleInfoTyresTecRmi = 2062
}
