import { Box, BoxProps, styled } from "@mui/material"
import { Loader } from "../loader"

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isLoading",
})<{ isLoading?: boolean }>(({ theme, isLoading }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    ...(isLoading && {
        pointerEvents: "none",
        opacity: theme.opacity?.disabled ?? "0.38",
    }),
}))

type Props = BoxProps & {
    isLoading?: boolean
}

export function LoadingContainer({ isLoading, children, ...rest }: Props) {
    return (
        <Box position="relative" width="100%" height="100%" {...rest}>
            {isLoading && (
                <Box width="100%" height="100%" zIndex="1" display="flex" alignItems="center" justifyItems="center" position="absolute">
                    <Loader />
                </Box>
            )}
            <StyledBox isLoading={isLoading}>{children}</StyledBox>
        </Box>
    )
}
