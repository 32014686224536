import * as React from "react"
import { ModelState, FieldValidationState } from "@tm/utils";
import { Size } from "./models/skins";
import { TooltipPositions } from "./components/tooltip";
import ReactDOM from "react-dom"

export type FormElementLayouts = "Default" |
    "holo" |
    "description" |
    "readonly" |
    "shortfield" |
    "dropshadow"


export interface FormElementProps {
    className?: string
    value?: string | number | Date
    model?: any
    path?: Array<any>
    readonly?: boolean
    modelState?: ModelState
    label?: string | JSX.Element
    tabIndex?: number
    disabled?: boolean
    autoFocus?: boolean
    layout?: Array<FormElementLayouts>
    floatingLabel?: boolean
    name?: string
    size?: Size

    onRef?(ref: any): void
    onChange?(model: any, path?: Array<any>): void
    onFocus?(e?: React.FocusEvent): void
    onBlur?(e?: React.FocusEvent): void
}

export type TextAutoComplete = "name" | "username" | "on" | "off" | string
export type PasswordAutoComplete = "current-password" | "new-password"

export interface FormElementState {
    value?: string;
    errors?: Array<string>;
    active?: boolean;
    id?: string;
}

export type Skins = "warning" | "danger" | "success"


export function createErrorMessage(errors: Array<string>, positionToElement: HTMLElement, position: TooltipPositions) {
    const tooltipsRef: HTMLElement | null = document.getElementById("tooltips")
    if(!tooltipsRef) {
        return createErrorElements(errors)
    }

    return createPortal(tooltipsRef, positionToElement, position, errors)
}


export function createErrorElements(errors: Array<string>, styles?: React.CSSProperties): JSX.Element {
    let i = 0;
    const tokens: Array<JSX.Element> = [];

    errors.forEach((text) => {
        tokens.push(React.createElement("li", { key: i++ }, text));
    });

    return React.createElement("ul", { className: "error-message error-message--slide-down", ref: handleErrorMessageRef, style: styles }, tokens);
}

function handleErrorMessageRef(ref: HTMLElement | null) {
    if(!ref) return
    setTimeout(() => {
        ref.classList.remove("error-message--slide-down")
    }, 10)
}

function createPortal(tooltipsRef: HTMLElement, positionToElement: HTMLElement, position: TooltipPositions, errors: Array<string>) {
    // const { style, className } = this.props

    const wrapperPositioning = getStylePosition(positionToElement, position)

    if(!wrapperPositioning || !tooltipsRef) { return null }

    const portal = ReactDOM.createPortal(
        createErrorElements(errors, wrapperPositioning),
        tooltipsRef
    )

    return portal
}

function getStylePosition(child: HTMLElement, position: TooltipPositions): React.CSSProperties | null {
    let boundingRect: DOMRect | null = null

    if(child && typeof(child) !== "string" && typeof(child) !== "function" && child) {
        boundingRect = child.getBoundingClientRect() as DOMRect
    }

    if(!boundingRect) { return null }

    const { height, top, left, x:xbr, y: ybr } = boundingRect
    const y = ybr || top
    const x = xbr || left

    const positions = {
        top: {
            top: y + window.pageYOffset,
            left: x
        },
        left: {
            top: y + (height / 2) ,
            left: x
        },
        bottom: {
            top: y + height + window.pageYOffset,
            left: x
        },
        right: {
            top: y  + (height / 2) + window.pageYOffset,
            left: x
        }
    }

    const wrapperPositioning: React.CSSProperties = {
        minWidth: boundingRect.width,
        // height: 0, //boundingRect.height,
        position: "absolute",
        // visibility: "",
        ...positions[position||"top"]
    }

    return wrapperPositioning
}
