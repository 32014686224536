import * as React from "react"
import { createErrorMessage, FormElementProps } from "../../models"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { bindMethodsToContext } from "../../helper"
import { Button } from "..";

export type DateFieldWithCustomPatternProps = LocalizationProps & FormElementProps & {
    /**
     * @description make sure your placeholder fit's to the format and the pattern
     */
    placeholder: string
    showClear?: boolean
    minDate?: Date
    maxDate?: Date
    value?: Date
    /**
     * @description make sure your pattern fit's to the placeholder and the format
     */
    pattern: RegExp
    /**
     * @description if a datefield is required the last valid date will be set if user clicks the clear button
     */
    required?: boolean
    onChange?: (value: Date) => void
    onKeyPress?: (e: React.KeyboardEvent) => void
}

export type DateFieldWithCustomPatternState = {
    value: string
    id?: string
    error: string | null
    edit?: boolean
    isFocused: boolean
}

const createDate = (value: string) => {
    const parts = value.split('.')
    if (parts.length == 1 && !parts[0].trim()) {
        return
    }
    return new Date(Date.UTC(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0])))
}

const defPat = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)(:?\d){2})$/
export class DateFieldWithCustomPattern extends React.Component<DateFieldWithCustomPatternProps, DateFieldWithCustomPatternState> {
    fallbackValue?: Date
    inputRef: HTMLInputElement | null
    outerContainerRef: HTMLElement | null

    public static defaultProps = {
        format: "d",
        pattern: defPat
    }

    unregisterOutsideClick?: () => void

    constructor(props: DateFieldWithCustomPatternProps) {
        super(props)
        bindMethodsToContext(this)
        this.state = {
            value: "",
            error: null,
            isFocused: false
        }
    }

    UNSAFE_componentWillMount() {
        this.fallbackValue = this.props.value

        const { value } = this.props

        if (value) {
            this.setState({
                value: value.toLocaleDateString()
            })
        }
    }

    componentDidUpdate() {
        const { autoFocus } = this.props
        autoFocus && this.focus()
    }

    handleInputChange(e: any) {
        const { value } = e.currentTarget
        const { onChange } = this.props
        this.validateInput(value).then((error, date) => {
            this.setInputValue(value)

            if (!error) {
                onChange && onChange(date)
            }
        })
    }

    setInputValue = (value: string) => {
        this.setState({
            value
        })
    }

    validatePattern = (value: string) => {
        const { pattern } = this.props
        const dateValidator = pattern ? pattern : defPat
        return dateValidator.test(value)
    }

    validateInput = (value: string) => {
        let error: string | null = null
        const { maxDate, minDate } = this.props

        const isValidDate = this.validatePattern(value)
        let date = createDate(value)

        if ((!isValidDate && date) || date) {
            if (!isValidDate || (!isValidDate && date.toString() === "Invalid Date")) {
                error = "Bitte prüfen Sie das Datum " + (this.props.placeholder ? this.props.placeholder : "dd.mm.yyyy")
            }

            if (!error && maxDate && maxDate < date) {
                // this.props.format
                error = `Das Datum muss vor dem "${maxDate.toLocaleDateString()}" liegen`
            }

            if (!error && minDate && minDate > date) {
                error = `Das Datum darf nicht vor "${minDate.toLocaleDateString()}" liegen`
            }
        }

        if (error) {
            this.setState({ error })
        }
        else {
            this.setState({ error: null })
        }

        return {
            then: (callback: (error: string | null, date?: string) => void) => {
                callback(error, value)
            }
        }
    }

    handleKeyUp = (e: React.KeyboardEvent) => {
        const { onKeyPress } = this.props
        onKeyPress && onKeyPress(e)
    }

    handleClear(ev?: React.SyntheticEvent<HTMLButtonElement>) {
        ev && ev.preventDefault()
        this.clearDateFieldWithCustomPattern()
    }

    render() {
        const { label, className, readonly, placeholder, showClear, disabled, floatingLabel, pattern } = this.props
        const { error, isFocused, value } = this.state
        const hasErrors = !!error

        let elClassName = "input input--textfield input--datefield date-field"
        elClassName += className ? ` ${className}` : ""
        elClassName += hasErrors ? " has-error" : ""
        elClassName += readonly ? " readonly" : ""
        elClassName += isFocused ? " is-active" : ""
        elClassName += showClear && !readonly ? " clearable" : ""
        elClassName += (value && value != "") || (placeholder && placeholder) ? " has-value" : ""
        elClassName += floatingLabel ? " input--floating-label" : ""
        elClassName += this.props.size ? ` input--${this.props.size}` : ""

        const labelElement = label ? <label className="input__label" htmlFor={this.state.id}>{label}</label> : false
        const tabIndex = readonly ? 0 : this.props.tabIndex

        let inputPattern = pattern ? pattern.toString() : defPat.toString()
        const ppL = inputPattern.length
        inputPattern = inputPattern[0] === "/" && inputPattern[ppL - 1] === "/" ? inputPattern.substr(1, ppL - 2) : inputPattern
        const doFocus = this.props.autoFocus ? { autoFocus: true } : {}

        return (
            <div className={elClassName} ref={this.handleOuterRef}>
                <div className="input__inner">
                    {labelElement}
                    <input
                        {...doFocus}
                        className="input__field input__field--date"
                        type="text"
                        placeholder={placeholder}
                        value={value}
                        onChange={this.handleInputChange}
                        onKeyUp={this.handleKeyUp}
                        tabIndex={tabIndex}
                        onFocus={this.handleFocus}
                        disabled={!!disabled}
                        onBlur={this.handleFocusLost}
                        pattern={inputPattern}
                        ref={this.setRef}
                    />
                    {this.props.showClear && !this.props.readonly ? <Button layout={["ghost"]} size="xs" icon={"close"} className="input__field-close" onClick={this.handleClear} /> : null}
                    {hasErrors && this.outerContainerRef ? createErrorMessage(error ? [error] : [], this.outerContainerRef, "bottom") : null}
                </div>
            </div>
        )
    }

    handleOuterRef = (ref: HTMLElement | null) => {
        this.outerContainerRef = ref
    }

    handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        this.setState({ isFocused: true })
    }

    handleFocusLost = (e: React.FocusEvent<HTMLInputElement>) => {
        this.resetFocus()

        if (this.state.error) {
            this.resetDateFieldWithCustomPattern()
        }
    }

    resetFocus = () => {
        this.setState({ isFocused: false })
    }


    setRef = (ref: HTMLInputElement | null) => {
        this.inputRef = ref
    }

    resetDateFieldWithCustomPattern = () => {
        this.setState({
            value: this.fallbackValue ? this.fallbackValue.toLocaleDateString() : "",
            error: ""
        })
    }

    clearDateFieldWithCustomPattern = () => {
        const { required } = this.props
        this.setState({
            value: required ? (this.fallbackValue || { toLocaleDateString: () => "" }).toLocaleDateString() : "",
            error: ""
        })
    }

    public focus = () => {
        this.inputRef && this.inputRef.focus()
    }
}

const Component: React.ComponentClass<Omit<DateFieldWithCustomPatternProps, keyof LocalizationProps>> = withLocalization(DateFieldWithCustomPattern)
export default Component
