import { useMemo } from "react"
import { useUser } from "../user/consumer/useUser"
import { UserModuleType } from "@tm/models"

type DatCredentials =
    | {
          customerNumber: string | undefined
          userName: string | undefined
          isPasswordSet: boolean
          showDatSettings: boolean
      }
    | undefined


/**
 * @memberof Context-Distribution
 */
export function useDatCredentials(): DatCredentials {
    const { userContext } = useUser()
    const damageCalculationModule = userContext.modules?.find((x) => x.type === UserModuleType.TMDamageCalculationDat)

    return useMemo(() => {
        if (damageCalculationModule) {
            const customerNumber = damageCalculationModule.parameters?.find((x) => x.key === "BuyerCode")?.value
            const userName = damageCalculationModule.parameters?.find((x) => x.key === "Username")?.value
            const isPasswordSet = damageCalculationModule.parameters?.find((x) => x.key === "Password") !== undefined
            const showDatSettings = damageCalculationModule.parameters?.find((x) => x.key === "AccessDataFromCatalogue")?.value === "1"

            if(showDatSettings === false) {
                return
            }
            
            return { customerNumber, userName, isPasswordSet, showDatSettings }
        }
    },[damageCalculationModule])
}
