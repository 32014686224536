import { useUser } from "../../user/consumer/useUser"
import { getShortCountryCodeByUserContext, plateCountryCodeMapper, useAvailableVehicleSearchOptions } from "@tm/utils"
import { useMemo } from "react"
import { useSelectedVehicleLookup } from "./useSelectedVehicleLookup"

/**
 * get the default countrycode and map a vehicle country code to a license country code. if not vehicle countrycode is given, the selected vehiclelookup oder the default vehiclelookup countrycode id will be used
 * @param countryCode used for the vehicle country code to get a translated version
 * @returns plateCode is the licensePlateCountryCode and countryCode is the default country code
 */
export function useCountryCodeToLicensePlate(countryCode?: string) {
    const { selectedVehicleLookup } = useSelectedVehicleLookup()
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const { userContext } = useUser() ?? {}

    const tempCountryCode = useMemo(() => {
        return (
            countryCode ||
            selectedVehicleLookup.countryCode ||
            availableVehicleSearches.defaultVehicleLookup?.countryCode ||
            getShortCountryCodeByUserContext(userContext)
        )
    }, [countryCode, selectedVehicleLookup.countryCode, userContext, availableVehicleSearches.defaultVehicleLookup?.countryCode])

    const plateCode = useMemo(() => {
        return plateCountryCodeMapper(tempCountryCode)
    }, [tempCountryCode])

    return { plateCode, countryCode: tempCountryCode }
}
