import { Box, TypographyProps } from "@mui/material"
import { Typography } from "../../../generics/typographie"

type PriceAdditionProps = {
    content: string
    isCompact?: boolean
    mb?: TypographyProps["mb"]
}

export function PriceAddition({ content, isCompact, mb }: PriceAdditionProps) {
    if (isCompact) {
        return (
            <Typography variant="label" gridColumn="1 / span 3" lineHeight={1.2} textAlign="right" mb={mb} overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                {content}
            </Typography>
        )
    }
    return (
        <>
            <Typography variant="label" gridColumn="1 / span 2" lineHeight={1.2} textAlign="right" mb={mb}>
                {content}
            </Typography>
            <Box /> {/* The typography will span two columns, so we need to render an empty box to fill the last column */}
        </>
    )
}
