import { memo, useMemo } from "react"
import { TmaHelper } from "@tm/utils"
import { Badge, Box, Stack, styled } from "@mui/material"
import { AttributeItem } from "./AttributeItem"
import { useDefaultArticleItemState } from "../ContextProviderStates/DefaultArticleItemState"
import { AttributesBlock } from "./index"
import { Icon } from "../../../generics/Icons"
import { Typography } from "../../../generics/typographie"
import { TooltipWrapper } from "./TooltipWrapper"
import { Divider } from "../../../generics/divider"
import { CompactAttributesBlock } from "./CompactAttributesBlock"

const AttributesContainer = styled(Stack)({
    backgroundColor: "#f0f0f0",
    flexDirection: "row",
    gap: "5px",
})

const CompactAttributesContainer = styled(AttributesBlock)(({ theme }) => ({
    borderLeft: `2px solid ${theme.palette.highlight.main}`,
    justifyContent: "space-between",
    minWidth: 0,
}))

const VehicleBadge = styled(Badge)({
    ".MuiBadge-badge": {
        height: "18px",
        width: "18px",
        minWidth: "18px",
        fontSize: "12px",
        lineHeight: 1,
        padding: "2px",
        transform: "translate(8px, -40%)",
        color: "white",
        backgroundColor: "black",
    },
})

export const CompactAttributes = memo(() => {
    const attributes = useDefaultArticleItemState((x) => x.displayArticle.attributes)
    const sharedAttributes = useDefaultArticleItemState((x) => x.displayArticle.sharedAttributes)
    const handleClickAttribute = useDefaultArticleItemState((x) => x.handleClickAttribute)

    const handleClick = useMemo<typeof handleClickAttribute>(
        () =>
            handleClickAttribute
                ? (is, value) => {
                      TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
                      return handleClickAttribute(is, value)
                  }
                : undefined,
        [handleClickAttribute]
    )

    const vehicleAttributes = useMemo(() => {
        if (attributes && attributes?.length > 1) {
            return attributes?.map((val) => {
                return {
                    topArticleAttributes: val.topArticleAttributes?.filter((topAttribute) => !topAttribute.isSharedAttribute),
                    additionalAttributes: val.additionalAttributes,
                }
            })
        }
        return undefined
    }, [attributes])

    const filteredSharedAttributes = useMemo(() => {
        if (sharedAttributes?.length) {
            return sharedAttributes.filter((attribute) => !attribute.isArticleAttribute)
        }

        return undefined
    }, [sharedAttributes])

    if (!attributes?.length) {
        return <></>
    }

    const showCarAttributes = attributes.length > 1

    const visibleAttributes = showCarAttributes ? filteredSharedAttributes ?? [] : attributes[0]?.topArticleAttributes

    return (
        <CompactAttributesContainer>
            <CompactAttributesBlock visibleArticleAttributes={visibleAttributes} handleClick={handleClick} />
            {showCarAttributes && !!attributes?.length && (
                <VehicleBadge badgeContent={attributes?.length}>
                    <AttributesContainer>
                        <Divider orientation="vertical" flexItem />
                        <TooltipWrapper color="primary" icon="car">
                            <Stack my={1}>
                                {vehicleAttributes?.map(({ topArticleAttributes }, blockIndex) => (
                                    <Stack direction="row" key={`vehicleAttribute_${blockIndex}`} alignItems="start" gap="10px">
                                        {attributes?.length > 1 && (
                                            <Box alignItems="center" mt="10px" mr={1} whiteSpace="nowrap">
                                                <Typography lineHeight="20px" variant="label">
                                                    {blockIndex + 1}.
                                                </Typography>
                                                &nbsp;
                                                <Icon name="car" sx={{ verticalAlign: "top" }} />
                                            </Box>
                                        )}
                                        {!!topArticleAttributes?.length && (
                                            <AttributesBlock>
                                                {topArticleAttributes?.map((attr, index) => (
                                                    <AttributeItem
                                                        key={`${attr.id}:${attr.key}`}
                                                        attribute={attr}
                                                        onClickAttributeValue={handleClick}
                                                        hideDivider={index + 1 === topArticleAttributes?.length}
                                                    />
                                                ))}
                                            </AttributesBlock>
                                        )}
                                    </Stack>
                                ))}
                            </Stack>
                        </TooltipWrapper>
                    </AttributesContainer>
                </VehicleBadge>
            )}
        </CompactAttributesContainer>
    )
})
