import { bindMethodsToContext } from "../../helper"
import * as React from "react"
import { getFieldErrors } from "@tm/utils"
import { createErrorMessage } from "../../models"
import Button from "../button"

export type DateFieldCustomInputProps = {
    floatingLabel?: boolean
    id?: string
    label?: string | JSX.Element
    placeholder?: string
    readOnly?: boolean
    required?: boolean
    showClear: boolean
    minDate?: string
    maxDate?: string
    size?: number
    value?: string
    wrapperClassName?: string
    initiallyFocused?: boolean
    // calender?: DatePicker
    path?: string[]
    modelState?: { [key: string]: Array<string> }
    onChange?: (test: { target: { value: string } }) => void
    onBlur?: () => void
    onFocus?: () => void
}

type DateFieldCustomInputState = {
    errors?: string[]
    showErrorMessage: boolean
}
export default class DateFieldCustomInput extends React.Component<DateFieldCustomInputProps, DateFieldCustomInputState> {
    private outerContainerRef: HTMLElement | null = null
    constructor(props: DateFieldCustomInputProps) {
        super(props)
        bindMethodsToContext(this)
        this.state = {
            showErrorMessage: false
        }
    }

    UNSAFE_componentWillMount() {
        this.checkErrors(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps: DateFieldCustomInputProps) {
        this.checkErrors(nextProps)
    }


    checkErrors(props: DateFieldCustomInputProps) {
        if (props.modelState && props.path) {
            const errors = getFieldErrors(props.modelState, props.path)
            if (errors) {
                this.setState({
                    errors
                })
            }
        } else {
            this.setState({
                errors: []
            })
        }
    }

    handleClear() {
        const { onChange } = this.props

        if (onChange) {
            onChange({ target: { value: "" } })
        }
    }

    handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter" && (e.target as any).blur) {
            (e.target as any).blur()
        }
    }

    handleOuterRef = (ref: HTMLElement | null) => {
        this.outerContainerRef = ref
    }

    handleFocus(e: React.FocusEvent) {
        this.setState({
            showErrorMessage: true
        })

        this.props.onFocus && this.props.onFocus()
    }

    handleBlur(e: React.FocusEvent) {
        this.setState({
            showErrorMessage: false
        })

        this.props.onBlur && this.props.onBlur()
    }

    handleMouseEnter = () => {
        this.setState({ showErrorMessage: true })
    }

    handleMouseOut = () => {
        this.setState({ showErrorMessage: false })
    }

    render() {
        const { readOnly, id, label, initiallyFocused, wrapperClassName, showClear, placeholder, floatingLabel, size, value } = this.props
        const hasErrors = this.state.errors?.length

        let elClassName = "input input--textfield input--datefield date-field"
        elClassName += hasErrors ? " field-error" : ""
        elClassName += hasErrors && this.state.showErrorMessage ? " field-error--show-message has-error" : ""
        elClassName += wrapperClassName ? ` ${wrapperClassName}` : ""
        elClassName += readOnly ? " readonly" : ""
        elClassName += showClear && !readOnly ? " clearable" : ""
        elClassName += (value && value != "") || (placeholder && placeholder) ? " has-value" : ""
        elClassName += floatingLabel ? " input--floating-label" : ""
        elClassName += size ? ` input--${size}` : ""

        const resetProps: Partial<DateFieldCustomInputProps> = { ...this.props }
        delete resetProps.showClear
        delete resetProps.floatingLabel
        delete resetProps.wrapperClassName
        delete resetProps.initiallyFocused
        delete resetProps.modelState
        delete resetProps.path

        return (
            <div className={elClassName} ref={this.handleOuterRef} >
                <div className="input__inner">
                    {label ? <label className="input__label" htmlFor={id}>{label}</label> : null}
                    <input
                        {...resetProps}
                        inputMode="none"
                        autoFocus={initiallyFocused}
                        type="text"
                        onFocus={this.handleFocus.bind(this)}
                        onBlur={this.handleBlur.bind(this)}
                        onKeyUp={this.handleKeyPress.bind(this)}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseOut={this.handleMouseOut}
                    />
                    {showClear && !readOnly ?
                        <div className="input__icons">
                            <Button layout={["ghost"]} size="xs" icon={"close"} className="input__field-close" onClick={this.handleClear} />
                        </div>
                        : null}
                    {hasErrors && this.state.showErrorMessage && this.outerContainerRef ? createErrorMessage(this.state.errors ?? [], this.outerContainerRef, "bottom") : null}
                </div>
            </div>
        )
    }

}
