import { ReactNode } from "react"
import { lighten, styled } from "@mui/material"

export type MessageBubbleProps = {
    headline?: string
    subline?: string | ReactNode
    left?: boolean
    children?: ReactNode | ReactNode[]
    attachment?: ReactNode | ReactNode[]
    dateTime?: string
}

const MessageContainer = styled("div")<MessageBubbleProps>(({ left, theme }) => ({
    margin: `${theme.spacing(2)} 20px`,
    alignSelf: left ? "start" : "end",
    minWidth: "250px",
    maxWidth: "49%",
    wordBreak: "break-word",
    flexShrink: 0,
    backgroundColor: left ? "#fafafa" : lighten(theme.palette.primary.main, 0.8),
    position: "relative",
    borderRadius: left ? "0 3px 3px 3px" : "3px 0 3px 3px",
    padding: 0,
    filter: "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.13))",

    "&:after": {
        left: "unset",
        right: "-15px",
        ...(left && {
            left: "-15px",
            right: "unset",
        }),
        content: "''",
        position: "absolute",
        display: "block",
        width: "0",
        zIndex: 1,
        borderStyle: "solid",
        borderWidth: left ? "0 15px 17px 0" : "15px 15px 0px 0px",
        borderColor: `${left ? "transparent #fafafa" : `${lighten(theme.palette.primary.main, 0.8)} transparent`} transparent transparent`,
        top: "0",
    },
}))

const Content = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 2),
}))

const AttachmentWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 2),
}))

const Headline = styled("div")(({ theme }) => ({
    padding: theme.spacing(1, 2, 0, 2),
    fontSize: "18px",
}))

const Subline = styled("div")(({ theme }) => ({
    borderTop: "1px solid #c2c2c2",
    fontSize: "12px",
    padding: theme.spacing(0.5, 2),
}))

const DateTime = styled("div")<{ left?: boolean }>(({ left, theme }) => ({
    position: "absolute",
    fontSize: "9px",
    right: 0,
    padding: theme.spacing(0, 1),
    transform: "translateY(5px)",
    ...(left && {
        right: "unset",
    }),
}))

export const MessageBubble = (props: MessageBubbleProps) => {
    return (
        <>
            <MessageContainer {...props}>
                {props.headline && <Headline>{props.headline}</Headline>}
                <Content>{props.children}</Content>
                {props.attachment && <AttachmentWrapper>{props.attachment}</AttachmentWrapper>}
                {props.subline && <Subline>{props.subline}</Subline>}
                <DateTime {...props}>{props.dateTime}</DateTime>
            </MessageContainer>
        </>
    )
}
