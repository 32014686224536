import { UIAHtmlAttribut, UITypes } from "../types"

const SearchtreeContainer: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtree",
}

const SearchtreeGroup: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtree.group",
}

const SearchtreeNode: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtree.node",
}

const SearchtreeIcon: UIAHtmlAttribut = {
    "data-type": UITypes.Icon,
    "data-id": "searchtree.icon",
}

const SearchtreeLabel: UIAHtmlAttribut = {
    "data-type": UITypes.label,
    "data-id": "searchtree.label",
}

const SearchtreeBreadcrumbs: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtree.breadcrumbs",
}

const SearchtreeBreadcrumbsOther: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtree.breadcrumbs.Other",
}

const SearchtreeBestseller: UIAHtmlAttribut = {
    "data-type": UITypes.Container,
    "data-id": "searchtree.bestseller",
}

export const Searchtree = {
    SearchtreeContainer,
    SearchtreeGroup,
    SearchtreeNode,
    SearchtreeIcon,
    SearchtreeLabel,
    SearchtreeBreadcrumbs,
    SearchtreeBreadcrumbsOther,
    SearchtreeBestseller,
}
