import { Box, Collapse, LinearProgress, Stack, styled } from "@mui/material"
import { memo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useDefaultArticleItemState } from "./ContextProviderStates/DefaultArticleItemState"
import { Icon } from "../../generics/Icons"
import { Tooltip } from "../../generics/tooltip"
import { IconButton } from "../../generics/button"
import { Tab, TabPanel, Tabs } from "../Tabs"
import { Typography } from "../../generics/typographie"

const Container = styled(Box)({
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    padding: "10px 5px 5px 20px",
    marginLeft: 16,
})

export const RecommendedArticle = memo(() => {
    const { translateText } = useLocalization()
    const [activeNavIdentifier, setActiveNavIdentifier] = useState<string>("CustomersAlsoBought")
    const recommendedArticles = useDefaultArticleItemState((x) => x.recommendedArticles)

    if (!recommendedArticles) {
        return null
    }

    const { enabled, isLoading, onClose, ListComponent } = recommendedArticles

    if (!enabled) {
        return null
    }

    function handleNavSelect(_: unknown, nextNavIdentifier: string) {
        setActiveNavIdentifier(nextNavIdentifier)
    }

    function renderTabButtons() {
        return (
            <Stack direction="row" justifyContent="space-between" sx={{ marginTop: "-4px" }}>
                <Tabs size="small" value={activeNavIdentifier} onChange={handleNavSelect} sx={{ "& .MuiTabs-indicator": { borderRadius: "3px" } }}>
                    <Tab sx={{ borderRadius: "3px" }} value="CustomersAlsoBought" label={translateText(13838)} />
                </Tabs>
                <Tooltip title={translateText(70)}>
                    <IconButton onClick={onClose} sx={{ padding: "0 0.5em" }}>
                        <Icon name="close" />
                    </IconButton>
                </Tooltip>
            </Stack>
        )
    }

    function renderTabContent() {
        return (
            <TabPanel value="CustomersAlsoBought" index={activeNavIdentifier}>
                <Stack>
                    <Typography ml={0.5} mb={1} variant="body2">
                        {translateText(13837)}
                    </Typography>
                    <ListComponent listId="recommended-articles" listType="recommended-articles" />
                    <Icon name="poweredByTM" width="180px" sx={{ alignSelf: "end", opacity: "0.5", marginTop: "-6px" }} />
                </Stack>
            </TabPanel>
        )
    }

    return (
        <Collapse in={enabled}>
            <Container>
                <LinearProgress sx={{ visibility: isLoading ? "visible" : "hidden" }} />
                {renderTabButtons()}
                {renderTabContent()}
            </Container>
        </Collapse>
    )
})
