import { Box, styled, Typography } from "@mui/material"
import { TreeNode as TreeNodeModel } from "@tm/models"
import { getUIA } from "@tm/utils"
import { TreeNode } from "./TreeNode"

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isAnonymous" })<AnonymousProps>(({ isAnonymous }) => ({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    paddingBottom: !isAnonymous ? 0 : "16px",
}))

const ParentNode = styled(Box, { shouldForwardProp: (prop) => prop !== "isAnonymous" && prop !== "isFirstInChunk" })<AnonymousProps>(
    ({ isAnonymous, isFirstInChunk }) => ({
        paddingBottom: !isAnonymous ? "6px" : "12px",
        paddingTop: isAnonymous && !isFirstInChunk ? "18px" : 0,
    })
)

const Headline = styled(Typography, { shouldForwardProp: (prop) => prop !== "isAnonymous" })<AnonymousProps>(({ theme, isAnonymous }) => ({
    fontSize: "18px",
    ...(isAnonymous && {
        fontWeight: 600,
        color: theme.overwrites?.components?.searchTree?.treeCategory?.headerColor,
        opacity: 1,
    }),
}))

type AnonymousProps = {
    isAnonymous?: boolean
    isFirstInChunk?: boolean
}

type Props = {
    category: TreeNodeModel
    onNodeClick: (node: TreeNodeModel) => void
    hideSecondLevel?: boolean
    nodeVariant?: "fastClick" | "default"
    anonymousProps?: AnonymousProps
}

export function TreeCategory(props: Props) {
    const {
        hideSecondLevel,
        category,
        category: { childNodes = [] },
        anonymousProps,
    } = props

    const displayHeadlines = !hideSecondLevel && props.nodeVariant !== "fastClick"

    return (
        <Wrapper isAnonymous={anonymousProps?.isAnonymous}>
            <ParentNode isAnonymous={anonymousProps?.isAnonymous} isFirstInChunk={anonymousProps?.isFirstInChunk}>
                {displayHeadlines ? (
                    <Headline isAnonymous={anonymousProps?.isAnonymous} variant="h3">
                        {category.name}
                    </Headline>
                ) : (
                    <TreeNode key={category.id} node={category} onClick={props.onNodeClick} nodeVariant={props.nodeVariant} />
                )}
            </ParentNode>
            {!hideSecondLevel && (
                <Box display="flex" ml="5px" flexDirection="column" {...getUIA("SearchtreeV2TreeNodeContainer", category.id)}>
                    {childNodes.map((node) => {
                        return <TreeNode key={node.id} node={node} onClick={props.onNodeClick} nodeVariant={props.nodeVariant} />
                    })}
                </Box>
            )}
        </Wrapper>
    )
}
