import { Memo } from "./shared"
import { DataSupplier, ProductGroup, Price } from "./parts"
import { VoucherReference } from "./vouchers"

export type ShowAvailabilityRequest = {
    vehicleReferenceId?: string
    items: Array<LookupItem>
}

export type ShowAvailabilityResponse = {
    items: Array<StockItem>
}

export type LookupItem = {
    id: string
    referenceId: string

    wholesalerArticleId: string
    dataSupplierArticleId: string

    description: string

    dataSupplier: DataSupplier
    productGroup: ProductGroup

    quantity: number
}

export type StockItem = {
    id: string
    referenceId: string

    wholesalerArticleId: string
    dataSupplierArticleId: string

    description: string

    dataSupplier: DataSupplier
    productGroup: ProductGroup

    quantity: Quantity
    availability: Availability

    prices: Array<Price>
    warehouses: Array<Warehouse>

    icons: Array<Icon>
    memos: Array<Memo>

    vouchers: Array<VoucherReference>

    invoiced: boolean
}

export type Quantity = {
    description: string
    value?: number

    quantityUnit: string
    packagingUnit: string

    quantityPackingUnit?: number

    minQuantity?: number
    maxQuantity?: number

    availability: Availability
    expectedDeliveryTime?: Date

    division: number

    confirmedValue?: number
    requestedValue: number
}

export enum Availability {
    Undefined = 0,
    Unknown = 1,
    Available = 2,
    PartiallyAvailable = 3,
    NotAvailable = 4,
    AvailableInAlternativeWarehouse = 5,
    PartiallyAvailableInAlternativeWarehouse = 6,
    AvailableInCentralWarehouse = 7,
    PartiallyAvailableInCentralWarehouse = 8,
    NotYetDefined = 9,
    NoLongerAvailable = 10,
}

export type Warehouse = {
    id: string
    name: string
    description: string
    quantities: Array<Quantity>
}

export type Icon = {
    topmotiveId: string
    description: string
    url: string
    linkUrl: string
    useIconFromUrl: boolean
}
