import type { ModuleTab } from "@tm/models"

export { useModuleNavigation } from "./useModuleNavigation"
export { useInitModuleNavigation } from "./useInitModuleNavigation"

export function getNextUrlComparable(pathname: string) {
    const fragments = pathname.split("/")
    if (fragments.length <= 2) {
        return null
    }

    let basePath = fragments.slice(0, 3).join("/")

    // TODO: PLEASE REFACTOR, JRE 03.11.2022 Hacky way to get the correct basepath even if it must be a 3-fragments one
    const threeFragsBasePath = ["sd", "td", "tdw", "gpi", "repair-times", "damage-calculation", "ext", "truck"]
    if (fragments.length > 3 && threeFragsBasePath.indexOf(fragments[2].toLowerCase()) !== -1) {
        basePath = fragments.slice(0, 4).join("/")
    }

    return basePath
}

export function isModalUrl(url: string) {
    return decodeURIComponent(url).indexOf("?(1)=") !== -1
}

export function isSameTab(a: ModuleTab, b: ModuleTab): boolean {
    return a.key && b.key ? a.key === b.key : a.title === b.title && isModalUrl(a.url) === isModalUrl(b.url)
}

