import {
    Box,
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Stack,
    SxProps,
    Theme,
    Typography,
    styled,
} from "@mui/material"
import { useLocalization } from "@tm/localization"
import { ManufacturerBase, SelectedLookupType, Vehicle, VehicleLookupConfig, VehicleType } from "@tm/models"
import { Dispatch, ReactNode, SetStateAction } from "react"
import { Icon } from "../../generics/Icons"
import { LinkButton } from "../../generics/button"
import { CountrySelection } from "./CountrySelection"
import { TopManufacturerCards } from "./TopManufacturerCards"
import { VehicleSelection } from "./VehicleSelection"

const Accordion = styled(MuiAccordion)(() => ({
    borderRadius: "3px",
    boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    backgroundColor: "#fbfdff",
}))

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<Icon name="down" size="24px" />} {...props} />)(() => ({
    "& .MuiAccordionSummary-content": {
        alignItems: "center",
        "&.Mui-expanded": {
            margin: "12px 0",
        },
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "-30px",
}))

type VehicleSearchProps = {
    children: ReactNode
    selectedSearchConfig?: VehicleLookupConfig
    onSelectSearchOption: (config: VehicleLookupConfig) => void
    onOpen?: () => void
    sx?: SxProps<Theme>
    variant?: "outlined" | "standard" | "filled"
    tooltipTitle?: string
    className?: string
    selectedVehicle?: Vehicle
    useSelectedVehicleLookup: (vehicleType: VehicleType) => {
        selectedVehicleLookup: SelectedLookupType
        setSelectedVehicleLookup(selectedLookupType: SelectedLookupType): void
        selectableWithoutDuplicates: Array<VehicleLookupConfig>
    }
    flagPath: string
    onSelectManufacturer(manufacturer: ManufacturerBase): void
    useTopManufacturers: () => {
        topManufacturers: Array<ManufacturerBase>
    }
    hideManufacturerLogos?: boolean
    to: string
    vehicleType: VehicleType
    setVehicleType: Dispatch<SetStateAction<VehicleType>>
}

export const VehicleSearch = (props: VehicleSearchProps) => {
    const {
        selectedSearchConfig,
        onSelectSearchOption,
        onOpen,
        sx,
        variant,
        tooltipTitle,
        className,
        selectedVehicle,
        useSelectedVehicleLookup,
        flagPath,
        onSelectManufacturer,
        useTopManufacturers,
        hideManufacturerLogos,
        to,
        vehicleType,
        setVehicleType,
    } = props

    const { translate, translateText } = useLocalization()

    return (
        <Accordion>
            <AccordionSummary>
                <Icon name="car-front" size="20px" sx={{ margin: "0 8px 0 0" }} />
                <Typography variant="h4">{translateText(99)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack>
                    <VehicleSelection vehicleType={vehicleType} setVehicleType={setVehicleType} />
                    <Box margin="7px 0 13.8px 0">
                        <CountrySelection
                            vehicleType={vehicleType}
                            selectedSearchConfig={selectedSearchConfig}
                            onSelectSearchOption={onSelectSearchOption}
                            onOpen={onOpen}
                            sx={sx}
                            variant={variant}
                            tooltipTitle={tooltipTitle}
                            className={className}
                            selectedVehicle={selectedVehicle}
                            useSelectedVehicleLookup={useSelectedVehicleLookup}
                            flagPath={flagPath}
                        />
                        {props.children}
                    </Box>
                    <Box display="flex" alignItems="center">
                        <TopManufacturerCards
                            onSelect={onSelectManufacturer}
                            useTopManufacturers={useTopManufacturers}
                            hideManufacturerLogos={hideManufacturerLogos}
                        />
                        <LinkButton sx={{ width: "63px", height: "32px" }} to={to}>
                            {translate(869)}
                        </LinkButton>
                    </Box>
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}
