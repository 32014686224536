import { styled } from "@mui/material"
import { useEffect, useState } from "react"
import { Icon } from "../../generics/Icons"
import { Image } from "../../generics/image"

type Props = {
    iconName?: string
    imageUrl?: string
    width?: string
    height?: string
}

const DEFAULT_ICON_SIZE = "18px" // Should match of the "Description" height

const AvailabilityImage = styled(Image)({
    display: "block",
    padding: "1px",
    objectFit: "contain",
})

const AvailabilityIcon = Icon

export function AvailabilitySymbol({ imageUrl, iconName, width = DEFAULT_ICON_SIZE, height = DEFAULT_ICON_SIZE }: Props) {
    const [imageError, setImageError] = useState(false)

    useEffect(() => {
        setImageError(false)
    }, [imageUrl])

    function handleImageError() {
        setImageError(true)
    }

    if (imageUrl && !imageError) {
        return <AvailabilityImage src={imageUrl} onError={handleImageError} width={width} height={height} />
    }

    if (iconName) {
        return <AvailabilityIcon name={iconName} width={width} height={height} />
    }

    return null
}
