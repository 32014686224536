import { CategoryType, TmaEModule } from "@tm/models"
import { getCategoryTypeFromUrl } from "../../helper/category"
import { getCatalogOrigin } from "../../helper/origin"

export function determineOrigin() {
    const origin = getCatalogOrigin()
    const isKeywordSearch = document.location.search.toLowerCase().includes("query")
    const category = getCategoryTypeFromUrl(document.location.pathname)
    const eventOrigin = isKeywordSearch ?
        determineKeywordSearchOriginFromDashboard(category, TmaEModule.DASHBOARD === origin) :
        determineSearchTreeTmaModule(origin)
    return eventOrigin
}

export function determineSearchTreeTmaModule(eventStartOrigin: TmaEModule, mode: "fast-click" | "fast-click-2.0" | "buttons" | "hide" = "fast-click-2.0") {
    let eModule = TmaEModule.UNDEFINED
    if (TmaEModule.ARTICLE_LIST === eventStartOrigin) {
        switch (mode) {
            case "fast-click": {
                eModule = TmaEModule.FAST_CLICK_ARTICLE_LIST
                break
            }
            case "fast-click-2.0": {
                eModule = TmaEModule.FAST_CLICK_V2_ARTICLE_LIST
            }
            default: {

            }
        }
    } else if (TmaEModule.DASHBOARD === eventStartOrigin) {
        switch (mode) {
            case "fast-click": {
                eModule = TmaEModule.FAST_CLICK_DASHBOARD
                break
            }
            case "fast-click-2.0": {
                eModule = TmaEModule.FAST_CLICK_V2_DASHBOARD
            }

            default: {

            }
        }
    } else if (TmaEModule.PREMIUMUNIPARTSSEARCH === eventStartOrigin || TmaEModule.PREMIUMUNIPARTSSEARCH_ARTICLE_LIST === eventStartOrigin) {
        eModule = determineUniSearchTreeTmaModule(eventStartOrigin)
    }

    if (eModule === TmaEModule.UNDEFINED) {
        eModule = eventStartOrigin
    }

    return eModule
}

export function determineUniSearchTreeTmaModule(eventStartOrigin: TmaEModule, mode: "fast-click" | "fast-click-2.0" | "buttons" | "hide" = "fast-click-2.0") {
    let eModule = TmaEModule.UNDEFINED
    if (TmaEModule.PREMIUMUNIPARTSSEARCH === eventStartOrigin || TmaEModule.PREMIUMUNIPARTSSEARCH_ARTICLE_LIST === eventStartOrigin) {
        switch (mode) {
            case "fast-click": {
                eModule = TmaEModule.FAST_CLICK_UNIPARTSSEARCH
                break
            }
            case "fast-click-2.0": {
                eModule = TmaEModule.FAST_CLICK_V2_UNIPARTSSEARCH_ARTICLE_LIST
            }
            default: {

            }
        }
    } else if (TmaEModule.DASHBOARD === eventStartOrigin) {
        switch (mode) {
            case "fast-click": {
                eModule = TmaEModule.FAST_CLICK_DASHBOARD
                break
            }
            case "fast-click-2.0": {
                eModule = TmaEModule.FAST_CLICK_V2_UNIPARTSSEARCH_DASHBOARD
            }

            default: {

            }
        }
    } else if (TmaEModule.START_PAGE === eventStartOrigin) {
        eModule = TmaEModule.PREMIUMUNIPARTSSEARCH_START_PAGE
    }

    if (eModule === TmaEModule.UNDEFINED) {
        eModule = eventStartOrigin
    }

    return eModule
}


export function determineKeywordSearchOrigin(category: CategoryType, fromWidget: boolean = false) {
    const origin = getCatalogOrigin()
    let eventOrigin = TmaEModule.UNDEFINED
    if (origin === TmaEModule.START_PAGE && (category === "directSearch" || category === "universalParts")) {
        eventOrigin = category === "directSearch" ? TmaEModule.ARTICLE_DIRECT_SEARCH_START_PAGE : TmaEModule.PREMIUMUNIPARTSSEARCH_START_PAGE
    }
    else {
        eventOrigin = determineKeywordSearchOriginFromDashboard(category, fromWidget)
    }

    return eventOrigin
}

/**
 * get the origin for a keywordsearch from dashboard or (uni-)articlelist
 * @param category active category like uni parts direct
 * @param fromWidget called from a widget?
 * @returns event origin
 */
export function determineKeywordSearchOriginFromDashboard(category: CategoryType, fromWidget: boolean = false): TmaEModule {
    let origin = TmaEModule.UNDEFINED

    switch (category) {
        case "vehicleParts": {
            origin = fromWidget ? TmaEModule.PRODUCT_GROUP_KEYWORD_SEARCH_DASHBOARD : TmaEModule.PRODUCT_GROUP_KEYWORD_SEARCH_ARTICLE_LIST
            break
        }
        case "directSearch": {
            origin = fromWidget ? TmaEModule.ARTICLE_DIRECT_SEARCH_DASHBOARD : TmaEModule.ARTICLE_DIRECT_SEARCH_ARTICLE_LIST
            break
        }
        case "universalParts": {
            origin = fromWidget ? TmaEModule.PREMIUMUNIPARTSSEARCH_DASHBOARD : TmaEModule.PREMIUMUNIPARTSSEARCH_ARTICLE_LIST
            break
        }
    }

    if (origin === TmaEModule.UNDEFINED) { console.warn(`TMA determineKeywordSearchOrigin could not resolve category >> ${category}`) }

    return origin
}
