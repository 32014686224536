import { useMemo } from "react"

export function useSessionStorage<T>(key: string, data?: T): { sessionStorageData: T | undefined; setSessionStorageData(data: T): void } {
    function setSessionStorageData(data: T) {
        data && sessionStorage.setItem(key, JSON.stringify(data))
    }

    if (!sessionStorage[key] && data) {
        setSessionStorageData(data)
    }

    const sessionStorageData: T | undefined = useMemo(() => {
        return sessionStorage[key] ? JSON.parse(sessionStorage[key]) : undefined
    }, [sessionStorage[key]])

    return { sessionStorageData, setSessionStorageData }
}
