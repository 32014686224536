import { ArticleAction } from "@tm/models"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { Tooltip } from "../../../generics/tooltip"
import { IconAction } from "./IconAction"
import { sendTmaEvent } from "./ActionsTmaHelper"

type ButtonActionProps = {
    action: ArticleAction
    hideText?: boolean
}

export function ButtonAction({ action, hideText }: ButtonActionProps) {
    const { tooltip, text, handler, icon, isHighlight, id } = action
    const handleClickEventWithTma = () => {
        handler()
        sendTmaEvent(id)
    }

    let content = (
        <>
            {!hideText ? (
                <Button
                    onClick={handleClickEventWithTma}
                    startIcon={<Icon name={icon} color={isHighlight ? "primary" : undefined} />}
                    size="small"
                    variant="outlined"
                    color={isHighlight ? "primary" : undefined}
                >
                    {text}
                </Button>
            ) : (
                <IconAction key={action.id} action={action} />
            )}
        </>
    )

    if (tooltip) {
        content = <Tooltip title={tooltip}>{content}</Tooltip>
    }

    return content
}
