import { deleteResponseCacheEntry, ResponseCache } from "./response-cache"

function startGarbageCollection() {
    const gcIntervalMS = window.gcIntervalMS ?? (window.gcIntervalMS = 900) // default interval is every 900 ms

    const interval = window.setInterval(() => {
        const now = Date.now()
        const responseKeys = Object.keys(ResponseCache)

        for (let i = 0; i < responseKeys.length; i++) {
            const entry = ResponseCache[responseKeys[i] as any]

            // If cache entry is finished and expired
            if (entry.finished && now > (entry.expires ?? 0)) {
                deleteResponseCacheEntry(responseKeys[i] as any)
            }
        }

        // Check the interval was changed using the browser dev tools
        if (gcIntervalMS != window.gcIntervalMS) {
            window.clearInterval(interval)
            startGarbageCollection()
        }
    }, gcIntervalMS)
}

startGarbageCollection()
