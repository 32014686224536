import { FunctionComponent, memo } from "react"
import { style, keyframes } from "typestyle";

const SkeletonKeyFrame = keyframes({
    "0%": {
        opacity: 1,
    },
    "50%": {
        opacity: 0.4,
    },
    "100%": {
        opacity: 1,
    }
})

const SkeletonStyles = style({
    display: "block",
    backgroundColor: "rgba(0, 0, 0, 0.11)",

    $nest: {
        "&:empty::before": {
            content: "'\\00a0'",
        },

        "&.pulse": {
            animationName: SkeletonKeyFrame,
            animationDuration: '1.5s',
            animationTimingFunction: 'ease-in-out',
            animationIterationCount: 'infinite'
        },
        "&.text": {
            height: 'auto',
            transform: 'scale(1, 0.60)',
            marginTop: 0,
            borderRadius: '4px',
            marginBottom: 0,
            transformOrigin: '0 60%',
        },
        "&.circle": {
            borderRadius: '50%',
        }
    }
})

interface IProps {
    variant?: 'text' | 'circle' | 'rect',
    height?: string,
    width?: string
}

export const Skeleton: FunctionComponent<IProps> = memo(({ children, variant, width, height }) => (
    <span className={`${SkeletonStyles} pulse ${variant || 'text'}`}
          style={{ width: width || 'inherit', height: height || 'inherit' }}>
        {children}
    </span>
))