import * as React from "react"
import { ButtonKeyDefinition } from "@tm/utils";
import { DecimalAmountItem } from "./decimal-amount-field";

type Props = DecimalAmountItem & {
    inputRef: React.RefObject<HTMLInputElement>
    onChange?(item: DecimalAmountItem): void
}

export default function DecimalAmountFieldInput(props: Props) {
    const { maxAmount, minAmount, value, inputPrecision, unit, inputRef } = props

    const [showValue, setShowValue] = React.useState<string>(value ? value.toString() : "")
    const [dropdownNavigationActive, setDropdownNavigationActive] = React.useState<boolean>(false)

    React.useEffect(() => {
        setShowValue(value.toString())
    }, [value])

    function convertToDisplayValue(): string {
        const values = showValue.toString().split('.')
        if (values.length > 1) {
            const precisionValue = values.last()
            const integerValues = values.first()

            if (!(integerValues && precisionValue)) {
                return showValue
            }

            return `${integerValues},${precisionValue}`
        } else {
            return showValue
        }
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.target

        const isNumber = !Number.isNaN(value)
        const inBoundary = validateBoundary(value)

        if (!isNumber) {
            setShowValue(minAmount.toString())
            return
        }

        if (!inBoundary) {
            setShowValue(parseFloat(value.replace(",", ".")) > maxAmount ? maxAmount.toString() : minAmount.toString())
            return
        }

        const noEmptyValue = value === "" ? "0" : value

        const validDecimalRegex = /^(\d+[,\\.]$)/
        if (typeof (noEmptyValue) == "string" && validDecimalRegex.exec(noEmptyValue)) {
            setShowValue(value)
            return
        }

        let convertedValue = convertToValidDecimal(value)
        const fixedPrecision = fixPrecision(convertedValue)
        const showValue = adjustMinMaxValue(fixedPrecision).toString()
        setShowValue(showValue)
    }

    function validateBoundary(value: string): boolean {
        const currentValue = parseFloat(value)
        if (currentValue > maxAmount) {
            return false
        }

        if (currentValue < minAmount) {
            return false
        }

        if (isNaN(currentValue)) {
            return false
        }

        return true
    }

    function fixPrecision(value: string): string {
        return (parseFloat(value).toFixed(inputPrecision || 2)).toString();
    }

    function convertToValidDecimal(value: string): string {
        const hasPrecision = /(\d(?:.\d{3})?),(\d+)$/
        return value.replace(hasPrecision, (value, integerGroup, precisionGroup) => { return `${integerGroup}.${precisionGroup}` })
    }

    function adjustMinMaxValue(value: string | number) {
        let fitValue = typeof (value) == "string" ? parseFloat(value) : value

        if (fitValue > maxAmount) {
            fitValue = maxAmount
        }

        if (fitValue < minAmount) {
            fitValue = minAmount
        }

        return fitValue
    }

    function handleSubmitValue() {
        let inputValue = showValue

        if (!inputValue) return

        inputValue = inputValue.replace(",", ".")
        const submitValue = parseFloat(inputValue) || value

        props.onChange && props.onChange({ value: submitValue, unit, inputPrecision, maxAmount, minAmount })
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.key) {
            case ButtonKeyDefinition.Enter: {
                if (dropdownNavigationActive) {
                    setDropdownNavigationActive(false)
                } else {
                    handleBubbling(e)
                    handleSubmitValue()
                }
                break
            }
            case ButtonKeyDefinition.ArrowDown:
            case ButtonKeyDefinition.ArrowDown: {
                setDropdownNavigationActive(true)
                break;
            }

            case ButtonKeyDefinition.Tab: {
                handleSubmitValue()
                break;
            }

            default: { }
        }
    }

    function handleBubbling(e: React.SyntheticEvent<HTMLInputElement>) {
        e.stopPropagation()
        e.preventDefault()
        e.bubbles = false
    }

    let className = "amount-field__input input amount-field--decimal"

    if (unit) {
        className += "amount-field--unit"
    }

    return (
        <div className={className} >
            <div className="input__inner">
                <input
                    type={"text"}
                    ref={inputRef}
                    value={convertToDisplayValue()}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    onClick={handleBubbling}
                />
            </div>
        </div>
    )
}
