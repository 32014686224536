import { EventInterruptionHandler, EventListenerEvents, ListenerItem } from "../models"

export class IsTheSameElementClickInterruption extends EventInterruptionHandler {
    ev = "outsideClick"

    process = (ev: EventListenerEvents, initiallyClickedElement: Element, sourceElement: Element, eventListeners: ListenerItem[], e: Event) => {
        // console.log(`i check if an registered outsideclick element is clicked ${ev} ${sourceElement.textContent}`)
        // console.log(e.target ,e.currentTarget,`${(e.srcElement as HTMLDivElement).textContent} was bubbled? ${e.BUBBLING_PHASE} -  ${sourceElement.textContent}`, e)
        if ((e as any).target == sourceElement) {
            // console.log(`interrupting everything ${ev}`)
            return true
        }

        return false
    }
}
