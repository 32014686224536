import { buttonClasses, Menu, menuItemClasses, SxProps, useTheme, lighten } from "@mui/material"
import { HistoryVehicle, UserContext } from "@tm/models"
import { useRef, useState } from "react"
import { Button } from "../../generics/button"
import HistoryItem from "./HistoryItem"

export type VehicleHistoryProps = {
    vehicle: HistoryVehicle
    vehicleHistory: HistoryVehicle[]
    handleSelect(vehicleId: string): void
    userContext?: UserContext
    displayCurrentVehicle: boolean
}

export const VehicleHistory = (props: VehicleHistoryProps) => {
    const { vehicle, vehicleHistory, handleSelect, userContext, displayCurrentVehicle } = props
    if (!vehicle || !vehicleHistory || !vehicleHistory.length) {
        return null
    }

    const [open, setOpen] = useState(false)
    const filteredList = vehicleHistory.filter((x) => x.id !== vehicle.id).orderBy((x) => x.modifiedDate || new Date(0), true)

    if (displayCurrentVehicle) {
        filteredList.unshift(vehicle)
    }

    const buttonRef = useRef<HTMLButtonElement>(null)

    const theme = useTheme()

    const sxButton: SxProps = {
        padding: "7px",
        borderRadius: `0 ${theme.radius?.default || "3px"} ${theme.radius?.default || "3px"} 0`,
        lineHeight: 1,
        minWidth: "auto",
        border: `2px solid ${theme.colors?.highlight}`,
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        [`&.${buttonClasses.outlined}:hover`]: {
            boxShadow: "1px 1px 3px rgb(0 0 0 / 20%)",
            border: `2px solid ${theme.colors?.highlight}`,
            background: lighten(theme.colors?.highlight || "#fff", 0.92),
        },
    }

    const sxMenu: SxProps = {
        border: `1px solid ${theme.colors?.highlight}`,
        [`.${menuItemClasses.root}`]: {
            fontSize: "0.9em",
        },
    }

    return (
        <>
            <Button variant="outlined" sx={sxButton} ref={buttonRef} onClick={() => setOpen(true)}>
                +{filteredList.length}
            </Button>
            <Menu
                open={open}
                PaperProps={{ sx: sxMenu }}
                onClose={() => setOpen(false)}
                anchorEl={buttonRef.current}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                {filteredList.map((vehicle) => (
                    <HistoryItem key={vehicle.id} vehicle={vehicle} onClick={handleSelect} userContext={userContext} />
                ))}
            </Menu>
        </>
    )
}
