export function parseIfStringifiedData<T>(data: T): T | undefined {
    if (typeof data === "string" && /^\{.*\}$/.exec(data)) {
        try {
            return JSON.parse(data)
        } catch (ex) {
            console.warn("Postmessage API tried to parse: ", data, ex)
            return
        }
    }

    return data
}
