import { useLayoutEffect, useState } from "react"
import { BoxSize } from "."

export function useWindowSize(): BoxSize {
    const [size, setSize] = useState<BoxSize>([0, 0])
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener("resize", updateSize)
        updateSize()
        return () => window.removeEventListener("resize", updateSize)
    }, [])
    return size
}
