export type AllowedFileSelectionTypes = Array<keyof typeof fileTypeMapping>

export const fileTypeMapping = {
    jpg: { accept: [".jpg", ".jpeg"], mime: ["image/jpeg"] },
    png: { accept: [".png"], mime: ["image/png"] },
    gif: { accept: [".gif"], mime: ["image/gif"] },
    pdf: { accept: [".pdf"], mime: ["application/pdf"] },
    audioMP3: { accept: [".mp3"], mime: ["audio/mpeg"] },
    audioOgg: { accept: [".ogg"], mime: ["audio/ogg"] },
    audioWAV: { accept: [".wav"], mime: ["audio/wav"] },
    videoMP4: { accept: [".mp4"], mime: ["video/mp4"] },
    videoWebM: { accept: [".webm"], mime: ["video/webm"] },
    videoOgg: { accept: [".ogg"], mime: ["video/ogg"] },
    textTxt: { accept: [".txt"], mime: ["text/plain"] },
    textCsv: { accept: [".csv"], mime: ["text/csv"] },
    blobBin: { accept: [".bin"], mime: ["application/octet-stream"] },
}

export const getAcceptedFileTypes = (allowedFileTypes: AllowedFileSelectionTypes) => {
    return allowedFileTypes.map((type) => [...fileTypeMapping[type].accept]).join(", ")
}
