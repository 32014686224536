export function registerScrollBottomHandler(handler: (e: Event) => void): () => void {
    function handleWindowScroll(e: Event) {
        const { documentElement } = document
        if (documentElement && documentElement.scrollTop + documentElement.clientHeight >= documentElement.scrollHeight - 10) {
            handler(e)
        }
    }

    window.addEventListener("scroll", handleWindowScroll)
    return () => window.removeEventListener("scroll", handleWindowScroll)
}
