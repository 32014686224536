import { forwardRef, useCallback, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { styled } from "@mui/material"
import { Button, ButtonProps } from "../../../generics/button"
import { Tooltip } from "../../../generics/tooltip"
import { Icon } from "../../../generics/Icons"

type CostEstimationProps = ButtonProps & {
    highlightCostEstimationButton?: boolean
    disabled?: boolean
    onAddToCostEstimation(): void
}

const CostEstimationButtonComponent = styled(Button)(({ theme }) => ({
    ".MuiButton-startIcon>svg.icon-add-repair-times": {
        width: 30,
    },
    ".MuiButton-startIcon>svg.icon-add-repair-times-international": {
        width: 30,
    },
    ...(theme.name === "create-business" && {
        ".MuiButton-startIcon>svg.icon-add-repair-times": {
            width: 36,
        },
        ".MuiButton-startIcon>svg.icon-add-repair-times-international": {
            width: 36,
        },
    }),
}))

export const CostEstimationButton = forwardRef<HTMLButtonElement, CostEstimationProps>((props, ref) => {
    const { onAddToCostEstimation, highlightCostEstimationButton, disabled, title } = props
    const { translateText } = useLocalization()

    const handleAddToCostEstimation = useCallback(() => onAddToCostEstimation(), [onAddToCostEstimation])

    const costEstimationButtonProps = useMemo<Partial<ButtonProps>>(
        () => ({
            ...(!!highlightCostEstimationButton && {
                color: "highlight",
                sx: { width: 60 },
            }),
        }),
        [highlightCostEstimationButton]
    )

    return (
        <Tooltip title={translateText(917)}>
            <CostEstimationButtonComponent
                ref={ref}
                className="costEstimationButton"
                disabled={disabled}
                onClick={handleAddToCostEstimation}
                variant="bordered"
                startIcon={<Icon name="add-repair-times" />}
                {...costEstimationButtonProps}
                title={title}
            />
        </Tooltip>
    )
})
