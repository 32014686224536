import { useQuery } from "react-query"
import { Container } from "@tm/nexus"
import { RegisteredModels, UrlResolverContainer, UserContext } from "@tm/models"
import { ExternalCatalogUrlParams, ExternalCatalogUrlResult } from "./models"

function externalCatalogUrlRequest(request: ExternalCatalogUrlParams, userContext: UserContext) {
    if (!request.externalSystemId) {
        return undefined
    }

    const container = Container.getInstance(RegisteredModels.UrlResolver) as UrlResolverContainer
    return container
        .action("getExternalCatalogUrl")(request.externalSystemId, request.telesalesCustomerNo, userContext)
        .catch(() => undefined)
}

/**
 * get an external catalog url, there is a config param called "enabledExternalCatalogCacheUrlReset" to control if a module should cache the url for 60mins
 * @param request ExternalCatalogUrlParams
 * @param externalCatalogUrlCachingDuration [optional] number - used to control if the catalog url should be cached and for how long (in seconds)
 * @returns ExternalCatalogUrlResult
 */
export function useExternalCatalogUrl(
    request: ExternalCatalogUrlParams,
    externalCatalogUrlCachingDuration?: number,
    allowRequest?: () => boolean
): ExternalCatalogUrlResult {
    const { userContext } = window
    let staleTime: number | undefined

    if (externalCatalogUrlCachingDuration !== undefined) {
        if (externalCatalogUrlCachingDuration === -1) {
            staleTime = Infinity
        } else {
            staleTime = externalCatalogUrlCachingDuration * 1000
        }
    }

    const result = useQuery(
        ["authority_externalCatalogUrlSelector", request, userContext],
        () => {
            return externalCatalogUrlRequest(request, userContext)
        },
        {
            staleTime,
            enabled: allowRequest ? allowRequest() : true,
        }
    )

    if (result.isSuccess) {
        return { loadingExternalCatalogUrl: false, externalCatalogUrl: result.data }
    }

    return { loadingExternalCatalogUrl: result.isFetching }
}
