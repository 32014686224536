import { ConfigParams, RepairTimeProviderConfig, RepairTimesProvidersNames } from "@tm/models"
import Morpheus from "@tm/morpheus"

export function getRepairTimeProviders() {
    const repairTimeProvidersFromConfig = Morpheus.getParams<ConfigParams>().repairTimeProviders
    let repairTimeProviders: RepairTimeProviderConfig[] = []
    let repairTimeProviderIds: RepairTimesProvidersNames[] = []
    if (repairTimeProvidersFromConfig !== undefined) {
        repairTimeProviders = Object.keys(repairTimeProvidersFromConfig || {})
            .map((key) => ({ ...repairTimeProvidersFromConfig[key] }))
            .filter((x) => !!x && !!x.id)

        repairTimeProviderIds = repairTimeProviders.map((x) => x.id)
    }

    return { repairTimeProviders, repairTimeProviderIds }
}
