import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { ButtonProps } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { ShareButton } from "./ShareButton"

type Props = Omit<ButtonProps, "href"> & {
    /**
     * The phone number that should be used as recipient of the email.
     */
    recipient?: string

    /**
     * The message that should be sent (should contain the link).
     *
     * When set to `undefined` the button is disabled.
     */
    message: string | undefined
}

export function WhatsAppButton({ recipient, message, ...buttonProps }: Props) {
    const { translate, language } = useLocalization()

    const href = useMemo(() => {
        if (message) {
            return `https://api.whatsapp.com/send?${recipient ? `phone=${recipient}&` : ""}text=${encodeURIComponent(message)}&lang=${language}`
        }
    }, [recipient, message, language])

    return (
        <ShareButton
            target="_blank"
            startIcon={<Icon name="whatsapp" color="green" />}
            disabled={!href}
            {...buttonProps}
            href={href}
            data-action="share/whatsapp/share"
        >
            {translate(12784)}
        </ShareButton>
    )
}
