import { SyntheticEvent } from "react"
import { IconButton } from "./IconButton"
import { Icon } from "../../generics/Icons"

type Props = {
    copyToClipboard: () => void
}

export function ArticleNumberCopyButton(props: Props) {
    return (
        <IconButton
            onClick={(e: SyntheticEvent) => {
                e.stopPropagation()
                props.copyToClipboard()
            }}
        >
            <Icon name="copy" />
        </IconButton>
    )
}
