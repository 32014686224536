import { Box, ClickAwayListener, Fade, Paper, Popper, Stack } from "@mui/material"
import { useLocalization } from "@tm/localization"
import { Article, ArticleAction, ArticleInfoGroup, EMediaType } from "@tm/models"
import { useCallback, useRef, useState } from "react"
import { Button } from "../../../generics/button"
import { Icon } from "../../../generics/Icons"
import { ButtonAction } from "./ButtonAction"

type Props = {
    article: Article
}

export function AdditionalInformation({ article }: Props) {
    const { translateText } = useLocalization()
    const [showPopper, setShowPopper] = useState(false)
    const buttonRef = useRef<HTMLButtonElement>(null)

    const handleClick = useCallback(() => {
        setShowPopper((state) => !state)
    }, [])

    const articleAction: ArticleAction = {
        handler: handleClick,
        icon: "trader-icon",
        text: translateText(526),
        id: "additional-information",
        area: "CENTER_BUTTONS",
    }

    function getDocumentButton(articleInfoGroup: ArticleInfoGroup) {
        if (!articleInfoGroup.member) {
            return null
        }

        return articleInfoGroup.member.map((link) => (
            <Button
                key={link.description}
                size="small"
                variant="outlined"
                value={link.description}
                startIcon={<Icon name={getDocumentTypeIcon(link.mediaType)} />}
                href={link.content}
                target="_blank"
            />
        ))
    }

    function getDocumentTypeIcon(mediaType: EMediaType) {
        switch (mediaType) {
            case EMediaType.Video:
            case EMediaType.Audio:
                return "arrow-right"
            case EMediaType.Pdf:
                return "file-pdf"
            case EMediaType.Website:
            case EMediaType.YoutubeChannel:
                return "partslink24"
            default:
                break
        }
    }

    return (
        <ClickAwayListener onClickAway={() => setShowPopper(false)}>
            <Box>
                <Box ref={buttonRef}>
                    <ButtonAction key="additional-information" action={articleAction} />
                </Box>

                <Popper open={showPopper} anchorEl={buttonRef.current} placement="bottom" transition sx={{ zIndex: 20 }}>
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper
                                sx={{ px: 1, py: 1, maxHeight: 200, minWidth: 150, maxWidth: 200, borderRadius: 1, overflow: "auto" }}
                                elevation={4}
                            >
                                <Stack gap={1}>{article.additionalReferenceArticleInformation.links.map((link) => getDocumentButton(link))}</Stack>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </Box>
        </ClickAwayListener>
    )
}
