import { CachingOptions } from "@tm/models"

import { CacheKey, AjaxRequest } from "."
import { parseTimespan } from "../../date"
import { logEntry } from "./caching-counter"
import { RequestCache } from "./request-cache"

export type CacheEntry = {
    expires?: number
    request: Promise<unknown>
    response?: unknown
    finished: boolean
    ttl: number
}

export const ResponseCache: Record<CacheKey, CacheEntry> = {}

export function handleResponseCaching<Res>(
    doAjaxRequest: AjaxRequest<Res>,
    key: CacheKey,
    cachingOptions: CachingOptions,
    requestUrl: string
): Promise<Res> {
    let cacheEntry = ResponseCache[key]

    // Cache entry exists and doesn't expire or isn't expired yet
    if (cacheEntry && (!cacheEntry.expires || cacheEntry.expires > Date.now())) {
        logEntry(key, requestUrl, cacheEntry)

        if (cacheEntry.finished) {
            return Promise.resolve(cacheEntry.response) as Promise<Res>
        }
    } else {
        cacheEntry = getCacheEntry(doAjaxRequest, key, cachingOptions)
    }

    return cacheEntry.request as Promise<Res>
}

function onResponse(key: CacheKey, response: unknown) {
    if ((response as any)?.error) {
        deleteRequestCacheEntry(key)
        return
    }

    finishedRequest(key, response)
}

function deleteRequestCacheEntry(key: CacheKey) {
    delete RequestCache[key]
}

export function deleteResponseCacheEntry(key: CacheKey) {
    delete ResponseCache[key]
}

function finishedRequest(key: CacheKey, response: unknown) {
    const entry = ResponseCache[key]

    if (entry) {
        entry.response = response
        entry.finished = true
        entry.expires = Date.now() + entry.ttl
    }
}

function createCacheEntry(cachingOptions: CachingOptions, request: Promise<unknown>): CacheEntry {
    const ttl = parseTimespan(cachingOptions.ttl)

    return {
        request,
        finished: false,
        ttl,
    }
}

function getCacheEntry(doAjaxRequest: AjaxRequest, key: CacheKey, cachingOptions: CachingOptions): CacheEntry {
    deleteRequestCacheEntry(key)

    const request = doAjaxRequest()
    request.catch(() => deleteRequestCacheEntry(key))
    request.then((response) => onResponse(key, response))

    return (ResponseCache[key] = createCacheEntry(cachingOptions, request))
}
