
import { ArticleAttributes } from "./article";

export type OePosition = OeNumber & {
    replacements?: Array<OeNumber>
}

export type OePart = {
    id?: string // A unqiue GUID identifying this part
    thumbnailUrl?: string
    number: string
    traderNumber?: string
    description: string
    manufacturerId?: number
    manufacturerName?: string
    manufacturerThumbnail?: string
    prices?: Array<OePartPrice>
    quantity?: number
    additionalDescription?: string
    attributes?: Array<ArticleAttributes>
    productGroupId?: number
    fittingPosition?: number
    oeArticleOrigin?: OeArticleOrigin
}

export type OeArticleOrigin = {
    provider: OeArticleProvider
    module: OeArticleModule
}


export enum OeArticleProvider {
    Topmotive,
    DAT,
    Eurotax
}

export enum OeArticleModule {
    Undefined,
    RepairEstimation,
    GraphicalPartsIdentification,
    GlassRepairEstimation
}

export type OePartPrice = {
    description?: string
    value: number
    currency: string
}

export type OeNumber = {
    number: string
    description?: string
    additionalDescription?: string
    parts?: Array<OePart>
    isSelected?: boolean
}

export type OePartRD = {
    oeNumber?: string
    description?: string
    additionalDescription?: string
    oeReplacements?: string[]
    currency?: string
    price?: number
    isSelected?: boolean
    quantity?: number
}

export type OECrit = {
    ownerId: number
    ownerNr: string
    genartNr: number
    critNr: number
    critValue: string
    lkz: string
    exclude: boolean
    prio: number
}