import { Component } from "react"
import Week from "./Week";
import { DateUnit } from "@tm/utils";

export default class Month extends Component<MonthProps, any> {
    render() {
        return (
            <div className="calendar__month">
                {this.renderWeeks()}
            </div>
        );
    }

    private isWeekInMonth(startOfWeek: Date): boolean {
        const { day } = this.props;
        const endOfWeek = startOfWeek.add(6, DateUnit.Day);
        return startOfWeek.isSame(day, DateUnit.Month) || endOfWeek.isSame(day, DateUnit.Month);
    }

    private renderWeeks(): JSX.Element[] {
        const { day, onDayClick, selected, minDate, maxDate } = this.props;
        const startOfMonth = day.startOf(DateUnit.Month).startOf(DateUnit.Week);

        return [0, 1, 2, 3, 4, 5]
            .map((offset) => startOfMonth.add(offset, DateUnit.Week))
            .filter((startOfWeek) => this.isWeekInMonth(startOfWeek))
            .map((startOfWeek, offset) =>
                <Week
                    key={offset}
                    day={startOfWeek}
                    month={day.month()}
                    onDayClick={onDayClick}
                    selected={selected}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            );
    }
}

export interface MonthProps {
    day: Date;
    selected?: Date;
    minDate?: Date;
    maxDate?: Date;
    onDayClick(date: Date): void;
}
