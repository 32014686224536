import { ContainerAction, IMergable, IModelContainer, IModelSubscription, SubscriptionEvents } from "../../models"
import { ModelSubscriptionFactory } from "../factories/ModelSubscriptionFactory"
import { SubscriptionsHolder } from "../subscriptions/subscription-holder"
import { IRegisteredContainerBundle, RegisteredContainerBundle } from "./container-bundle"

export interface PromiseExecuter<T> { resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void }
export interface ActionResolver { args?: unknown, params?: Array<any>, promiseExecutor: PromiseExecuter<any> }

export class UnregisteredContainerBundle<SModel> implements IModelContainer<SModel, ContainerAction>, IMergable<IRegisteredContainerBundle<SModel>, IRegisteredContainerBundle<SModel>> {

    private subscriptions: SubscriptionsHolder<SModel>;
    public loadRequests: { [key: string]: Array<PromiseExecuter<SModel>> };
    public delayedActionRequests: { [key: string]: Array<ActionResolver> }
    private listeners: { [type: string]: Array<(identifier: any[], object: any) => void> }
    public isRegistered: boolean = false

    constructor() {
        this.subscriptions = ModelSubscriptionFactory.createSubscriptionHolder<SModel>()
        this.loadRequests = {}
        this.delayedActionRequests = {}
        this.listeners = {}
    }

    /**
     * subscribe on an entity
     * @param identifier parameter which are needed to identify the result e.g.: sgs.get<VehicleRecord>().subscribe("7861ada7-6b1e-4546-8cf1-1422bd49c0cd", 5600)
     * @returns ISubscriptionContract<SModel>, a contract which handles listeners, save and load the entity
     */
    public subscribe = (...identifier: any[]): IModelSubscription<SModel> => {
        const contract = this.subscriptions.create(this.load, () => { }, ...identifier) // save contract
        Object.keys(this.listeners).forEach(event => {
            this.listeners[event].forEach(listener => {
                const hookListener = (object: any) => listener(contract.identifier, object)
                hookListener.isHook = true
                contract.addListener(event as SubscriptionEvents, hookListener)
            })
        })
        return contract
    }

    public addListener(event: SubscriptionEvents, listener: (identifier: any[], object: any) => void) {
        if (!this.listeners[event]) {
            this.listeners[event] = []
        }
        Object.keys(this.subscriptions.contracts).forEach(key => {
            const contract = this.subscriptions.contracts[key]
            const hookListener = (object: any) => listener(contract.identifier, object)
            hookListener.isHook = true
            contract.addListener(event, hookListener)
        })
        this.listeners[event].push(listener)
    }

    merge(bundle: RegisteredContainerBundle<SModel>): IRegisteredContainerBundle<SModel> {
        const { subscriptions, listeners, delayedActionRequests } = this
        return bundle.merge({
            subscriptions,
            listeners,
            pendingActionRequests: delayedActionRequests,
        })
    }

    /**
     * will be triggered when no container is registered
     * need to resolve the promise outside of this container,
     * so i need to remember the resolve and reject
     */
    load(contractId: string) {
        return new Promise<SModel>((resolve, reject) => {
            this.loadRequests = this.loadRequests || {} // it's initialized by the constructor, but somehow there are still undefined errors. so lets try this.
            this.loadRequests[contractId] = this.loadRequests[contractId] || []
            this.loadRequests[contractId].push({ resolve, reject });
        })
    }

    callAction<TResponse>(name: string, ...params: Array<any>): Promise<TResponse> {
        this.delayedActionRequests[name] = this.delayedActionRequests[name] || []

        const promise = new Promise<TResponse>((resolve, reject) => {
            this.delayedActionRequests[name].push({ params, promiseExecutor: { resolve, reject } })
        })

        return promise
    }

    action(name: any): any {
        return (...args: Array<unknown>) => this.callAction(name, ...args)
    }

}
